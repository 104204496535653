import OnboardingStepContainer from "../components/OnboardingStepContainer";

import { useEffect, useState } from "react";

import FButton from "@components/FButton";

import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

import { updateProfile } from "@api/Profile";
import { Profile } from "@types";

import { isEmpty } from "lodash";
import useAuth from "@hooks/useAuth";
import { getCreator } from "@api/Creators/Creators";
import PersonalInfoForm from "./components/PersonalInfoForm";
import moment from "moment";

const AddPersonalInfo = () => {
  const { brand } = useParams<{ brand: string }>();

  const [values, setValues] = useState<Partial<Profile>>({
    first_name: "",
    last_name: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    gender: [],
    ethnicity: [],
    birthday: moment().format("YYYY-MM-DD"),
  });

  const [image, setImage] = useState<{ file: File; url: string }>(null);
  const [loading, setLoading] = useState(false);

  const [isSubmitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      return;
    }

    const fetchCreatorData = async () => {
      const response = await getCreator(user.creator_id);

      setValues(response.data);
    };

    fetchCreatorData();
  }, [user]);

  const handleSave = async () => {
    const { birthday, ...rest } = values;

    if (Object.keys(rest).find((key) => !values[key] || isEmpty(values[key]))) {
      setSubmitted(true);

      return;
    }

    setLoading(true);

    try {
      await updateProfile({
        avatar: image?.file,
        birthday,
        email: "mihovil@fohr.co",
        ...rest,
      });

      toast.success("Changes saved");

      const destination = brand
        ? `/${brand}/ambassador-onboarding/confirm-details`
        : "/signup/influencer/confirm-details";

      navigate(destination);
    } catch (e) {
      console.error("E", e);
      toast.error("Error updating creator", e.response?.data);
    } finally {
      setLoading(false);
    }
  };

  console.log("Values", values);

  return (
    <OnboardingStepContainer
      title={brand ? null : "Personal Info"}
      description="Add your personal info"
      currentStep={2}
      isBrand={!!brand}
      stepsCount={3}
    >
      <PersonalInfoForm
        values={values}
        onUpdate={setValues}
        image={image}
        onUpdateImage={setImage}
        isSubmitted={isSubmitted}
      />
      <FButton
        loading={loading}
        height="48px"
        width="100%"
        primary
        label="Continue"
        onClick={handleSave}
      />
    </OnboardingStepContainer>
  );
};

export default AddPersonalInfo;
