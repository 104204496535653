import { Toaster as ToasterBase } from "react-hot-toast";

export default function Toaster() {
  return (
    <ToasterBase
      toastOptions={{
        duration: 2000,
        success: {
          iconTheme: {
            primary: "#47B37F",
            secondary: "#fff",
          },
        },
        error: {
          iconTheme: {
            primary: "#E47667",
            secondary: "#fff",
          },
        },
      }}
    />
  );
}
