import React from "react";
import PropTypes from "prop-types";

export default function ImageWithPlaceholder({
  image,
  placeholderType,
  classes,
}) {
  let url;
  if (image) {
    url = image;
  } else if (placeholderType === "influencer") {
    // These do not work. We needto change these to be props so the url
    // with the asset pipeline link will work.
    url =
      "https://cdn-d35wth38dfye85.fohr.co/card/cards/profile_images/000/217/944/original/user-icon-new.jpeg";
  } else {
    url = "/assets/rebrand/image_placeholder.jpg";
  }
  return <div className={classes} style={{ backgroundImage: `url(${url})` }} />;
}

ImageWithPlaceholder.defaultProps = {
  image: null,
  classes: "",
};

ImageWithPlaceholder.propTypes = {
  image: PropTypes.string,
  placeholderType: PropTypes.string.isRequired,
  classes: PropTypes.string,
};
