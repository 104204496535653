import Table from "@components/Table";
import { FollowerGrowthDailyStats } from "@types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { sortBy } from "lodash";
import moment from "moment";

const TABLE_COLUMNS = [
  {
    key: "date",
    label: "Month",
    getValue: (row) => moment(row.date).format("MMM YY'"),
  },
  {
    key: "followers_avg",
    label: "Followers",
    getValue: (row) => (row.followers_avg || "").toLocaleString(),
  },
];

const OPTIONS = {
  xAxis: [
    {
      type: "datetime",
      labels: {
        formatter: function () {
          return moment(this.value).format("MMM");
        },
      },
      startOnTick: true,
      endOnTick: true,
    },
  ],
};

interface FollowersGrowthRateProps {
  data: FollowerGrowthDailyStats[];
  isEngagement?: boolean;
}

const FollowersGrowthRate = ({ data }: FollowersGrowthRateProps) => {
  const dataSortedByTime = sortBy(data, ({ date }) => moment(date).valueOf());

  const options = {
    ...OPTIONS,
    series: [
      {
        data: dataSortedByTime.map(({ date, followers_avg }) => [
          moment(date).valueOf(),
          followers_avg,
        ]),
        name: "Followers average",
        type: "line",
        marker: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <div>
      <p className="mb-8">Follower Growth Rate</p>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div className="mt-9" />
      <Table
        columns={TABLE_COLUMNS}
        data={[...dataSortedByTime].reverse()}
      ></Table>
    </div>
  );
};

export default FollowersGrowthRate;
