export const formatDateMessage = (date, withDayOfWeek = true) => {
  const dayArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (withDayOfWeek) {
    return `${dayArr[date.getDay()]}, ${monthArr[date.getMonth()]} ${date.getDate()}`;
  } else {
    return `${monthArr[date.getMonth()]} ${date.getDate()}`;
  }
};
