import React, { useContext } from "react";

import { OutreachContext } from "@contexts/index";

import Table from "@components/Table";

const tableColumns = [
  {
    key: "full_name",
    label: "Influencers",
    isLink: true,
    linkURL: "/card/{slug}",
    isImage: true,
    imageKey: "profile_image_url",
    imageType: "profile" as const,
  },
  { key: "group_name", label: "Group" },
  { key: "delivered", label: "Delivered", isBoolean: true },
  { key: "opens", label: "Opens" },
  { key: "clicks", label: "Clicks" },
];

const OutreachRecipients = () => {
  const { outreach, selectedRecipientIds, setSelectedRecipientIds } =
    useContext(OutreachContext);

  const { recipients } = outreach;

  const isReadOnly = null && !!outreach.sent;

  const recipientsWithCreators = recipients.map((recipient) => {
    const { creator } = recipient;

    const { id, ...rest } = creator;

    return {
      ...recipient,
      ...rest,
      creator_id: id,
    };
  });

  return (
    <div className="py-4">
      <Table
        data={recipientsWithCreators}
        columns={tableColumns}
        selectedItems={isReadOnly ? null : selectedRecipientIds}
        onChangeSelectedItems={isReadOnly ? null : setSelectedRecipientIds}
      />
    </div>
  );
};

export default OutreachRecipients;
