import React from "react";
import RichTextEditor from "@components/RichTextEditor";

export default function BriefGuidelines({ brief, onUpdate, containerRef }) {
  const { sections } = brief;

  const doSection = sections.find(({ kind }) => kind === "do");
  const dontSection = sections.find(({ kind }) => kind === "dont");

  const handleUpdateSection = (sectionId, updates) => {
    const newSections = sections.map((section) =>
      section.id === sectionId ? { ...section, ...updates } : section
    );

    onUpdate({ sections: newSections });
  };

  return (
    <div ref={containerRef}>
      <div className="mb-10 pt-10 border-t border-light_border">
        <div className="mb-6">
          <h3 className="text-xl mb-2">Do's</h3>
          <p className="text-neutral_600 text-[15px]">
            What should your creators explicitly keep in mind while they
            participate?
          </p>
        </div>
        <div className="w-full bg-white">
          <RichTextEditor
            id="do"
            value={doSection?.body || ""}
            onChange={(value) =>
              handleUpdateSection(doSection.id, { body: value })
            }
          />
        </div>
      </div>
      <div className="mt-10">
        <div className="mb-6">
          <h3 className="text-xl mb-2">Don'ts</h3>
          <p className="text-neutral_600 text-[15px]">
            What should your creators explicitly keep in mind while they
            participate?
          </p>
        </div>
        <div className="w-full bg-white">
          <RichTextEditor
            id="dont"
            value={dontSection?.body || ""}
            onChange={(value) =>
              handleUpdateSection(dontSection.id, { body: value })
            }
          />
        </div>
      </div>
    </div>
  );
}
