import ModalSection from "./ModalSection";

import { AdvancedSearchParams } from "@pages/search/types";
import FRangeInput from "@components/Form/FRangeInput";

interface TotalReachCountProps {
  filters: Partial<AdvancedSearchParams>;
  onFiltersUpdate: (filters: Partial<AdvancedSearchParams>) => void;
}

const TotalReachCount = ({
  filters,
  onFiltersUpdate,
}: TotalReachCountProps) => {
  const { custom_followers: followers } = filters;

  return (
    <ModalSection icon="user" title="Total Reach Count">
      <div className="flex items-start mt-8 gap-8 justify-between w-full">
        <FRangeInput
          minValue={0}
          label="Audience"
          value={{
            min: parseInt(followers?.min, 10),
            max: parseInt(followers?.max, 10),
          }}
          onChange={(value) =>
            onFiltersUpdate({
              custom_followers: { min: `${value.min}`, max: `${value.max}` },
            })
          }
        />
      </div>
    </ModalSection>
  );
};

export default TotalReachCount;
