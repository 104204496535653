import React, { useEffect, useState } from "react";

import { AmbassadorQuestion, FieldType } from "types/ambassador-question";

import {
  getAmbassadorQuestionsWithChildren,
  listAmbassadorQuestions,
} from "@api/Ambassadors/AmbassadorQuestions";
import toast from "react-hot-toast";

import { useNavigate, useParams } from "react-router-dom";
import Question from "./components/Question";
import FButton from "@components/FButton";
import { isEmpty } from "lodash";

import { createAmbassadorResponse } from "@api/Ambassadors/AmbassadorResponses";
import SocialMediaConnections from "@pages/auth/influencer-onboarding/SocialMediaConnections";
import { getBrandBySlugForAmbassadorOnboarding } from "@api/brands";
import { Brand, SocialMedialPlatform } from "@types";

const isQuestionAnswered = (
  question: AmbassadorQuestion,
  answer: string | string[]
) => {
  const { field_type } = question;

  return field_type === FieldType.MULTI_SELECT
    ? !isEmpty(answer as string[])
    : answer;
};

const areAllQuestionsAnswered = (
  questions: AmbassadorQuestion[],
  answers: Record<string, string | string[]>
) =>
  questions.every((question) => {
    const { required } = question;

    const answer = answers[question.id];

    return !required || isQuestionAnswered(question, answer);
  });

const getAllApplicableQuestions = (
  questions: AmbassadorQuestion[],
  answers: Record<string, string | string[]>
) =>
  questions.reduce((result, question) => {
    const { children } = question;

    const answer = answers[question.id];

    const childQuestion = children && children[`${answer}`];

    const newResult = [...result, question];

    if (childQuestion) {
      newResult.push(childQuestion);
    }

    return newResult;
  }, []);

const AnswerQuestions = () => {
  const { brand: brandSlug } = useParams<{
    brand: string;
  }>();

  const [brand, setBrand] = useState<Brand>(null);
  const [questions, setQuestions] = useState<AmbassadorQuestion[]>([]);

  const [answers, setAnswers] = useState<Record<string, string | string[]>>({});

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const loadBrand = async () => {
      const brand = await getBrandBySlugForAmbassadorOnboarding(brandSlug);

      setBrand(brand);

      const questions = await listAmbassadorQuestions({
        q: {
          brand_id_eq: brand.id,
        },
      });

      setQuestions(getAmbassadorQuestionsWithChildren(questions));
    };

    loadBrand();
  }, []);

  const handleSubmit = async () => {
    setIsSubmitted(true);

    const allQuestions = getAllApplicableQuestions(questions, answers);

    if (!areAllQuestionsAnswered(allQuestions, answers)) {
      return;
    }

    setIsWaiting(true);

    toast.success("Question created");

    try {
      for (const question of questions) {
        const { id } = question;

        await createAmbassadorResponse({
          ambassador_question_id: id,
          answer: answers[id],
          brand_slug: brandSlug,
        });
      }

      toast.success("Application submitted!");

      navigate("/");
    } catch (e) {
      toast.error("Error saving changes");
    } finally {
      setIsWaiting(false);
    }
  };

  const { primary_connection = "instagram", required_connections } =
    brand || {};

  const otherConnections =
    required_connections &&
    required_connections.filter(
      (connection) => connection !== primary_connection
    );

  return (
    <div className="flex flex-col gap-8 w-full h-full">
      <div className="flex-1 h-full overflow-y-auto">
        <SocialMediaConnections
          hideContinueButton
          platforms={[primary_connection as SocialMedialPlatform]}
        />
        <p className="mb-8 text-xs text-default_weak">
          Answering these questions and connecting your account will allow Fohr
          Inc to view your content, as well as your performance metrics to help
          track the partnerships you work on. Want to learn more about how this
          information will be used, or see our privacy policy.
        </p>
        <p className="text-md mb-2">Connect additional socials (optional)</p>
        <SocialMediaConnections
          hasCompactView
          hideContinueButton
          platforms={otherConnections}
        />
        <div className="flex flex-col gap-8">
          {questions.map((question) => {
            const { children } = question;

            const answer = answers[question.id];

            const childQuestion = children && children[`${answer}`];

            return (
              <>
                <Question
                  question={question}
                  answer={answer}
                  onUpdateAnswer={(value) =>
                    setAnswers({
                      ...answers,
                      [question.id]: value,
                    })
                  }
                  validate={isSubmitted}
                  key={question.id}
                />
                {childQuestion ? (
                  <Question
                    question={childQuestion}
                    answer={answers[childQuestion.id]}
                    onUpdateAnswer={(value) =>
                      setAnswers({
                        ...answers,
                        [childQuestion.id]: value,
                      })
                    }
                    validate={isSubmitted}
                    key={childQuestion.id}
                  />
                ) : null}
              </>
            );
          })}
        </div>
      </div>
      <FButton
        loading={isWaiting}
        primary
        onClick={handleSubmit}
        label="Submit application"
        width="100%"
      />
    </div>
  );
};
export default AnswerQuestions;
