import { Sort, SortOrder } from "@types";
import { getCampaignList } from "@api/Campaign/Campaigns";
import { getCampaignParticipantContent } from "@api/Campaign/CampaignParticipantContent";
import { getCampaignParticipant } from "@api/Campaign/CampaignParticipants";
import { ICampaign, CampaignContent } from "types/types";

export type DateRange = "7" | "30" | "60" | "90";

export const dateRangeOptions = [
  { label: "Last 7 Days", value: "7" },
  { label: "Last 30 Days", value: "30" },
  { label: "Last 60 Days", value: "60" },
  { label: "Last 90 Days", value: "90" },
] as const;

export const defaultSort: Sort = {
  key: "created_at",
  direction: "desc" as SortOrder,
};

export interface TimeSeriesData {
  engagements: { date: Date; value: number }[];
  posts: { date: Date; value: number }[];
  influencers: { date: Date; value: number }[];
  impressions: { date: Date; value: number }[];
}

export interface CampaignMetrics {
  campaigns: number;
  engagementRate: number;
  impressions: number;
  posts: number;
  influencers: number;
}

export const filterByDateRange = (date: string, dateRange: string): boolean => {
  const itemDate = new Date(date);
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - parseInt(dateRange));
  return itemDate >= startDate;
};

export const calculatePostEngagement = (
  interactions: string,
  impressions: string
): number => {
  const interactionCount = parseInt(interactions) || 0;
  const impressionCount = parseInt(impressions) || 0;

  return impressionCount > 0 ? (interactionCount / impressionCount) * 100 : 0;
};

export const calculateOverallEngagement = (
  content: CampaignContent[]
): string => {
  let totalEngagement = 0;
  let validPosts = 0;

  content.forEach((item) => {
    const postEngagement = calculatePostEngagement(
      item.data.interactions,
      item.data.impressions
    );

    if (postEngagement > 0) {
      totalEngagement += postEngagement;
      validPosts++;
    }
  });

  return validPosts > 0 ? (totalEngagement / validPosts).toFixed(2) : "0";
};

export const calculateTotalImpressions = (
  content: CampaignContent[]
): number => {
  return content.reduce((sum, item) => {
    const impressions = parseInt(item.data.impressions) || 0;
    return sum + impressions;
  }, 0);
};

export const getUniqueInfluencerCount = (campaigns: ICampaign[]): number => {
  return campaigns.reduce((sum, campaign) => {
    return sum + (campaign.participants_count || 0);
  }, 0);
};

export const generateTimeSeriesPoints = (
  content: CampaignContent[],
  campaignsList: ICampaign[],
  dateRange: string
): TimeSeriesData => {
  const days = parseInt(dateRange);
  const points = Math.min(10, Math.floor(days / 7));
  const interval = Math.floor(days / points);

  const timeSeriesData: TimeSeriesData = {
    engagements: [],
    posts: [],
    influencers: [],
    impressions: [],
  };

  for (let i = 0; i <= points; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i * interval);

    const relevantContent = content.filter(
      (item) => new Date(item.published_at) <= date
    );

    timeSeriesData.engagements.push({
      date,
      value: parseFloat(calculateOverallEngagement(relevantContent)),
    });

    timeSeriesData.posts.push({
      date,
      value: relevantContent.length,
    });

    timeSeriesData.impressions.push({
      date,
      value: calculateTotalImpressions(relevantContent),
    });

    const relevantCampaigns = campaignsList.filter(
      (campaign) => new Date(campaign.created_at) <= date
    );

    timeSeriesData.influencers.push({
      date,
      value: getUniqueInfluencerCount(relevantCampaigns),
    });
  }

  // Reverse all time series data
  Object.keys(timeSeriesData).forEach((key) => {
    timeSeriesData[key] = timeSeriesData[key].reverse();
  });

  return timeSeriesData;
};

export const fetchCampaignData = async (
  currentBrandId: number,
  dateRange: string
): Promise<{
  campaigns: ICampaign[];
  content: CampaignContent[];
}> => {
  const response = await getCampaignList(1, defaultSort, false, {
    status_not_eq: "archived",
    per_page: 10000,
    brand_id: currentBrandId,
  });

  if (!response.data?.campaigns) {
    return { campaigns: [], content: [] };
  }

  const filteredCampaigns = response.data.campaigns.filter((campaign) =>
    filterByDateRange(campaign.created_at, dateRange)
  );

  const campaignsWithParticipants = filteredCampaigns.filter(
    (campaign) => (campaign.participants_count || 0) > 0
  );

  const contentPromises = campaignsWithParticipants.map((campaign) =>
    getCampaignParticipantContent(campaign.id)
  );

  const contentResponses = await Promise.all(contentPromises);
  const allContent = contentResponses.flatMap(
    (response) => response.data?.participant_contents || []
  );

  const filteredContent = allContent.filter((content) =>
    filterByDateRange(content.published_at, dateRange)
  );

  return {
    campaigns: filteredCampaigns as unknown as ICampaign[],
    content: filteredContent as unknown as CampaignContent[],
  };
};

export const getAnalyticsData = (
  metrics: CampaignMetrics,
  dateRange: string
) => {
  return {
    ...metrics,
    dateRange:
      dateRangeOptions
        .find((opt) => opt.value === dateRange)
        ?.label.toLowerCase() || "",
  };
};

export const sortMembershipsByCurrentBrand = (
  memberships: any[],
  currentBrandId: number
) => {
  return [...memberships].sort((a, b) => {
    if (a.brand_id === currentBrandId) return -1;
    if (b.brand_id === currentBrandId) return 1;
    return 0;
  });
};

interface TopPost {
  participant_id: number;
  engagement_rate: number;
  impressions: number;
  interactions: string;
  published_at: string;
  platform: string;
  type: string;
  data: any;
}

interface ParticipantContent {
  id: number;
  full_name: string;
  profile_image: string;
  platform: string;
  permalink: string;
  media_type: string;
  content_type: string;
  published_at: string;
  photo_url: string;
  stats: {
    views_count: number;
    total_interactions: number;
  };
  creator: {
    username: string;
    socials: any;
    slug: string;
  };
  social_username: string;
  data: {
    caption: string;
    comments_count: number;
  };
}

export const getTopPerformingContent = (
  content: CampaignContent[],
  maxPosts: number = 4
): TopPost[] => {
  // Create a map to store the highest engagement post for each participant
  const participantBestPosts = new Map();

  content.forEach((post) => {
    const engagementRate = calculatePostEngagement(
      post.data.interactions,
      post.data.impressions
    );

    // If this participant doesn't exist in map or has higher engagement, update it
    if (
      !participantBestPosts.has(post.participant_id) ||
      engagementRate >
        participantBestPosts.get(post.participant_id).engagement_rate
    ) {
      participantBestPosts.set(post.participant_id, {
        participant_id: post.participant_id,
        engagement_rate: engagementRate,
        impressions: parseInt(post.data.impressions) || 0,
        interactions: post.data.interactions,
        published_at: post.published_at,
        platform: post.platform,
        type: post.type,
        data: post.data,
      });
    }
  });

  // Convert map to array, sort by engagement rate and take top N posts
  return Array.from(participantBestPosts.values())
    .sort((a, b) => b.engagement_rate - a.engagement_rate)
    .slice(0, maxPosts);
};

export const fetchParticipantDetails = async (
  participantId: number
): Promise<ParticipantContent> => {
  try {
    const response = await getCampaignParticipant(participantId);
    const participant = response.data;

    if (!participant || !participant.creator) {
      console.error("Invalid participant data:", participant);
      throw new Error("Invalid participant data");
    }

    // Get the latest content from content_review.deliverables
    const latestDeliverable = participant.content_review?.deliverables?.[0];
    const latestContent = latestDeliverable?.content;
    const latestRound = latestContent?.rounds?.slice(-1)[0];
    const approvedAsset = latestRound?.assets?.find((asset) => asset.approved);

    // Get the platform-specific username
    const platformUsername =
      participant.creator.socials?.[latestDeliverable?.platform?.toLowerCase()]
        ?.username;

    return {
      id: participant.id,
      full_name: participant.creator.full_name,
      profile_image: participant.creator.profile_image_url,
      platform:
        latestDeliverable?.platform || participant.deliverables?.[0]?.platform,
      permalink: approvedAsset?.file_url,
      media_type:
        latestDeliverable?.media_type ||
        participant.deliverables?.[0]?.media_type,
      content_type:
        latestDeliverable?.kind || participant.deliverables?.[0]?.kind,
      published_at: participant.created_at,
      photo_url:
        approvedAsset?.transcoded_thumbnail || approvedAsset?.transcoded_url,
      stats: {
        views_count: parseInt(latestDeliverable?.impressions) || 0,
        total_interactions:
          participant[`${latestDeliverable?.platform}_stats`]
            ?.engagement_percentage || 0,
      },
      creator: {
        username: platformUsername,
        socials: participant.creator.socials,
        slug: participant.creator.slug,
      },
      social_username: latestContent?.social_username || platformUsername,
      data: {
        caption: latestContent?.caption || "",
        comments_count: latestContent?.comments_count || 0,
      },
    };
  } catch (error) {
    console.error("Error fetching participant details:", error);
    console.error("Failed participant ID:", participantId);
    throw error;
  }
};

export const getLatestContent = (
  content: CampaignContent[],
  maxPosts: number = 4
): TopPost[] => {
  // Create a map to store the latest post for each participant
  const participantLatestPosts = new Map();

  content.forEach((post) => {
    const postDate = new Date(post.published_at).getTime();

    // If this participant doesn't exist in map or has a more recent post, update it
    if (
      !participantLatestPosts.has(post.participant_id) ||
      postDate >
        new Date(
          participantLatestPosts.get(post.participant_id).published_at
        ).getTime()
    ) {
      participantLatestPosts.set(post.participant_id, {
        participant_id: post.participant_id,
        engagement_rate: calculatePostEngagement(
          post.data.interactions,
          post.data.impressions
        ),
        impressions: parseInt(post.data.impressions) || 0,
        interactions: post.data.interactions,
        published_at: post.published_at,
        platform: post.platform,
        type: post.type,
        data: post.data,
      });
    }
  });

  // Convert map to array, sort by date and take most recent N posts
  return Array.from(participantLatestPosts.values())
    .sort(
      (a, b) =>
        new Date(b.published_at).getTime() - new Date(a.published_at).getTime()
    )
    .slice(0, maxPosts);
};
