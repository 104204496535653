import Table from "@components/Table";
import {
  Campaign,
  CampaignContentReview,
  ContentReviewDeliverable,
} from "@types";
import { groupBy, uniq } from "lodash";
import STATUSES from "../statuses";

const TABLE_COLUMNS = [
  {
    key: "participant",
    label: "Participant",
    getValue: (deliverable: ContentReviewDeliverable) =>
      deliverable.participant.full_name,
  },
  {
    key: "platform",
    label: "",
    type: "platform",
  },
  {
    key: "submission_due_date",
    label: "Due date",
    isDate: true,
  },
  {
    key: "status",
    label: "Status",
    getValue: (item) => {
      const statusValue = item.content?.status || "-";

      const status = STATUSES.find(({ value }) => value === statusValue);

      return status.label;
    },
  },
  {
    key: "updated_at",
    label: "Modified",
    isDate: true,
  },
];

const getAllDeliverables = (contentReview: CampaignContentReview) =>
  contentReview.participants.reduce((result, item) => {
    const deliverables = item.deliverables.map((deliverable) => ({
      ...deliverable,
      participant: item,
    }));

    return [...result, ...deliverables];
  }, []);

interface DeliverablesOverviewProps {
  campaign: Campaign;
  contentReview: CampaignContentReview;
}

const DeliverablesOverview = ({ contentReview }: DeliverablesOverviewProps) => {
  const deliverables = getAllDeliverables(contentReview);

  const groupedDeliverables = groupBy(deliverables, "name");

  return (
    <div className="flex flex-col gap-8">
      {Object.keys(groupedDeliverables).map((deliverableName) => {
        const deliverables = groupedDeliverables[deliverableName];

        const participantCount = uniq(deliverables.map(({ id }) => id)).length;

        const submitted = deliverables.filter(
          (deliverable) => deliverable.content.status !== "not_submitted"
        );

        console.log("del", deliverables);

        return (
          <div className="border rounded-lg" key={deliverableName}>
            <div className="border-b flex items-center p-7">
              <div className="flex items-center">
                <div
                  className={`bg-center bg-cover w-[46px] h-[46px] mr-3 rounded-full`}
                  style={
                    {
                      // backgroundImage: `url(${profile_image_url})`,
                    }
                  }
                />
                <div>
                  <p className="text-lg">{deliverableName}</p>
                  <p className="text-default_weak">
                    {participantCount} Participant
                    {participantCount === 1 ? "" : "s"} | {submitted.length}{" "}
                    Submitted
                  </p>
                </div>
              </div>
            </div>
            <div className="p-4">
              <Table columns={TABLE_COLUMNS} data={deliverables} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DeliverablesOverview;
