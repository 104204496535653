import React, { ReactNode } from "react";
import FButton from "@components/FButton";
import FIcon from "@components/FIcon";
import ModalContainer, { ModalContainerProps } from "./ModalContainer";

interface ModalProps extends ModalContainerProps {
  title: string;
  titleRight?: ReactNode;
  subtitle?: string;
  onAction?: () => void;
  actionLabel?: string;
  hasForm?: boolean;
  waiting?: boolean;
  className?: string;
  width?: number;
}

const Modal: React.FC<ModalProps> = ({
  title,
  subtitle,
  isOpen,
  onClose,
  onAction,
  actionLabel,
  children,
  titleRight,
  className,
  zIndex = 9000,
  hasForm = false,
  waiting,
  width,
}) => {
  return (
    <ModalContainer isOpen={isOpen} zIndex={zIndex} onClose={onClose}>
      <div
        className={`bg-light_red rounded-lg shadow-2xl p-10 pb-5 ${className && className.includes("w-[") ? "" : `w-[${width || 540}px]`} relative ${className}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between mb-6 items-center align-middle">
          <h2 className="text-[1.5rem] font-medium">{title}</h2>
          {titleRight && (
            <div className="relative flex items-center">{titleRight}</div>
          )}
        </div>
        {subtitle && <p className="text-sm my-6">{subtitle}</p>}
        <div className="flex flex-col w-full">{children}</div>
        {!hasForm && (
          <div className="flex justify-end space-x-4 mt-4">
            <FButton
              onClick={onClose}
              label="Cancel"
              width="100%"
              height="40px"
            />
            <FButton
              onClick={onAction}
              label={actionLabel}
              loading={waiting}
              width="100%"
              height="40px"
              primary
              type="submit"
            />
          </div>
        )}
        <div
          className="absolute top-3 right-4 cursor-pointer opacity-100"
          onClick={onClose}
        >
          <FIcon size={18} color="#000F45" icon="exit-2" />
        </div>
      </div>
    </ModalContainer>
  );
};

export default Modal;
