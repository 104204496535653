import React, { useEffect, useState } from "react";

import RichTextEditor from "@components/RichTextEditor";
import FInput from "@components/FInputs/FInput";
import FButton from "@components/FButton";

import { CampaignDeliverable } from "@types";
import FDatePicker from "@components/FDatePicker/FDatePicker";
import moment from "moment";
import { FCheckbox } from "@components/FInputs";
import FRangePicker from "@components/FDatePicker/FRangePicker";

interface EventDeliverableProps {
  onSubmit: (values: any) => void;
  onClose: () => void;
  deliverable: CampaignDeliverable;
  isOther?: boolean;
  waiting: boolean;
}

const EventDeliverable = ({
  onSubmit,
  deliverable,
  isOther,
  onClose,
  waiting,
}: EventDeliverableProps) => {
  const [values, setValues] = useState<CampaignDeliverable>({
    deliverable_start: moment().format(),
    deliverable_end: moment().format(),
    location: "",
    forecasted_date: moment().format(),
    notes: "",
    position: 0,
    estimated_flat_cost: 0,
    overwrite: false,
    ...(isOther
      ? { hashtags: "", mentions: "", tagged_users: "", geotags: "" }
      : {}),
  });

  useEffect(() => {
    if (deliverable) {
      setValues({ ...values, ...deliverable });
    }
  }, []);

  const handleUpdate = (updates) => setValues({ ...values, ...updates });

  return (
    <div className="max-h-[60vh] overflow-y-auto">
      <div className="space-y-4">
        <FInput
          type="text"
          onChange={(value) => handleUpdate({ name: value })}
          label="Name"
          value={values.name}
          width="100%"
        />
        <FRangePicker
          minDate={moment().format()}
          value={{
            from: values.deliverable_start,
            to: values.deliverable_end,
          }}
          onChange={({ from, to }) =>
            handleUpdate({
              deliverable_start: from,
              deliverable_end: to,
            })
          }
        />
        <FDatePicker
          disabled={{
            before: new Date(),
          }}
          value={values.forecasted_date}
          onSelectedValue={(value) => handleUpdate({ forecasted_date: value })}
          label="Deliverable Forecast Date"
        />
        <FInput
          type="text"
          label="Location"
          value={values.location}
          onChange={(value) => handleUpdate({ location: value })}
          width="100%"
        />
        <div className="bg-white rounded-lg">
          <RichTextEditor
            value={values.notes}
            id="notes"
            onChange={(value) => handleUpdate({ notes: value })}
          />
        </div>
        <FInput
          value={`${values.position}`}
          onChange={(value) => handleUpdate({ position: parseInt(value, 10) })}
          type="number"
          label="Position"
          width="100%"
        />
        <FInput
          value={`${values.estimated_flat_cost}`}
          onChange={(value) =>
            handleUpdate({ estimated_flat_cost: parseInt(value, 10) })
          }
          type="number"
          label="Estimated Flat Cost"
          width="100%"
        />
        {isOther ? (
          <>
            <FInput
              value={values.hashtags}
              onChange={(value) => handleUpdate({ hashtags: value })}
              type="text"
              label="Hashtags"
              width="100%"
            />
            <FInput
              value={values.mentions}
              onChange={(value) => handleUpdate({ mentions: value })}
              type="text"
              label="Mentions"
              width="100%"
            />
            <FInput
              value={values.tagged_users}
              onChange={(value) => handleUpdate({ taggedUsers: value })}
              type="text"
              label="Tagged Users"
              width="100%"
            />
            <FInput
              value={values.geotags}
              onChange={(value) => handleUpdate({ geotags: value })}
              type="text"
              label="Geotags"
              width="100%"
            />
          </>
        ) : null}
        <div className="flex items-center">
          <FCheckbox
            className="w-full"
            label="Overwrite All Participants"
            id="checkbox1"
            onChange={(value) =>
              handleUpdate({
                overwrite: value,
              })
            }
            checked={values.overwrite}
          />
        </div>
      </div>
      <div className="flex justify-end space-x-2 mt-8 pb-3 sticky bottom-0 bg-light_red pt-4">
        <FButton onClick={onClose} label="Cancel" width="100%" height="40px" />
        <FButton
          onClick={() => onSubmit(values)}
          loading={waiting}
          label="Save"
          width="100%"
          height="40px"
          primary
          type="submit"
        />
      </div>
    </div>
  );
};

export default EventDeliverable;
