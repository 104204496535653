import FDropdown from "@components/FDropdown";
import ResultsGrid from "@components/ResultsGrid";
import {
  Campaign,
  ContentReviewContent,
  ContentReviewDeliverable,
  ParticipantContentReview,
} from "@types";
import { capitalize, uniq, uniqBy } from "lodash";
import { useState } from "react";

const getParticipantOptions = (
  participants: ParticipantContentReview[],
  campaign
) => {
  const { participants: campaignParticipants } = campaign;

  return participants.map((participant) => {
    const { fohr_campaigns_participant_id } = participant;

    const campaignParticipant = campaignParticipants.find(
      ({ id }) => id === fohr_campaigns_participant_id
    );

    const { creator } = campaignParticipant;

    const { firstName, lastName } = creator;

    return {
      value: fohr_campaigns_participant_id,
      label: `${firstName} ${lastName}`,
    };
  });
};

const getMediaTypes = (deliverables: ContentReviewDeliverable[]) => {
  const mediaTypes = deliverables.map(({ media_type }) => media_type);

  return uniq(mediaTypes).map((value) => ({
    value,
    label: capitalize(value),
  }));
};

const getRounds = (content: ContentReviewContent[]) => {
  const roundNumbers = content.reduce((result, item) => {
    const { rounds } = item;

    return [...result, ...rounds.map(({ stage }) => stage)];
  }, []);

  return uniq(roundNumbers).map((stage) => ({
    value: stage,
    label: `R${stage}`,
  }));
};

const getFilters = (content: ContentReviewContent[], campaign) => {
  const participants = content.reduce((result, { participant }) => {
    if (result.find(({ id }) => id === participant.id)) {
      return result;
    }

    return [...result, participant];
  }, []);

  const deliverables = content.reduce((result, { deliverable }) => {
    if (result.find(({ id }) => id === deliverable.id)) {
      return result;
    }

    return [...result, deliverable];
  }, []);

  return [
    {
      name: "stage",
      options: [
        {
          value: "",
          label: "All Rounds",
        },
        ...getRounds(content),
      ],
    },
    {
      name: "deliverable",
      options: [
        {
          value: "",
          label: "All Deliverables",
        },
        ...uniqBy(deliverables, "name").map(({ name }) => ({
          value: name,
          label: name,
        })),
      ],
    },
    {
      name: "participant",
      options: [
        {
          value: "",
          label: "All Participants",
        },
        ...getParticipantOptions(participants, campaign),
      ],
    },
    {
      name: "mediaType",
      options: [
        {
          value: "",
          label: "All Media Types",
        },
        ...getMediaTypes(deliverables),
      ],
    },
  ];
};

const getContentMatchingFilters = (content, selectedFilters) =>
  content.filter((item) =>
    Object.keys(selectedFilters).every((key) => {
      const value = selectedFilters[key];

      if (!value) {
        return true;
      }

      if (key === "stage") {
        return item.stage === value;
      }

      if (key === "deliverable") {
        return item.deliverable.name === value;
      }

      if (key === "participant") {
        return item.participant.fohr_campaigns_participant_id === value;
      }

      if (key === "mediaType") {
        return item?.deliverable?.media_type === value;
      }
    })
  );

interface ContentReviewListProps {
  campaign: Campaign;
  content: ContentReviewContent[];
  status: string;
  onSelectedContent: (content: ContentReviewContent) => void;
}

const ContentReviewList = ({
  campaign,
  content: allContent,
  onSelectedContent,
}: ContentReviewListProps) => {
  const [selectedFilters, setSelectedFilters] = useState({});

  const filters = getFilters(allContent, campaign);

  const content = getContentMatchingFilters(allContent, selectedFilters);

  return (
    <div>
      <div className="flex items-center gap-4 relative z-60">
        {filters.map((filter, index) => {
          const { name, options } = filter;

          return (
            <FDropdown
              key={index}
              options={options}
              onChange={(value) =>
                setSelectedFilters({
                  ...selectedFilters,
                  [name]: value,
                })
              }
              selectedValue={selectedFilters[name]}
            />
          );
        })}
      </div>
      <ResultsGrid
        cardData={content}
        cardType="contentReview"
        onCardClick={onSelectedContent}
      />
    </div>
  );
};

export default ContentReviewList;
