import React, { useEffect, useState } from "react";
import FIcon from "../FIcon";

interface Props {
  className?: string;
  file: any;
  onDelete?: (file: any) => void;
  error?: boolean;
  index: number;
}

const FFile = ({
  className = "",
  file = null,
  error = false,
  onDelete,
  index = 0,
}: Props) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const getReadableFileSize = (bytes: number) => {
    const thresh = 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let u = -1;
    const r = 10 ** 2;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(2) + " " + units[u];
  };

  useEffect(() => {
    setTimeout(
      () => {
        setIsLoaded(true);
      },
      (index + 1) * 300
    );
  });

  return (
    <div
      className={`
      ${error ? "border-red" : "border-neutral_300"} flex items-center
      transition-all duration-600 ${isLoaded ? "ml-0 opacity-100" : "ml-[-100px] opacity-0 pointer-events-none"}
      border rounded-[4px]  p-[8px] w-[271px] h-[36px] w-full max-w-[350px] ${className}
    `}
    >
      <FIcon
        icon={error ? "warning-2" : "file"}
        size={20}
        color={error ? "#E47667" : "#000F45"}
      />
      <p className="font-medium text-sm mx-[8px] truncate whitespace-nowrap">
        {file?.name}
      </p>
      <p
        className="text-xs text-neutral_600 whitespace-nowrap"
        style={{ letterSpacing: "0.12px" }}
      >
        {file?.size && getReadableFileSize(file.size)}
      </p>
      <div
        className="cursor-pointer ml-auto"
        onClick={onDelete ? () => onDelete(file) : null}
      >
        <FIcon icon="exit" size={12} color="#797E92" />
      </div>
    </div>
  );
};

export default FFile;
