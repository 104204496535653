import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import UpdateOutreachModal from "./components/UpdateOutreachModal";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import { Outreach, Sort } from "@types";
import FButton from "@components/FButton";

import apiClient from "@apiClient";

import toast from "react-hot-toast";
import useAuth from "@hooks/useAuth";
import { omit } from "lodash";

const { create, delete: deleteOutreach, get, index } = apiClient.outreach;

const filterOptions = [
  { label: "All", value: null },
  { label: "Sent", value: "sent" },
  { label: "Draft", value: "draft" },
  { label: "Archived", value: "archived" },
];

const actions = {
  delete: async (id: number) => deleteOutreach(id),
};

const tableRowDropDownOptions = [
  { label: "Duplicate", value: "duplicate" },
  { label: "Delete", value: "delete" },
];

const OutreachList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<Outreach[]>([]);

  const [isCreatingOutreach, setCreatingOutreach] = useState(false);

  const { user } = useAuth();

  const [isUpdateOutreachModalVisible, setUpdateOutreachModalVisible] =
    useState(false);

  const getOutreach = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;

      console.log("Filter", filter);

      const query = {
        title_i_cont: search,
      };

      if (filter) {
        query[`${filter}_eq`] = true;
      }
      const response = await index(false, page, query);

      const { communications: items } = response.data;

      setData(items);

      return {
        ...response.data,
        items,
      };
    },
    [setData]
  );

  const tableColumns = [
    {
      key: "title",
      label: "Title",
      isLink: true,
      linkURL: "/outreach/{id}/message",
    },
    { key: "state", label: "Status", isStateBubble: true },
    { key: "owner_name", label: "Created By" },
    { key: "brand_name", label: "Brand" },

    { key: "recipients", label: "Recipients" },
    { key: "delivered", label: "Delivered" },
    { key: "opened", label: "Opened" },
    { key: "clicks", label: "Clicks" },
    { key: "date_sent", label: "Date Sent" },
    { key: "created_at", label: "Created At" },
  ];

  const handleTableRowDropDownSelect = (value: string, id: number) => {
    if (value === "duplicate") {
      handleDuplicateOutreach(id);
    }
  };

  const handleDuplicateOutreach = async (id: number) => {
    const original = await get(id);

    const { title, recipients, ...rest } = original;

    const duplicate = {
      ...omit(rest, "id"),
      recipients_attributes: recipients.map(({ creator }) => ({
        creator_id: creator.id,
      })),
      title: `Copy of ${title}`,
    };

    const newOutreachResponse = await create(duplicate);

    toast.success("Outreach duplicated");

    navigate(`/outreach/${newOutreachResponse.data.id}/message`);
  };

  const handleCloseOutreachModal = (newCommunication: Outreach) => {
    if (newCommunication) {
      setData([newCommunication, ...data]);
    }

    setUpdateOutreachModalVisible(false);
  };

  const handleCreateOutreach = async () => {
    setCreatingOutreach(true);

    try {
      const response = await create({
        brand_id: user.brand_id,
        title: "New Outreach",
        message: {
          subject: "",
          body: "test",
          archived: false,
          user_id: user.user_id,
        },
      });

      setCreatingOutreach(false);

      toast.success("Outreach created successfully!");

      navigate(`/outreach/${response.data.id}/message`);
    } catch (error) {
      toast.error("Failed to create outreach. Please try again.");
    } finally {
      setCreatingOutreach(false);
    }
  };

  const headerButtons = (
    <div className="flex flex-row justify-end items-center gap-2">
      <FButton
        label="View Templates"
        onClick={() => navigate("/outreach/templates")}
      />
      <FButton
        primary
        iconLeft={{ name: "add", size: 14, color: "white" }}
        loading={isCreatingOutreach}
        onClick={handleCreateOutreach}
        label="Create Outreach"
      />
    </div>
  );

  return (
    <>
      <SinglePageLayoutWithData
        entityName="communcation"
        pageTitle="Outreach"
        pageName="Outreach"
        headerButtons={headerButtons}
        sortPathName="title"
        sortPathDate="dateSent"
        filterOptions={filterOptions}
        filterPath="state"
        tableColumns={tableColumns}
        rowActions={tableRowDropDownOptions}
        cardDropDownOptions={tableRowDropDownOptions}
        createWithEntityCrud={() => setUpdateOutreachModalVisible(true)}
        onTableRowDropDownSelect={handleTableRowDropDownSelect}
        tableOnly
        asyncActions={actions}
        getItems={getOutreach}
      />
      {isUpdateOutreachModalVisible ? (
        <UpdateOutreachModal onClose={() => handleCloseOutreachModal(null)} />
      ) : null}
    </>
  );
};
export default OutreachList;
