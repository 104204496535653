import OnboardingStepContainer from "../components/OnboardingStepContainer";
import SocialMediaConnections from "./SocialMediaConnections";

const ConnectSocials = () => (
  <OnboardingStepContainer
    title="Connect your socials"
    description="One connection required. The more connections, the better the insights"
    currentStep={1}
    stepsCount={3}
  >
    <SocialMediaConnections />
  </OnboardingStepContainer>
);

export default ConnectSocials;
