import React from "react";
import { FTooltip } from "../components/FTooltip"; // Assuming Tooltip is a custom component
import FIcon from "@components/FIcon";

interface ViralPosts {
  ten_reach_count: number;
  ten_post_count: number;
  five_reach_count: number;
  five_post_count: number;
  two_reach_count: number;
  two_post_count: number;
}

interface PerformanceTilesProps {
  viralPosts: ViralPosts;
}

const PerformanceTiles: React.FC<PerformanceTilesProps> = ({ viralPosts }) => {
  const formatNumber = (num: number): string => {
    return num.toLocaleString();
  };

  return (
    <div className="mr-[8px]">
      <div className="heading-8 mb-[8px]">How many posts overperformed</div>
      <div className="h-full rounded-[8px] p-[16px]">
        <div className="flex bg-[#FFF5F3] rounded-[8px] p-[16px] justify-between items-center">
          <FTooltip
            label="Number of posts that had > 10x the median reach of all content within report"
            style={{ bottom: "120%" }}
          >
            <div className="flex flex-col">
              <span className="heading-8 block mb-[4px]">
                {">"}
                10x Median Reach
              </span>
              <span className="text-[10px] text-[#797E92] flex items-center w-full">
                {">"}
                {formatNumber(viralPosts.ten_reach_count)} Reach
              </span>
            </div>
          </FTooltip>

          <div className="justify-end flex items-baseline">
            <span className="heading-3">
              {formatNumber(viralPosts.ten_post_count)}
            </span>
            <FIcon
              icon="volume-full"
              size={18}
              color="#E47667"
              className="inline-block vertical-align-middle ml-[8px]"
            />
          </div>
        </div>

        <div className="flex rounded-[8px] p-[16px] justify-between items-center">
          <FTooltip
            label="Number of posts that had > 5x the median reach of all content within report"
            style={{ bottom: "120%" }}
          >
            <div className="flex flex-col">
              <span className="heading-8 block  mb-[4px]">
                {">"}
                5x Median Reach
              </span>
              <span className="text-[10px] text-[#797E92] block flex items-center w-full">
                {">"}
                {formatNumber(viralPosts.five_reach_count)} Reach
              </span>
            </div>
          </FTooltip>

          <div className="justify-end flex items-baseline">
            <span className="heading-3">{viralPosts.five_post_count}</span>
            <FIcon
              icon="volume-medium"
              size={18}
              color="#E47667"
              className="inline-block vertical-align-middle ml-[8px]"
            />
          </div>
        </div>

        <div className="flex rounded-[8px] p-[16px] justify-between items-center">
          <FTooltip
            label="Number of posts that had > 2x the median reach of all content within report"
            style={{ bottom: "115%" }}
          >
            <div className="flex flex-col">
              <span className="heading-8 block  mb-[4px]">
                {">"}
                2x Median Reach
              </span>
              <span className="text-[10px] text-[#797E92] block flex items-center w-full">
                {">"}
                {formatNumber(viralPosts.two_reach_count)} Reach
              </span>
            </div>
          </FTooltip>

          <div className="justify-end flex items-baseline">
            <span className="heading-3">{viralPosts.two_post_count}</span>
            <FIcon
              icon="volume-small"
              size={18}
              color="#E47667"
              className="inline-block vertical-align-middle ml-[8px]"
            />
          </div>
        </div>
        <div className="text-[12px] text-[#797E92] text-center flex items-center justify-center">
          <span>Virality Coefficient</span>
          <FTooltip
            label="The virality Coefficient, or K-value, measures the effect of campaign virality based on shares vs total reach. The K-Value formula is: 1+(Total Shares / (Total Reach-Total Shares))"
            style={{ width: 200 }}
          >
            <FIcon
              icon="info"
              size={18}
              color="#B0B5C4"
              className="inline-block vertical-align-middle ml-[8px]"
            />
          </FTooltip>
        </div>
      </div>
    </div>
  );
};

export default PerformanceTiles;
