import { Pod } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { getQueryParamsForSearchRequest } from "@constants/api";
import { Sort } from "@types";

export const getPods = async (page?: number, sort?: Sort): Promise<Pod[]> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, {});

  const response = await apiWrapper(`/pods?${queryParams.toString()}`, "GET");

  return response.data;
};
