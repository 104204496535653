import React from "react";
import FIcon from "./FIcon";

interface Props {
  backgroundColor: string;
  color?: string;
  className?: string;
  width?: number | string;
  height?: number | string;
  label?: string;
  icon?: {
    name: string;
    className?: string;
    size: number;
    color: string;
    align?: string;
  };
  onClick?: () => void;
  style?: object;
}

const FBadge = ({
  backgroundColor = "#FBD45B",
  color = "#000F45",
  className = "",
  label,
  icon,
  width = "auto",
  height = 28,
  ...props
}: Props) => {
  return (
    <div style={{ ...props?.style }}>
      {
        <div
          className={` inline-flex text-[12px] items-center justify-center font-medium text-center rounded-lg px-[16px] py-[8px] ${className}`}
          style={{
            backgroundColor: backgroundColor,
            color: color,
            height: height,
            width: width,
          }}
          {...props}
        >
          {icon?.name && (
            <FIcon
              icon={icon.name}
              size={icon.size}
              color={icon.color}
              className={`mr-[4px] ${icon.className}`}
            />
          )}
          {label}
        </div>
      }
    </div>
  );
};

export default FBadge;
