import React from "react";
import FIcon from "../FIcon";

export default function CollapseButton({
  className = "",
  collapsed,
  collapsible,
  onClick,
}: {
  className?: string;
  collapsed: boolean;
  collapsible: boolean;
  onClick: (collapsed: boolean) => void;
}) {
  return (
    <div
      className={`absolute top-1/2 -translate-y-1/2 right-[-12px] bg-light_red cursor-pointer flex border border-light_border flex-row items-center h-[24px] w-[24px] text-[14px] rounded-[20px] ${className} ${
        collapsed ? "justify-center" : ""
      }`}
      onClick={() => onClick(collapsed)}
    >
      {collapsible && (
        <>
          <FIcon
            icon="chevron"
            size={12}
            className={
              collapsed ? "ml-[0px] rotate-[270deg]" : "ml-[4px] rotate-90"
            }
            color="#5C6276"
          />
        </>
      )}
    </div>
  );
}
