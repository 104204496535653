import React, { useEffect, useState, useContext } from "react";

import FDropdown from "@components/FDropdown";

import { ProfileContext } from "@contexts/index";

import { platformList } from "@constants/constants";

import { formatLargeNumber } from "@utils/number_utilities";
import { capitalize } from "@utils/string_utilities";

import LoadingSpinner from "@components/LoadingSpinner";
import toast from "react-hot-toast";
import { getStats } from "@api/Profile";
import { ProfileStats, TopStats } from "@types";
import StatCardWithGradientBar from "../components/StatCardWithGradientBar";
import SponsoredContentBreakdown from "./fragments/SponsoredContentBreakdown";
import FollowersGrowthRate from "./fragments/FollowersGrowthRate";
import EngagementGrowthRate from "./fragments/EngagementGrowthRate";
import MonthlyComparisonTable from "./fragments/MonthlyComparisonTable";
import useAuth from "@hooks/useAuth";
import ContentGuard from "../components/ContentGuard";

const getStatsForCards = (topStats: TopStats) => {
  const { engagement, engagement_rate, views } = topStats;

  return [
    {
      title: "Average Views",
      value: views?.value,
      tier_average: 100000,
    },
    {
      title: "Average engagement",
      value: engagement_rate?.value || engagement,
      tier_average: engagement_rate?.tier_average,
      isPercentage: true,
    },
  ];
};

export default function ProfileStatsPage() {
  const { profile } = useContext(ProfileContext);

  const [stats, setStats] = useState<ProfileStats>(null);

  const {
    engagement_growth: engagementGrowth = [],
    follower_growth: followerGrowth = [],
    monthly_stats,
    sponsored,
    top_stats: topStats,
  } = stats || {};

  const breakdown = monthly_stats;

  const [timePeriod, setTimePeriod] = useState(90);

  const [selectedPlatform, setSelectedPlatform] = useState<string>("");

  const [followerTier, setFollowerTier] = useState("");

  const [loaded, setLoaded] = useState(false);

  const { user } = useAuth();

  const getCircleColor = (tier) => {
    switch (tier) {
      case "nano":
        return "#9CDCF1";
      case "micro":
        return "#1F8855";
      case "mid":
        return "#FBD45B";
      case "macro":
        return "#FF8421";
      case "mega":
        return "#E47667";
      default:
        return "#000000"; // Default color for unknown
    }
  };

  const platform = selectedPlatform
    ? profile.platforms?.find(({ name }) => name === selectedPlatform)
    : null;

  useEffect(() => {
    const fetchStats = async () => {
      setLoaded(false);

      if (!user) {
        setLoaded(true);

        setStats({
          follower_growth: [],
          engagement_growth: [],
          monthly_stats: {
            date: "",
            label: "",
            engagement_avg: 0,
            followed_by_avg: 0,
            media_avg: 0,
            likes_avg: 0,
            comments_avg: 0,
            stats: {
              engagement: { value: 0, change_number: 0, change_percentage: 0 },
              followed_by: { value: 0, change_number: 0, change_percentage: 0 },
              media: { value: 0, change_number: 0, change_percentage: 0 },
              likes: { value: 0, change_number: 0, change_percentage: 0 },
              comments: { value: 0, change_number: 0, change_percentage: 0 },
            },
          },
          sponsored: {
            sponsored_percentage: 0,
            organic_percentage: 0,
            engagement: {
              sponsored: 0,
              organic: 0,
            },
          },
          top_stats: {
            engagement: 0,
            views: 0,
            followers: 0,
            follower_tier: "",
            reels_views: {
              value: 0,
            },
            stories_views: {
              value: 0,
            },
            engagement_rate: {
              value: 0,
              tier_average: 0,
            },
            reels_engagement_rate: {
              value: 0,
              tier_average: 0,
            },
            reels_views_rate: {
              value: 0,
              tier_average: 0,
            },
            stories_views_rate: {
              value: 0,
              tier_average: 0,
            },
          },
        });

        return;
      }

      try {
        const newStats = await getStats(
          profile.slug,
          selectedPlatform || null,
          timePeriod.toString()
        );

        setStats(newStats.data);

        setFollowerTier(newStats.data.top_stats.follower_tier);
      } catch (e) {
        console.log("E", e);
        toast.error("Error loading data");

        setStats(null);
      } finally {
        setLoaded(true);
      }
    };

    fetchStats();
  }, [selectedPlatform, timePeriod, setStats, profile.slug, user]);

  if (!loaded) {
    return (
      <div className="mt-[200px]">
        <LoadingSpinner />
      </div>
    );
  }

  const statsForCards = stats ? getStatsForCards(topStats) : [];

  return (
    <div className="relative pt-5 w-full">
      <div className="flex justify-between w-full mb-5">
        <FDropdown
          options={[
            ...platformList.filter((p) =>
              profile.platforms?.find(({ name }) => name === p.value)
            ),
          ]}
          selectedValue={selectedPlatform}
          onChange={(value) => setSelectedPlatform(value as string)}
        />
        <div className="max-w-[250px]">
          <FDropdown
            selectedValue={timePeriod}
            options={[
              { label: "Past Month", value: 30 },
              { label: "Past 60 days", value: 60 },
              { label: "Past 90 days", value: 90 },
            ]}
            onChange={(value) => setTimePeriod(value as number)}
            width="100%"
          />
        </div>
      </div>
      {stats ? (
        <div className="relative">
          <div className="flex gap-4 items-center">
            <div className="">
              <p className="font-sm text-default_weak mb-0.5">Followers</p>
              <p className="text-2xl mb-2">
                {formatLargeNumber(topStats.followers)}
              </p>
            </div>
            <div className="bg-light_beige_100 h-[32px] flex gap-2 items-center px-4 py-1.5 rounded-4xl">
              <div
                className="h-[14px] w-[14px] rounded-3xl"
                style={{
                  backgroundColor: getCircleColor(followerTier),
                }}
              />
              <span className="text-sm">{capitalize(followerTier)} Tier</span>
            </div>
          </div>
          <div className="grid grid-cols-2 mb-[48px] mt-[40px]">
            {statsForCards.map((card) => {
              const { title, value, tier_average } = card;

              return (
                <StatCardWithGradientBar
                  key={title}
                  title={title}
                  value={value}
                  tier_average={tier_average}
                />
              );
            })}
          </div>
          <div className="grid grid-cols-2 gap-[56px] mb-[48px]">
            <MonthlyComparisonTable
              monthlyStats={breakdown}
              platform={platform}
            />
            <SponsoredContentBreakdown sponsored={sponsored} />
          </div>
          <div className="grid grid-cols-2 gap-14 mb-[48px] mt-[40px]">
            <FollowersGrowthRate data={followerGrowth} />
            <EngagementGrowthRate data={engagementGrowth} />
          </div>
          <ContentGuard />
        </div>
      ) : null}
    </div>
  );
}
