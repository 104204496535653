import React, { useCallback, useContext, useEffect, useState } from "react";

import { ProfileContext } from "@contexts/index";
import Spinner from "@components/Spinner";

import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import { Content, Sort } from "@types";
import { searchPosts } from "@api/Search";
import GeneralPostModal from "@components/Modals/GeneralPostModal";
import { platformList } from "@constants/constants";
import FIcon from "@components/FIcon";
import BlankState from "@components/BlankState";
import FButton from "@components/FButton";
import { isEmpty, partition } from "lodash";
import {
  addToParticipantSampleContent,
  deleteParticipantSampleContent,
  getParticipantSampleContent,
} from "@api/Campaign/CampaignParticipants";
import toast from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import { LazyRouteContent } from "@components/navigation/LazyRouteContent";
import ResultsGrid from "@components/ResultsGrid";
import { SampleContentPermalinks } from "./sample-content/SampleContentPermalinks";

const SAMPLE_CONTENT = "sampleContent";

const TABLE_COLUMNS = [
  {
    key: "caption",
    label: "Caption",
  },
  {
    key: "platform",
    label: "",
    getContent: (content) => {
      const { platform } = content;

      const { icon } = platformList.find(({ value }) => platform === value);

      return <FIcon icon={icon} size={16} color="#000721" />;
    },
  },
  {
    key: "media_type",
    label: "Media Type",
  },
  {
    key: "published_at",
    label: "Posted",
    isDate: true,
  },
  {
    key: "views_count",
    label: "Views count",
  },
];

const FILTERS = [
  {
    label: "Top Performing",
    value: "topContent",
  },
  {
    label: "Recent Sponsored",
    value: "sponsoredContent",
  },
  {
    label: "Recent Posts",
    value: "allContent",
  },
  {
    label: "Sample Content",
    value: SAMPLE_CONTENT,
  },
];

export default function ProfileContent() {
  const { participant_id } = useParams();
  const { profile } = useContext(ProfileContext);

  const location = useLocation();

  const { search } = location;

  const showSampleContent = new URLSearchParams(search);

  const queryParams = new URLSearchParams(search);

  const [loading] = useState(false);
  const [activePost, setActivePost] = useState();

  const [activeFilter, setActiveFilter] = useState(
    queryParams.get("activeFilter") || FILTERS[0].value
  );

  const [selectedContent, setSelectedContent] = useState([]);
  const [selectedSampleContent, setSelectedSampleContent] = useState([]);

  const [sampleContent, setSampleContent] = useState([]);

  const [isUpdatingSampleContent, setIsUpdatingSampleContent] = useState(false);

  const loadParticipantSampleContent = async () => {
    const response = await getParticipantSampleContent(participant_id);

    setSampleContent(response.data.contents);
  };

  useEffect(() => {
    if (!showSampleContent) {
      return;
    }

    loadParticipantSampleContent();
  }, []);

  const handleContentModalClose = () => {
    setActivePost(null);
  };

  const handleContentModalClick = (content) => {
    setActivePost(content);
  };

  const handleAddToSampleContent = async () => {
    setIsUpdatingSampleContent(true);

    const idsNotAlreadyInSampleContent = selectedContent.filter(
      (id) => !sampleContent.find((item) => item.post_id === id)
    );

    if (isEmpty(idsNotAlreadyInSampleContent)) {
      toast.success("Already in sample content");

      setIsUpdatingSampleContent(false);

      return;
    }

    try {
      await addToParticipantSampleContent({
        contents: idsNotAlreadyInSampleContent.map((id) => ({
          participant_id: parseInt(participant_id, 10),
          post_id: id,
        })),
      });

      loadParticipantSampleContent();

      toast.success("Added to sample content");
    } catch {
      toast.error("Error adding to sample content");
    } finally {
      setIsUpdatingSampleContent(false);
    }
  };

  const handleRemoveFromSampleContent = async () => {
    setIsUpdatingSampleContent(true);

    try {
      for (const id of selectedSampleContent) {
        await deleteParticipantSampleContent(id);

        setSampleContent(
          sampleContent.filter(
            (item) => !selectedSampleContent.includes(item.id)
          )
        );
      }

      toast.success("Deleted from sample content");
    } catch {
      toast.error("Error deleting from sample content");
    } finally {
      setIsUpdatingSampleContent(false);
    }
  };

  const handlePermalinkSamplePostAdded = (post: Content) => {
    setSampleContent([post, ...sampleContent]);
  };

  const handlePermalinkSamplePostDeleted = (postId: number) => {
    setSampleContent(sampleContent.filter((item) => item.id !== postId));
  };

  const handlePermalinkSamplePostUpdated = (post: Content) => {
    setSampleContent(
      sampleContent.map((item) => (item.id === post.id ? post : item))
    );
  };

  const getContent = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search } = params;

      const sortToUse = activeFilter === "topContent" ? "interactions" : "";

      const isSponsored = activeFilter === "sponsoredContent";

      const response = await searchPosts({
        creator_ids: [profile.id],
        searchTerm: search,
        p: page,
        sort: sortToUse,
        sponsored: isSponsored,
      });

      const { results, total } = response.data;

      return {
        items: results,
        total_items: total,
      };
    },
    [profile, activeFilter]
  );

  if (loading) {
    return <Spinner />;
  }

  const isSampleContent = activeFilter === SAMPLE_CONTENT;

  const [sampleInPlatformContent, samplePermalinks] = partition(
    sampleContent,
    ({ post_id }) => post_id
  );

  const filters = FILTERS.filter(
    (item) => showSampleContent || item.value !== SAMPLE_CONTENT
  );

  return (
    <div>
      <div className="flex justify-between items-center mb-8 mt-4 w-full">
        <div className="flex gap-2">
          {filters.map((filter) => {
            const { label, value } = filter;

            const isSelected = value === activeFilter;

            return (
              <button
                className={`h-[24px] rounded-[32px] px-4 py-1 text-xs text-${isSelected ? "white" : "dark_night_sky"} text-sofia_pro bg-${isSelected ? "dark_night_sky" : "light_beige_100"}`}
                key={value}
                onClick={() => setActiveFilter(value)}
              >
                {label}
              </button>
            );
          })}
        </div>
        {!isEmpty(selectedContent) && !isSampleContent ? (
          <FButton
            height="36px"
            primary
            label={`Add to sample content (${selectedContent.length})`}
            loading={isUpdatingSampleContent}
            onClick={handleAddToSampleContent}
          />
        ) : null}
        {!isEmpty(selectedSampleContent) && isSampleContent ? (
          <FButton
            height="36px"
            primary
            label={`Remove from sample content (${selectedSampleContent.length})`}
            loading={isUpdatingSampleContent}
            onClick={handleRemoveFromSampleContent}
          />
        ) : null}
      </div>
      <LazyRouteContent isActive={activeFilter !== SAMPLE_CONTENT}>
        <SinglePageLayoutWithData
          entityName="content"
          pageName="Content"
          cardType="content"
          onChangeSelectedItems={participant_id && setSelectedContent}
          onCardClick={handleContentModalClick}
          selectedItems={selectedContent}
          tableColumns={TABLE_COLUMNS}
          getItems={getContent}
          customBlankState={
            <BlankState
              title="No content found"
              subtitle="Try updating your search"
              icon="content"
            />
          }
        />
      </LazyRouteContent>
      <LazyRouteContent isActive={activeFilter === SAMPLE_CONTENT}>
        {!isEmpty(sampleInPlatformContent) ? (
          <ResultsGrid
            cardData={sampleInPlatformContent}
            cardType="content"
            onCardClick={(content) => handleContentModalClick(content)}
            onToggleItemSelection={(id) => {
              let newSelectedItems = selectedSampleContent;

              if (selectedSampleContent.includes(id)) {
                newSelectedItems = newSelectedItems.filter(
                  (item) => item !== id
                );
              } else {
                newSelectedItems = [...selectedSampleContent, id];
              }

              setSelectedSampleContent(newSelectedItems);
            }}
            selectedItems={selectedSampleContent}
          />
        ) : (
          <BlankState
            title="No sample content found"
            subtitle="Try selecting items from other tabs to add them to sample content"
            icon="content"
          />
        )}
        <div className="mt-8" />
        <SampleContentPermalinks
          participantId={parseInt(participant_id, 10)}
          contents={samplePermalinks}
          onPostAdded={handlePermalinkSamplePostAdded}
          onPostDeleted={handlePermalinkSamplePostDeleted}
          onPostUpdated={handlePermalinkSamplePostUpdated}
        />
      </LazyRouteContent>
      {activePost && (
        <GeneralPostModal
          post={activePost}
          isOpen
          onClose={handleContentModalClose}
        />
      )}
    </div>
  );
}
