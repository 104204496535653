import React from "react";

import { groupBy, isEmpty, maxBy, sortBy } from "lodash";
import { DemographicsBreakdownItem, Participant } from "@types";

import CircleChart from "@components/Graphs/CircleChart";
import WidgetContainer from "@components/charts/demographics/WidgetContainer";
import { SEXUAL_ORIENTATIONS } from "@constants/demographics";

const getSexualOrientationBreakdown = (
  participants: Participant[]
): DemographicsBreakdownItem[] => {
  const allSexualOrientations = participants.reduce((result, participant) => {
    const { sexual_orientation } = participant;

    let value;

    if (isEmpty(sexual_orientation)) {
      value = "other";
    } else {
      [value] = sexual_orientation;
    }

    return [...result, { value }];
  }, []);

  const total = allSexualOrientations.length;

  const groupedSexualOrientations = groupBy(allSexualOrientations, "value");

  const result = SEXUAL_ORIENTATIONS.reduce((result, item) => {
    const count = groupedSexualOrientations[item.value]?.length;

    if (!count) {
      return result;
    }

    return [
      ...result,
      {
        name: item.label,
        percentage: Math.round((100 * count) / total),
      },
    ];
  }, []);

  return sortBy(result, "percentage").reverse();
};

interface SexualOrientationBreakdownProps {
  participants: Participant[];
}

export default function SexualOrientationBreakdown({
  participants,
}: SexualOrientationBreakdownProps) {
  const items = getSexualOrientationBreakdown(participants);

  return (
    <WidgetContainer title="Sexuality" value={maxBy(items, "percentage").name}>
      <CircleChart data={items || []} />
    </WidgetContainer>
  );
}
