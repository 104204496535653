import { Fragment as F } from "react";
import { times } from "lodash";

export interface OnboardingStepsButtonsProps {
  stepsCount: number;
  currentStep: number;
}

const OnboardingStepsButtons = ({
  stepsCount,
  currentStep,
}: OnboardingStepsButtonsProps) => (
  <div className="flex items-center gap-4">
    {times(stepsCount, (index) => {
      const isActive = currentStep === index + 1;

      return (
        <F key={index}>
          <div
            className={`bg-${isActive ? "black" : "light_beige_100"} flex items-center justify-center w-[32px] h-[32px]`}
          >
            <span className={`text-xl ${isActive ? "text-white" : ""}`}>
              {index + 1}
            </span>
          </div>
          {index !== stepsCount - 1 ? (
            <div className="bg-black h-[1px] w-[16px]" />
          ) : null}
        </F>
      );
    })}
  </div>
);

export default OnboardingStepsButtons;
