import React from "react";
import FIcon from "@components/FIcon";
import { useNavigate } from "react-router-dom";

const ACCOUNT_TYPES = [
  {
    name: "Influencer",
    icon: "skincare",
  },
  {
    name: "Brand",
    icon: "store",
  },
];

const AccountTypeBox = ({ icon, name, onSelected }) => (
  <div
    className={`cursor-pointer flex flex-col items-center justify-center py-[56px] w-[212px] h-[236px] border border-neutral_400 hover:border-dark_border gap-4`}
    onClick={onSelected}
  >
    <FIcon icon={icon} size={40} />
    <p className="text-2xl">{name}</p>
    <FIcon color="#000721" icon="next-arrow" size={24} />
  </div>
);

export default function Login() {
  const navigate = useNavigate();

  const handleSelected = (accountType) =>
    navigate(`/signup/${accountType.toLowerCase()}`);

  return (
    <div className="flex flex-col items-center">
      <p className="text-3xl leading-[30px] mb-4 text-center">
        Welcome to Fohr
      </p>
      <p className="mb-14 text-center">Select your account type</p>
      <div className="flex items-center gap-8 mb-6">
        {ACCOUNT_TYPES.map(({ icon, name }) => (
          <AccountTypeBox
            icon={icon}
            name={name}
            onSelected={() => handleSelected(name)}
          ></AccountTypeBox>
        ))}
      </div>
    </div>
  );
}
