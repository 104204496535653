import React from "react";
import { Line } from "react-chartjs-2";
import { formatLargeNumber } from "@utils/number_utilities";

function BasicLineChart({
  chartData = {},
  lineColor,
  legendLabel,
  height = "auto",
  width = "auto",
  borderWidth = 2,
  pointRadius = 2,
  yAxisTickSuffix = "",
  showLegend = true,
  options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            display: true,
            maxTicksLimit: 6,
            callback(value) {
              return yAxisTickSuffix
                ? `${value}${yAxisTickSuffix}`
                : formatLargeNumber(value);
            },
          },
          gridLines: {
            display: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            display: true,
            callback(value) {
              return value.split(" ")[0];
            },
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label(tooltipItem) {
          return parseInt(tooltipItem.value).toLocaleString();
        },
      },
    },
  },
}) {
  const data = {
    labels: Object.keys(chartData),
    datasets: [
      {
        data: Object.values(chartData),
        borderColor: lineColor,
        backgroundColor: lineColor,
        borderWidth: borderWidth,
        fill: false,
        pointRadius: pointRadius,
        lineTension: 0,
        label: legendLabel,
      },
    ],
  };

  const updatedOptions = {
    ...options,
    plugins: {
      legend: {
        display: showLegend,
      },
    },
  };

  return (
    <div>
      {data && (
        <Line
          data={data}
          options={updatedOptions}
          height={height}
          width={width}
        />
      )}
    </div>
  );
}

export default BasicLineChart;
