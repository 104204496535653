import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { groupBy, partition, sortBy } from "lodash";
import {
  AmbassadorQuestion,
  AmbassadorQuestionRequest,
} from "types/ambassador-question";

export const getAmbassadorQuestionsWithChildren = (
  questions: AmbassadorQuestion[]
) => {
  const [parentQuestions, childQuestions] = partition(
    questions,
    ({ question_id }) => !question_id
  );

  const childQuestionsByParent = groupBy(childQuestions, "question_id");

  const fullQuestions = parentQuestions.map((question) => {
    const children = childQuestionsByParent[question.id];

    if (!children) {
      return question;
    }

    const childrenByAnswer = children.reduce((result, question) => {
      return {
        ...result,
        [question.conditional_answer]: question,
      };
    }, {});

    return {
      ...question,
      children: childrenByAnswer,
    };
  });

  return sortBy(fullQuestions, "position");
};

const listAmbassadorQuestions = async (params?: {
  q?: {
    brand_id_eq?: number;
    question_id_eq?: number;
  };
}): Promise<AmbassadorQuestion[]> => {
  const queryParams = new URLSearchParams();

  if (params?.q?.brand_id_eq) {
    queryParams.append("q[brand_id_eq]", params.q.brand_id_eq.toString());
  }
  if (params?.q?.question_id_eq) {
    queryParams.append("q[question_id_eq]", params.q.question_id_eq.toString());
  }

  const response = await apiWrapper(
    `/ambassador_questions?${queryParams.toString()}`,
    "GET"
  );

  return response.data.ambassador_questions;
};

const createAmbassadorQuestion = async (
  params: AmbassadorQuestionRequest
): Promise<ApiResponse<AmbassadorQuestion>> => {
  return apiWrapper("/ambassador_questions", "POST", params);
};

const updateAmbassadorQuestion = async (
  question_id: string | number,
  params: Partial<AmbassadorQuestionRequest>
): Promise<ApiResponse<AmbassadorQuestion>> => {
  return apiWrapper(`/ambassador_questions/${question_id}`, "PUT", params);
};

const deleteAmbassadorQuestion = async (question_id: string | number) => {
  return apiWrapper(`/ambassador_questions/${question_id}`, "DELETE");
};

const showAmbassadorQuestion = async (
  question_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/ambassador_questions/${question_id}`, "GET");
};

export {
  listAmbassadorQuestions,
  createAmbassadorQuestion,
  updateAmbassadorQuestion,
  deleteAmbassadorQuestion,
  showAmbassadorQuestion,
};
