import React from "react";

const CreatorPrivacySettings = () => {

  return (
    <div className="max-w-[800px] py-[40px]">
      <h1 className="mb-4">GDPR Privacy Policy</h1>
      <p>
        To keep your Fohr account accurate and up to date, we collect data
        from Instagram, Tumblr, Facebook, Pinterest, YouTube, and Google
        Analytics including photos, tweets, videos, and stats about your
        accounts. We do this so we can show you how your following has grown
        over time and calculate things like your engagement rate on
        Instagram. We share this data with some of the most incredible
        brands and agencies, who use Fohr to discover influencers like you to
        work with everyday. As long as you have a Fohr account, we'll keep
        your information on file. Got questions about any of this? Shoot us
        an email at <a className="underline" href="mailto:support@fohr.co">support@fohr.co</a> and we'll be more than happy to help.
      </p>
    </div>
  );
};

export default CreatorPrivacySettings;
