import FDropdown from "@components/FDropdown";
import FIcon from "@components/FIcon";
import Heading from "@components/Heading";
import { platformList, allSearchCategories } from "@constants/constants";
import React, { useEffect, useState, useRef } from "react";

import { isArray, isEmpty, isObject, isPlainObject } from "lodash";

import { CreatorFromSearch } from "@apiTypes";
import ResultsGrid from "@components/ResultsGrid";
import { searchCreators, searchPosts } from "@api/Search";

import { CREATOR_CATEGORIES, POST_CATEGORIES } from "@api/Search";
import useDebouncedValue from "@hooks/useDebouncedValue";

import FPagination from "@components/FPagination";
import LoadingSpinner from "@components/LoadingSpinner";
import GeneralPostModal from "@components/Modals/GeneralPostModal";
import { useNavigate } from "react-router-dom";

import BlankState from "@components/BlankState";
import FButton from "@components/FButton";
import AddCreatorsToGroupModal from "@components/Profile/AddCreatorsToGroupModal";
import { Content } from "@types";
import CreateReportModal from "@components/Modals/CreateReportModal";
import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";
import { FCheckbox } from "@components/FInputs";
import AdvancedCreatorSearchModal from "./advanced/AdvancedCreatorSearchModal";
import { AdvancedSearchParams } from "./types";
import { getSearchString } from "@utils/history";
import AdvancedContentSearchModal from "./advanced/AdvancedContentSearchModal";
import SearchResultsSkeleton from "@components/Search/SearchResultsSkeleton";
import { categoryImages, contentCategoryImages } from "./constants";

const getOptions = (categories: {
  [key: number]: string;
}): { value: string; label: string }[] =>
  Object.keys(categories).map((key) => ({
    value: key,
    label: categories[key],
    icon: allSearchCategories.find((category) => category.value === `${key}`)
      ?.icon,
  }));

const DEFAULT_FILTERS = {
  api_connected: false,
  searchTerm: null,
  selectedPlatforms: [],
  selectedCategories: [],
  page: 1,
  resource: "creators",
};

const DEFAULT_ADVANCED_FILTERS = {
  custom_locations: {},
  custom_followers: {},
  custom_age_range: {},
  date_from: "",
  date_to: "",
  gender: [],
  ethnicity: [],
  previously_contracted: false,
  api_connected: false,
  address_state: [],
  address_country: [],
  managed: "",
  target_opportunities: [],
  children: "",
  relationship_status: [],
  sexual_orientation: [],
};

const areAdvancedFiltersEmpty = (advancedFilters: AdvancedSearchParams) => {
  const a = Object.keys(advancedFilters).every((key) => {
    const value = advancedFilters[key];

    if (isObject(value)) {
      return isEmpty(value);
    }
    return !value;
  });

  console.log("A", a);

  return a;
};

const getDefaultFilters = (searchParams, initialValue) => {
  const result = Object.keys(initialValue).reduce((result, key) => {
    const paramValue = searchParams.get(key);

    if (paramValue) {
      let value = isArray(initialValue[key])
        ? paramValue.split(",")
        : paramValue;

      if (value && isPlainObject(initialValue[key])) {
        value = JSON.parse(value);
      }

      return {
        ...result,
        [key]: value,
      };
    } else {
      return result;
    }
  }, initialValue);

  return result;
};

// Add this helper function to reorder categories
const reorderCategories = (
  categories: typeof allSearchCategories,
  selectedValue: string
) => {
  const category = categories.find((c) => c.value === selectedValue);
  if (!category) return categories;

  const otherCategories = categories.filter((c) => c.value !== selectedValue);
  return [category, ...otherCategories];
};

export default function Search() {
  const [categoriesExpanded, setCategoriesExpanded] = useState<boolean>(false);

  const [hoveredPlatform, setHoveredPlatform] = useState<string>(null);
  const [isAdvancedFiltersHovered, setIsAdvancedFiltersHovered] =
    useState(false);

  const [loading, setLoading] = useState(false);

  const [creators, setCreators] = useState<CreatorFromSearch[]>([]);
  const [posts, setPosts] = useState<Content[]>([]);

  const [selectedCreatorIds, setSelectedCreatorIds] = useState<number[]>([]);
  const [isAddToGroupModalVisible, setAddToGroupModalVisible] = useState(false);
  const [isCreateReportModalVisible, setCreateReportModalVisible] =
    useState(false);

  const [
    isAdvancedCreatorSearchModalVisible,
    setAdvancedCreatorSearchModalVisible,
  ] = useState(false);

  const [
    isAdvancedContentSearchModalVisible,
    setAdvancedContentSearchModalVisible,
  ] = useState(false);

  const [total, setTotal] = useState(null);

  const navigate = useNavigate();

  const { can } = useAuth();

  const searchParams = new URLSearchParams(window.location.search);

  const [filters, setFilters] = useState(
    getDefaultFilters(searchParams, DEFAULT_FILTERS)
  );
  const [advancedFilters, setAdvancedFilters] = useState(
    getDefaultFilters(searchParams, DEFAULT_ADVANCED_FILTERS)
  );

  const { resource, searchTerm, selectedCategories, selectedPlatforms, page } =
    filters;

  const debouncedSearchTerm = useDebouncedValue(
    searchTerm !== null ? searchTerm : searchParams.get("searchTerm"),
    500
  );

  const [activePost, setActivePost] = useState(null);
  const [contentModalOpen, setContentModalOpen] = useState(false);
  const resources = [{ label: "Content", icon: "content", value: "content" }];

  if (can(Permission.USE_SEARCH_INFLUENCERS)) {
    resources.push({ label: "Creators", icon: "audience", value: "creators" });
  }

  console.log(activePost, "activePost");

  const searchInputRef = useRef<HTMLInputElement>(null);

  // Add state for reordered categories
  const [orderedCategories, setOrderedCategories] =
    useState(allSearchCategories);

  useEffect(() => {
    console.log("Content Categories:", POST_CATEGORIES);
    console.log("Creator Categories:", CREATOR_CATEGORIES);

    // Log out in a more readable format
    console.log("Content Categories Mapped:");
    Object.entries(POST_CATEGORIES).forEach(([key, value]) => {
      console.log(`ID: ${key}, Name: ${value}`);
    });

    console.log("Creator Categories Mapped:");
    Object.entries(CREATOR_CATEGORIES).forEach(([key, value]) => {
      console.log(`ID: ${key}, Name: ${value}`);
    });
  }, []);

  useEffect(() => {
    const handleSearch = async () => {
      const queryParams = getSearchString({
        searchTerm: debouncedSearchTerm,
        page,
        selectedPlatforms,
        selectedCategories,
        resource,
        ...advancedFilters,
      });

      navigate(`/search?${queryParams.toString()}`);

      if (
        !debouncedSearchTerm &&
        isEmpty(selectedCategories) &&
        isEmpty(selectedPlatforms) &&
        areAdvancedFiltersEmpty(advancedFilters)
      ) {
        setCreators([]);
        setPosts([]);

        return;
      }

      setLoading(true);

      if (resource === "content") {
        const response = await searchPosts({
          searchTerm: debouncedSearchTerm,
          platforms: selectedPlatforms,
          categories: selectedCategories,
          p: page,
        });

        const { results, total } = response.data;

        setPosts(results);
        setTotal(total);

        setLoading(false);

        return;
      }

      const response = await searchCreators({
        p: page,
        searchTerm: debouncedSearchTerm,
        platforms: selectedPlatforms,
        tags: selectedCategories,
        ...advancedFilters,
      });

      const { results, total } = response.data;

      setCreators(results);
      setTotal(total);

      setLoading(false);
    };

    handleSearch();
  }, [
    debouncedSearchTerm,
    resource,
    selectedCategories,
    selectedPlatforms,
    page,
    advancedFilters,
    navigate,
  ]);

  useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  // Update the searchCategories calculation
  const searchCategories = getOptions(
    resource === "content" ? POST_CATEGORIES : CREATOR_CATEGORIES
  ).sort((a, b) => {
    // Keep selected items at the front
    const aSelected = selectedCategories.includes(a.value);
    const bSelected = selectedCategories.includes(b.value);
    if (aSelected && !bSelected) return -1;
    if (!aSelected && bSelected) return 1;
    return 0;
  });

  const handleContentModalClick = (content) => {
    setActivePost(content);
    setContentModalOpen(true);
  };

  const handleContentModalClose = () => {
    setContentModalOpen(false);
    setActivePost(null);
  };

  const handleToggleItemSelection = (id) => {
    let newSelectedItems = selectedCreatorIds || [];

    if (selectedCreatorIds.includes(id)) {
      newSelectedItems = newSelectedItems.filter((item) => item !== id);
    } else {
      newSelectedItems = [...selectedCreatorIds, id];
    }

    setSelectedCreatorIds(newSelectedItems);
  };

  const handleAddToGroup = () => setAddToGroupModalVisible(true);

  const isEmptySearch =
    !debouncedSearchTerm &&
    isEmpty(selectedCategories) &&
    isEmpty(selectedPlatforms);

  return (
    <div className="pb-16">
      <div className="w-full mb-20">
        <Heading
          title={"Search & Discover"}
          className="pb-8"
          headerButtons={
            <div className="flex gap-4">
              {selectedCreatorIds.length > 0 && resource === "creators" ? (
                <FButton
                  onClick={handleAddToGroup}
                  label={`(${selectedCreatorIds.length}) Add to group`}
                  width="max-content"
                ></FButton>
              ) : null}

              {posts.length > 0 ? (
                <FButton
                  onClick={() => setCreateReportModalVisible(true)}
                  label={`Create Report`}
                  width="max-content"
                ></FButton>
              ) : null}
              <FButton
                label="Advanced Search"
                primary
                iconLeft={{ name: "filter", size: 14, color: "#fff" }}
                onClick={() =>
                  resource === "creators"
                    ? setAdvancedCreatorSearchModalVisible(true)
                    : setAdvancedContentSearchModalVisible(true)
                }
              />
            </div>
          }
        />
      </div>
      <div className="flex items-center border-b border-b-dark gap-[16px] pb-[10px]">
        {/* search */}
        <div className="flex items-center w-full">
          <FIcon icon="search" size={20} color="#000F45" className="mb-2" />
          <input
            ref={searchInputRef}
            className="pl-[16px] bg-transparent border-none w-full h-[30px] text-[20px] outline-none "
            type="text"
            value={searchTerm}
            onChange={(e) => {
              setFilters({
                ...filters,
                page: 1,
                searchTerm: e.target.value,
              });
            }}
            placeholder="Search by name, keyword, hashtag, and more"
          />
          {searchTerm || selectedCategories.length > 0 ? (
            <div
              onClick={() => {
                setFilters({
                  ...filters,
                  page: 1,
                  searchTerm: "",
                  selectedCategories: [],
                });
              }}
              className="cursor-pointer h-[30px] w-auto px-4 gap-1 flex items-center justify-center mr-4 bg-highlight_red rounded-full"
            >
              <FIcon icon="exit" size={12} color="#000" className="-mt-[1px]" />
              <p className="text-[12px] text-black">Clear</p>
            </div>
          ) : null}
          {/* dropdown */}
          {/* <div className="min-w-[1px] max-w-[1px] w-[1px] h-[30px] bg-black mr-4" /> */}

          <div className="flex items-center pr-[10px]">
            <FIcon
              icon={resources.find(({ value }) => value === resource).icon}
              size={18}
              color="#000F45"
              className="mr-[10px] -mt-[2px]"
            />
            <FDropdown
              options={resources}
              transparent
              height={30}
              width="100px"
              className="text-[18px] w-auto"
              selectedValue={resource}
              titleDropDown={true}
              onChange={(value) => {
                setFilters({
                  ...filters,
                  page: 1,
                  resource: value as string,
                  selectedCategories: [],
                });
              }}
            />
          </div>
          {/* divider */}

          {/* divider */}
          <div className="w-[1px] h-[30px] bg-black ml-2" />
        </div>
        {/* platforms */}
        <div className="flex gap-[8px]">
          {platformList.map((platform) => {
            const selected = selectedPlatforms.indexOf(platform.value) !== -1;

            const hovered = hoveredPlatform === platform.value;

            return (
              <div
                key={platform.value}
                className={`
									cursor-pointer flex items-center justify-center
									border border-[#000721] rounded-full w-[32px]
									h-[32px] hover:bg-[#000721] ${selected ? "bg-[#000721]" : "bg-transparent"}
								`}
                onClick={() => {
                  let newSelectedPlatforms;

                  if (selected) {
                    newSelectedPlatforms = selectedPlatforms.filter(
                      (p) => p !== platform.value
                    );
                  } else {
                    newSelectedPlatforms = [
                      ...selectedPlatforms,
                      platform.value,
                    ];
                  }

                  setFilters({
                    ...filters,
                    page: 1,
                    selectedPlatforms: newSelectedPlatforms,
                  });
                }}
                onMouseEnter={() => setHoveredPlatform(platform.value)}
                onMouseLeave={() => setHoveredPlatform(null)}
              >
                <FIcon
                  icon={platform.icon}
                  size={14}
                  color={selected || hovered ? "#fff" : "#000721"}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="relative mt-[16px]">
        <div
          className={`flex flex-wrap items-center gap-[10px] ${
            !categoriesExpanded ? "h-[35px]" : "h-full"
          } overflow-hidden w-[calc(100%-40px)]`}
        >
          <FIcon
            icon="tag"
            size={18}
            color="#000721"
            className="mr-[8px] -mt-[2px]"
          />
          {searchCategories.map((category) => {
            return (
              <p
                className={`cursor-pointer border border-neutral_400 rounded-full px-[13px] pb-[2px] pt-[4px] text-[14px]
                ${selectedCategories.indexOf(category.value) !== -1 ? "bg-dark_night_sky text-white border-dark_night_sky" : ""}`}
                key={category.value}
                onClick={() => {
                  let newSelectedCategories;

                  if (selectedCategories.indexOf(category.value) !== -1) {
                    newSelectedCategories = selectedCategories.filter(
                      (c) => c !== category.value
                    );
                  } else {
                    newSelectedCategories = [
                      category.value,
                      ...selectedCategories,
                    ];
                    // Reorder categories when selecting a new one
                    setOrderedCategories(
                      reorderCategories(orderedCategories, category.value)
                    );
                  }

                  setFilters({
                    ...filters,
                    page: 1,
                    selectedCategories: newSelectedCategories,
                  });
                }}
              >
                {category.label}
              </p>
            );
          })}
          <div
            className="absolute right-1 top-[7px] cursor-pointer transition duration-300"
            style={{
              transform: categoriesExpanded ? "rotate(180deg)" : "rotate(0deg)",
            }}
            onClick={() => setCategoriesExpanded(!categoriesExpanded)}
          >
            <FIcon icon="chevron" size={14} color="#000721" />
          </div>
        </div>
      </div>
      {selectedPlatforms.length > 0 ? (
        <div className="mt-4">
          <FCheckbox
            label="API Connected"
            id="apiConnected"
            onChange={(value) =>
              setFilters({
                ...filters,
                api_connected: value,
              })
            }
            checked={filters.api_connected}
          />
        </div>
      ) : null}
      {/* results */}
      {loading ? (
        <SearchResultsSkeleton
          type={resource === "content" ? "content" : "creators"}
        />
      ) : (
        <>
          <div>
            {resource === "content" ? (
              !isEmpty(posts) ? (
                <ResultsGrid
                  cardData={posts.map((post, index) => {
                    if (index === 0) {
                      return { ...post, stats: null };
                    }

                    return post;
                  })}
                  cardType="content"
                  numColumns={6}
                  onCardClick={(content) => handleContentModalClick(content)}
                />
              ) : isEmptySearch ? (
                <div className="mt-10 h-full overflow-hidden">
                  <div className="flex gap-2 pb-4">
                    {contentCategoryImages.map((category, index) => (
                      <div
                        key={index}
                        className="cursor-pointer flex-1 min-h-[50vh] relative group overflow-hidden rounded-lg shadow-lg"
                        onClick={() => {
                          if (category.tag) {
                            setFilters({
                              ...filters,
                              page: 1,
                              selectedCategories: [category.tag],
                            });
                          }
                        }}
                      >
                        <img
                          src={category.image}
                          alt="Category"
                          className="w-full h-full object-cover transition-transform duration-500 ease-out group-hover:scale-105"
                        />
                        <div className="absolute z-0 inset-x-0 bottom-0 h-[80px] bg-gradient-to-t from-[rgba(0,0,0,0.3)] to-transparent" />
                        <div className="absolute z-10 bottom-0 left-0 right-0 p-4 text-white text-lg font-semibold capitalize">
                          {POST_CATEGORIES[category.tag]}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <BlankState
                  title={`No content found`}
                  subtitle="Try updating your search or create one"
                  icon="content"
                />
              )
            ) : !isEmpty(creators) ? (
              <ResultsGrid
                cardData={creators}
                cardType="individual"
                isProfile={false}
                numColumns={6}
                selectedItems={selectedCreatorIds}
                onToggleItemSelection={handleToggleItemSelection}
              />
            ) : isEmptySearch ? (
              <div className="mt-10 h-full overflow-hidden">
                <div className="flex gap-2 pb-4">
                  {(resource === "content"
                    ? contentCategoryImages
                    : categoryImages
                  ).map((category, index) => (
                    <div
                      key={index}
                      className="cursor-pointer flex-1 min-h-[50vh] relative group overflow-hidden rounded-lg shadow-lg"
                      onClick={() => {
                        if (category.tag) {
                          setFilters({
                            ...filters,
                            page: 1,
                            selectedCategories: [category.tag],
                          });
                        }
                      }}
                    >
                      <img
                        src={category.image}
                        alt="Category"
                        className="w-full h-full object-cover transition-transform duration-500 ease-out group-hover:scale-105"
                      />
                      <div className="absolute z-0 inset-x-0 bottom-0 h-[80px] bg-gradient-to-t from-[rgba(0,0,0,0.3)] to-transparent" />
                      <div className="absolute z-10 bottom-0 left-0 right-0 p-4 text-white text-lg font-semibold capitalize">
                        {console.log(
                          "Category tag:",
                          category.tag,
                          "Categories:",
                          resource === "content"
                            ? POST_CATEGORIES
                            : CREATOR_CATEGORIES
                        )}
                        {resource === "content"
                          ? POST_CATEGORIES[category.tag]
                          : CREATOR_CATEGORIES[category.tag]}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <BlankState
                title="No creators found"
                subtitle="Can't find the creator you're looking for? Add them via their Instagram or TikTok handle."
                icon="user"
                actionLabel="Add Creator"
                onActionClick={() => navigate("/off-platform-creator")}
              />
            )}
          </div>
          {total &&
          total > 0 &&
          total > (resource === "content" ? posts.length : creators.length) &&
          (resource === "content" ? posts.length : creators.length) > 0 ? (
            <div className="mt-[28px]">
              <FPagination
                page={page}
                pageSize={24}
                total={total}
                onPageChange={(page) =>
                  setFilters({
                    ...filters,
                    page,
                  })
                }
              />
            </div>
          ) : null}
        <div className="flex flex-col items-center gap-3 mt-4">
          <p>
            Can't find the creator you're looking for? Add them via their
            Instagram or TikTok handle.
          </p>
          <FButton
            label="Add Creator"
            width="200px"
            link="/off-platform-creator"
          />
        </div>
          {activePost && (
            <GeneralPostModal
              post={activePost}
              isOpen={contentModalOpen}
              onClose={handleContentModalClose}
            />
          )}
          {isAddToGroupModalVisible ? (
            <AddCreatorsToGroupModal
              creatorIds={selectedCreatorIds}
              onClose={() => setAddToGroupModalVisible(false)}
            />
          ) : null}
          {isCreateReportModalVisible ? (
            <CreateReportModal
              filters={filters}
              isOpen={isCreateReportModalVisible}
              onClose={() => setCreateReportModalVisible(false)}
            />
          ) : null}
        </>
      )}
      {isAdvancedCreatorSearchModalVisible ? (
        <AdvancedCreatorSearchModal
          filters={advancedFilters as unknown as AdvancedSearchParams}
          onClose={() => {
            setAdvancedCreatorSearchModalVisible(false);
            searchInputRef.current?.focus();
          }}
          onUpdateFilters={(filters) => {
            setAdvancedFilters(filters);
            setAdvancedCreatorSearchModalVisible(false);
            searchInputRef.current?.focus();
          }}
        />
      ) : null}
      {isAdvancedContentSearchModalVisible ? (
        <AdvancedContentSearchModal
          filters={advancedFilters as unknown as AdvancedSearchParams}
          onClose={() => {
            setAdvancedContentSearchModalVisible(false);
            searchInputRef.current?.focus();
          }}
          onUpdateFilters={(filters) => {
            setAdvancedFilters(filters);
            setAdvancedContentSearchModalVisible(false);
            searchInputRef.current?.focus();
          }}
        />
      ) : null}
    </div>
  );
}
