import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";

interface CaptchaProps {
  action?: string
  className?: string
}

export interface CaptchaInstance {
  isValid: () => boolean
  getToken: () => string | undefined
  reset: () => void
}

export class CaptchaError extends Error {}

export const Captcha = forwardRef(({
  action,
  className
}: CaptchaProps, ref) => {
  const cfTurnstileRef = useRef<TurnstileInstance>();
  const siteKey = import.meta.env.VITE_TURNSTILE_SITE_KEY
  const [showing, setShowing] = useState(false);

  useImperativeHandle(ref, (): CaptchaInstance => ({
      isValid() {
        const turnstile = cfTurnstileRef.current;
        return turnstile?.getResponse() && !turnstile?.isExpired();
      },
      getToken() {
        if (!siteKey) {
          return '';
        }

        const token = cfTurnstileRef.current?.getResponse();
        if (!token) {
          setShowing(true);
          throw new CaptchaError('Human verification is required');
        }

        return token;
      },
      reset() {
        cfTurnstileRef.current?.reset();
      },
  }));

  if (!siteKey) {
    return <></>
  }

  return (
    <div className={className} style={{ display: (showing ? 'block' : 'none') }}>
      <Turnstile
        ref={cfTurnstileRef}
        siteKey={siteKey}
        options={{
          action: action,
          size: 'flexible',
          theme: 'light',
          language: 'en',
        }}
        scriptOptions={{
          appendTo: 'body'
        }}
        onBeforeInteractive={() => {
          setShowing(true);
        }}
      />
    </div>
  )
});
