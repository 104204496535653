import { UserNotificationSetting } from "@types";
import FIcon from "../../components/FIcon";
import FMultiSelect from "../../components/FMultiSelect";
// import apiResourceQuery from 'api';
import * as React from "react";
import {
  deleteUserNotificationSettings,
  updateUserNotificationSettings,
} from "@api/Notifications/UserNotificationSettings";
import toast from "react-hot-toast";
import { SETTINGS } from "./settings";

interface NotificationSettingProps {
  subscription: UserNotificationSetting;
  className: string;
  onSubscriptionDeleted: (id: number) => void;
  onSubscriptionUpdated: (id: number, updates) => void;
}

export default function NotificationSetting({
  subscription,
  className = "",
  onSubscriptionDeleted,
  onSubscriptionUpdated,
}: NotificationSettingProps) {
  const handleUpdateNotificationSettings = async (
    id: number,
    selected: string[]
  ) => {
    const newSettings = SETTINGS.reduce((result, item) => {
      return { ...result, [item.value]: selected.includes(item.value) };
    }, {});

    try {
      const updates = {
        settings: newSettings,
      };

      await updateUserNotificationSettings(id, updates);

      toast.success("Notification updated");

      onSubscriptionUpdated(id, updates);
    } catch (error) {
      console.error("Failed to update notification:", error);
    }
  };

  const handleDeleteNotification = async (id: number) => {
    try {
      await deleteUserNotificationSettings(id);

      onSubscriptionDeleted(id);
    } catch (error) {
      console.error("Failed to delete notification:", error);
    }
  };

  let selectedSettings = [];

  if (subscription.settings) {
    selectedSettings = Object.keys(subscription.settings).filter(
      (key: string) => subscription.settings[key]
    );
  }

  return (
    <div
      className={`notification-container flex justify-between items-center bg-[#fff] p-[16px] border border-neutral_300 rounded-lg ${className}`}
    >
      <div className="flex justify-between items-center gap-[8px] w-full">
        <div className="max-w-[450px]">
          <strong>{subscription.campaign?.title}</strong>
          <p className="text-neutral_500 mt-[6px]">
            {selectedSettings.map((setting, i) => {
              return (
                <span key={setting}>
                  {SETTINGS.find((s: any) => s.value === setting)?.label}
                  {i < selectedSettings.length - 1 && ", "}
                </span>
              );
            })}
          </p>
        </div>
        <div className="flex items-center gap-[8px]">
          <FMultiSelect
            selected={selectedSettings}
            placeholder="Notify me about"
            options={SETTINGS}
            onChange={(selected: string[]) => {
              handleUpdateNotificationSettings(subscription.id, selected);
            }}
            width={"100%"}
          />
          <div
            className="cursor-pointer"
            onClick={() => handleDeleteNotification(subscription.id)}
          >
            <FIcon icon="delete" color="#000F45" size={20} />
          </div>
        </div>
      </div>
    </div>
  );
}
