import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { Report, Sort } from "@types";

const getCampaignReportSummary = async (
  campaign_id?: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/reports/summary?campaign_id=${campaign_id}`, "GET");
};

const getCampaignReportPerformanceGraphs = async (
  campaign_id?: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/reports/performance_tiles?campaign_id=${campaign_id}`,
    "GET"
  );
};

const getCampaignPostsEngagementGraph = async (
  campaign_id: string | number,
  platform?: string,
  dateRange?: string,
  include_paid_media?: boolean
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/reports/posts_engagements_graph/?campaign_id=${campaign_id}&platform=${platform}&date_range=${dateRange}&include_paid_media=${include_paid_media}`,
    "GET"
  );
};

const getCampaignReportsAudienceData = async (
  campaignId: string | number,
  platform: string
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/reports/audience_demographics?campaign_id=${campaignId}&platform=${platform}`,
    "GET"
  );
};

const getReport = async (campaign_id: number): Promise<ApiResponse<Report>> => {
  return apiWrapper(
    `/reports?q[source_id_eq]=${campaign_id}?q[source_type_eq]=campaigns`,
    "GET"
  );
};

export {
  getCampaignReportSummary,
  getCampaignReportPerformanceGraphs,
  getCampaignPostsEngagementGraph,
  getCampaignReportsAudienceData,
  getReport,
};
