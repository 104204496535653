import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { getQueryParamsForSearchRequest } from "@constants/api";
import { Attachment, OutreachTemplate, Sort } from "@types";

const getOutreachTemplateList = async (
  page?: number,
  sort?: Sort,
  params?: object
): Promise<
  ApiResponse<{ templates: OutreachTemplate[]; total_items: number }>
> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);

  return apiWrapper(`/outreach/templates?${queryParams.toString()}`, "GET");
};

const getOutreachTemplate = async (
  outreach_template_id: string | number
): Promise<OutreachTemplate> => {
  const response = await apiWrapper(
    `/outreach/templates/${outreach_template_id}`,
    "GET"
  );

  return response.data;
};

export const updateOutreachTemplateAssets = (
  outreach_template_id: string | number,
  assets: Attachment[],
  assetsToRemove?: Attachment[]
) => {
  const formData = new FormData();

  assets.forEach((attachment, index) => {
    formData.append(
      `outreach_template[assets_attributes][${index}][file]`,
      attachment.file
    );
  });

  (assetsToRemove || []).forEach((attachment, index) => {
    formData.append(
      `outreach_template[assets_attributes][${index}][id]`,
      `${attachment.id}`
    );

    formData.append(
      `outreach_template[assets_attributes][${index}][_destroy]`,
      "true"
    );
  });
  return apiWrapper(
    `/outreach/templates/${outreach_template_id}`,
    "PATCH",
    formData
  );
};

const createOutreachTemplate = async (
  params: Partial<OutreachTemplate>
): Promise<ApiResponse<OutreachTemplate>> => {
  const { assets, ...rest } = params;

  const response = await apiWrapper("/outreach/templates", "POST", rest);

  if (assets?.length) {
    await updateOutreachTemplateAssets(response.data.id, assets);
  }

  return response;
};

const updateOutreachTemplate = async (
  outreach_template_id: string | number,
  params: Partial<OutreachTemplate>,
  assets?: Attachment[],
  assetsToRemove?: Attachment[]
): Promise<ApiResponse<OutreachTemplate>> => {
  let response = await apiWrapper(
    `/outreach/templates/${outreach_template_id}`,
    "PATCH",
    params
  );

  if (assets?.length || assetsToRemove?.length) {
    response = await updateOutreachTemplateAssets(
      outreach_template_id,
      assets,
      assetsToRemove
    );
  }

  return response;
};

const deleteOutreachTemplate = async (
  outreach_template_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/outreach/templates/${outreach_template_id}`, "DELETE");
};

export {
  getOutreachTemplateList,
  getOutreachTemplate,
  createOutreachTemplate,
  updateOutreachTemplate,
  deleteOutreachTemplate,
};
