import React from "react";
import { Link } from "react-router-dom";
import { HorizontalDecorationBadge } from "@components/Cards/CardAssets";
import { formatDate } from "@utils/string_utilities";

interface AssetCardProps {
  id: string;
  cardType: string;
  className?: string;
  cardHeight?: number;
  cardWidth?: number;
  cover_image_url?: string;
  large_cover_image_url?: string;
  imageUrl?: string;
  image_url?: string;
  brief?: {
    go_live_start?: Date;
    go_live_end?: Date;
  };
  decoration?: string;
  title?: string;
  name?: string;
  participants_count?: number;
  date?: string;
  cardDropDownOptions?: { label: string; value: string }[];
  status?: string;
  onOptionSelect?: (value: string, id: string) => void;
}

const AssetCard = ({
  className,
  cardHeight,
  cardWidth,
  ...props
}: AssetCardProps) => {
  const bg_image =
    props.large_cover_image_url || props.cover_image_url || props.imageUrl || props.image_url;
  const stockImage = bg_image && !bg_image.includes("missing_default");
  const dates =
    props.brief?.go_live_start && props.brief?.go_live_end
      ? `${formatDate(props.brief?.go_live_start)} - ${formatDate(
          props.brief?.go_live_end
        )}`
      : "";
  return (
    <>
      <div
        className="relative"
        key={props.id}
        style={{
          minHeight: `${cardHeight}px`,
          minWidth: `${cardWidth}px`,
        }}
      >
        <Link
          to={`${window.location.pathname}/${props.id}`}
          className={`w-full h-auto ${className} z-1`}
          key={props.id}
        >
          <div className="flex flex-col justify-between h-full relative z-10 overflow-hidden border border-[#C9CDD9]">
            <div className="h-[50%] w-full relative">
              <div
                className="w-full h-full absolute top-0 left-0 bg-cover bg-center overflow-hidden"
                style={{ backgroundImage: `url(${bg_image})` }}
              >
                {props.status && (
                  <div className="relative">
                    <HorizontalDecorationBadge decoration={props.status} />
                  </div>
                )}
                {stockImage && (
                  <div className="absolute top-0 left-0 z-1 w-full h-[60px] bg-gradient-to-b from-dark_sky_opacity to-white_opacity" />
                )}
              </div>
            </div>
            <div className="h-[50%] w-full relative">
              <div
                className="w-full h-full absolute top-0 left-0 bg-cover bg-center rotate-180 overflow-hidden z-0"
                style={{ backgroundImage: `url(${bg_image})` }}
              >
                {props.status && (
                  <div className="relative">
                    <HorizontalDecorationBadge decoration={props.status} />
                  </div>
                )}
              </div>
              <div className="bg-light_red_opacity backdrop-blur-xl z-10 w-full px-6 py-4 h-full flex flex-col justify-between items-start">
                <h2 className="text-[16px] font-medium line-clamp-2">
                  {props.title || props.name}
                </h2>

                <div className="flex flex-col justify-center gap-[4px] items-start">
                  <div className="flex flex-row items-center justify-start space-x-2">
                    <p className="text-[15px] text-neutral_600 truncate whitespace-nowrap">
                      {props.date}
                      {dates}
                    </p>
                  </div>
                  <div className="flex items-center justify-start space-x-2">
                    <p className="text-[15px] text-neutral_600 truncate whitespace-nowrap">
                      {props.participants_count || "0"} influencer
                      {props.participants_count === 1 ? "" : "s"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default AssetCard;
