import React, { useState } from "react";
import { Link } from "react-router-dom";
import FDropdown from "../../../FDropdown";
import DecorationBadge from "../../CardAssets";
import FIcon from "../../../FIcon";
import { formatLargeNumber } from "../../../../utils/number_utilities";
import { IndividualCardProps } from "@components/Cards/BasicCard";

const IndividualCard = ({
  decoration,
  cardHeight,
  cardWidth,
  className,
  checkBoxClickList,
  onCheckBoxClick,
  onCardClick,
  ...props
}: IndividualCardProps) => {
  const [hover, setHover] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const ProfileImage =
    props.profile_image ||
    props.creator?.profile_image_url ||
    props.profile_image_url;

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`card-parent w-full relative overflow-hidden h-full min-h-[400px] rounded-xl ${className} ${
        checkBoxClickList && checkBoxClickList.includes(props.id)
          ? "border-2 border-black"
          : "border-2 border-light_red"
      }  ${isDropdownOpen ? "z-50" : "z-10"}`}
      key={props.id}
      style={{
        minHeight: `${cardHeight}px`,
        minWidth: `${cardWidth}px`,
      }}
    >
      {!onCardClick && (
        <Link
          to={`${window.location.origin}/card/${props.creator?.slug || props.slug}`}
          className={`w-full absolute top-0 left-0 z-10`}
          target="_blank"
          style={{ height: "calc(100% - 80px)" }}
          key={props.id}
        />
      )}
      <div
        className="absolute inset-0 overflow-hidden"
        onClick={() =>
          onCardClick &&
          onCardClick({
            ...props,
          })
        }
      >
        <div
          className={`w-full h-full bg-cover bg-center absolute transition-all duration-500 ease-out ${
            hover ? "scale-105" : "scale-100"
          }`}
          style={{ backgroundImage: `url(${ProfileImage})` }}
        >
          <div
            className="absolute top-0 left-0 w-full h-8"
            style={{
              background:
                "linear-gradient(to bottom, rgba(116, 116, 116, 0.5), transparent)",
            }}
          />
          <div
            className="absolute bottom-0 left-0 w-full h-8"
            style={{
              background:
                "linear-gradient(to top, rgba(116, 116, 116, 0.5), transparent)",
            }}
          />
        </div>
      </div>
      {decoration && <DecorationBadge decoration={decoration} />}
      <div className="absolute flex flex-row top-2 left-4 z-20">
        {onCheckBoxClick && (
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              className="w-5 h-5 text-white border-light_red focus:ring-black checked:bg-black focus:ring-none cursor-pointer hover:border-black"
              checked={
                checkBoxClickList && checkBoxClickList.includes(props.id)
              }
              onChange={(e) => {
                e.stopPropagation();

                onCheckBoxClick && onCheckBoxClick(props.id);
              }}
            />
          </div>
        )}
        {(props.decoration || props.status) && (
          <div className="relative">
            <DecorationBadge decoration={props.decoration || props.status} />
          </div>
        )}
      </div>
      <div className="absolute bottom-0 w-full">
        <div className="h-14 bg-gradient-to-t from-[rgba(116,116,116,0.5)] to-transparent relative left-0" />
        <div
          className={`px-4 py-2 bottom-0 transition-all duration-200 ease-in-out 
          bg-light_red_opacity backdrop-blur-xl flex flex-col justify-between items-start
          rounded-b-lg h-[45px] ${
            hover ? "bg-light_red bg-opacity-100" : "bg-light_red bg-opacity-80"
          }`}
        >
          <div className="flex flex-row justify-between items-center w-full">
            {props.full_name && (
              <a
                href={`${window.location.origin}/card/${props.creator?.slug || props.slug}`}
                target="_blank"
                className="w-full"
              >
                <p className="text-[20px] text-blue_night_sky font-sofia-pro truncate w-[95%]">
                  {props.full_name}
                </p>
              </a>
            )}
            {props.cardDropDownOptions && (
              <FDropdown
                options={props.cardDropDownOptions}
                onChange={(value) => props.onOptionSelect(value, props.id)}
                width="auto"
                height="28"
                iconOnly={true}
                iconColor="black"
                icon="ellipsis"
                className="ml-auto"
                zIndex={999}
                onOpenChange={setIsDropdownOpen}
              />
            )}
          </div>
          {props.socials && Object.keys(props.socials).length > 0 && (
            <div className="flex flex-row justify-between items-start w-full">
              <div className="flex flex-row gap-4 items-start">
                {Object.values(props.socials)
                  .filter((platform: any) => platform.followers_count > 0)
                  .sort(
                    (a: any, b: any) => b.followers_count - a.followers_count
                  )
                  .slice(0, 1)
                  .map((platform: any) => (
                    <div
                      className="flex items-center gap-2"
                      key={platform.name}
                    >
                      <FIcon size={13} icon={platform.name} color="black" />
                      <p className="text-sm truncate text-black whitespace-nowrap">
                        {formatLargeNumber(platform.followers_count)} followers
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IndividualCard;
