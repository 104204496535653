import React, { useEffect, useState } from "react";
import { getReportInfluencerBreakdown } from "../../api/Reports";
import { formatNumber } from "@utils/number_utilities";

const ReportInfluencerBreakdown = ({ report_id }) => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState([]);

  useEffect(() => {
    const fetchOverview = async () => {
      setLoading(true);

      const response = await getReportInfluencerBreakdown(report_id);

      setStats(response.data);

      setLoading(false);
    };

    fetchOverview();
  }, [report_id]);

  const formatRange = (rangeString) => {
    // Split the input string by '..'
    const [start, end] = rangeString.split("..").map(Number);

    // Function to format the number
    const formatNumber = (num) => {
      if (num >= 1000000) {
        return `${Math.floor(num / 1000000)}M`; // Format as millions
      } else if (num >= 1000) {
        return `${Math.floor(num / 1000)}k`; // Format as thousands
      }
      return num.toString(); // Return the number as-is if less than 1000
    };

    // Format the start and end values and return the formatted range
    return `${formatNumber(start)}-${formatNumber(end)}`;
  };

  // Render only if the data is loaded
  if (loading || !stats) {
    return null; // or you can return a loading spinner
  }

  return (
    <div>
      <h1 className="my-6">Influencer Breakdown</h1>

      <div className="flex justify-between border border-[#EDEFF4] p-4">
        {stats
          .filter((stat) => stat.impressions > 0)
          .map((stat, index) => (
            <div
              key={index}
              className="flex-1 text-center px-2 border-r last:border-none border-[#EDEFF4]"
            >
              <p className="text-3xl font-bold capitalize">{stat.tier}</p>
              <p className="text-sm text-gray-600 mb-4 border-b border-[#EDEFF4]">
                {formatRange(stat.followers)} followers
              </p>
              <p className="text-3xl font-bold">
                {formatNumber(stat.impressions)}
              </p>
              <p className="text-sm text-gray-600 mb-4">Views</p>
              <p className="text-3xl font-bold">
                {formatNumber(stat.engagements)}
              </p>
              <p className="text-sm text-gray-600 mb-4">Engagements</p>
              <p className={`text-3xl font-bold text-[#c4aa00]`}>
                {stat.engagement_rate}%
              </p>
              <p className="text-sm text-gray-600">Engagement Rate</p>
              <p className="text-sm text-gray-600 mb-4">
                Tier average: {stat.engagement_average}%
              </p>

              <p className={`text-3xl font-bold text-[#59BBDB]`}>
                {stat.viewership_rate}%
              </p>
              <p className="text-sm text-gray-600">Viewership Rate</p>
              <p className="text-sm text-gray-600 mb-4">
                Tier average: {stat.viewership_average}%
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ReportInfluencerBreakdown;
