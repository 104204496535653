export const platformList = [
  { label: "Instagram", value: "instagram", icon: "instagram" },
  { label: "TikTok", value: "tiktok", icon: "tiktok" },
  { label: "YouTube", value: "youtube", icon: "youtube" },
  { label: "Facebook", value: "facebook", icon: "facebook" },
];

export const timeRanges = [
  { label: "This Week", value: "This Week" },
  { label: "This Month", value: "This Month" },
  { label: "All Time", value: "All Time" },
];

export const modalStyles = {
  zIndex: 20,
  overlay: {
    backgroundColor: "rgba(40, 41, 55, 0.7)",
    zIndex: 99,
  },
  content: {
    backgroundColor: "#fff",
    bottom: 0,
    top: 0,
    position: "fixed",
    left: 0,
    right: 0,
    padding: "0",
    width: "100%",
    maxWidth: "788px",
    margin: "auto",
  },
};

export const oppurtunitiesSubNavItems = [
  {
    label: "Briefs",
    value: "briefs",
    link: "/opportunities/briefs",
  },
  {
    label: "Bulletins",
    value: "bulletins",
    link: "/opportunities/bulletins",
  },
];

export const settingsSubNavItems = [
  {
    label: "Personal Settings",
    value: "edit",
    link: "/brands/edit",
  },
  {
    label: "Brand Settings",
    value: "brand edit",
    link: "/brands/brand/edit",
  },
  {
    label: "Data Privacy",
    value: "data privacy",
    link: "/brands/privacy",
  },
];

export const reportSubNavItems = [
  {
    label: "Report Information",
    value: "profile",
    link: "",
  },
  {
    label: "Report Content",
    value: "content",
    link: "/content",
  },
];

export const ambassadorsSubNavItems = [
  {
    label: "My Ambassadors",
    value: "ambassadors",
    link: "/ambassadors",
  },
  {
    label: "New Applicants",
    value: "new applicants",
    link: "/ambassadors/interested",
  },
  {
    label: "Rejected Applicants",
    value: "rejected applicants",
    link: "/ambassadors/rejected",
  },
  {
    label: "All Applicants",
    value: "all applicants",
    link: "/ambassadors/all",
  },
];

export const allSearchCategories = [
  {
    value: "3",
    label: "Fashion",
    icon: "fashion",
  },
  {
    value: "4",
    label: "Lifestyle",
    icon: "lifestyle",
  },
  {
    value: "37",
    label: "Makeup",
    icon: "makeup",
  },
  {
    value: "24",
    label: "Travel",
    icon: "travel",
  },
  {
    value: "44",
    label: "Sports",
    icon: "sports",
  },
  {
    value: "32",
    label: "Gaming",
    icon: "gaming",
  },
  {
    value: "31",
    label: "Fitness",
    icon: "fitness",
  },
  {
    value: "17",
    label: "Parenting",
    icon: "parenting",
  },
  {
    value: "40",
    label: "Food",
    icon: "food",
  },
  {
    value: "23",
    label: "Art & Design",
    icon: "art",
  },
  {
    value: "10",
    label: "Photography",
    icon: "photography",
  },
  {
    value: "30",
    label: "Fragrance",
    icon: "fragrance",
  },
  {
    value: "33",
    label: "Hair",
    icon: "hair",
  },
  {
    value: "43",
    label: "Outdoors",
    icon: "sun",
  },
  {
    value: "34",
    label: "Wellness & Mindfulness",
    icon: "wellness",
  },
  {
    value: "38",
    label: "Sustainability",
    icon: "sustainability",
  },
  {
    value: "41",
    label: "Music & Entertainment",
    icon: "music",
  },
  {
    value: "35",
    label: "Home Decor & DIY",
    icon: "home-2",
  },
  {
    value: "39",
    label: "Skincare",
    icon: "skincare",
  },
  {
    value: "42",
    label: "Pets",
    icon: "pets",
  },
  {
    value: "45",
    label: "Education",
    icon: "whiteboard",
  },
  {
    value: "46",
    label: "Business Owner",
    icon: "business",
  },
  {
    value: "47",
    label: "Professional",
    icon: "professionals",
  },
  {
    value: "48",
    label: "Jewelry",
    icon: "jewelry",
  },
  {
    value: "49",
    label: "College Students",
    icon: "graduation-cap",
  },
];
