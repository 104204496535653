import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import {
  CampaignContentReview,
  ContentReviewComment,
  ContentReviewContent,
} from "types/content-review";

const BASE_URL = "/content_review";

export const getContentReviewForCampaign = async (
  contentReviewId: number
): Promise<CampaignContentReview> => {
  const response = await apiWrapper(
    `${BASE_URL}/campaigns/${contentReviewId}`,
    "GET"
  );

  return response.data;
};

export const getContentsForReview = async (
  campaignId: number
): Promise<CampaignContentReview> => {
  const response = await apiWrapper(
    `${BASE_URL}/contents?q[campaign_id_eq]=${campaignId}`,
    "GET"
  );

  return response.data;
};

export const getContentReviewForParticipant = async (
  participantId: number
): Promise<ApiResponse<any>> => {
  const queryParams = new URLSearchParams();

  const params = {
    fohr_campaigns_participant_id_in: participantId,
  };

  Object.keys(params).forEach((param) => {
    queryParams.append(`q[${param}]`, params[param]);
  });

  return apiWrapper(
    `${BASE_URL}/participants?${queryParams.toString()}`,
    "GET"
  );
};

export const updateContentStatus = async (
  contentId: number,
  status: string
): Promise<ApiResponse<any>> => {
  return apiWrapper(`${BASE_URL}/contents/${contentId}`, "PATCH", {
    content: { status },
  });
};

export const addFeedback = async (params: {
  content_id: number;
  round_id: number;
  subject: string;
  body: string;
}): Promise<ApiResponse<any>> => {
  return apiWrapper(`${BASE_URL}/feedbacks`, "POST", params);
};

export const addContent = async (params: {
  participant_id: number;
  deliverable_id: number;
  campaign_id: number;
  status: string;
  caption: string;
}): Promise<ApiResponse<ContentReviewContent>> =>
  apiWrapper(`${BASE_URL}/contents`, "POST", params);

export const updateContent = async (
  contentId: number,
  params: {
    caption: string;
  }
): Promise<ApiResponse<ContentReviewContent>> =>
  apiWrapper(`${BASE_URL}/contents/${contentId}`, "PATCH", params);

export const uploadContentReviewAsset = async (params: {
  content_id: number;
  file: string;
  filename: string;
  position?: number;
}): Promise<ApiResponse<string>> => {
  const formData = new FormData();

  Object.keys(params).forEach((key) => {
    formData.append(`asset[${key}]`, params[key]);
  });

  return apiWrapper(`${BASE_URL}/assets`, "POST", formData);
};

export const updateContentReviewAsset = async (
  assetId: number,
  params: {
    asset: { position: number };
  }
): Promise<ApiResponse> =>
  apiWrapper(`${BASE_URL}/assets/${assetId}`, "PATCH", params);

export const deleteContentReviewAsset = async (
  assetId: number
): Promise<ApiResponse> =>
  apiWrapper(`${BASE_URL}/assets/${assetId}`, "DELETE");

export const createRound = async (params: {
  content_id: number;
  caption: string;
  current: boolean;
}): Promise<ApiResponse<ContentReviewContent>> =>
  apiWrapper(`${BASE_URL}/rounds`, "POST", { round: params });

export const addComment = async (params: {
  content_id: number;
  round_id: number;
  brand_user_id: number;
  body: string;
}): Promise<ApiResponse<ContentReviewComment>> => {
  return apiWrapper(`${BASE_URL}/comments`, "POST", params);
};

export const getComments = async (
  contentId: number
): Promise<ApiResponse<{ comments: ContentReviewComment[] }>> => {
  const queryParams = new URLSearchParams();

  const params = {
    content_id_eq: contentId,
  };

  Object.keys(params).forEach((param) => {
    queryParams.append(`q[${param}]`, params[param]);
  });

  return apiWrapper(`${BASE_URL}/comments?${queryParams.toString()}`, "GET");
};

export const updateComment = async (
  commentId: number,
  params: Partial<ContentReviewComment>
): Promise<ApiResponse<ContentReviewComment>> => {
  return apiWrapper(`${BASE_URL}/comments/${commentId}`, "PATCH", params);
};

export const deleteComment = async (
  commentId: string
): Promise<ApiResponse> => {
  return apiWrapper(`${BASE_URL}/comments/${commentId}`, "DELETE");
};
