import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import MultiPageLayout from "@layouts/MultiPageLayout";
import FButton from "@components/FButton";
import { AmbassadorsContext } from "@contexts/index";
import AmbassadorOnboardingSettings from "./settings";
import FIcon from "@components/FIcon";
import { showBrand } from "@api/brands";
import useAuth from "@hooks/useAuth";
import { Brand } from "@types";
import LoadingSpinner from "@components/LoadingSpinner";

const subNavItems = [
  { label: "Accepted", value: "accepted", link: "accepted" },
  { label: "Applicants", value: "applicants", link: "applicants" },
  { label: "Content", value: "content", link: "content" },
  /*  { label: "Stats", value: "stats", link: "stats" }, */
];

const Ambassadors = () => {
  const [content, setContent] = useState(null);
  const [stats, setStats] = useState(null);

  const [brand, setBrand] = useState<Brand>(null);

  const navigate = useNavigate();

  const { user } = useAuth();

  const location = useLocation();

  useEffect(() => {
    const loadBrand = async () => {
      const brand = await showBrand(user.brand_id);

      await setBrand(brand);
    };

    loadBrand();
  }, []);

  if (!brand) {
    return <LoadingSpinner className="h-screen" />;
  }

  const isSettings = location.pathname.includes("settings");

  return (
    <AmbassadorsContext.Provider
      value={{
        brand,
        content,
        stats,
        setBrand,
        setContent,
        setStats,
      }}
    >
      {isSettings ? (
        <div>
          <Link className="flex items-center mb-4" to={`/ambassadors`}>
            <FIcon icon="back" size={18} color="#000F45" />
            Go back to ambassadors
          </Link>
          <AmbassadorOnboardingSettings />
        </div>
      ) : (
        <MultiPageLayout
          pageName="Ambassadors"
          subNavItems={subNavItems}
          headerButtons={
            <div className="flex flex-row gap-2">
              {brand?.amp_description ? (
                <FButton
                  iconLeft={{ name: "settings", size: 20, color: "black" }}
                  label="Settings"
                  onClick={() => {
                    navigate("/ambassadors/onboarding-settings");
                  }}
                />
              ) : null}
              <FButton
                primary
                label="Add Ambassador"
                width="w-[100px]"
                height="h-[30px]"
              />
            </div>
          }
        >
          <div className="pt-[40px]">
            <Outlet />
          </div>
        </MultiPageLayout>
      )}
    </AmbassadorsContext.Provider>
  );
};

export default Ambassadors;
