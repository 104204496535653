import axios from "axios";

import { DirectUpload } from "@rails/activestorage";
import { LOCAL_API_ORIGIN } from "@constants/api";

const ACTIVE_STORAGE_UPLOAD_URL = `${LOCAL_API_ORIGIN}/api/v1/direct_uploads`;

export const uploadContract = async (params: {
  htmlContent: string;
  participant: string;
}): Promise<{ docUrl: string }> => {
  try {
    const response = await axios.post(
      "http://localhost:4000/upload-contract",
      params,
      {
        withCredentials: false,
      }
    );

    console.log("Upload successful:", response.data);

    return response.data;
  } catch (error) {
    console.error("Error uploading the file:", error);
  }
};

export const uploadToActiveStorage = async (
  files: { file: File; position: number }[],
  onLoadedEvent: (loaded: number, index: number) => void
): Promise<{ filename: string; file: string; position: number }[]> =>
  new Promise((resolve) => {
    const uploads = files.map(() => null);

    files.forEach(({ file, position }, index) => {
      const upload = new DirectUpload(file, ACTIVE_STORAGE_UPLOAD_URL, {
        directUploadWillCreateBlobWithXHR: (xhr) => {
          xhr.withCredentials = true;
        },
        directUploadWillStoreFileWithXHR: (request) => {
          request.upload.addEventListener(
            "progress",
            function (event) {
              if (event.lengthComputable) {
                onLoadedEvent(event.loaded, index);
              }
            },
            false
          );
        },
      });

      upload.create((error, blob) => {
        if (error) {
          uploads[index] = {
            error: true,
          };
        } else {
          uploads[index] = {
            filename: blob.filename,
            file: blob.signed_id,
            position,
          };
        }

        if (uploads.filter(Boolean).length === files.length) {
          resolve(uploads);
        }
      });
    });
  });
