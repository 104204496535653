import React, { useState, useEffect } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import { useParams } from "react-router-dom";
import BriefPageTemplate from "@components/Campaign/Brief/BriefPageTemplate";

import apiClient from "@apiClient";
const { update, create } = apiClient.contract_template_sections;

import toast from "react-hot-toast";
import { getContractTemplate } from "@api/ContractTemplates/ContractTemplates";

function ContractTemplate() {
  const { id: contract_template_id } = useParams<{
    id: string;
  }>();
  const [template, setTemplate] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const findChanges = (initialSections, changedSections) => {
    const changes = [];

    changedSections.forEach((changedSection) => {
      const initialSection = initialSections.find(
        (section) => section.id === changedSection.id
      );

      if (initialSection) {
        const sectionChanges = { id: changedSection.id };

        if (changedSection.name !== initialSection.name) {
          sectionChanges.name = changedSection.name;
        }

        if (changedSection.body !== initialSection.body) {
          sectionChanges.body = changedSection.body;
        }

        if (Object.keys(sectionChanges).length > 1) {
          changes.push(sectionChanges);
        }
      }
    });

    return changes;
  };

  const handleSave = async (initialValues, changedValues, values) => {
    try {
      const updatedSections = [];
      if (
        changedValues.custom_sections &&
        changedValues.custom_sections.length > 0
      ) {
        const changes = findChanges(
          initialValues.custom_sections,
          values.custom_sections
        );

        for (const section of changes) {
          if (template.sections.some((s) => s.id === section.id)) {
            // Update existing section
            const updateParams = {
              contract_template_section: {
                name: section.name,
                body: section.body,
              },
            };
            if (section.name) updateParams.name = section.name;
            if (section.body) updateParams.body = section.body;

            const updatedSection = await update(
              contract_template_id,
              section.id,
              updateParams
            );
            updatedSections.push(updatedSection);
          } else {
            // Create new section
            const createParams = {
              contract_template_id: template.id,
              kind: "contract",
              name: section.name,
              position: 1,
              body: section.body,
              custom: true,
            };
            const newSection = await create(contract_template_id, createParams);
            updatedSections.push(newSection);
          }
        }
      }
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        sections: prevTemplate.sections.map(
          (section) =>
            updatedSections.find((updated) => updated.id === section.id) ||
            section
        ),
      }));
    } catch (error) {
      toast.error("Error:", error);
      console.error("Error saving contract template:", error);
    } finally {
      toast.success("Saved");
    }
  };

  useEffect(() => {
    const addCustomToSections = (template) => {
      if (template && template.sections) {
        return {
          ...template,
          sections: template.sections.map((section) => ({
            ...section,
            custom: true,
          })),
        };
      }
      return template;
    };

    const getTemplate = async () => {
      setLoading(true);
      try {
        const result = await getContractTemplate(contract_template_id);
        if (result.success) {
          setTemplate(result.data);
        } else {
          throw new Error(result.message);
        }
      } catch (error) {
        console.error("Error fetching contract template:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchAndModifyTemplate = async () => {
      await getTemplate();

      setTemplate((prevTemplate) => addCustomToSections(prevTemplate));
    };

    fetchAndModifyTemplate();
  }, [contract_template_id]);

  if (loading || !template) {
    return <LoadingSpinner />;
  }

  return (
    <div className="bg-white rounded-xl shadow-md pt-10 max-w-[1000px] mx-auto">
      {template && template.name && (
        <BriefPageTemplate
          brief={template}
          pageTitle={template.name}
          pageDescription="Please only adjust with the approval from legal"
          customSectionName="all"
          pageTitleCMSName=""
          handleSave={handleSave}
        />
      )}
    </div>
  );
}

export default ContractTemplate;
