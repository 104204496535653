import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner from "@components/LoadingSpinner";
import { BulletinData } from "@apiTypes";
import apiClient from "@apiClient";
import "App.css";
import FButton from "@components/FButton";
import FBadge from "@components/FBadge";

export default function BulletinsProfile() {
  const [bulletin, setBulletin] = useState<BulletinData>();
  const { bulletin_id } = useParams<{ bulletin_id: string }>();
  const loading = !bulletin;

  useEffect(() => {
    async function loadBulletin() {
      const result = await apiClient.bulletins.get(bulletin_id);
      console.log({ result });
      if (result.success) {
        setBulletin(result.data);
      }
    }

    loadBulletin();
  }, [bulletin_id]);

  if (loading) {
    return <LoadingSpinner className="w-full h-[90vh]" />;
  } else {
    return (
      <div
        className="p-[150px] h-[100vh] overflow-auto"
        style={{
          background: `linear-gradient(rgba(20,20,20, .6), rgba(20,20,20, .6)), url(${bulletin.image_url})`,
          backgroundSize: "cover",
        }}
      >
        <FBadge
          label={bulletin.category}
          backgroundColor="black"
          color="white"
          className="capitalize mb-[50px]"
        />

        <h1 className="text-white font-bold text-[100px] leading-[100px] pb-[3rem]">
          {bulletin.name}
        </h1>
        <div
          dangerouslySetInnerHTML={{ __html: bulletin.description }}
          className="bulletin-content text-white text-[22px]"
        ></div>

        <div className="pt-10 text-white flex gap-[1.5rem]">
          <FButton label="Continue" />
          <FButton label="Not Interested" />
        </div>
      </div>
    );
  }
}
