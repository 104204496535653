import React, { useEffect, useState } from "react";
import { useParams, Outlet } from "react-router-dom";
import { Campaign } from "../../../src//types/types";

import BlankState from "@components/BlankState";
import LoadingSpinner from "@components/LoadingSpinner";
import MultiPageCardLayout from "@layouts/MultiPageLayout";

import apiClient from "@apiClient";
const { campaigns } = apiClient;

const GiftTableProfile = () => {
  const { giftTable_id } = useParams<{ giftTable_id: string }>();
  const [giftTable, setGiftTable] = useState<Campaign>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGiftTable = async () => {
      setLoading(true);
      const response = await campaigns.get(giftTable_id);

      setGiftTable(response.data);

      setLoading(false);
    };

    fetchGiftTable();
  }, [giftTable_id]);

  const subNavItems = [
    {
      label: "GiftTables",
      value: "giftTables",
      link: `/giftTables/${giftTable_id}/giftTables`,
    },
    {
      label: "Content",
      value: "content",
      link: `/giftTables/${giftTable_id}/content`,
    },
  ];

  if (loading) {
    return <LoadingSpinner className="w-full h-full h-[300px]" />;
  }

  return (
    <div>
      {giftTable ? (
        <>
          <MultiPageCardLayout
            pageName={giftTable.title}
            subNavItems={subNavItems}
          >
            <Outlet />
          </MultiPageCardLayout>
        </>
      ) : (
        <div className="flex items-center justify-center mt-[200px]">
          <BlankState
            title={"Table not Found"}
            subtitle={"Please try another link or reach out to support"}
            icon={"warning-2"}
            onActionClick={() => {
              window.location.href = "/";
            }}
            actionLabel={"Go Home"}
          />
        </div>
      )}
    </div>
  );
};

export default GiftTableProfile;
