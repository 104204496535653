import moment from "moment";
import FDatePicker from "./FDatePicker";

interface FRangePickerProps {
  minDate?: string;
  value: {
    from: string;
    to: string;
  };
  onChange: (value: { from: string; to: string }) => void;
}

const FRangePicker = ({ minDate, value, onChange }: FRangePickerProps) => {
  const handleUpdateFrom = (from) => {
    let to = value.to;

    if (moment(value.to).isBefore(from)) {
      to = from;
    }

    onChange({
      from,
      to,
    });
  };

  return (
    <div className="flex gap-4">
      <FDatePicker
        disabled={{
          before: moment(minDate).toDate(),
          after: null,
        }}
        label="From"
        value={value.from}
        onSelectedValue={(value) => handleUpdateFrom(value)}
      />
      <FDatePicker
        disabled={{
          before: moment(value.from).toDate(),
          after: null,
        }}
        label="To"
        value={value.to}
        onSelectedValue={(newValue) =>
          onChange({
            ...value,
            to: newValue,
          })
        }
      />
    </div>
  );
};

export default FRangePicker;
