import { statusNames } from "@utils/string_utilities";
import * as React from "react";

function ContentReviewStatusBadge({
  status,
  round,
  children,
}: {
  status: string;
  round?: string;
  children?: any;
}): JSX.Element {
  const getStatusColor = (status) => {
    const greenStatuses = ["active", "accepted", "approved", "soft_approved"];
    const blueStatuses = ["finished", "viewed", "live"];
    const yellowStatuses = [
      "pending",
      "onboarding",
      "admin_review",
      "client_review",
      "influencer_review",
      "Round 1",
      "Round 2",
    ];
    const redStatuses = [
      "admin_changes",
      "rejected",
      "influencer_changes",
      "Round 3",
      "Round 4",
      "Round 5",
    ];

    if (greenStatuses.includes(status)) {
      return "green";
    } else if (yellowStatuses.includes(status)) {
      return "yellow";
    } else if (blueStatuses.includes(status)) {
      return "blue";
    } else if (redStatuses.includes(status)) {
      return "red";
    }

    return "neutral_300";
  };

  const getStatusNameByType = (status, round) => {
    if (round) {
      return statusNameWithRound(status, round);
    } else {
      return statusNames(status);
    }
  };

  const statusNameWithRound = (status, round) => {
    switch (status) {
      case "approved":
        return "Approved";
      case "soft_approved":
        return "Approved";
      case "live":
        return "Live";
      case "client_review":
        return `R${round} Review`;
      case "influencer_review":
        return `R${round} Review`;
      case "influencer_changes":
        return `R${round} Edits`;
      case "none":
        return "";
      default:
        return status;
    }
  };

  const color = getStatusColor(status);

  return (
    <div
      className={`text-${color === "neutral_300" ? "black" : "white"} rounded-xl p-2 bg-${color}`}
    >
      {getStatusNameByType(status, round)}
      {children}
    </div>
  );
}

export default ContentReviewStatusBadge;
