import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { capitalize, groupBy, isEmpty, isNumber, sortBy } from "lodash";
import { Content, Platform } from "@types";
import { formatLargeNumber } from "@utils/number_utilities";
import getMedian from "@utils/array";

const INPUT_DATA_FORMAT = "MMM DD, YYYY";

const COLORS = ["#B0B5C4", "#47B37F", "#E47667"];

const PLOTLINE_OPTIONS = {
  color: "#000721",
  dashStyle: "dash",
  width: 2,
};

const OPTIONS = {
  xAxis: {
    labels: {
      formatter: function () {
        return moment(this.value).format("MMM 'YY");
      },
      type: "datetime",
      startOnTick: true,
      endOnTick: true,
    },
  },
  tooltip: {
    formatter: function () {
      console.log(this);
      return (
        "Platform: <b>" +
        this.series.name +
        "</b>, Day: <b>" +
        moment(this.x).format("MMM D 'YY") +
        "</b>, Views: <b>" +
        formatLargeNumber(this.y) +
        "</b>"
      );
    },
  },
};

interface ViewershipOverTimeProps {
  content: Content[];
  platforms: Platform[];
  selectedPlatform: string;
}

export default function ViewershipOverTime({
  content,
  platforms,
  selectedPlatform,
}: ViewershipOverTimeProps) {
  const contentByPlatform = groupBy(content, ({ platform }) => platform);

  const series = platforms.reduce((result, platform) => {
    const { name } = platform;

    if (selectedPlatform && selectedPlatform !== name) {
      return result;
    }

    const contentForPlatform = contentByPlatform[name];

    const contentSortedByTime = sortBy(
      contentForPlatform,
      ({ published_at: publishedAt }) =>
        moment(publishedAt, INPUT_DATA_FORMAT).valueOf()
    );

    const data = contentSortedByTime.map((item) => {
      const { published_at: publishedAt, stats } = item;

      const { views_count } = stats;

      return [
        moment(publishedAt, INPUT_DATA_FORMAT).valueOf(),
        isNumber(views_count) ? views_count : parseFloat(views_count),
      ];
    });

    if (!isEmpty(data)) {
      return [
        ...result,
        {
          data,
          name: name === "tiktok" ? "TikTok" : capitalize(name),
          color: COLORS[result.length],
        },
      ];
    }

    return result;
  }, []);

  const median = getMedian(
    content.map(({ stats: { views_count } }) => views_count)
  );

  const options = {
    ...OPTIONS,
    xAxis: {
      ...OPTIONS.xAxis,
      //  categories,
    },
    yAxis: {
      plotLines: [
        {
          ...PLOTLINE_OPTIONS,
          value: median,
        },
      ],
    },
    series,
  };

  return (
    <div>
      {content?.length > 0 ? (
        <div>
          <p className="mb-4 text-sm w-full">Viewership over time</p>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      ) : (
        <p className="text-center mt-[23px] text-neutral_500 text-[18px]">
          No recent posts
        </p>
      )}
    </div>
  );
}
