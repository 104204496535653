import React, { useContext } from "react";

import FIcon from "@components/FIcon";
import FDatePicker from "@components/FDatePicker/FDatePicker";
import { CampaignBrief } from "@types";
import FileUpload from "@components/FileUpload";

import moment from "moment";

import FRangePicker from "@components/FDatePicker/FRangePicker";
import { CampaignContext } from "@contexts/index";
import {
  updateParticipantBrief,
  uploadParticipantBriefFiles,
} from "@api/CampaignParticipantBriefs/CampaignParticipantBriefs";
import {
  updateCampaignBrief,
  uploadCampaignBriefFiles,
} from "@api/CampaignBriefs/CampaignBriefs";

const MISSING_IMAGE_URL =
  "https://s3.amazonaws.com/assets.fohrcard.com/uploads/x4OGW62/missing_default.png";

interface BriefSettingsProps {
  brief: CampaignBrief;
  onUpdate: (brief: Partial<CampaignBrief>) => void;
  onBriefUpdated: (brief: CampaignBrief) => void;
}

export default function BriefSettings({
  brief,
  onUpdate,
  onBriefUpdated,
}: BriefSettingsProps) {
  const { campaign } = useContext(CampaignContext);

  const { owner_user: manager } = campaign;

  const handleUpdateBrief = (updates) =>
    onBriefUpdated({ ...brief, ...updates });

  const uploadFunction = brief?.participant_id
    ? uploadParticipantBriefFiles
    : uploadCampaignBriefFiles;

  const handleRemoveImage = async (propertyName: string) => {
    const updateFunction = brief?.participant_id
      ? updateParticipantBrief
      : updateCampaignBrief;

    await updateFunction(brief.id, {
      campaign_brief: {
        [propertyName]: null,
      },
    });

    handleUpdateBrief({
      [propertyName]: { large: MISSING_IMAGE_URL, original: MISSING_IMAGE_URL },
    });
  };

  const uploadName = brief?.participant_id
    ? "participant_brief"
    : "campaign_brief";

  return (
    <>
      <div className="mb-10 border-t border-light_border pt-10">
        <div className="mb-8">
          <h3 className="text-xl">Timelines</h3>
        </div>
        <div className="flex flex-col gap-4">
          <FRangePicker
            minDate={moment().format()}
            value={{
              from: brief.go_live_start,
              to: brief.go_live_end,
            }}
            onChange={({ from, to }) =>
              onUpdate({
                go_live_start: from,
                go_live_end: to,
              })
            }
          />

          <div className="w-full">
            <div className="flex flex-row items-center justify-start  mb-[7px] text-[15px]">
              <p>Application Deadline</p>
            </div>
            <FDatePicker
              disabled={{
                after: null,
                before: moment(brief.submit_application).toDate(),
              }}
              label="Start Date"
              value={brief.submit_application}
              onSelectedValue={(value) =>
                onUpdate({
                  submit_application: value,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="border-t border-light_border pt-10">
        <div className="mb-8">
          <h3 className="text-xl mb-2">People</h3>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex-row flex gap-4">
            <div className="w-full">
              <div className="flex flex-row items-center justify-start  mb-[7px] text-[15px]">
                <FIcon
                  icon="user-bold"
                  size={14}
                  className="mr-2 relative -top-[2px]"
                  color="#000F45"
                />
                <p>Campaign Manager</p>
              </div>
              <p className="font-light text-sm">{manager?.full_name}</p>
              <p className="font-light mb-4 text-sm">{manager?.email}</p>
            </div>
          </div>
        </div>
        <div className="pt-8 pb-10 border-t border-light_border">
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Logo Image</h3>
          </div>
          <FileUpload
            type="image"
            url={brief.logo_image?.original || brief.logo_image?.large}
            id="logo_image"
            name="logo_image"
            apiFunction={uploadFunction}
            onUpdate={(logo_image, response) => {
              const updates = {
                logo_image: (response as CampaignBrief).logo_image,
              };

              handleUpdateBrief(updates);
            }}
            onDelete={() => handleRemoveImage("logo_image")}
            resourceName={uploadName}
            resourceId={brief.id}
            includeHover={true}
          />
        </div>
        <div className="pt-8 pb-10 border-t border-light_border">
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Cover Image</h3>
          </div>
          <FileUpload
            type="image"
            url={brief.cover_image.original || brief.cover_image.large}
            id="cover_image"
            name="cover_image"
            apiFunction={uploadFunction}
            onUpdate={(cover_image, response) => {
              const updates = {
                cover_image: (response as CampaignBrief).cover_image,
              };

              handleUpdateBrief(updates);
            }}
            onDelete={() => handleRemoveImage("cover_image")}
            resourceName={uploadName}
            resourceId={brief.id}
            includeHover={true}
          />
        </div>
        <div className="pt-8 pb-10 border-t border-light_border">
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Mood Image</h3>
          </div>
          <FileUpload
            type="image"
            url={brief.mood_image?.original}
            id="mood_image"
            name="mood_image"
            apiFunction={uploadFunction}
            onUpdate={(mood_image, response) => {
              const updates = {
                mood_image: (response as CampaignBrief).mood_image,
              };

              handleUpdateBrief(updates);
            }}
            onDelete={() => handleRemoveImage("mood_image")}
            resourceName={uploadName}
            resourceId={brief.id}
            includeHover={true}
          />
        </div>
      </div>
    </>
  );
}
