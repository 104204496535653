import FButton from "@components/FButton";
import moment from "moment";

export interface Comment {
  author_name: string;
  body: string;
  created_at: string;
}

export interface CommentProps {
  comment: Comment;
  onDelete: () => void;
  isDeleting: boolean;
  onEdit: () => void;
}

const Comment = ({ comment, onDelete, isDeleting, onEdit }: CommentProps) => {
  const { author_name, body, created_at } = comment;

  return (
    <div className="mb-8 relative">
      <p className="mb-1 font-bold text-sm">{author_name}</p>
      <p className="mb-1 opacity-80 pr-[20px]">{body}</p>
      <p className="text-sm text-default_weak">
        {moment(created_at).format("MMM DD, H:mm A")}
      </p>
      <div className="flex gap-0 absolute top-0 right-0">
        <FButton
          className=""
          icon={{ color: "#000F45", name: "edit", size: 14 }}
          iconButton
          onClick={onEdit}
        />
        {onDelete ? (
          <FButton
            className=""
            icon={{ color: "#000F45", name: "delete", size: 14 }}
            iconButton
            loading={isDeleting}
            onClick={onDelete}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Comment;
