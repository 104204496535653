import React, { useCallback, useState, useContext } from "react";

import apiClient from "@apiClient";

import { Sort, SortOrder } from "@types";

import CreateOrUpdateCampaignModal from "@pages/campaigns/components/CreateOrUpdateCampaignModal";
import { getCampaignList } from "@api/Campaign/Campaigns";
import { UserContext } from "@contexts/index";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import { useNavigate } from "react-router-dom";
import { userInfo } from "os";

const defaultSort = {
  key: "created_at",
  direction: "desc" as SortOrder,
};

const { campaigns } = apiClient;
const { memberships } = apiClient;

const cardDropDownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Archive", value: "archive" },
  { label: "Add Me", value: "addMembership" },
];

const filterOptions = [
  { label: "All Campaigns", static: "Campaigns", value: null },
  { label: "Active", static: "Campaigns", value: "active" },
  { label: "Proposal", static: "Campaigns", value: "proposal" },
  { label: "Finished", static: "Campaigns", value: "finished" },
];

const actions = {
  archive: async (id: number) =>
    campaigns.update(id, {
      status: "archived",
    }),
  addMembership: async (brand_id: number) =>
    memberships.create( {
      brand_id: brand_id,
      user_id: user.id
    }),
};


const CampaignsAdmin = () => {
  const [
    isCreateOrUpdateCampaignModalVisible,
    setCreateOrUpdateCampaignModalVisible,
  ] = useState(false);

  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const getCampaigns = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params || {};

      const response = await getCampaignList(page, sort || defaultSort, true, {
        status_eq: filter,
        title_i_cont: search,
      });

      const { campaigns: items } = response.data;

      return {
        ...response.data,
        items,
      };
    },
    []
  );

  const handleCampaignCreated = (newCampaign) => {
    setCreateOrUpdateCampaignModalVisible(false);

    navigate(`/campaigns/${newCampaign.id}`);
  };

  console.log(user)

  const tableColumns = [
    {
      key: "title",
      label: "Title",
      isLink: true,
      linkURL: "/campaigns/{id}",
      isImage: false,
      imageType: "asset" as "asset" | "profile",
      imageKey: "imageUrl",
    },
    { key: "status", label: "Status", isStateBubble: true },
    { key: "brand_name",
      label: "Company Name",
      isLink: true,
      linkURL: "/admin/brands/{brand_id}",
    },
    {
      key: "membership",
      label: "Your Membership",
      getValue: (item) => {
        const membership = user.memberships.find((membership) => membership.brand_id === item.id);
    
        return membership ? "User" : ""; // Handle null or undefined
      },
    },  
    {
      key: "budget",
      label: "budget",
      isMoney: true,
      defaultLabel: "No budget specified",
    },
    {
      key: "go_live_start",
      label: "Start Date",
      nestedKey: "brief",
      isDate: true,
    },
    { key: "go_live_end", label: "End Date", nestedKey: "brief", isDate: true },
    { key: "full_name", label: "Manager", nestedKey: "owner_user" },
    { key: "created_at", label: "Created On", isDate: true },
  ];
  

  return (
    <>
      <SinglePageLayoutWithData
        entityName="campaign"
        pageTitle="Campaigns"
        pageName="Campaigns"
        cardType="asset"
        sortPathName="title"
        sortPathDate="created_at"
        filterOptions={filterOptions}
        filterPath="status"
        tableColumns={tableColumns}
        rowActions={cardDropDownOptions}
        cardDropDownOptions={cardDropDownOptions}
        asyncActions={actions}
        tableOnly
        createWithEntityCrud={() => setCreateOrUpdateCampaignModalVisible(true)}
        getItems={getCampaigns}
        updateModal={CreateOrUpdateCampaignModal}
      />
      {isCreateOrUpdateCampaignModalVisible ? (
        <CreateOrUpdateCampaignModal
          onClose={() => setCreateOrUpdateCampaignModalVisible(false)}
          onCreated={handleCampaignCreated}
        />
      ) : null}
    </>
  );
};

export default CampaignsAdmin;