import React, { useContext } from "react";
import ProgressCard from "../../components/ProgressCard";
import ImageWithPlaceholder from "../../components/ImageWithPlaceholder";
import { truncate, formatDate } from "../../utils/string_utilities";
import { Link } from "react-router-dom";
import PageSection from "../../components/PageSection";
import Table from "@components/Table";
import ListRow from "../../components/ListRow";
import { ReportContext } from "@contexts/index";

const ReportInformation = () => {
  const { report } = useContext(ReportContext);
  const { brief, lists } = report.participants;

  const developmentSamples = {
    hasParticipants: true,
    has_content: true,
  };

  const platformNameMap = {
    Instagram: "Instagram",
    Facebook: "Facebook",
    Youtube: "YouTube",
    Tiktok: "TikTok",
    Instagram_story: "Instagram Story",
  };

  function renderProgressCard() {
    const data = [
      {
        copy: "Add report information",
        title: "Overview",
        complete: report.start_date,
      },
      {
        copy: "Add influencers",
        title: "Influencers",
        complete: developmentSamples.hasParticipants,
      },
      {
        copy: "Add platforms and social tags",
        title: "Social Info",
        complete: true,
      },
      {
        copy: "View and Edit Report Content",
        title: "Data",
        complete: developmentSamples.has_content,
      },
    ];

    return (
      <ProgressCard
        progressCardItems={data}
        header="Get Started!"
        subheader="Build a better report by completing each step."
      >
        <div className="relative">
          <ImageWithPlaceholder
            image={report.background_url}
            classes="w-full h-auto"
            placeholderType="image"
          />
          <div className="absolute inset-0 bg-black opacity-50" />
        </div>
      </ProgressCard>
    );
  }

  function renderOverviewSection() {
    if (report.start_date && report.end_date) {
      return [
        <p key={1} className="text-lg font-semibold">
          Report Overview:
        </p>,
        <p
          key={2}
          className="text-sm"
          dangerouslySetInnerHTML={{
            __html: truncate(report.description, 200),
          }}
        />,
        <p key={3} className="text-lg font-semibold">
          Project Date Range:
        </p>,
        <p key={4} className="text-sm">
          {formatDate(report.start_date.replace(/-/g, "/").replace(/T.+/, ""))}{" "}
          -{formatDate(report.end_date.replace(/-/g, "/").replace(/T.+/, ""))}
        </p>,
      ];
    }
    return null;
  }

  function handleDelete(e, listId) {
    console.log(e, listId);
  }

  function renderParticipantRows() {
    let rows = [];

    if (Object.keys(brief).length > 0) {
      rows.push(
        <ListRow item={brief} key={brief.id}>
          <Link
            to={`/reports/${report.slug}/participants/brief/edit`}
            className="text-blue-500 hover:text-blue-700"
          >
            Edit
          </Link>
          <button
            onClick={(e) => handleDelete(e, brief.id)}
            className="text-red-500 hover:text-red-700"
          >
            Delete
          </button>
        </ListRow>
      );
    }

    if (lists) {
      rows = rows.concat(
        lists.map((list) => (
          <ListRow item={list} key={list.id}>
            <Link
              to={`/reports/${report.slug}/participants/groups/${list.id}/edit`}
              className="text-blue-500 hover:text-blue-700"
            >
              Edit
            </Link>
            <button
              onClick={(e) => handleDelete(e, list.id)}
              className="text-red-500 hover:text-red-700"
            >
              Delete
            </button>
          </ListRow>
        ))
      );
    }

    return rows;
  }

  function renderParticipantsTable() {
    if (report.hasParticipants) {
      const columns = [
        { key: "group_name", label: "Group Name" },
        { key: "influencers", label: "Influencers On Report" },
        { key: "", label: "" },
      ];

      return <Table columns={columns} data={renderParticipantRows()} />;
    }
    return null;
  }

  function renderAddParticipantButtons() {
    return (
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
        <Link
          to={`/reports/${report.slug}/participants/groups/new`}
          className="btn btn-primary mr-3"
        >
          + Add Group
        </Link>
        <Link
          to={`/reports/${report.slug}/participants/influencers/new`}
          className="btn btn-secondary"
        >
          + Add Individual Influencer
        </Link>
      </div>
    );
  }

  function renderPlatformsSection() {
    if (report.platforms.length > 0) {
      return [
        <p key={1} className="text-lg font-semibold">
          Platforms:
        </p>,
        <p key={2} className="text-sm">
          {report.platforms
            .map((platform) => platformNameMap[platform])
            .join(", ")}
        </p>,
      ];
    }
    return null;
  }

  function renderHashtagsSection() {
    if (report.hashtags.length > 0 && report.hashtags[0] !== "") {
      return [
        <p key={3} className="text-lg font-semibold">
          #Hashtags:
        </p>,
        <p key={4} className="text-sm">
          {report.hashtags.join(", ")}
        </p>,
      ];
    }
    return null;
  }

  function renderMentionsSection() {
    if (report.mentioned_users.length > 0 && report.mentioned_users[0] !== "") {
      return [
        <p key={5} className="text-lg font-semibold">
          @Mentions:
        </p>,
        <p key={6} className="text-sm">
          {report.mentioned_users.join(", ")}
        </p>,
      ];
    }
    return null;
  }

  return (
    <div className="flex flex-col lg:flex-row justify-end space-y-4 lg:space-y-0 lg:space-x-4">
      <div className="w-full lg:w-1/3">{renderProgressCard()}</div>
      <div className="w-full lg:w-1/3">
        <PageSection
          title="Report Overview"
          captions={[
            "Add in description / reasoning of the report, date range for analytics, and imagery as needed.",
          ]}
        >
          {renderOverviewSection()}
          <Link to={`/reports/${report.slug}/edit`} className="btn btn-primary">
            {report.start_date ? "Edit Info" : "+ Add Info"}
          </Link>
        </PageSection>
        <PageSection
          title="Influencers"
          captions={[
            "Add the list of influencers by a list and / or individually based on whose content you would like to see in the report.",
          ]}
        >
          {renderParticipantsTable()}
          {renderAddParticipantButtons()}
        </PageSection>
        <PageSection
          title="Platforms + Social Tags (Content)"
          captions={[
            "Add all social platforms and social tags that you would like to report on.",
          ]}
        >
          {renderPlatformsSection()}
          {renderHashtagsSection()}
          {renderMentionsSection()}
          <Link
            to={`/reports/${report.slug}/settings`}
            className="btn btn-primary"
          >
            {report.start_date
              ? "Edit Content Settings"
              : "+ Add Content Settings"}
          </Link>
        </PageSection>
      </div>
    </div>
  );
};

export default ReportInformation;
