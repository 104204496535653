import React, { useState } from 'react';
import { FInput } from "@components/FInputs";
import FButton from "@components/FButton";
import { createOffPlatformCreator, checkOffPlatformUsernames } from "@api/Creators/Creators";
import { toast } from "react-hot-toast";
import { formatNumber } from "@utils/number_utilities";
import FIcon from "@components/FIcon";

const CreateOffPlatformCreator = () => {
  const [username, setUsername] = useState('');
  const [instagramHandle, setInstagramHandle] = useState('');
  const [tiktokHandle, setTiktokHandle] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [platformsFound, setPlatformsFound] = useState({ instagram: false, tiktok: false });
  const [isChecking, setIsChecking] = useState(false);

  const handleUsernameCheck = async () => {
    setIsChecking(true);
    try {
      const response = await checkOffPlatformUsernames(username.trim().toLowerCase());
      setPlatformsFound(response);
    } catch (error) {
      console.error("Error checking username:", error);
      toast.error('Failed to check username.');
    } finally {
      setIsChecking(false);
    }
  };

  const handleAddPlatform = (platform) => {
    const normalizedUsername = username.trim().toLowerCase();
    if (platform === 'instagram') {
      setInstagramHandle((prev) => (prev === normalizedUsername ? '' : normalizedUsername));
    } else if (platform === 'tiktok') {
      setTiktokHandle((prev) => (prev === normalizedUsername ? '' : normalizedUsername));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (!instagramHandle && !tiktokHandle) {
      toast.error('Please add at least one platform handle.');
      return;
    }

    try {
      const response = await toast.promise(
        createOffPlatformCreator({ instagram_handle: instagramHandle, tiktok_handle: tiktokHandle }),
        {
          loading: 'Saving...',
          success: 'Creator saved successfully!',
          error: 'Failed to save creator.',
        }
      );

      if (response?.success) {
        window.location.href = response.creator_slug
          ? `/card/${response.creator_slug}/content`
          : '/dashboard';
      }
    } catch (error) {
      console.error('Error during submission:', error);
    }
  };

  return (
    <div>
      <h1>Create an Off Platform Creator</h1>
      <h3 className="mb-8">Add any Instagram or TikTok creator via their handle. Creators must have a publicly visible business or creator account.</h3>

      <form onSubmit={handleSubmit}>
        {/* Handle Search Section */}
        <div className="mb-6 flex w-1/2">
          <span className="pt-1 pr-1 text-xl">@</span>
          <FInput
            value={username}
            onChange={(value) => {
              if (!value.includes('@')) {
                setUsername(value);
              }
            }}
            width="50%"
            className="mb-6 mr-4"
          />

          <FButton
            label={isChecking ? "Checking..." : "Check Username"}
            onClick={handleUsernameCheck}
            disabled={isChecking}
          />
        </div>

        {/* Instagram Account Display */}
        {platformsFound.instagram?.table?.profile?.username && (
          <div className="border rounded-lg p-4 mb-4 flex items-center w-1/2">
            <div
              className="mr-4 w-24 h-24 rounded-full bg-center bg-cover"
              style={{
                backgroundImage: `url(${platformsFound.instagram.table.profile.profile_picture_url})`,
              }}
            />
            <div className="flex-grow">
              <div className="flex items-center mb-2">
                <FIcon icon="instagram" size={24} color="#000F45" className="mr-2" />
                <span className="text-lg font-semibold">Instagram account found</span>
              </div>
              <p>
                <a
                  href={`https://www.instagram.com/${platformsFound.instagram.table.profile.username}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500"
                >
                  @{platformsFound.instagram.table.profile.username}
                </a>
              </p>
              <p>{formatNumber(platformsFound.instagram.table.profile.followers)} Followers</p>
            </div>
            <FButton
              label={instagramHandle === username.trim().toLowerCase() ? "Added" : "Add Platform"}
              onClick={() => handleAddPlatform('instagram')}
              className="ml-auto"
              disabled={instagramHandle === username.trim().toLowerCase()}
            />
          </div>
        )}

        {/* TikTok Account Display */}
        {platformsFound.tiktok && (
          <div className="border rounded-lg p-4 mb-4 flex items-center w-1/2">
            <div
              className="mr-4 w-24 h-24 rounded-full bg-center bg-cover"
              style={{
                backgroundImage: `url(${platformsFound.tiktok.profile_image})`,
              }}
            />
            <div className="flex-grow">
              <div className="flex items-center mb-2">
                <FIcon icon="tiktok" size={24} color="#000F45" className="mr-2" />
                <span className="text-lg font-semibold">TikTok account found</span>
              </div>
              <p>
                <a
                  href={`https://www.tiktok.com/@${platformsFound.tiktok.handle_name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500"
                >
                  @{platformsFound.tiktok.handle_name}
                </a>
              </p>
              <p>{formatNumber(platformsFound.tiktok.followers_count)} Followers</p>
            </div>
            <FButton
              label={tiktokHandle === username.trim().toLowerCase() ? "Added" : "Add Platform"}
              onClick={() => handleAddPlatform('tiktok')}
              className="ml-auto"
              disabled={tiktokHandle === username.trim().toLowerCase()}
            />
          </div>
        )}

        {/* Selected Platforms Section */}
        {(instagramHandle || tiktokHandle) && (
          <div className="p-4 border rounded-lg bg-gray-50 w-1/2 mt-8 mb-8">
            <h2 className="text-lg font-semibold mb-4">Selected Platforms</h2>

            {/* Instagram Section */}
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <span className="font-medium mr-2">Instagram:</span>
                <span className={instagramHandle ? "" : "text-neutral_600 italic"}>
                  {instagramHandle ? `@${instagramHandle}` : 'Not Selected'}
                </span>
              </div>
              {instagramHandle && (
                <button
                  onClick={() => setInstagramHandle('')}
                  className="flex items-center text-red-500 hover:underline"
                >
                  <FIcon icon="delete" size={20} color="#FF5C5C" className="mr-2" />
                  <span>Remove</span>
                </button>
              )}
            </div>

            {/* TikTok Section */}
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <span className="font-medium mr-2">TikTok:</span>
                <span className={tiktokHandle ? "" : "text-neutral_600 italic"}>
                  {tiktokHandle ? `@${tiktokHandle}` : 'Not Selected'}
                </span>
              </div>
              {tiktokHandle && (
                <button
                  onClick={() => setTiktokHandle('')}
                  className="flex items-center text-red-500 hover:underline"
                >
                  <FIcon icon="delete" size={20} color="#FF5C5C" className="mr-2" />
                  <span>Remove</span>
                </button>
              )}
            </div>
          </div>
        )}

        {/* Submit Button */}
        <div className="w-1/2">
          <FButton type="submit" label="Create" primary className="float-right" />
        </div>
      </form>
    </div>
  );
};

export default CreateOffPlatformCreator;
