import React, { useContext, useEffect, useState } from "react";
import { CampaignContext } from "@contexts/index";
import BriefContractSection from "./BriefContractSection";
import ConfirmModal from "@components/Modals/ConfirmModal";
import { toast } from "react-hot-toast";

import apiClient from "@apiClient";
import Modal from "@components/Modals/Modal";
import AddEditContractSection from "./AddEditContractSection";
import { ContractTemplate } from "@types";

const { get } = apiClient.contract_templates;

const {
  create,
  update,
  delete: deleteSection,
} = apiClient.contract_template_sections;

export default function BriefContract() {
  const { campaign, activeBrief: brief } = useContext(CampaignContext);

  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [sectionToEdit, setSectionToEdit] = useState(null);
  const [isLocalModalOpen, setIsLocalModalOpen] = useState(false);

  const [contractTemplate, setContractTemplate] =
    useState<ContractTemplate>(null);

  const [waiting, setWaiting] = useState(false);

  const templateId = campaign.contract_template_id || 1;

  const handleSubmit = (values) => {
    setWaiting(true);

    updateSections(values);
  };

  const updateSections = async (sectionData) => {
    if (sectionToEdit) {
      // Update existing product
      await update(
        sectionToEdit.contract_template_id,
        sectionToEdit.id,
        sectionData
      );

      const updatedSections = contractTemplate.sections.map((p) =>
        p.id === sectionToEdit.id ? { ...p, ...sectionData } : p
      );

      setContractTemplate({ ...contractTemplate, sections: updatedSections });
    } else {
      // Add new product
      const response = await create(brief.campaign_id, sectionData);

      const newSections = [
        ...contractTemplate.sections,
        { ...sectionData, id: response.data.id },
      ];

      setContractTemplate({ ...contractTemplate, sections: newSections });
    }

    setWaiting(false);

    setIsLocalModalOpen(false);
  };

  const removeSection = async (sectionId) => {
    await deleteSection(sectionId);

    setContractTemplate({
      ...contractTemplate,
      sections: sections.filter((section) => section.id !== sectionId),
    });
    setIsDeleteModalOpen(false);
    setSectionToEdit(null);
    toast.success("Deleted");
  };

  useEffect(() => {
    const getTemplate = async () => {
      setLoading(true);

      try {
        const result = await get(templateId);

        setContractTemplate(result.data);
      } catch (error) {
        console.error("Error fetching contract template:", error);
      } finally {
        setLoading(false);
      }
    };

    getTemplate();
  }, [templateId]);

  const sections = contractTemplate ? contractTemplate.sections : [];

  return (
    <div>
      {loading || sections?.length > 0 ? (
        <div className={`flex flex-col gap-4 ${sections.length > 0 ? "" : ""}`}>
          <div className="flex flex-col mb-8">
            {sections
              ?.sort(
                (a, b) =>
                  new Date(b.updated_at).getTime() -
                  new Date(a.updated_at).getTime()
              )
              .map((section) => (
                <BriefContractSection
                  key={section.id}
                  section={section}
                  editSection={(section) => {
                    setIsLocalModalOpen(true);
                    setSectionToEdit(section);
                  }}
                />
              ))}
          </div>
        </div>
      ) : (
        <p>Loading</p>
      )}
      <ConfirmModal
        title="Delete Product"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onAction={() => removeSection(sectionToEdit.id)}
        actionLabel="Delete"
        subtitle="Are you sure you want to delete this product?"
      />
      {isLocalModalOpen ? (
        <Modal
          title="Edit Contract"
          isOpen
          onClose={() => {
            setIsLocalModalOpen(false);
            setSectionToEdit(null);
          }}
          actionLabel="Save"
          hasForm
          className="w-[850px] pb-0"
          zIndex={9999}
          children={
            <AddEditContractSection
              contract={contractTemplate}
              onClose={() => {
                setIsLocalModalOpen(false);
                setSectionToEdit(null);
              }}
              sectionId={sectionToEdit?.id}
              onSubmit={handleSubmit}
              waiting={waiting}
            />
          }
        />
      ) : null}
    </div>
  );
}
