import React, { useCallback, useState } from "react";

import apiClient from "@apiClient";

import { Sort } from "@types";

import CreateOrUpdateCampaignModal from "./components/CreateOrUpdateCampaignModal";
import { getCampaignList } from "@api/Campaign/Campaigns";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import { useNavigate } from "react-router-dom";
import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";

const { campaigns } = apiClient;

const filterOptions = [
  { label: "All Campaigns", static: "Campaigns", value: null },
  { label: "Active", static: "Campaigns", value: "active" },
  { label: "Proposal", static: "Campaigns", value: "proposal" },
  { label: "Finished", static: "Campaigns", value: "finished" },
];

const actions = {
  archive: async (id: number) =>
    campaigns.update(id, {
      status: "archived",
    }),
};

const tableColumns = [
  {
    key: "title",
    label: "Title",
    isLink: true,
    linkURL: "/campaigns/{id}",
    isImage: false,
    imageType: "asset" as "asset" | "profile",
    imageKey: "imageUrl",
  },
  { key: "status", label: "Status", isStateBubble: true },
  {
    key: "budget",
    label: "budget",
    isMoney: true,
    defaultLabel: "No budget specified",
  },
  {
    key: "go_live_start",
    label: "Start Date",
    nestedKey: "brief",
    isDate: true,
  },
  { key: "go_live_end", label: "End Date", nestedKey: "brief", isDate: true },
  { key: "full_name", label: "Manager", nestedKey: "owner_user" },
];

const CampaignListPage = () => {
  const [
    isCreateOrUpdateCampaignModalVisible,
    setCreateOrUpdateCampaignModalVisible,
  ] = useState(false);

  const navigate = useNavigate();

  const { can } = useAuth();

  const getCampaigns = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;

      const response = await getCampaignList(page, sort, false, {
        status_eq: filter,
        title_i_cont: search,
      });

      const { campaigns: items } = response.data;

      return {
        ...response.data,
        items,
      };
    },
    []
  );

  const handleCampaignCreated = (newCampaign) => {
    setCreateOrUpdateCampaignModalVisible(false);

    navigate(`/campaigns/${newCampaign.id}`);
  };

  const cardDropDownOptions = [
    can(Permission.EDIT_ALL_CAMPAIGNS)
      ? { label: "Edit", value: "edit" }
      : null,
    can(Permission.DELETE_ALL_CAMPAIGNS)
      ? { label: "Archive", value: "archive" }
      : null,
  ].filter(Boolean);

  return (
    <>
      <SinglePageLayoutWithData
        entityName="campaign"
        pageTitle="Campaigns"
        pageName="Campaigns"
        cardType="asset"
        sortPathName="title"
        sortPathDate="created_at"
        filterOptions={filterOptions}
        filterPath="status"
        tableColumns={tableColumns}
        rowActions={cardDropDownOptions}
        cardDropDownOptions={cardDropDownOptions}
        asyncActions={actions}
        createWithEntityCrud={() => setCreateOrUpdateCampaignModalVisible(true)}
        getItems={getCampaigns}
        updateModal={CreateOrUpdateCampaignModal}
      />
      {isCreateOrUpdateCampaignModalVisible ? (
        <CreateOrUpdateCampaignModal
          onClose={() => setCreateOrUpdateCampaignModalVisible(false)}
          onCreated={handleCampaignCreated}
        />
      ) : null}
    </>
  );
};

export default CampaignListPage;
