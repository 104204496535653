import React from "react";

import { maxBy, sortBy } from "lodash";
import { DemographicsBreakdownItem } from "@types";

import TriangleChart from "@components/Graphs/TriangleChart";
import WidgetContainer from "@components/charts/demographics/WidgetContainer";

interface KidsBreakdownProps {
  items: DemographicsBreakdownItem[];
}

export default function KidsBreakdown({ items }: KidsBreakdownProps) {
  return (
    <WidgetContainer
      title="Parental Status"
      value={maxBy(items, "percentage")?.name}
    >
      <TriangleChart data={sortBy(items || [], "percentage").reverse()} />
    </WidgetContainer>
  );
}
