import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";

export const getCreatorReviewComments = async (
  participantId?: number
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/creator_review/comments?q[participant_id_eq]=${participantId}`,
    "GET"
  );
};

export const createCreatorReviewComment = async (params: {
  body: string;
  participant_id: number;
}): Promise<ApiResponse<any>> => {
  return apiWrapper(`/creator_review/comments`, "POST", params);
};

export const updateCreatorReviewComment = async (
  id: number,
  params: { body: string }
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/creator_review/comments/${id}`, "PATCH", params);
};

export const deleteCreatorReviewComment = async (
  commentId: number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/creator_review/comments/${commentId}`, "DELETE");
};
