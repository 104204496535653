import axios from "axios";
import { ApiResponse, GroupData } from "../types/apiTypes";
import { LOCAL_API_ORIGIN } from "../constants/api";

const API_BASE_URL = `${LOCAL_API_ORIGIN}/api/v1/memberships`;

const createMembership = async (
  brandId: number,
  userId: number,
  accountId: number
): Promise<ApiResponse<GroupData>> => {
  try {
    const response = await axios.post(`${API_BASE_URL}`, {
      membership: {
        brand_id: brandId,
        user_id: userId,
        account_id: accountId
      }
    });

    return {
      success: true,
      message: `created successfully`,
      data: { ...response.data },
    };
  } catch (error: any) {
    return {
      success: false,
      message: `Failed to create`,
    };
  }
};

export { createMembership };
