import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { sortBy } from "lodash";
import { formatLargeNumber } from "@utils/number_utilities";
import getMedian from "@utils/array";
import Legend from "@components/Graphs/components/Legend";

const DATA_MAP = {
  Posts: "posts_graph_data",
  Reach: "reach_graph_data",
  Views: "impressions_graph_data",
};

const INPUT_DATA_FORMAT = "MM/DD/YY";

const COLORS = ["#B0B5C4", "#E47667", "#59BBDB"];

const PLOTLINE_OPTIONS = {
  color: "#000721",
  dashStyle: "dash",
  width: 2,
};

const OPTIONS = {
  xAxis: {
    labels: {
      formatter: function () {
        return moment(this.value).format("MMM 'YY");
      },
      type: "datetime",
      startOnTick: true,
      endOnTick: true,
    },
  },
  yAxis: [
    {
      title: {
        text: "Post Count",
      },
      labels: {
        style: {
          color: "#797E92",
          fontSize: "12",
        },
      },
    },
    {
      title: {
        text: "Total Reach",
      },
      opposite: true,
    },
  ],
  tooltip: {
    formatter: function () {
      return (
        "<b>" +
        this.series.name +
        "</b>, Day: <b>" +
        moment(this.x).format("MMM D 'YY") +
        "</b>, Value: <b>" +
        formatLargeNumber(this.y) +
        "</b>"
      );
    },
  },
};

interface PostsEngagementGraphProps {
  postsEngagement: any;
}

export default function PostsEngagementGraph({
  postsEngagement,
}: PostsEngagementGraphProps) {
  const { engagement_graph_data } = postsEngagement;

  const series = ["Posts", "Views", "Reach"].map((metric, index) => {
    const dailyDataMap = postsEngagement[DATA_MAP[metric]];

    const dailyData = Object.keys(dailyDataMap).map((key) => [
      moment(key, INPUT_DATA_FORMAT).valueOf(),
      dailyDataMap[key],
    ]);

    const dailyDataSortedByTime = sortBy(dailyData, ([timestamp]) => timestamp);

    return {
      data: dailyDataSortedByTime,
      name: metric,
      color: COLORS[index],
      type: metric === "Posts" ? "column" : "line",
      yAxis: metric === "Posts" ? 0 : 1,
    };
  });

  const median = getMedian(Object.values(engagement_graph_data));

  const options = {
    ...OPTIONS,
    xAxis: {
      ...OPTIONS.xAxis,
    },
    yAxis: OPTIONS.yAxis.map((axis, index) => {
      if (index === 0) {
        return axis;
      }

      return {
        ...axis,
        plotLines: [
          {
            ...PLOTLINE_OPTIONS,
            value: median,
          },
        ],
      };
    }),
    series,
  };

  return (
    <div>
      {postsEngagement ? (
        <div>
          <HighchartsReact highcharts={Highcharts} options={options} />
          <Legend
            labels={series.map((item) => ({
              color: item.color,
              label: item.name,
            }))}
          />
        </div>
      ) : (
        <p className="text-center mt-[23px] text-neutral_500 text-[18px]">
          No recent posts
        </p>
      )}
    </div>
  );
}
