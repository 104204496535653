import {
  addContent,
  createRound,
  updateContent,
  updateContentStatus,
  uploadContentReviewAsset,
} from "@api/Campaign/ContentReview";
import { uploadToActiveStorage } from "@api/upload";
import FButton from "@components/FButton";
import FFileUpload from "@components/FFileUpload/FFileUpload";
import FIcon from "@components/FIcon";
import { FInput } from "@components/FInputs";
import FProgressBar from "@components/FProgressBar";
import Modal from "@components/Modals/Modal";
import { CONTENT_REVIEW_STATUS } from "@pages/campaigns/content-review/statuses";
import { ContentReviewContent, ContentReviewDeliverable } from "@types";
import { isVideo } from "@utils/file";
import { isEmpty } from "lodash";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const getCaptionError = (
  caption: string,
  deliverable: ContentReviewDeliverable
) => {
  const { hashtags, mentions } = deliverable;

  if (hashtags) {
    const missingHashtags = hashtags.filter(
      (hashtag) => !caption.toLowerCase().includes(hashtag.toLowerCase())
    );

    if (!isEmpty(missingHashtags)) {
      return `Caption has to include the following hashtags: ${missingHashtags.join(", ")}`;
    }
  }

  if (mentions) {
    const missingMentions = mentions.filter(
      (mention) => !caption.includes(mention)
    );

    if (!isEmpty(missingMentions)) {
      return `Caption has to include the following mentions: ${missingMentions.join(", ")}`;
    }
  }

  return null;
};

interface UploadDeliverableContentModalProps {
  deliverable: ContentReviewDeliverable;
  isUploadingAssets: boolean;
  onClose: () => void;
  onContentUpdated?: (content: ContentReviewContent) => void;
}

const UploadDeliverableContentModal = ({
  isUploadingAssets,
  deliverable,
  onClose,
  onContentUpdated,
}: UploadDeliverableContentModalProps) => {
  const [isSubmitted, setSubmitted] = useState(false);

  const [captionError, setCaptionError] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState<
    { name: string; file: File; url: string; position?: number }[]
  >([]);

  const [fileSizesLoaded, setFileSizesLoaded] = useState<number[]>([]);

  const [isSaving, setSaving] = useState(false);

  const [values, setValues] = useState({
    caption: "",
  });

  const { content } = deliverable;

  const isUploadingNewRound =
    content?.status === CONTENT_REVIEW_STATUS.ADMIN_CHANGES;

  const isUpdatingCaption =
    content && !isUploadingAssets && !isUploadingNewRound && content.caption;

  useEffect(() => {
    if (!content) {
      return;
    }

    const { caption } = content;

    setValues({
      caption,
    });
  }, []);

  const handleUpdate = (updates) =>
    setValues({
      ...values,
      ...updates,
    });

  const handleUpdateContent = async () => {
    const { caption } = values;

    setSaving(true);

    try {
      await updateContent(content.id, { caption });
    } catch (e) {
      toast.error("Error updating caption");
    } finally {
      toast.success("Content updated");

      onContentUpdated({
        ...content,
        caption,
      });
    }
  };

  const handleSubmit = async () => {
    const { caption } = values;

    setCaptionError(null);

    if (!caption) {
      setSubmitted(true);

      return;
    }

    const captionError = getCaptionError(caption, deliverable);

    if (captionError) {
      setCaptionError(captionError);

      return;
    }

    if (isUpdatingCaption) {
      handleUpdateContent();

      return;
    }

    setSaving(true);

    let content = deliverable.content;

    if (content && !content.caption) {
      try {
        await updateContent(content.id, { caption });
      } catch (e) {
        toast.error("Error updating caption");
      }
    }

    setFileSizesLoaded(uploadedFiles.map(() => 0));

    const uploads = await uploadToActiveStorage(
      uploadedFiles.map(({ file, position }) => ({ file, position })),
      (loaded, index) => {
        setFileSizesLoaded((fileSizesLoaded) =>
          fileSizesLoaded.map((size, i) => {
            if (i === index) {
              return loaded;
            }

            return size;
          })
        );
      }
    );

    const { campaign_id, id, participant_id } = deliverable;

    if (!content) {
      const newContentResponse = await addContent({
        participant_id,
        deliverable_id: id,
        campaign_id,
        caption,
        status: "not_submitted",
      });

      content = newContentResponse.data;
    }

    let newRound;

    if (isUploadingNewRound) {
      const newRoundResponse = await createRound({
        content_id: content.id,
        caption,
        current: true,
      });

      await updateContentStatus(
        content.id,
        CONTENT_REVIEW_STATUS.NOT_SUBMITTED
      );

      content = {
        ...content,
        status: CONTENT_REVIEW_STATUS.NOT_SUBMITTED,
      };

      newRound = newRoundResponse.data;
    }

    const uploadedAssets = [];

    try {
      for (const upload of uploads) {
        const { filename, file, position } = upload;

        const assetResponse = await uploadContentReviewAsset({
          content_id: content.id,
          filename,
          file,
          position,
        });

        uploadedAssets.push(assetResponse.data);
      }

      const newAssets = isUploadingNewRound
        ? uploadedAssets
        : [...content.rounds[0].assets, ...uploadedAssets];

      const { rounds } = content;

      const newRounds = isUploadingNewRound
        ? [...rounds, { ...newRound, assets: newAssets }]
        : [
            {
              ...rounds[0],
              assets: newAssets,
            },
            ...rounds.slice(1),
          ];

      onContentUpdated({
        ...content,
        caption: content.caption || caption,
        rounds: newRounds,
      });
    } finally {
      setSaving(false);
    }
  };

  const handleFileUpload = (files) => {
    setUploadedFiles([...uploadedFiles, ...files]);
  };

  const handleFileUpdate = (fileIndex, updates) => {
    setUploadedFiles(
      uploadedFiles.map((file, index) => {
        if (fileIndex === index) {
          return {
            ...file,
            ...updates,
          };
        }

        return file;
      })
    );
  };

  const handleDeleteFile = (indexToDelete) => {
    setUploadedFiles(
      uploadedFiles.filter((file, index) => index !== indexToDelete)
    );
  };

  return (
    <Modal
      className="w-[550px] max-h-screen"
      title={
        content && !isUploadingNewRound
          ? isUploadingAssets
            ? "Upload assets"
            : "Update caption"
          : `Add content for deliverable ${isUploadingNewRound ? `(Round ${content.rounds.length + 1})` : ""}`
      }
      hasForm
      zIndex={9999}
      onClose={onClose}
      isOpen
    >
      <>
        {!isUploadingAssets ? (
          <FInput
            className="mb-4"
            error={captionError}
            value={values.caption}
            validate={isSubmitted}
            type="text"
            required
            onChange={(value) => handleUpdate({ caption: value })}
            label="Caption"
            width="100%"
          />
        ) : null}
        {!isUpdatingCaption ? (
          <div className="max-h-[400px] overflow-y-auto w-full">
            <FFileUpload
              className="mb-4 w-full"
              multiple
              onUpload={handleFileUpload}
            />
            {uploadedFiles.map(({ name, position, url }, index) => {
              const loaded = fileSizesLoaded[index];

              const { file } = uploadedFiles[index];

              const fileSize = file.size;

              return (
                <>
                  <FInput
                    className="mb-4"
                    value={name}
                    validate={isSubmitted}
                    key={index}
                    type="text"
                    required
                    onChange={(value) =>
                      handleFileUpdate(index, { name: value })
                    }
                    label="Content Name"
                    width="100%"
                  />
                  <FInput
                    className="mb-4"
                    key={index}
                    type="number"
                    value={`${position || ""}`}
                    onChange={(value) =>
                      handleFileUpdate(index, {
                        position: value ? parseInt(value, 10) : 0,
                      })
                    }
                    label="Content Position"
                    width="100%"
                  />
                  {loaded > 0 ? (
                    <FProgressBar
                      className="mb-2 w-full"
                      completed={loaded / 1024}
                      total={fileSize / 1024}
                      percent={(100 * loaded) / fileSize}
                      showPercent
                      unit="kb"
                    />
                  ) : null}
                  <div
                    className="border border-default_weak border-dashed rounded-lg relative flex all-center h-[150px] mb-4"
                    style={{
                      backgroundImage: url ? `url(${url})` : "none",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "auto 150px",
                    }}
                  >
                    <div
                      className="cursor-pointer ml-auto absolute top-[10px] right-[10px]"
                      onClick={() => handleDeleteFile(index)}
                    >
                      <FIcon icon="exit" size={20} color="#000000" />
                    </div>
                    {isVideo(file) ? (
                      <video
                        src={url}
                        autoPlay
                        playsInline
                        style={{ height: "100%" }}
                      />
                    ) : null}
                  </div>
                </>
              );
            })}
          </div>
        ) : null}
        <div className="flex justify-end space-x-2 w-full">
          <FButton
            onClick={onClose}
            label="Cancel"
            width="100%"
            height="40px"
          />
          <FButton
            onClick={handleSubmit}
            loading={isSaving}
            label="Submit"
            width="100%"
            height="40px"
            primary
          />
        </div>
      </>
    </Modal>
  );
};

export default UploadDeliverableContentModal;
