import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { getQueryParamsForSearchRequest } from "@constants/api";

const getAmbassadorResponses = async (params?: {
  creator_id_eq?: number;
}): Promise<ApiResponse<any>> => {
  const queryParams = getQueryParamsForSearchRequest(1, null, params);

  const response = await apiWrapper(
    `/ambassador_responses?${queryParams.toString()}`,
    "GET"
  );
  return response;
};

export const createAmbassadorResponse = async (params?: {
  ambassador_question_id?: number;
  answer: string | string[];
  brand_slug: string;
}): Promise<ApiResponse<any>> => {
  const response = await apiWrapper(`/ambassador_responses`, "POST", params);
  return response;
};

export { getAmbassadorResponses };
