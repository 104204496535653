import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { ContractTemplate } from "@types";

const getContractTemplateIndex = async (): Promise<
  ApiResponse<{ contract_templates: ContractTemplate[] }>
> => {
  return apiWrapper("/contract_templates", "GET");
};

const getContractTemplate = async (
  contract_template_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/contract_templates/${contract_template_id}`, "GET");
};

const createContractTemplate = async (
  params: any
): Promise<ApiResponse<any>> => {
  return apiWrapper("/contract_templates", "POST", params);
};

const updateContractTemplate = async (
  contract_template_id: number | string,
  params: any
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/contract_templates/${contract_template_id}`,
    "PUT",
    params
  );
};

const deleteContractTemplate = async (
  contract_template_id: number | string
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/contract_templates/${contract_template_id}`, "DELETE");
};

export {
  getContractTemplateIndex,
  getContractTemplate,
  createContractTemplate,
  updateContractTemplate,
  deleteContractTemplate,
};
