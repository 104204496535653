import React from "react";
interface Props {
  children: React.ReactNode;
  collapsed?: boolean;
  hideOnCollapse?: boolean;
  isDropdown?: boolean;
  overflowScroll?: boolean;
  topSpacing?: number;
  topBorder?: boolean;
}

const FSidebarSection = ({
  children,
  hideOnCollapse,
  collapsed,
  overflowScroll,
  topSpacing = 0,
  topBorder = true,
}: Props) => {
  if (collapsed && hideOnCollapse) {
    return <></>;
  }

  return (
    <>
      <div style={{ height: topSpacing + "px" }}></div>
      {topBorder && <hr className={"m-0 border-gray"} />}
      <div
        className={`flex flex-col flex-grow  ${
          overflowScroll ? "overflow-hidden" : "overflow-auto"
        } ${collapsed ? "px-[8px]" : "px-[12px]"}`}
      >
        {children}
      </div>
    </>
  );
};

export default FSidebarSection;
