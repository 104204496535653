import React from "react";
import FIcon from "@components/FIcon";

interface CoverImageProps {
  imageUrl: string;
}

const CoverImage = ({ imageUrl }: CoverImageProps) => {
  const isValid =
    imageUrl && !imageUrl.includes("missing_default") && imageUrl.trim() !== "";

  return (
    <div className="w-full mt-8 h-[220px] flex items-center justify-center rounded-2xl">
      {isValid ? (
        <img src={imageUrl} alt="Image" className="rounded-lg h-full" />
      ) : (
        <FIcon icon="image" size={40} color="#000F45" />
      )}
    </div>
  );
};

export default CoverImage;
