import React, { useState } from "react";

function ReportContent() {
  const [activeTab, setActiveTab] = useState("accepted"); // State to track active tab

  const sampleParticipants = [
    {
      id: 92936,
      type: "participant",
      links: {
        self: "/reports/97b5fdf1/participants/92936",
      },
      attributes: {
        type: "card",
        name: "petitemarienyc",
        firstName: "marie",
        lastName: "zoumanigui",
        email: "mjzoum@gmail.com",
        slug: "petitemarienyc",
        profile_image:
          "https://cdn-d35wth38dfye85.fohr.co/card/cards/profile_images/000/048/151/large/IMG_2972.JPG?1692063537",
        archived: false,
        data: {
          approved: [
            {
              id: 129250,
              type: "content",
              links: {
                self: "https://instagram.com/stories/petitemarienyc/3389953587704884369",
              },
              attributes: {
                type: "instagram",
                approved: true,
                found_by: [],
                manual: false,
                post: {
                  type: "post",
                  links: {
                    self: "https://instagram.com/stories/petitemarienyc/3389953587704884369",
                    related: {
                      href: "https://app.fohr.co/petitemarienyc",
                      type: "card",
                      title: "petitemarienyc",
                    },
                  },
                  attributes: {
                    type: "video",
                    photo:
                      "https://cdn-d35wth38dfye85.fohr.co/post-images/instagram/173015860/original/open-uri20240614-1-17476a1?1718337941",
                    story: true,
                    video: null,
                    auth_id: 89312,
                    caption:
                      "An evening with @fohr.co at their TenFohr series Learned about self-love with @brianna.who /\nBoards games and how they may or may not ruin your relationships with @luke_winkie /\nFinding Micro-Joys in our daily lives with @gracemurray",
                    card_id: 48151,
                    post_id: "3389953587704884369",
                    hashtags: [],
                    mentions: [
                      "fohr.co",
                      "brianna.who",
                      "luke_winkie",
                      "gracemurray",
                    ],
                    provider: "instagram",
                    username: "petitemarienyc",
                    verified: false,
                    has_photo: true,
                    permalink:
                      "https://instagram.com/stories/petitemarienyc/3389953587704884369",
                    retweeted: false,
                    sponsored: false,
                    categories: [270, 269, 272, 683, 698, 680, 239, 14556, 186],
                    impressions: 76479,
                    interactions: 0,
                    published_at: "2024-06-14T02:59:37.000Z",
                    search_cache:
                      "an evening with @fohr.co at their tenfohr series learned about self-love with @brianna.who /\nboards games and how they may or may not ruin your relationships with @luke_winkie /\nfinding micro-joys in our daily lives with @gracemurray fohr.co brianna.who luke_winkie gracemurray petitemarienyc petitemarienyc marie zoumanigui",
                    unique_stats: {
                      reach: 1526,
                      replies: 3,
                      impressions: 1564,
                      likes_count: 0,
                      views_count: 1564,
                      comments_count: 0,
                    },
                    caption_length: 233,
                    media_product_type: "STORY",
                    exclusive_ambassador: false,
                    followed_by: 76479,
                    name: "marie zoumanigui",
                    engagement: 0,
                    content_type: null,
                    impressions_pretty: "76,479",
                  },
                },
                last_refreshed: "2024-06-19T05:00:46.505Z",
              },
            },
          ],
          rejected: [],
        },
      },
    },
  ];

  // Call Report Influencers and their content

  // Render page intro

  // Create the Influencer Bars
  // Make the influencer graph be created
  // Make/Find the Content Cards
  // Create Buttons
  // Add modal functionality

  return (
    <div>
      {/* Page Intro */}
      <div className="grid grid-cols-[245px_auto] w-full">
        <div className="flex flex-col w-[245px] bg-transparent" />
        <div className="flex flex-col w-full p-[48px]">
          {sampleParticipants.map((participant) => {
            return (
              <div key={participant.id} className="mb-4">
                <h2 className="text-lg font-bold mb-2">
                  {participant.attributes.name}
                </h2>
                <div className="border-b border-gray-200">
                  <button
                    className={`py-2 px-4 ${
                      activeTab === "accepted"
                        ? "text-blue-500 border-b-2 border-blue-500"
                        : "text-gray-500"
                    }`}
                    onClick={() => setActiveTab("accepted")}
                  >
                    Accepted
                  </button>
                  <button
                    className={`py-2 px-4 ${
                      activeTab === "rejected"
                        ? "text-blue-500 border-b-2 border-blue-500"
                        : "text-gray-500"
                    }`}
                    onClick={() => setActiveTab("rejected")}
                  >
                    Rejected
                  </button>
                </div>
                <div className="p-4">
                  {activeTab === "accepted" ? (
                    <div>
                      {/* Render accepted content */}
                      {participant.attributes.data.approved.map((content) => (
                        <div
                          key={content.id}
                          className="p-2 border rounded mb-2"
                        >
                          {/* {content.attributes.post.caption} */}
                          <p>accepted</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      {/* Render rejected content */}
                      {participant.attributes.data.rejected.map((content) => (
                        <div
                          key={content.id}
                          className="p-2 border rounded mb-2"
                        >
                          {/* {content.attributes.post.caption} */}
                          <p>rejected</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ReportContent;
