import { fetcher } from "@utils/fetcher";

const createCampaignBriefAsset = async (params: any) => {
  return await fetcher(`/api/v1/campaign_brief_assets`, "POST", params);
};

const updateCampaignBriefAsset = async (id: number, params: any) => {
  return await fetcher(`/api/v1/campaign_brief_assets/${id}`, "PUT", params);
};

const deleteCampaignBriefAsset = async (id: number) => {
  return await fetcher(`/api/v1/campaign_brief_assets/${id}`, "DELETE");
};

const uploadCampaignBriefAssetFiles = async (
  id: number,
  formData: FormData
) => {
  const response = await fetch(`/api/v1/campaign_brief_assets/${id}`, {
    method: "PUT",
    body: formData,
    credentials: "include",
  });
  const json = await response.json();
  return json;
};

export {
  createCampaignBriefAsset,
  updateCampaignBriefAsset,
  deleteCampaignBriefAsset,
  uploadCampaignBriefAssetFiles,
};
