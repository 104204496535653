import React, { useCallback } from "react";
import SinglePageLayoutWithData from "../../../src/layouts/SinglePageLayoutWithData";
import { getOutreachCollaboratorsList } from "../../api/Collaborators";
import { Sort } from "@types";

const tableColumns = [
  {
    key: "full_name",
    label: "Name",
    isLink: true,
    linkURL: "/card/{slug}",
    isFirstColumn: true,
    linkTarget: "_blank",
  },
  {
    key: "outreach_name",
    label: "Message Title",
    isLink: true,
    linkURL: "/outreach/{outreach_id}",
    linkTarget: "_blank",
  },
  {
    key: "opened_at",
    label: "Opened At",
    isDate: true,
  },
];

const OutreachCollaboratorsList = () => {
  const fetchCollaborators = useCallback(
    async (page?: number, sort?: Sort, params?: { search?: string }) => {
      const { search } = params;
      const response = await getOutreachCollaboratorsList(page, sort, {
        creator_firstName_or_creator_lastName_cont: search,
      });
      const { recipients: items } = response.data;

      return {
        ...response.data,
        items,
      };
    },
    []
  );

  return (
    <div>
      <SinglePageLayoutWithData
        entityName="recipients"
        pageName="Outreach Recipients"
        className="pt-[0px]"
        sortPathName="creator_lastName"
        sortPathDate="opened_at"
        filterPath="state"
        tableColumns={tableColumns}
        tableOnly
        hideCreateNewButton
        getItems={fetchCollaborators}
      />
    </div>
  );
};

export default OutreachCollaboratorsList;
