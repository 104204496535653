import { AdvancedSearchParams } from "../types";

import AdvancedSearchModal from "./components/AdvancedSearchModal";

import InfluencerDetails from "./components/InfluencerDetails";
import InfluencerDemographics from "./components/InfluencerDemographics";
import TotalReachCount from "./components/TotalReachCount";
import InfluencerContent from "./components/InfluencerContent";
import { useEffect, useState } from "react";
import ContentDetails from "./components/ContentDetails";

interface AdvancedContentSearchModalProps {
  onUpdateFilters: (filters: Partial<AdvancedSearchParams>) => void;
  onClose: () => void;
  filters: AdvancedSearchParams;
}

const AdvancedContentSearchModal = ({
  onClose,
  filters,
  onUpdateFilters,
}: AdvancedContentSearchModalProps) => {
  const [pendingFilters, setPendingFilters] = useState<
    Partial<AdvancedSearchParams>
  >({});

  useEffect(() => {
    setPendingFilters({
      ...filters,
    });
  }, []);

  const handleAddFilters = () => {
    onUpdateFilters(pendingFilters);
  };

  const handleUpdatePendingFilters = (updates: Partial<AdvancedSearchParams>) =>
    setPendingFilters({
      ...pendingFilters,
      ...updates,
    });

  return (
    <AdvancedSearchModal
      onClose={onClose}
      onAddToFilters={handleAddFilters}
      onClearFilters={() => onUpdateFilters({})}
      title="Content Filters"
    >
      <>
        <ContentDetails
          filters={pendingFilters}
          onFiltersUpdate={handleUpdatePendingFilters}
        />
        <TotalReachCount
          filters={pendingFilters}
          onFiltersUpdate={handleUpdatePendingFilters}
        />
        <InfluencerDetails
          filters={pendingFilters}
          onFiltersUpdate={handleUpdatePendingFilters}
          hideLocation
        />
        <InfluencerDemographics
          filters={pendingFilters}
          onFiltersUpdate={handleUpdatePendingFilters}
        />
      </>
    </AdvancedSearchModal>
  );
};

export default AdvancedContentSearchModal;
