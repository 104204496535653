import React from "react";

export default function PageSection({ title, captions = [""], children }) {
  const renderCaptions = () =>
    captions.map((caption) => (
      <p key={caption} className="c-page-layout__overview__section__caption">
        {caption}
      </p>
    ));

  return (
    <div className="c-page-layout__overview__section">
      <h2
        className="c-page-layout__overview__section__title"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {renderCaptions()}
      {children}
    </div>
  );
}
