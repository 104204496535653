import React, { createContext } from "react";
import {
  Campaign,
  CampaignBrief,
  User,
  Outreach,
  BrandSettings,
  Content,
  Profile,
  CampaignContentReview,
  Brand,
} from "@types";

import { GroupData, Creator } from "@apiTypes";
import { CampaignMetrics, DateRange } from "@utils/dashboardUtils";

const LayoutContext = createContext<{
  collapsed: boolean;
  fullNavWidth: string;
  collapsedNavWidth: string;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  collapsed: false,
  fullNavWidth: "200px",
  collapsedNavWidth: "50px",
  setCollapsed: () => {},
});

const ProfileContext = createContext<{
  profile?: Profile;
  contentHighlight?: Content[];
  topContent?: Content[];
  followerGrowth?: any;
  responses?: any;
  collapsed?: boolean;
  isBriefNavFixed?: boolean;
  setProfile?: (profile: any) => void;
  setContent?: (content: any) => void;
  setCollapsed?: (collapsed: boolean) => void;
  setIsBriefNavFixed?: (isBriefNavFixed: boolean) => void;
  setResponses: (responses: any) => void;
}>({
  profile: null,
  contentHighlight: null,
  followerGrowth: null,
  responses: null,
  collapsed: null,
  isBriefNavFixed: false,
  setContent: () => {},
  setProfile: () => {},
  setResponses: () => {},
  setCollapsed: () => {},
  setIsBriefNavFixed: () => {},
});

interface UserContextType {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  can: (permission: string) => boolean;
  loading: boolean;
}

const UserContext = createContext<UserContextType>(null);

const AmbassadorsContext = createContext<{
  content: any | null;
  stats: any | null;
  brand: Brand;
  setContent: (content: any | null) => void;
  setStats: (stats: any | null) => void;
  setBrand: (brand: Brand) => void;
}>({
  content: null,
  stats: null,
  brand: null,
  setBrand: () => {},
  setContent: () => {},
  setStats: () => {},
});

const CampaignContext = createContext<{
  activeBrief?: CampaignBrief;
  briefs?: CampaignBrief[];
  campaign: Campaign;
  participantBriefs?: any[] | null;
  setActiveBrief?: (activeBrief: CampaignBrief) => void;
  setBriefs?: (brief: CampaignBrief[]) => void;
  setCampaign: (campaign: Campaign) => void;
  setParticipantBriefs?: (participantBriefs: CampaignBrief[]) => void;
}>({
  activeBrief: null,
  briefs: [],
  campaign: null,
  participantBriefs: null,
  setActiveBrief: () => {},
  setBriefs: () => {},
  setCampaign: () => {},
  setParticipantBriefs: () => {},
});

type ReportPost = {
  type: string;
  links: {
    self: string;
    related: {
      href: string;
      type: string;
      title: string;
    };
  };
  attributes: {
    type: string;
    photo: string;
    story: boolean;
    video: any;
    auth_id: number;
    caption: string;
    card_id: number;
    post_id: string;
    hashtags: any[];
    mentions: string[];
    provider: string;
    username: string;
    verified: boolean;
    has_photo: boolean;
    permalink: string;
    retweeted: boolean;
    sponsored: boolean;
    categories: number[];
    impressions: number;
    interactions: number;
    published_at: string;
    search_cache: string;
    unique_stats: {
      reach: number;
      replies: number;
      impressions: number;
      likes_count: number;
      views_count: number;
      comments_count: number;
    };
    caption_length: number;
    media_product_type: string;
    exclusive_ambassador: boolean;
    followed_by: number;
    name: string;
    engagement: number;
    content_type: any;
    impressions_pretty: string;
  };
};

const GroupContext = createContext<{
  group: GroupData | null;
  setGroup: (group: GroupData | null) => void;
}>({
  group: null,
  setGroup: () => {},
});

type ReportContent = {
  id: number;
  type: string;
  links: {
    self: string;
  };
  attributes: {
    type: string;
    approved: boolean;
    found_by: any[];
    manual: boolean;
    post: ReportPost;
    last_refreshed: string;
  };
};

interface ReportParticipantData {
  id: number;
  links?: {
    self: string;
  };
  attributes: {
    archived: boolean;
    data: {
      approved: ReportContent[]; // Specify more detailed type if possible
      rejected: ReportContent[]; // Specify more detailed type if possible
    };
    email: string;
    firstName: string;
    lastName: string;
    name: string;
    profile_image: string;
    slug: string;
    type: string;
  };
}

interface ReportParticipantList {
  data: ReportParticipantData[];
  id: number;
  included: number;
  links: {
    self: string;
  };
  name: string;
  total: number;
}

interface ReportParticipants {
  brief: { id: string };
  cards: Record<string, unknown>; // Specify more detailed type if possible
  unverified: Record<string, unknown>; // Specify more detailed type if possible
  lists: ReportParticipantList[];
}

interface ReportProps {
  id: number;
  title: string;
  createdBy: string;
  brand: string;
  date: string;
  status: string;
  start_date: string;
  end_date: string;
  background_url: string;
  description: string;
  platforms: string[];
  hashtags: string[];
  mentioned_users: string[];
  slug: string;
  hasParticipants: boolean;
  participants: ReportParticipants;
}

const ReportContext = createContext<{
  report: ReportProps | null;
  // setReport: (report: ReportProps | null) => void;
}>({
  report: null,
  // setReport: () => { },
});

const ReportParticipantContext = createContext<{
  participant: ReportParticipantData | null;
  // setReport: (report: ReportProps | null) => void;
}>({
  participant: null,
  // setReport: () => { },
});

const OutreachContext = createContext<{
  outreach: Outreach;
  setOutreach: (outreach: Outreach) => void;
  selectedRecipientIds: number[];
  setSelectedRecipientIds: (ids: number[]) => void;
}>({
  outreach: null,
  setOutreach: () => {},
  selectedRecipientIds: [],
  setSelectedRecipientIds: () => {},
});

const BrandEditContext = createContext<{
  brandSettings: BrandSettings[] | null;
  setBrandSettings: (brandSettings: BrandSettings[]) => void;
}>({
  brandSettings: null,
  setBrandSettings: () => {},
});

const CreatorEditContext = createContext<{
  creator: Creator;
  setCreator: (creator: Creator) => void;
}>({
  creator: null,
  setCreator: () => {},
});

const ContentReviewContext = createContext<{
  contentReview: CampaignContentReview;
  setContentReview: (contentReview: CampaignContentReview) => void;
}>({
  contentReview: null,
  setContentReview: () => {},
});

interface DashboardContextType {
  dateRange: DateRange;
  setDateRange: (range: DateRange) => void;
  metrics: CampaignMetrics;
  setMetrics: (metrics: CampaignMetrics) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  currentBrandId: number;
  setCurrentBrandId: (brandId: number) => void;
}

const DashboardContext = createContext<DashboardContextType>({
  dateRange: "90",
  setDateRange: () => {},
  metrics: {
    campaigns: 0,
    engagementRate: 0,
    impressions: 0,
    posts: 0,
    influencers: 0,
  },
  setMetrics: () => {},
  isLoading: true,
  setIsLoading: () => {},
  currentBrandId: 0,
  setCurrentBrandId: () => {},
});

export {
  UserContext,
  ProfileContext,
  LayoutContext,
  CampaignContext,
  GroupContext,
  AmbassadorsContext,
  ReportContext,
  ReportParticipantContext,
  OutreachContext,
  BrandEditContext,
  CreatorEditContext,
  ContentReviewContext,
  DashboardContext,
};
