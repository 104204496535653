import FIcon from "@components/FIcon";

interface ArrowButtonProps {
  direction: "up" | "down" | "left" | "right";
  onClick: () => void;
}

const ArrowButton = ({ direction, onClick }: ArrowButtonProps) => (
  <button
    className={`border-neutral_400 border ${["left", "right"].includes(direction) ? "-rotate-90" : ""} rounded-full p-3 focus:outline-none hover:border-neutral_600 focus:border-neutral_600`}
    onClick={onClick}
  >
    <FIcon
      icon={`sort-${["up", "left"].includes(direction) ? "up" : "down"}`}
      size={13}
      color="#000F45"
    />
  </button>
);

export default ArrowButton;
