import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { CampaignContent } from "@apiTypes";

interface ParticipantContentParams {
  deliverable_id_eq?: number;
  participant_id_eq?: number;
  campaign_id_eq?: number;
  platform_eq?: string;
  type_eq?: string;
}

interface ParticipantContentBody {
  campaign_id: number;
  participant_id: number;
  deliverable_id?: number;
  active?: boolean;
  bonus?: boolean;
  fohr_content_id?: number | string;
  platform_uuid?: string;
  image?: File;
  stats_screenshot?: File;
  platform?: string;
  type?: string;
  approved?: boolean;
  published_at?: string;
  caption?: string;
  data?: object;
  insights?: object;
}

// Campaign Content

const getCampaignParticipantContent = async (
  campaign_id: string | number,
  params?: ParticipantContentParams
): Promise<ApiResponse<{ participant_contents: CampaignContent[] }>> => {
  const queryParams = new URLSearchParams();

  const allParams = { ...(params || {}), campaign_id_eq: campaign_id };

  Object.keys(allParams).forEach((param) => {
    if (allParams[param]) {
      queryParams.append(`q[${param}]`, allParams[param]);
    }
  });

  return apiWrapper(`/participant_contents?${queryParams.toString()}`, "GET");
};

const updateParticipantContent = async (
  content_id: number | string,
  body: ParticipantContentBody
): Promise<ApiResponse<any>> => {
  const requestBody = {
    ...body,
    content_id: content_id,
  };

  return apiWrapper(`/participant_contents/${content_id}`, "PUT", requestBody);
};

const createParticipantContent = async (
  params: ParticipantContentBody
): Promise<ApiResponse<any>> => {
  const formData = new FormData();

  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (value !== null) {
      formData.append(`participant_content[${key}]`, value);
    }
  });

  return apiWrapper(`/participant_contents`, "POST", formData);
};

export const deleteParticipantContent = async (
  id: number
): Promise<ApiResponse> => {
  return apiWrapper(`/participant_contents/${id}`, "DELETE");
};

export {
  getCampaignParticipantContent,
  createParticipantContent,
  updateParticipantContent,
};
