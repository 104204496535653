import React from "react";
import { getStateEmoji } from "../../utils/EmojiUtilities";

const StatesTable = ({ states = [], style = {} }) => (
  <table className="table w-full w-100" style={style}>
    <tbody>
      {states.map((state, i) => (
        <tr
          className=" text-sm text-neutral_900"
          key={state.name}
          style={{
            borderBottom: i < states.length - 1 ? "1px solid #E3E6ED" : "none",
          }}
        >
          <td>{getStateEmoji(state.name)}</td>
          <td className="py-[8px] u-py-8">{state.name}</td>
          <td className="text-right">{Math.round(state.percentage)}%</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default StatesTable;
