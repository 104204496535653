import FIcon from "@components/FIcon";

export interface SocialConnectButtonProps {
  name: string;
  icon: string;
  iconOnly?: boolean;
  isConnected: boolean;
  onClick: () => void;
}

const SocialConnectButton = ({
  name,
  icon,
  isConnected,
  iconOnly,
  ...rest
}: SocialConnectButtonProps) => {
  return (
    <button
      type="button"
      className={`bg-transparent border border-${isConnected ? "" : "neutral_400"} h-[48px] flex items-center justify-${isConnected ? "between" : "center"} p-4 rounded-[56px] ${isConnected ? "" : "gap-4"}  ${iconOnly ? "" : "w-full"}`}
      {...rest}
    >
      <FIcon icon={icon} size={20} color="#000721" />
      {!iconOnly ? `${name}` : null}
      {isConnected ? (
        <div className="flex items-center gap-1">
          <FIcon icon="success-input" size={12} />
          {!iconOnly ? <span className="font-xs">Connected</span> : null}
        </div>
      ) : null}
    </button>
  );
};

export default SocialConnectButton;
