import FormField from "@components/Form/FormField";
import LocationFilter from "./LocationFilter";
import ModalSection from "./ModalSection";
import { FCheckbox, FRadioGroup } from "@components/FInputs";
import FMultiSelect from "@components/FMultiSelect";
import { PARENTAL_OPTIONS } from "@pages/search/constants";
import { AdvancedSearchParams } from "@pages/search/types";
import FRangeInput from "@components/Form/FRangeInput";
import { capitalize } from "lodash";
import {
  ETHNICITIES,
  GENDERS,
  MARITAL_STATUSES,
  SEXUAL_ORIENTATIONS,
} from "@constants/demographics";

interface InfluencerDemographicsProps {
  filters: Partial<AdvancedSearchParams>;
  onFiltersUpdate: (filters: Partial<AdvancedSearchParams>) => void;
}

const InfluencerDemographics = ({
  filters,
  onFiltersUpdate,
}: InfluencerDemographicsProps) => {
  const { custom_age_range: age } = filters;

  return (
    <ModalSection icon="user" title="Influencer Demographics">
      <div className="flex items-start mt-8 gap-8 justify-between w-full">
        <FRangeInput
          maxValue={99}
          minValue={18}
          label="Age"
          value={{ min: parseInt(age?.min, 10), max: parseInt(age?.max, 10) }}
          onChange={(value) =>
            onFiltersUpdate({
              custom_age_range: { min: `${value.min}`, max: `${value.max}` },
            })
          }
        />
        <FormField label="Parental Status">
          <FRadioGroup
            direction="column"
            options={PARENTAL_OPTIONS}
            name="ParentalStatus"
            value={filters.children}
            onChange={(value) => onFiltersUpdate({ children: value })}
          />
        </FormField>
        <FMultiSelect
          width="250px"
          label="Marital Status"
          selected={filters.relationship_status}
          options={MARITAL_STATUSES.map(({ value, label }) => ({
            value,
            label: label || capitalize(value),
          }))}
          onChange={(value) => onFiltersUpdate({ relationship_status: value })}
        />
      </div>
      <div className="flex items-start mt-8 justify-between w-full">
        <FMultiSelect
          width="225px"
          label="Gender"
          selected={filters.gender}
          options={GENDERS}
          onChange={(value) => onFiltersUpdate({ gender: value })}
        />
        <FMultiSelect
          width="225px"
          label="Race and enthnicity"
          selected={filters.ethnicity}
          options={ETHNICITIES}
          onChange={(value) => onFiltersUpdate({ ethnicity: value })}
        />
        <FMultiSelect
          width="225px"
          label="Sexual orientation"
          selected={filters.sexual_orientation}
          options={SEXUAL_ORIENTATIONS}
          onChange={(value) => onFiltersUpdate({ sexual_orientation: value })}
        />
      </div>
    </ModalSection>
  );
};

export default InfluencerDemographics;
