import React, { useCallback } from "react";

import { Sort } from "@types";
import { GroupData } from "@apiTypes";
import { getGroupList, GroupListRequestParams } from "@api/Groups/Groups";
import SelectEntitiesTable from "@components/SelectEntities/SelectEntitiesTable";

const TABLE_COLUMNS = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "creators_count",
    label: "Creators",
    isNumber: true,
  },
];

interface GroupsTableProps {
  excludeIds?: number[];
  onChangeSelectedItems?: (groups: GroupData[]) => void;
  selectedItems?: GroupData[];
  hasSingleSelection?: boolean;
  showJustSelected?: boolean;
}

export default function SelectGroupsTable({
  excludeIds,
  onChangeSelectedItems,
  selectedItems,
  hasSingleSelection,
  showJustSelected,
}: GroupsTableProps) {
  const getGroups = useCallback(
    async (page?: number, sort?: Sort, params?: GroupListRequestParams) => {
      const response = await getGroupList(false, page, sort, params);

      const { groups: items } = response.data;

      return {
        ...response.data,
        items,
      };
    },
    []
  );

  return (
    <SelectEntitiesTable
      excludeIds={excludeIds}
      tableColumns={TABLE_COLUMNS}
      onChangeSelectedItems={onChangeSelectedItems}
      selectedItems={selectedItems}
      hasSingleSelection={hasSingleSelection}
      showJustSelected={showJustSelected}
      getItems={getGroups}
    />
  );
}
