import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";

const listCampaignAccountings = async (params?: {
  q?: {
    campaign_id_eq?: number;
    invoice_status_eq?: string;
    po_status_eq?: string;
    contract_status_eq?: string;
  };
}): Promise<ApiResponse<any>> => {
  const queryParams = new URLSearchParams();

  if (params?.q?.campaign_id_eq) {
    queryParams.append("q[campaign_id_eq]", params.q.campaign_id_eq.toString());
  }
  if (params?.q?.invoice_status_eq) {
    queryParams.append("q[invoice_status_eq]", params.q.invoice_status_eq);
  }
  if (params?.q?.po_status_eq) {
    queryParams.append("q[po_status_eq]", params.q.po_status_eq);
  }
  if (params?.q?.contract_status_eq) {
    queryParams.append("q[contract_status_eq]", params.q.contract_status_eq);
  }

  return apiWrapper(
    `/api/v1/campaign_accountings?${queryParams.toString()}`,
    "GET"
  );
};

const getCampaignAccounting = async (
  accounting_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/api/v1/campaign_accountings/${accounting_id}`, "GET");
};

const updateCampaignAccounting = async (
  accounting_id: string | number,
  params: Partial<any>
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/api/v1/campaign_accountings/${accounting_id}`,
    "PUT",
    params
  );
};

export {
  listCampaignAccountings,
  getCampaignAccounting,
  updateCampaignAccounting,
};
