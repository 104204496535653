import * as React from "react";
import { useState, useEffect } from "react";
import SinglePageLayout from "@layouts/SinglePageLayout";
import LoadingSpinner from "@components/LoadingSpinner";
import { getContractTemplateIndex } from "@api/ContractTemplates/ContractTemplates";

const cardDropDownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Delete", value: "delete" },
  { label: "Archive", value: "archive" },
];

const filterOptions = [
  { label: "All Templates", static: "Templates", value: null },
  { label: "Default", static: "Templates", value: true },
  { label: "Not Default", static: "Templates", value: false },
];

const tableColumns = [
  {
    key: "name",
    label: "Name",
    isLink: true,
    linkURL: "{id}",
    isImage: false,
  },
  { key: "created_at", label: "Created", isDate: true },
  { key: "updated_at", label: "Updated", isDate: true },
  { key: "default", label: "Default", isStateBubble: true },
];

function ContractTemplateList() {
  const [templates, setTemplates] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadTemplates = async () => {
    setLoading(true);
    try {
      const result = await getContractTemplateIndex();

      const { contract_templates } = result.data;

      setTemplates(contract_templates);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  if (loading || !templates) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <SinglePageLayout
      pageTitle="Contract Templates"
      pageName="Contract Templates"
      cardData={templates}
      sortPathName="name"
      sortPathDate="created_at"
      filterOptions={filterOptions}
      filterPath="default"
      tableOnly
      tableColumns={tableColumns}
      cardDropDownOptions={cardDropDownOptions}
      loading={loading}
    />
  );
}

export default ContractTemplateList;
