import React from "react";

interface Props {
  width?: number;
  height?: number;
}

export default function FohrLogoShort({ width = 18, height = 18 }: Props) {
  return (
    <svg
      width={JSON.stringify(width)}
      height={JSON.stringify(height)}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.91309 1.39124V18.6087H7.48745V11.3118H12.785V8.96349H7.48745V3.74084H15.087V1.39124H4.91309Z"
        fill="#000F45"
      />
    </svg>
  );
}
