/**
 * Cloudflare Image Transformation docs:
 *
 * https://developers.cloudflare.com/images/transform-images/transform-via-url/
 *
 * This is a paid feature, so we should avoid using dynamic width and height values
 * not to generate a large volume of transformation requests.
 *
 * Pricing reference: https://developers.cloudflare.com/images/pricing/#images-paid
 */
export const getResizedImageURL = (
  src: string,
  width: number,
  height: number,
  quality: number = 75
) => {
  try {
    const url = new URL(src);

    if (url.host.endsWith('.fohr.co')) {
      return `${url.origin}/cdn-cgi/image/width=${width},height=${height},quality=${quality},fit=cover${url.pathname}${url.search}`
    }
  }
  catch (err) {
    console.warn(err);
  }

  return src;
};
