import ArrowButton from "@components/buttons/ArrowButton";

interface NavigationButtonsProps<T> {
  currentIndex: number;
  items: T[];
  onGoToIndex: (indes: number) => void;
}

const NavigationButtons = function <T>({
  currentIndex,
  items,
  onGoToIndex,
}: NavigationButtonsProps<T>) {
  const handlePrevClick = () => {
    const prevIndex = (currentIndex - 1 + items.length) % items.length;

    onGoToIndex(prevIndex);
  };

  const handleNextClick = () => {
    const nextIndex = (currentIndex + 1) % items.length;

    onGoToIndex(nextIndex);
  };

  return (
    <div className="flex flex-row gap-2 items-center justify-center">
      <ArrowButton direction="left" onClick={handlePrevClick} />
      <ArrowButton direction="right" onClick={handleNextClick} />
    </div>
  );
};

export default NavigationButtons;
