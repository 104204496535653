import FButton from "@components/FButton";
import { FInput } from "@components/FInputs";

interface EditPermalinkProps {
  pendingUrl: string;
  isSubmitted: boolean;
  onChange: (url: string) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

const EditPermalink = ({
  pendingUrl,
  isSubmitted,
  onChange,
  onSubmit,
  onCancel,
}: EditPermalinkProps) => {
  return (
    <div className="flex items-end gap-2">
      <FInput
        label="Edit URL"
        className="flex-1"
        value={pendingUrl}
        validate={isSubmitted}
        onChange={(value) => onChange(value)}
        required
      />
      <div className="flex gap-2">
        <FButton label="Save" onClick={onSubmit} primary />
        <FButton label="Cancel" onClick={onCancel} />
      </div>
    </div>
  );
};

export default EditPermalink;
