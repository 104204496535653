import React, { useRef, useState } from "react";
import { FTooltip } from "../../../FTooltip";
import DecorationBadge from "../../CardAssets";
import FIcon from "@components/FIcon";

import { formatLargeNumber } from "../../../../utils/number_utilities";
import { getResizedImageURL } from "@utils/image";
import FButton from "@components/FButton";
import VideoPlayer from "@components/Post/VideoPlayer";
import { Link } from "react-router-dom";

const ContentCard = ({
  post,
  className,
  imageOnly = false,
  isProfile,
  cardHeight,
  cardWidth,
  checkBoxClickList,
  onCheckBoxClick,
  onCardClick,
  platform,
  permalink,
  full_name,
  profile_image,
  media_type,
  content_type,
  published_at,
  onDelete,
  onViewStatsScreenshot,
  isHighlighted = false,
  ...props
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPaused, setIsPaused] = useState(true);
  const [hover, setHover] = useState(false);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPaused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      setIsPaused(!isPaused);
    }
  };

  const photo_url = props.image?.original || props.photo_url || props.file_url;
  const stockImage = photo_url && !photo_url.includes("missing_default");
  const isVideo = props.image_content_type?.includes("video");

  const contentTypeBannerText = (content_type, platform) => {
    if (
      platform === "instagram" &&
      (content_type === "image" || content_type === "video")
    ) {
      return "In-feed Post";
    } else if (
      platform === "instagram" ||
      platform === "tiktok" ||
      platform === "youtube"
    ) {
      return content_type;
    } else {
      return "Post";
    }
  };

  const ContentTypeBanner = () => {
    return (
      <span className="absolute justify-center inline-flex px-[10px] items-center top-[8px] right-[8px] bg-[#2B2C33] bg-opacity-60 rounded-md py-[4px] bg-blend-multiply">
        <p className="text-white text-[12px] font-medium capitalize">
          {media_type
            ? media_type
            : contentTypeBannerText(content_type, platform)}
        </p>
      </span>
    );
  };

  const isSelected =
    isHighlighted ||
    (checkBoxClickList && checkBoxClickList.includes(props.id));

  const hasCustomBorderRadius = className && className.includes("rounded-");

  return (
    <div
      className={`relative border overflow-hidden shadow-md ${hasCustomBorderRadius ? "" : "rounded-xl"} hover:z-[${props.hoverZIndex || 9999}] ${
        isSelected ? "border-1 border-black" : "border-1 border-light_border"
      } ${className || ""}`}
      style={{
        minHeight: `${cardHeight}px`,
        minWidth: `${cardWidth}px`,
      }}
    >
      {isSelected ? (
        <div
          className="absolute w-full h-full left-0 top-0"
          style={{
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 11,
          }}
        />
      ) : null}
      {onDelete ? (
        <div className="absolute right-[10px] top-[10px] z-[100]">
          <FButton
            icon={{ color: "#FFFFFF", name: "delete", size: 20 }}
            iconButton
            onClick={() => onDelete(props.id)}
          />{" "}
        </div>
      ) : null}
      <div
        className={`absolute w-full top-0 h-[50px] flex flex-row justify-between items-center z-${onCheckBoxClick ? 50 : 49} px-4 py-2`}
      >
        {content_type && <ContentTypeBanner />}
        {onCheckBoxClick && (
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              className="w-5 h-5 text-white !border-light_red focus:ring-black checked:bg-black focus:ring-none cursor-pointer hover:border-black"
              checked={
                checkBoxClickList && checkBoxClickList.includes(props.id)
              }
              onChange={() => {
                onCheckBoxClick && onCheckBoxClick(props.id);
              }}
            />
          </div>
        )}
        <DecorationBadge decoration={props.decoration} />
      </div>
      {isVideo && (
        <div
          className="absolute inset-0 z-50 flex items-center justify-center z-10 border-2 border-white opacity-50 hover:opacity-100 rounded-full cursor-pointer p-4 w-[60px] h-[60px] m-auto"
          onClick={togglePlayPause}
        >
          {isPaused ? (
            <FIcon
              icon="play-button"
              color="white"
              className="left-1 relative"
              size={24}
            />
          ) : (
            <FIcon icon="pause" color="white" size={24} />
          )}
        </div>
      )}
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={`card-parent cursor-pointer flex flex-col justify-between relative h-[${cardHeight}px] z-10 w-[${cardWidth}px] 
        ${isVideo ? `${hasCustomBorderRadius ? "" : "rounded-xl"} overflow-hidden` : ""} ${className}`}
        style={{
          ...props?.style,
          minHeight: `${cardHeight}px`,
          minWidth: `${cardWidth}px`,
        }}
        key={post?.content_id ? post.content_id : props.id}
        onClick={() =>
          onCardClick &&
          onCardClick({
            post,
            className,
            imageOnly,
            isProfile,
            cardHeight,
            cardWidth,
            checkBoxClickList,
            platform,
            permalink,
            full_name,
            profile_image,
            media_type,
            content_type,
            published_at,
            ...props,
          })
        }
      >
        {photo_url && stockImage ? (
          isVideo ? (
            <video
              ref={videoRef}
              src={photo_url}
              autoPlay={!isPaused}
              muted
              className="w-full h-full max-h-[400px] object-cover"
            />
          ) : (
            <div
              className={`w-full h-full bg-cover bg-center absolute top-0 left-0 transition-all duration-500 ease-out ${
                hover ? "scale-105" : "scale-100"
              }`}
              style={{
                backgroundImage: `url(${getResizedImageURL(photo_url, 500, 500)})`,
              }}
            />
          )
        ) : props.permalink_url || null ? (
          <VideoPlayer
            className="h-full"
            hidePlatformIcon={false}
            post={{ ...props, platform }}
            showPostType
          />
        ) : (
          <div className={`w-full h-full absolute top-0 left-0`}>
            <div className="bg-highlight_red flex justify-center items-center w-full h-full">
              <div className="bg-light_red rounded-full p-6 -mt-[40px]">
                <FIcon
                  icon="photo"
                  size={50}
                  color="#000F45"
                  className="inline-block vertical-align-middle"
                />
              </div>
            </div>
          </div>
        )}
        <div className="absolute bottom-0 w-full">
          <div className="relative max-w-full">
            {!imageOnly && (
              <>
                {!isProfile && (
                  <div>
                    <Link
                      to={`/card/${props.creator?.slug}`}
                      className="flex flex-row items-end justify-between w-full h-14  px-4"
                    >
                      {profile_image && (
                        <div
                          className="bg-cover bg-center rounded-full border border-light_border h-[46px] w-[46px] mb-2 bg-white"
                          style={{ backgroundImage: `url(${profile_image})` }}
                        />
                      )}
                    </Link>
                    <a
                      href={permalink}
                      target="_blank"
                      className="absolute justify-center inline-flex top-[0px] left-[47px] z-1 rounded-full"
                    >
                      <FIcon
                        icon={`${platform}-solid`}
                        size={20}
                        color="#000F45"
                        className="inline-block vertical-align-middle h-[28px] w-[28px]"
                      />
                    </a>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="bottom-0 left-0 right-0 flex flex-col items start w-full gap-1 bg-light_red_opacity px-4 py-2 backdrop-blur-sm">
            <div className="flex justify-between flex-row w-full">
              <p className="text-[16px] text-blue_night_sky font-sofia-pro truncate">
                {full_name}
              </p>
              <div className="flex items-center gap-2">
                {onViewStatsScreenshot && props.stats_screenshot ? (
                  <div className="group block">
                    <FTooltip
                      style={{
                        textWrap: "nowrap",
                        bottom: "120%",
                        right: "-80%",
                      }}
                      label="View stats screenshot"
                    >
                      <FButton
                        className=""
                        icon={{ color: "#000F45", name: "graph", size: 16 }}
                        iconButton
                        onClick={(e) => {
                          e.stopPropagation();

                          onViewStatsScreenshot(props);
                        }}
                      />
                    </FTooltip>
                  </div>
                ) : null}
                {published_at ? (
                  <div className="group block">
                    <FTooltip
                      style={{
                        textWrap: "nowrap",
                        bottom: "140%",
                        right: "-80%",
                      }}
                      label={published_at}
                    >
                      <FIcon icon="clock" size={16} color="#000F45" />
                    </FTooltip>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center gap-1">
                <div className="flex items-center mr-[14px]">
                  <FTooltip
                    label="Impressions"
                    style={{ bottom: "120%", left: "-1%" }}
                  >
                    <FIcon
                      icon="eye-solid"
                      color="#000"
                      size={14}
                      className="-mt-[2px] opacity-30"
                    />
                    <p
                      className="text-center text-[14px] leading-[19.66px] pl-[5px] text-[var(--Primary-Night-Sky,#000721)]"
                      style={{ fontFamily: "Sofia Pro" }}
                    >
                      {props.stats?.views_count || props.views_count
                        ? formatLargeNumber(
                            props.stats?.views_count || props.views_count
                          )
                        : "-"}
                    </p>
                  </FTooltip>
                </div>
                <div className="flex items-center">
                  <FIcon
                    icon="heart-solid"
                    color="#000F45"
                    size={14}
                    className="-mt-[2px] opacity-30"
                  />
                  <FTooltip label="Interactions" style={{ bottom: "120%" }}>
                    <p
                      className="text-center text-[14px] leading-[19.66px] pl-[5px] text-[var(--Primary-Night-Sky,#000721)]"
                      style={{ fontFamily: "Sofia Pro" }}
                    >
                      {props.stats?.interactions ||
                      props.interactions
                        ? formatLargeNumber(
                            props.stats?.interactions ||
                              props.interactions
                          )
                        : "-"}
                    </p>
                  </FTooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentCard;
