import FButton from "@components/FButton";
import FDropdown from "@components/FDropdown";
import FIcon from "@components/FIcon";
import { FCheckbox, FInput } from "@components/FInputs";
import Heading from "@components/Heading";
import React, { useState } from "react";

import {
  AmbassadorQuestion,
  AmbassadorQuestionRequest,
  FieldType,
} from "types/ambassador-question";

import { useLocation, useNavigate } from "react-router-dom";

const FIELD_TYPES = [
  {
    value: FieldType.TEXT,
    label: "Text",
  },
  {
    value: FieldType.TEXTAREA,
    label: "Text area",
  },
  {
    value: FieldType.SINGLE_SELECT,
    label: "Single select",
  },
  {
    value: FieldType.MULTI_SELECT,
    label: "Multi select",
  },
];

interface QuestionFormProps {
  question: AmbassadorQuestion;
  questions: AmbassadorQuestion[];
  onSave: (question: Partial<AmbassadorQuestionRequest>) => void;
  waiting: boolean;
}

const QuestionForm = ({
  question,
  questions,
  onSave,
  waiting,
}: QuestionFormProps) => {
  const [values, setValues] = useState<Partial<AmbassadorQuestion>>(question);

  const [newOption, setNewOption] = useState("");
  const [editingOption, setEditingOption] = useState<number>(null);

  const navigate = useNavigate();

  const location = useLocation();

  const handleUpdate = (updates) => setValues({ ...values, ...updates });

  const handleUpdateOption = (indexToUpdate, value) => {
    const newOptions = values.data.options.map((option, index) =>
      index === indexToUpdate ? value : option
    );

    handleUpdate({ data: { options: newOptions } });
  };

  const handleAddNewOption = () => {
    if (!newOption) {
      return;
    }

    handleUpdate({ data: { options: [...values.data.options, newOption] } });

    setNewOption("");
  };

  const handleDeleteOption = (indexToDelete) => {
    const newOptions = values.data.options.filter(
      (option, index) => option && index !== indexToDelete
    );

    handleUpdate({ data: { options: newOptions } });
  };

  const handleGoBackToList = () =>
    navigate("/ambassadors/onboarding_questions");

  const urlParams = new URLSearchParams(location.search);

  const questionId = urlParams.get("question_id");

  const parentQuestionId =
    question.question_id || (questionId && parseInt(questionId, 10));

  const parentQuestion = parentQuestionId
    ? questions.find((question) => question.id === parentQuestionId)
    : null;

  const handleSave = () => {
    const { data, ...rest } = values;

    let params = {
      ...rest,
      ...(parentQuestion ? { question_id: parentQuestion.id } : {}),
    } as AmbassadorQuestionRequest;

    if (parentQuestion && !params.conditional_answer) {
      params = {
        ...params,
        conditional_answer: parentQuestion.data.options[0],
      };
    }

    if (data?.options) {
      params = {
        ...params,
        data: data?.options.join(","),
      };
    }

    onSave(params);
  };

  console.log("Parent question", parentQuestion, questions, question);

  return (
    <div className="w-[600px] pb-20">
      <div className="flex gap-4">
        <FButton
          iconLeft={{
            name: "fancy-arrow-left",
            size: 12,
            color: "#000721",
            className: "mr-2",
          }}
          onClick={handleGoBackToList}
          label="Back"
          width="100px"
          height="40px"
        />
        <Heading
          title={`${question.id ? "Edit" : "Add New"} Ambassador Onboarding Question`}
        />
      </div>
      <div className="flex flex-col gap-6 m-auto mt-[40px] w-full">
        <FInput
          name="question"
          onChange={(value) => handleUpdate({ question: value })}
          label="Question"
          value={values.question}
          required
          width="100%"
        />
        <FInput
          name="description"
          onChange={(value) => handleUpdate({ description: value })}
          label="Description"
          value={values.description}
          width="100%"
        />
        {parentQuestion ? (
          <FDropdown
            label="Conditional answer for parent question"
            options={parentQuestion.data.options.map((option) => ({
              value: option,
              label: option,
            }))}
            required
            selectedValue={values.conditional_answer}
            onChange={(value) => handleUpdate({ conditional_answer: value })}
            height="40"
            width="100%"
          />
        ) : null}
        <FCheckbox
          id="required"
          checked={values.required || false}
          label="Required"
          onChange={(value) => handleUpdate({ required: value })}
        />
        <FCheckbox
          id="visible_form"
          checked={values.visible_form || false}
          label="Visible on onboarding form?"
          onChange={(value) => handleUpdate({ visible_form: value })}
        />
        <FCheckbox
          id="visible_search"
          checked={values.visible_search || false}
          label="Visible in search filters?"
          onChange={(value) => handleUpdate({ visible_search: value })}
        />
        <FInput
          name="search_question"
          onChange={(value) => handleUpdate({ search_question: value })}
          label="Search question"
          value={values.search_question}
          required
          width="100%"
        />
        <FDropdown
          label="Field type"
          options={FIELD_TYPES}
          required
          selectedValue={values.field_type}
          onChange={(value) => handleUpdate({ field_type: value })}
          height="40"
          width="100%"
        />
        {[FieldType.SINGLE_SELECT, FieldType.MULTI_SELECT].includes(
          values.field_type
        ) ? (
          <div className="flex flex-col gap-4 pl-2">
            <h4>Manage Options</h4>
            {values.data.options.map((option, index) => {
              const isEditing = editingOption === index;

              return (
                <div>
                  <div
                    className="flex items-center justify-between gap-2"
                    key={index}
                  >
                    {isEditing ? (
                      <FInput
                        onChange={(value) => handleUpdateOption(index, value)}
                        label="Option"
                        value={option}
                        width="100%"
                      />
                    ) : (
                      <span>{option}</span>
                    )}
                    <div className="flex gap-2">
                      <div
                        onClick={() =>
                          setEditingOption(isEditing ? null : index)
                        }
                        className="cursor-pointer bg-light_red w-[32px] h-[32px] rounded-lg border flex all-center"
                      >
                        <FIcon
                          icon={isEditing ? "checkmark" : "edit"}
                          color="#000000"
                          size={16}
                        />
                      </div>
                      <div
                        onClick={() => handleDeleteOption(index)}
                        className="cursor-pointer bg-light_red w-[32px] h-[32px] rounded-lg border border-red flex all-center"
                      >
                        <FIcon icon="delete" color="#E47667" size={16} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="flex items-end gap-2">
              <FInput
                onChange={(value) => setNewOption(value)}
                onEnter={handleAddNewOption}
                label="New Option"
                value={newOption}
                width="100%"
              />
              <FButton
                onClick={handleAddNewOption}
                primary
                label="Add+"
                height="40px"
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="flex gap-2 justify-end pt-16">
        <FButton
          onClick={handleGoBackToList}
          label="Cancel"
          width="100%"
          height="40px"
        />
        <FButton
          onClick={handleSave}
          loading={waiting}
          primary
          label="Save"
          width="100%"
          height="40px"
        />
      </div>
    </div>
  );
};

export default QuestionForm;
