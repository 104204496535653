import React, { useContext } from "react";
import CampaignBrief from "@pages/campaigns/CampaignBrief";
import { ProfileContext } from "@contexts/index";

const ProfileBrief = () => {
  const { collapsed } = useContext(ProfileContext);
  return <CampaignBrief collapsed={collapsed} />;
};

export default ProfileBrief;
