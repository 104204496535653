import React, { useState, useEffect, useRef } from "react";
import FIcon from "./FIcon";
import { isEmpty } from "lodash";
import { FInput } from "./FInputs";

function FMultiSelect({
  options = [],
  selected = [],
  hasSearch = false,
  onChange = null,
  height = 500,
  width = "100%",
  placeholder = "Select",
  label = "",
  required = false,
  validate = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);

  const handleChange = (option) => {
    if (selected.includes(option)) {
      onChange(selected.filter((item) => item !== option));
    } else {
      onChange([...selected, option]);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    // Add click event listener to the document body
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredOptions = searchTerm
    ? options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : options;

  const showSearch = hasSearch || options.length > 9;

  return (
    <div
      style={{
        width: window.innerWidth < 640 ? "100%" : width,
      }}
    >
      {label && (
        <div className="flex mb-[6px] justify-between items-center w-full">
          <div className="flex items-center">
            <label className="text-sm leading-[18px]">
              {label}
              {required ? "*" : ""}
            </label>
          </div>
        </div>
      )}
      <div
        ref={dropdownRef}
        className="relative inline-block  text-[14px] w-full"
        style={{
          maxWidth: width,
        }}
      >
        <div
          className="flex w-full items-center justify-between py-[8px] px-[16px] border border-[#C9CDD9] bg-white cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <p>
            {selected.length > 0 ? `${selected.length} Selected` : placeholder}
          </p>
          <FIcon
            icon="chevron"
            size={11}
            color="#000F45"
            className={`ml-[8px] ${isOpen ? "origin-center rotate-180" : ""}`}
          />
        </div>
        {validate && required && isEmpty(selected) && (
          <p className="text-red text-xs font-normal  tracking-[0.12px] mt-[6px]">
            At least one {label.toLowerCase()} is required
          </p>
        )}
        {selected && isOpen && (
          <div
            className="absolute text-[#5C6276] w-full top-[100%] left-0 z-[1080] p-0 my-[4px] bg-white border border-[#C9CDD9] overflow-y-auto"
            style={{
              maxHeight: height,
            }}
          >
            {showSearch ? (
              <div className="p-2 h-[50px]">
                <FInput
                  value={searchTerm}
                  placeholder="Search..."
                  width="100%"
                  type="text"
                  onChange={setSearchTerm}
                  required
                />
              </div>
            ) : null}
            <div
              className={`flex flex-col flex-nowrap ${showSearch ? " overflow-y-auto h-[350px]" : ""}`}
            >
              {filteredOptions.map((option) => (
                <div
                  key={option.value}
                  className={`py-[8px] px-[16px] cursor-pointer ${selected.includes(option.value) ? "bg-[#fff5f3] text-red" : "hover:bg-[#f2f2f2]"}`}
                  onClick={() => handleChange(option.value)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FMultiSelect;
