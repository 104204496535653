import React, { useState } from "react";
import FButton from "@components/FButton";
import { FInput } from "@components/FInputs";

import { resetPassword } from "@api/user";
import { useSearchParams } from "react-router-dom";

export default function ResetPassword() {
  const [searchParams] = useSearchParams();

  const [values, setValues] = useState({
    email: "",
    password: "",
    password_confirmation: "",
  });

  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const ref = searchParams.get("ref");
  const token = searchParams.get("token");

  const handleUpdate = (updates) => setValues({ ...values, ...updates });

  const handleSubmit = async () => {
    if (values.password !== values.password_confirmation) {
      setError("Password have to match");

      return;
    }

    setWaiting(true);

    try {
      await resetPassword(null, values.password, token, ref);

      setSuccess(
        "Your new password has been successfully set. You will be redirected to the app shortly"
      );

      setTimeout(() => {
        window.location.href = "/";
      }, 3 * 1000);
    } catch (err) {
      console.log(err);

      setError(err?.response?.data?.message);
    }
  };

  return (
    <>
      <p className="text-3xl mb-8 text-center">Reset your password</p>
      <p className="mb-8 text-center">
        Almost done. Enter your new password and you’re good to go Sign up
      </p>
      <div className="mt-[16px]">
        <FInput
          className="mb-[16px]"
          value={values.password}
          onChange={(value) => handleUpdate({ password: value })}
          type="password"
          label="Your New Password"
          required
          width="100%"
        />
        <FInput
          className="mb-[16px]"
          value={values.password_confirmation}
          onChange={(value) => handleUpdate({ password_confirmation: value })}
          type="password"
          label="Confirm New Password"
          required
          width="100%"
        />
        <>
          <FButton
            primary
            onClick={handleSubmit}
            label="Reset Password"
            loading={waiting}
            width="100%"
            type="submit"
          />
          {success ? (
            <p className={` text-[16px] mt-[16px] text-green `}>{success}</p>
          ) : null}
        </>
      </div>
      {error ? (
        <p className={" text-[16px] mt-[16px] text-red"}>{error}</p>
      ) : null}
    </>
  );
}
