import React from "react";

import { ComposableMap, Geographies, Geography } from "react-simple-maps";

import USTopoData from "us-atlas/states-10m.json";

import { maxBy } from "lodash";
import { DemographicsBreakdownItem } from "@types";

import getFillColor from "../map-utils";
import StatesTable from "@components/Tables/StatesTable";
import WidgetContainer from "@components/charts/demographics/WidgetContainer";

interface StatesBreakdownProps {
  items: DemographicsBreakdownItem[];
}

export default function StatesBreakdown({ items }: StatesBreakdownProps) {
  const maxValueItem = maxBy(items, "percentage");

  return (
    <WidgetContainer title="Top States" value={maxValueItem?.name}>
      <ComposableMap projection="geoAlbersUsa">
        <Geographies geography={USTopoData}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const value = items?.find((c) =>
                c.name.toLowerCase().includes(geo.properties.name.toLowerCase())
              )?.percentage;
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={getFillColor(value)}
                  stroke="#fff"
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
      <StatesTable states={items} style={{ marginTop: 32 }} />
    </WidgetContainer>
  );
}
