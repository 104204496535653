import React, { Ref } from "react";
import RichTextEditor from "@components/RichTextEditor";
import { CampaignBrief } from "@types";

interface Props {
  containerRef: Ref<HTMLDivElement>;
  brief: CampaignBrief;
  onUpdate: (updates: Partial<CampaignBrief>) => void;
}

export default function BriefAbout({ brief, containerRef, onUpdate }: Props) {
  const { description } = brief;

  return (
    <div ref={containerRef}>
      <div className="mb-6"></div>
      <div className="w-full bg-white">
        <RichTextEditor
          id="about"
          value={description || ""}
          onChange={(value) => onUpdate({ description: value })}
        />
      </div>
    </div>
  );
}
