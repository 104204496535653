import React from "react";

import { ComposableMap, Geographies, Geography } from "react-simple-maps";

import WorldTopoData from "world-atlas/countries-110m.json";

import { maxBy } from "lodash";
import { DemographicsBreakdownItem } from "@types";

import getFillColor from "../map-utils";
import CountriesTable from "@components/Tables/CountriesTable";
import WidgetContainer from "@components/charts/demographics/WidgetContainer";

interface CountriesBreakdownProps {
  items: DemographicsBreakdownItem[];
}

export default function CountriesBreakdown({ items }: CountriesBreakdownProps) {
  const maxValueItem = maxBy(items, "percentage");

  return (
    <WidgetContainer title="Top Countries" value={maxValueItem?.name}>
      <ComposableMap projection="geoMercator">
        <Geographies geography={WorldTopoData}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const value = items?.find((c) =>
                c.name.toLowerCase().includes(geo.properties.name.toLowerCase())
              )?.percentage;

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={getFillColor(value)}
                  stroke="#fff"
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
      <CountriesTable countries={items} style={{ marginTop: 32 }} />
    </WidgetContainer>
  );
}
