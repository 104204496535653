import { ApiResponse, BulletinData } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { getQueryParamsForSearchRequest } from "@constants/api";
import { Sort } from "@types";

const getBulletin = async (
  bulletin_id: string | number
): Promise<ApiResponse<BulletinData>> => {
  return await apiWrapper(`/bulletins/${bulletin_id}`, "GET");
};

const getBulletinList = async (
  admin?: boolean,
  page?: number,
  sort?: Sort,
  params?: object
): Promise<
  ApiResponse<{
    bulletins: BulletinData[];
    total_items: number;
  }>
> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);


  // Check if params.admin is true
  if (admin === true) {
    return apiWrapper(`/admin/bulletins?${queryParams.toString()}`, "GET");
  } else {
      return apiWrapper(`/bulletins?${queryParams.toString()}`, "GET");
    }

  /*  const queryParams = new URLSearchParams();

  if (params?.q?.brand_id_eq) {
    queryParams.append("q[brand_id_eq]", params.q.brand_id_eq.toString());
  }
  if (params?.q?.name_cont) {
    queryParams.append("q[name_cont]", params.q.name_cont);
  }

  return apiWrapper(`/bulletins?${queryParams.toString()}`, "GET"); */
};

export const createBulletin = async (
  params: Partial<BulletinData>
): Promise<ApiResponse<BulletinData>> => {
  return apiWrapper("/bulletins", "POST", params);
};

export const updateBulletin = async (
  bulletin_id: string | number,
  params: Partial<BulletinData>
): Promise<ApiResponse<BulletinData>> => {
  return apiWrapper(`/bulletins/${bulletin_id}`, "PUT", params);
};

const deleteBulletin = async (bulletin_id: string | number) => {
  return apiWrapper(`/bulletins/${bulletin_id}`, "DELETE");
};

export { getBulletin, getBulletinList, deleteBulletin };
