import FButton from "@components/FButton";
import useAuth from "@hooks/useAuth";

import { useNavigate } from "react-router-dom";

const ContentGuard = () => {
  const { user } = useAuth();

  const navigate = useNavigate();

  if (user) {
    return null;
  }

  return (
    <div
      className="h-full w-full flex flex-col items-center py-[200px]"
      style={{
        backdropFilter: "blur(10px)",
        position: "absolute",
        left: 0,
        top: 0,
      }}
    >
      <span className="mb-4 w-[400px] text-center">
        For a complete detailed breakdown, click here to sign up for a free
        trial or reach out to{" "}
        <a className="text-red" href="mailto:sales@fohr.co">
          sales@fohr.co
        </a>
      </span>
      <FButton primary label="Sign Up" onClick={() => navigate("/sign_up")} />
    </div>
  );
};

export default ContentGuard;
