import FBaseInput from "@components/FInputs/FBaseInput";
import FormField, { FormFieldProps } from "./FormField";

interface FRangeInputProps extends FormFieldProps {
  minValue?: number;
  maxValue?: number;
  value: { min: number; max: number };
  onChange: (value: { min: number; max: number }) => void;
}

const FRangeInput = ({
  minValue,
  maxValue,
  value,
  onChange,
  ...rest
}: FRangeInputProps) => {
  const { min, max } = value || {};

  const handleMinChange = (value: string) => {
    const valueAsNumber = parseInt(value, 10) || 0;

    onChange({
      min: valueAsNumber,
      max,
    });
  };

  const handleMaxChange = (value: string) => {
    const valueAsNumber = parseInt(value, 10);

    onChange({
      max: valueAsNumber,
      min,
    });
  };

  const handleValidateInputs = () =>
    onChange({
      min,
      max: Math.max(min, max),
    });

  return (
    <FormField {...rest}>
      <div className="flex gap-2 items-center">
        <FBaseInput
          min={minValue}
          max={maxValue}
          placeholder="Min"
          value={min ? `${min}` : ""}
          onChange={handleMinChange}
          onBlur={handleValidateInputs}
          type="number"
        />
        <div className="w-[10px] h-[1px] bg-neutral_500" />
        <FBaseInput
          min={minValue}
          max={maxValue}
          placeholder="Max"
          value={max ? `${max}` : ""}
          onChange={handleMaxChange}
          onBlur={handleValidateInputs}
          type="number"
        />
      </div>
    </FormField>
  );
};

export default FRangeInput;
