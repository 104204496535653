import React, { useState } from "react";
import { Outreach } from "@types";
import { FInput } from "@components/FInputs";
import FButton from "@components/FButton";
import FDropdown from "@components/FDropdown";
import RichTextEditor from "@components/RichTextEditor";
import FormField from "@components/Form/FormField";

import Modal from "@components/Modals/Modal";
import { toast } from "react-hot-toast";
import FIcon from "@components/FIcon";

import apiClient from "@apiClient";

const { create } = apiClient.outreach;

interface SendMessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreated?: (newOutreach: Outreach) => void;
}

const SendMessageModal = ({
  isOpen,
  onClose,
  onCreated,
}: SendMessageModalProps) => {
  const [waiting, setWaiting] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(0);

  const [values, setValues] = useState({
    message: { subject: "", body: "" },
    reply_to: "",
    cc_emails: "",
  });

  const [attachments, setAttachments] = useState<
    Array<{ url: string; file: File }>
  >([]);

  const sampleTemplates = [
    { id: 0, name: "None", subject: "", body: "" },
    { id: 1, name: "Template 1", subject: "Subject 1", body: "Body 1" },
    { id: 2, name: "Template 2", subject: "Subject 2", body: "Body 2" },
    { id: 3, name: "Template 3", subject: "Subject 3", body: "Body 3" },
  ];

  const handleTemplateChange = (templateId: number) => {
    setSelectedTemplateId(templateId);
    const selectedTemplate = sampleTemplates.find(
      (template) => template.id === templateId
    );
    if (selectedTemplate) {
      setValues((prevValues) => ({
        ...prevValues,
        message: {
          subject: selectedTemplate.subject,
          body: selectedTemplate.body,
        },
      }));
    }
  };

  const handleAddNewAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setAttachments((prev) => [
        ...prev,
        { url: URL.createObjectURL(file), file },
      ]);
    }
  };

  const handleRemoveAttachment = (index: number) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const handleUpdate = (newValues: Partial<typeof values>) => {
    setValues((prev) => ({ ...prev, ...newValues }));
  };

  const handleSend = async () => {
    setWaiting(true);
    try {
      const response = await create(values);
      onCreated?.(response.data);
      toast.success("Outreach created successfully");
      onClose();
    } catch (e) {
      toast.error("Error creating outreach");
    } finally {
      setWaiting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Send Message"
      className="w-[800px]"
      hasForm
      titleRight={
        <FDropdown
          leftLabel="Template:"
          options={sampleTemplates.map((template) => ({
            label: template.name,
            value: template.id,
          }))}
          onChange={handleTemplateChange}
          selectedValue={selectedTemplateId}
        />
      }
    >
      <div className="flex flex-col gap-6">
        <FInput
          width="100%"
          label="Reply To Address"
          required
          value={values.reply_to}
          onChange={(value) => handleUpdate({ reply_to: value })}
        />
        <FInput
          label="Add CC Recipients"
          value={values.cc_emails}
          onChange={(value) => handleUpdate({ cc_emails: value })}
          width="100%"
        />
        <FInput
          label="Subject"
          width="100%"
          value={values.message.subject}
          onChange={(value) =>
            handleUpdate({
              message: { ...values.message, subject: value },
            })
          }
          required
        />
        <FormField label="Body" required width="100%">
          <div className="bg-white">
            <RichTextEditor
              id="outreach_message_body"
              value={values.message.body}
              onChange={(value) =>
                handleUpdate({ message: { ...values.message, body: value } })
              }
            />
          </div>
        </FormField>
      </div>
      <div className="flex justify-between items-center space-x-2 pb-3 z-50 pt-4 mt-10">
        <FormField width="auto">
          {attachments.map((attachment, index) => (
            <div
              className="flex w-fit flex-row items-center gap-2 px-4 py-2 justify-between relative bg-highlight_red rounded-lg"
              key={index}
            >
              <span>{attachment.file.name}</span>
              <div
                className="cursor-pointer"
                onClick={() => handleRemoveAttachment(index)}
              >
                <FIcon
                  icon="exit-2"
                  color="#000F45"
                  className="cursor-pointer"
                  size={15}
                />
              </div>
            </div>
          ))}
          <div className="flex h-[40px] border-[1px] border-black px-4 items-center gap-3 cursor-pointer relative">
            <FIcon
              icon="upload"
              color="#000F45"
              className="inline-block"
              size={15}
            />
            <span className="text-[15px] text-black font-medium">
              Add attachment
            </span>
            <input
              className="block h-full w-full absolute left-0 top-0 opacity-0 cursor-pointer"
              type="file"
              onChange={handleAddNewAttachment}
            />
          </div>
        </FormField>
        <FButton
          type="submit"
          loading={waiting}
          label="Send"
          onClick={handleSend}
          primary
        />
      </div>
    </Modal>
  );
};

export default SendMessageModal;
