import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { UserNotificationSetting } from "@types";
import { getQueryParamsForSearchRequest } from "@constants/api";

const createUserNotificationSetting = async (
  params: any
): Promise<ApiResponse<UserNotificationSetting>> => {
  return apiWrapper("/api/v1/user_notification_settings", "POST", params);
};

const bulkCreateUserNotificationSetting = async (
  params: any
): Promise<ApiResponse<UserNotificationSetting>> => {
  return apiWrapper("/user_notification_settings/bulk_create", "POST", params);
};

const getUserNotificationSettings = async (
  page: number,
  params?: {
    campaign_id_eq?: number;
    per_page: number;
    user_id_eq?: number;
    status_eq?: string;
  }
): Promise<
  ApiResponse<{
    user_notification_settings: UserNotificationSetting[];
    total_items: number;
  }>
> => {
  const queryParams = getQueryParamsForSearchRequest(page, null, params);

  return apiWrapper(
    `/user_notification_settings?${queryParams.toString()}`,
    "GET"
  );
};

const updateUserNotificationSettings = async (
  notification_setting_id: number,
  params: any
): Promise<ApiResponse<UserNotificationSetting>> => {
  return apiWrapper(
    `/user_notification_settings/${notification_setting_id}`,
    "PUT",
    params
  );
};

const deleteUserNotificationSettings = async (
  notification_setting_id: number
): Promise<ApiResponse<UserNotificationSetting>> => {
  return apiWrapper(
    `/user_notification_settings/${notification_setting_id}`,
    "DELETE"
  );
};

export {
  bulkCreateUserNotificationSetting,
  createUserNotificationSetting,
  getUserNotificationSettings,
  updateUserNotificationSettings,
  deleteUserNotificationSettings,
};
