import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { AudienceDemographics, Profile, ProfileStats, ViewsData } from "@types";
import { isArray } from "lodash";

const getOverviewData = async (slug: string): Promise<ApiResponse<any>> => {
  return apiWrapper(`/creator_profiles/${slug}/user_details`, "GET");
};

const getAudienceData = async (
  slug: string,
  platform: string
): Promise<ApiResponse<AudienceDemographics>> => {
  return apiWrapper(
    `/creator_profiles/${slug}/audience_demographics?platform=${platform}`,
    "GET"
  );
};

const getTopContent = async (slug: string): Promise<ApiResponse<any>> => {
  return apiWrapper(`/creator_profiles/${slug}/top_content`, "GET");
};

const getAllContent = async (slug: string): Promise<ApiResponse<any>> => {
  return apiWrapper(`/creator_profiles/${slug}/all_content`, "GET");
};

const getRecentSponsoredContent = async (
  slug: string
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/creator_profiles/${slug}/recent_sponsored_content`,
    "GET"
  );
};

export const getViewsData = async (
  slug: string,
  platform: string
): Promise<ApiResponse<ViewsData>> => {
  return apiWrapper(
    `/creator_profiles/${slug}/views_data${platform ? `?platform=${platform}` : ""}`,
    "GET"
  );
};

const getStats = async (
  slug: string,
  platform: string,
  timePeriod: string
): Promise<ApiResponse<ProfileStats>> => {
  return apiWrapper(
    `/creator_profiles/${slug}/top_stats?platform=${platform}&time_period=${timePeriod}`,
    "GET"
  );
};

export const updateProfile = async (
  params: Partial<Profile>
): Promise<ApiResponse<Profile>> => {
  const formData = new FormData();

  Object.keys(params).forEach((key) => {
    const value = params[key];
    console.log("Key", key, value, isArray(value));

    if (isArray(value)) {
      value.forEach((member) => {
        formData.append(`profile[${key}][]`, member);
      });
    } else {
      formData.append(`profile[${key}]`, value);
    }
  });

  return apiWrapper(`/profiles`, "POST", formData);
};

export {
  getOverviewData,
  getAudienceData,
  getAllContent,
  getTopContent,
  getRecentSponsoredContent,
  getStats,
};
