import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { FInput } from "@components/FInputs";

import FDropdown from "@components/FDropdown";
import { listBrands } from "@api/brands";

import { Bulletin } from "@types";
import { createBulletin, updateBulletin } from "@api/Bulletins/Bulletins";
import useAuth from "@hooks/useAuth";
import Modal from "@components/Modals/Modal";
import { capitalize } from "lodash";
import RichTextEditor from "@components/RichTextEditor";
import FormField from "@components/Form/FormField";
import moment from "moment";
import FRangePicker from "@components/FDatePicker/FRangePicker";

const CATEGORIES = ["survey", "opportunity", "gifting", "event"];

interface Props {
  entity?: Bulletin;
  onClose: () => void;
  onUpdated?: (campaign: Bulletin) => void;
  onCreated?: (campaign: Bulletin) => void;
}

export default function CreateOrUpdateBulletinModal({
  onClose,
  onUpdated,
  onCreated,
  entity,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);

  const [isSubmitted, setSubmitted] = useState(false);

  const [values, setValues] = useState<Partial<Bulletin>>({
    name: "",
    description: "",
    category: CATEGORIES[0],
    start_date: null,
    end_date: null,
    brand: {
      id: null,
    },
  });

  const { user } = useAuth();

  useEffect(() => {
    const loadBrands = async () => {
      const brands = await listBrands();

      setBrands(brands.brands);
    };

    loadBrands();

    if (entity) {
      setValues((values) => ({ ...values, ...entity }));
    }
  }, [entity]);

  const handleUpdate = (updates: Partial<Bulletin>) =>
    setValues({ ...values, ...updates });

  const handleNext = async () => {
    setSubmitted(true);

    const {
      name,
      brand: { id: brand_id },
    } = values;

    if (!name || !brand_id) {
      return;
    }

    setLoading(true);

    try {
      if (entity?.id) {
        const response = await updateBulletin(entity.id, values);

        onUpdated(response.data);

        toast.success("Bulletin Updated");
      } else {
        const params = { ...values, brand_id, user_id: user.id };

        delete params.brand;

        const response = await createBulletin(params);

        onCreated(response.data);
      }
    } catch (e) {
      toast.error(`Error ${entity ? "updating" : "creating"} bulletin`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      actionLabel="Save"
      className="w-[800px] h-[660px] overflow-auto"
      isOpen
      onClose={onClose}
      onAction={handleNext}
      title={`${entity?.id ? "Edit" : "Create"} Bulletin`}
      waiting={loading}
    >
      <div className="flex flex-col gap-6 m-auto w-full">
        <FInput
          name="title"
          onChange={(value) => handleUpdate({ name: value })}
          label="Bulletin Name"
          value={values.name}
          required
          validate={isSubmitted}
          width="100%"
        />
        <FormField label="Body" required width="100%">
          <RichTextEditor
            value={values.description}
            id="notes"
            onChange={(value) => handleUpdate({ description: value })}
          />
        </FormField>
        <div className="flex gap-4">
          <FDropdown
            hasNoDefault
            label="Brand"
            options={brands.map((brand) => ({
              label: brand.name,
              value: brand.id,
            }))}
            required
            selectedValue={values.brand.id}
            onChange={(value) =>
              handleUpdate({ brand: { ...values.brand, id: value as number } })
            }
            height="40"
            validate={isSubmitted}
            width="100%"
          />
          <FDropdown
            label="Category"
            options={CATEGORIES.map((category) => ({
              label: capitalize(category),
              value: category,
            }))}
            required
            selectedValue={values.category}
            onChange={(value) => handleUpdate({ category: value as string })}
            height="40"
            validate={isSubmitted}
            width="100%"
          />
        </div>

        <FRangePicker
          minDate={moment().format()}
          value={{
            from: values.start_date,
            to: values.end_date,
          }}
          onChange={({ from, to }) =>
            handleUpdate({
              start_date: from,
              end_date: to,
            })
          }
        />
      </div>
    </Modal>
  );
}
