import React, { useState, useRef } from "react";
import FIcon from "../FIcon";
import {
  InstagramEmbed,
  TikTokEmbed,
  YouTubeEmbed,
  FacebookEmbed,
} from "react-social-media-embed";

function VideoPlayer({
  className = "",
  post,
  hidePlatformIcon = false,
  showPostType = true,
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [isValidVideo, setIsValidVideo] = useState(
    post.video_url ? true : false
  );

  const handlePlay = () => {
    setIsPlaying(true);
    videoRef.current.play();
  };

  const handlePause = () => {
    setIsPlaying(false);
    videoRef.current.pause();
  };

  const handleVideoError = (e) => {
    console.log("video error", e);
    setIsValidVideo(false);
  };

  return (
    <div className={`h-full w-full flex items-center ${className}`}>
      {/* {showPostType && (
        <div className="post-type-tag">
          <span className="text-on-overlay">{post.media_type}</span>
        </div>
      )} */}
      {!hidePlatformIcon && (
        <a
          href={post.permalink}
          target="_blank"
          rel="noreferrer"
          id={`content-link-${post.id}`}
        >
          <span className="post__icon-container">
            <FIcon
              icon={(post.platform || "").toLowerCase()}
              size={15}
              color="#000F45"
              className="inline-block vertical-align-middle"
            />
          </span>
        </a>
      )}

      {/* use internal video OR embed OR photo OR backup icon */}

      {post.platform == "youtube" && post.permalink && (
        <YouTubeEmbed url={post.permalink} width={500} />
      )}

      {isValidVideo && (
        <video
          ref={videoRef}
          src={post.video_url}
          className={"post__image h-full w-full"}
          onError={handleVideoError}
          autoPlay
          muted
        />
      )}

      {!isValidVideo && post.platform == "instagram" && post.permalink && (
        <InstagramEmbed url={post.permalink} />
      )}

      {!isValidVideo && post.platform == "tiktok" && post.handle && (
        <TikTokEmbed
          url={`https://www.tiktok.com/${post.handle}/video/${post.source_id}`}
        />
      )}

      {!isValidVideo && post.platform == "facebook" && post.permalink && (
        <FacebookEmbed url={post.permalink} />
      )}

      {/* {!isPlaying && isValidVideo && (
        <button onClick={handlePlay}>
          <div className="play-button">
            <FIcon
              icon="play"
              size={30}
              color="#FFFFFF"
              className="inline-block vertical-align-middle"
            />
          </div>
        </button>
      )} */}

      {isPlaying && (
        <button className="play-button-overlay" onClick={handlePause} />
      )}
    </div>
  );
}

export default VideoPlayer;
