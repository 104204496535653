import { ApiResponse, GroupCreator } from "@apiTypes";
import apiWrapper from "@apiWrapper";

const getGroupCreatorList = async (params?: {
  q?: {
    brand_list_id_eq?: number;
    card_id_eq?: number;
  };
}): Promise<ApiResponse<any>> => {
  const queryParams = new URLSearchParams();

  if (params?.q?.brand_list_id_eq) {
    queryParams.append(
      "q[brand_list_id_eq]",
      params.q.brand_list_id_eq.toString()
    );
  }
  if (params?.q?.card_id_eq) {
    queryParams.append("q[card_id_eq]", params.q.card_id_eq.toString());
  }

  return apiWrapper(`/api/v1/group_creators?${queryParams.toString()}`, "GET");
};

const getGroupCreator = async (
  group_creator_id: string | number
): Promise<ApiResponse<GroupCreator>> => {
  return apiWrapper(`/api/v1/group_creators/${group_creator_id}`, "GET");
};

const createGroupCreator = async (
  params: Partial<GroupCreator>
): Promise<ApiResponse<GroupCreator>> => {
  return apiWrapper("/api/v1/group_creators", "POST", params);
};

const updateGroupCreator = async (
  group_creator_id: string | number,
  params: Partial<GroupCreator>
): Promise<ApiResponse<GroupCreator>> => {
  return apiWrapper(
    `/api/v1/group_creators/${group_creator_id}`,
    "PUT",
    params
  );
};

const deleteGroupCreator = async (group_creator_id: string | number) => {
  return apiWrapper(`/api/v1/group_creators/${group_creator_id}`, "DELETE");
};

export {
  getGroupCreatorList,
  getGroupCreator,
  createGroupCreator,
  updateGroupCreator,
  deleteGroupCreator,
};
