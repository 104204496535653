import React, { useEffect, useState } from "react";
import StatsBar from "@components/Dashboard/StatsBar";
import { useParams } from "react-router-dom";
import {
  getReport,
  getReportSummary,
  getReportPerformanceGraphs,
  getPostsEngagementGraph,
  getReportsAudienceData,
  downloadReportCSV,
} from "../../api/Reports";
import CampaignProgressCard from "@components/Campaign/CampaignProgressCard";
import PerformanceTiles from "@components/PerformanceTiles";
import { capitalize } from "@utils/string_utilities";
import LoadingSpinner from "@components/LoadingSpinner";
import PostsEngagementsGraph from "@pages/campaigns/components/PostsEngagementGraph";
import BlankState from "@components/BlankState";
import moment from "moment";
import ReportContentTable from "./ReportContentOverview";
import ReportCreatorsTable from "./ReportCreatorsTable";
import ReportContentSummary from "./ReportContentSummary";
import ReportInfluencerBreakdown from "./ReportInfluencerBreakdown";
import GeneralPostModal from "@components/Modals/GeneralPostModal";

import FDropdown from "@components/FDropdown";

import EthnicityBreakdown from "../../components/charts/demographics/EthnicityBreakdownChart";
import ReligionBreakdown from "../profile/audience/fragments/ReligionBreakdown";
import KidsBreakdown from "../profile/audience/fragments/KidsBreakdown";
import MaritalStatusBreakdown from "../profile/audience/fragments/MaritalStatusBreakdown";
import toast from "react-hot-toast";
import CountriesBreakdown from "../profile/audience/fragments/CountriesBreakdown";
import StatesBreakdown from "../profile/audience/fragments/StatesBreakdown";
import FButton from "@components/FButton";
import AgeBreakdownChart from "@components/charts/demographics/AgeBreakdownChart";
import GenderBreakdownChart from "@components/charts/demographics/GenderBreakdownChart";

const ReportProfile: React.FC<any> = () => {
  const { report_id } = useParams<{ report_id: string | any }>();
  const [loading, setLoading] = useState(true);
  const [engagementGraphData, setEngagementGraphData] = useState<any>({
    posts: null,
    reach: null,
    views: null,
  });
  const [overview, setOverview] = useState<any>({});

  const [engagementDataFetched, setEngagementDataFetched] = useState(false);

  const [activePost, setActivePost] = useState(null);
  const [contentModalOpen, setContentModalOpen] = useState(false);

  const handleContentModalClick = (content) => {
    setActivePost(content);
    setContentModalOpen(true);
  };

  const handleContentModalClose = () => {
    setContentModalOpen(false);
    setActivePost(null);
  };

  const [SummaryStats, setSummaryStats] = useState<
    {
      cellTitle: string;
      statNumber: number | string;
      statType: string;
      statInfo?: string;
    }[]
  >([]);
  const [performanceGraphs, setPerformanceGraphs] = useState<any>({});

  const fetchPostsEngagementsGraph = async () => {
    setLoading(true);
    setEngagementDataFetched(false);

    const response = await getPostsEngagementGraph(report_id);
    setEngagementGraphData(response.data);
    setLoading(false);
    setEngagementDataFetched(true);
  };

  const [audience, setAudience] = useState<any>({});
  const [selectedPlatform, setSelectedPlatform] = useState<string>("");
  const [audienceDataFetched, setAudienceDataFetched] = useState(false);
  const [availablePlatforms, setAvailablePlatforms] = useState<any>([]);

  const fetchReportAudienceDemographics = async () => {
    setLoading(true);
    setAudienceDataFetched(false);

    const response = await getReportsAudienceData(report_id, selectedPlatform);
    setAudience(response.data);
    setLoading(false);
    setAudienceDataFetched(true);
  };

  const determineStatType = (dataLabel: string) => {
    return dataLabel.includes("Rate") ? "percent" : "number";
  };

  useEffect(() => {
    const fetchSummary = async () => {
      setLoading(true);

      const response = await getReportSummary(report_id);

      if (response.success) {
        const formattedStats = Object.entries(response.data).reduce(
          (
            acc: {
              cellTitle: string;
              statNumber: number | string;
              statType: string;
            }[],
            [key, value]
          ) => {
            if (typeof value === "number" || typeof value === "string") {
              const title = key
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
              const statType =
                key === "emv"
                  ? "dollars"
                  : key === "engagement"
                    ? "percent"
                    : "number";
              acc.push({
                cellTitle: title,
                statNumber: value,
                statType: statType,
              });
            }
            return acc;
          },
          []
        );
        setSummaryStats(formattedStats);
      }

      setLoading(false);
    };

    const fetchPerformanceGraphs = async () => {
      setLoading(true);

      const response = await getReportPerformanceGraphs(report_id);
      setPerformanceGraphs(response.data);
      setLoading(false);
    };

    const fetchOverview = async () => {
      setLoading(true);

      try {
        const response = await getReport(report_id);
        if (response && response.data && response.data.platforms) {
          setOverview(response.data);

          const allPlatforms = response.data.platforms.map((platform) => ({
            label: platform,
            value: platform,
          }));

          setAvailablePlatforms(allPlatforms);
        } else {
          console.error("Failed to retrieve platforms data:", response);
          toast.error("Failed to load platforms data.");
        }
      } catch (error) {
        console.error("Error fetching report overview:", error);
        toast.error("Error loading report data.");
      }

      setLoading(false);
    };

    fetchOverview();
    fetchSummary();
    fetchPerformanceGraphs();
    fetchPostsEngagementsGraph();
    fetchReportAudienceDemographics();
  }, [report_id, selectedPlatform]);

  const hasPerformanceGraphs =
    performanceGraphs && Object.keys(performanceGraphs).length > 0;

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  const handleDownloadReportCSV = async (report_id) => {
    try {
      const response = await downloadReportCSV(report_id);
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      console.log(response);
      a.style.display = "none";
      a.href = url;
      a.download = `${overview.title}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      toast.success("Group exported to CSV");
    } catch (error) {
      console.error("Error downloading CSV:", error);
      toast.error("Failed to download CSV");
    }
  };

  return (
    <div className="p-4">
      <div>
        <div className="flex justify-between items-center">
          <div>
            <div className="mb-4">
              <h1 className="text-2xl font-bold">{overview.title}</h1>
            </div>
            <div className="mb-4">
              <p>
                Featuring content
                {overview.hashtags && overview.hashtags.length > 0 && (
                  <>
                    {" "}
                    with <strong>{overview.hashtags.join(", ")}</strong>
                  </>
                )}
                {overview.mentioned_users &&
                  overview.mentioned_users.length > 0 && (
                    <>
                      {" "}
                      and <strong>{overview.mentioned_users.join(", ")}</strong>
                    </>
                  )}
                {overview.platforms && overview.platforms.length > 0 && (
                  <>
                    {" "}
                    published to{" "}
                    <strong>
                      {overview.platforms.length > 1
                        ? `${overview.platforms.slice(0, -1).join(", ")} and ${overview.platforms.slice(-1)}`
                        : overview.platforms[0]}
                    </strong>
                  </>
                )}
                {overview.start_date && overview.end_date && (
                  <>
                    {" "}
                    between{" "}
                    <strong>
                      {moment(overview.start_date).format("MMM DD YYYY")}
                    </strong>{" "}
                    and{" "}
                    <strong>
                      {moment(overview.end_date).format("MMM DD YYYY")}
                    </strong>
                  </>
                )}
                .
              </p>
            </div>
          </div>
          <div className="min-w-[130px]">
            <FButton
              label="Download CSV"
              onClick={() => handleDownloadReportCSV(report_id)}
            />
          </div>
        </div>
        <StatsBar stats={SummaryStats} />
      </div>
      <div className="mt-[48px] pb-[48px]">
        <ReportContentSummary report_id={report_id} />
      </div>
      <div className="my-[48px]">
        {loading || !engagementDataFetched ? (
          <LoadingSpinner />
        ) : Object.values(engagementGraphData).every(
            (value) => value === null
          ) ? (
          <BlankState
            title="No Data Available"
            subtitle="No engagement data is currently available to display. Please try again later."
            icon="info"
            actionLabel="Refresh"
            onActionClick={() => fetchPostsEngagementsGraph()}
          />
        ) : (
          <PostsEngagementsGraph postsEngagement={engagementGraphData} />
        )}
      </div>
      {hasPerformanceGraphs && (
        <div className="flex justify-between">
          <div className="flex flex-col justify-between w-full h-full">
            {/* Reach and Viewership Cards */}
            <div className="flex py-4 px-0flex-1 justify-between">
              {performanceGraphs.reach && (
                <div className="h-[100%] w-80">
                  <CampaignProgressCard
                    cardData={[
                      {
                        cardTitle: "How many users you're Reaching:",
                        cardStats: performanceGraphs.reach.stat_data.map(
                          (data) => ({
                            statNumber: data.value,
                            statName: data.label,
                            statType: determineStatType(data.label),
                            statInfo: data.tooltip,
                          })
                        ),
                      },
                    ]}
                    graphData={performanceGraphs.reach.chart_data || []}
                    graphedDataPoint="Reach"
                    chartType="line"
                    graphLegendLabel="Accounts Reached"
                  />
                </div>
              )}
              {performanceGraphs.viewership && (
                <div className="h-[100%] w-80">
                  <CampaignProgressCard
                    cardData={[
                      {
                        cardTitle: "How much your content is being viewed:",
                        cardStats: performanceGraphs.viewership.stat_data.map(
                          (data) => ({
                            statNumber: data.value,
                            statName: data.label,
                            statType: determineStatType(data.label),
                            statInfo: data.tooltip,
                          })
                        ),
                      },
                    ]}
                    graphData={performanceGraphs.viewership.chart_data || []}
                    graphedDataPoint="Views"
                    chartType="line"
                    graphLegendLabel="Views"
                  />
                </div>
              )}
              {/* Engagement and EMV Cards */}
              {performanceGraphs.engagement && (
                <div className="h-[100%] w-80">
                  <CampaignProgressCard
                    cardData={[
                      {
                        cardTitle: "How users are interacting with your brand:",
                        cardStats: performanceGraphs.engagement.stat_data
                          .slice(0, 3)
                          .map((data) => ({
                            statNumber: data.value,
                            statName: data.label,
                            statType: determineStatType(data.label),
                            statInfo: data.tooltip,
                          })),
                      },
                    ]}
                    graphData={performanceGraphs.engagement.chart_data || []}
                    graphedDataPoint="Engagement"
                    chartType="line"
                    graphLegendLabel="Engagement Rate"
                  />
                </div>
              )}
            </div>

            <div className="border-t border-[#9398AC] my-4"></div>

            <div className="flex py-4 flex-1 flex-row justify-between w-full h-full">
              {performanceGraphs.emv.stat_data !== undefined && (
                <div className="h-[100%] w-80">
                  <CampaignProgressCard
                    cardData={[
                      {
                        cardTitle: "What is your earned media value over time:",
                        cardStats: [
                          {
                            statNumber: performanceGraphs.emv.stat_data,
                            statName: "EMV over time",
                            statType: "dollars",
                          },
                        ],
                      },
                    ]}
                    graphData={performanceGraphs.emv.chart_data || []}
                    graphedDataPoint="Dollars"
                    chartType="line"
                    graphLegendLabel="Earned Media Value"
                  />
                </div>
              )}
              {/* Content and Viral Posts Cards */}
              {performanceGraphs.content_chart_data &&
                performanceGraphs.posts && (
                  <div className="h-[100%] w-80">
                    <CampaignProgressCard
                      cardData={[
                        {
                          cardTitle: "What Type of Content is Being Posted:",
                          cardStats: Object.entries(
                            performanceGraphs.posts
                          ).map(([key, value]) => ({
                            statNumber: value,
                            statName: capitalize(key),
                            statType: determineStatType(key),
                          })),
                        },
                      ]}
                      graphData={performanceGraphs.content_chart_data || []}
                      graphedDataPoint="Posts"
                      chartType="stackedBar"
                    />
                  </div>
                )}
              {performanceGraphs.viral_posts && (
                <div className="h-[100%] w-80">
                  <PerformanceTiles
                    viralPosts={performanceGraphs.viral_posts}
                  />
                </div>
              )}
            </div>
            <div className="mt-[48px]">
              <ReportCreatorsTable report_id={report_id} />
            </div>
            <div className="mt-[48px]">
              <ReportInfluencerBreakdown report_id={report_id} />
            </div>
            <div className="mt-[48px]">
              <ReportContentTable
                report_id={report_id}
                onCardClick={(content) => handleContentModalClick(content)}
              />
            </div>
            {audienceDataFetched ? (
              audience && (
                <div className="mt-[48px]">
                  <h1 className="my-6">Audience Demographics</h1>
                  {Object.values(audience).some((data) =>
                    Array.isArray(data) ? data.length > 0 : data
                  ) && (
                    <div className="flex justify-between w-full mb-5">
                      <FDropdown
                        options={availablePlatforms}
                        selectedValue={selectedPlatform}
                        onChange={(value) =>
                          setSelectedPlatform(value as string)
                        }
                      />
                    </div>
                  )}
                  {audience.age.length > 0 && audience.gender.length > 0 && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-[40px] mb-[32px]">
                      <AgeBreakdownChart
                        items={audience.age.map((item) => ({
                          ...item,
                          name: item.name.split("Age ")[1],
                        }))}
                      />
                      <GenderBreakdownChart items={audience.gender} />
                    </div>
                  )}
                  {audience.ethnicity.length > 0 &&
                    audience.religion.length > 0 && (
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-[40px] mb-[40px]">
                        <EthnicityBreakdown items={audience.ethnicity} />
                        <ReligionBreakdown items={audience.religion} />
                      </div>
                    )}
                  {audience.parental && audience.marital.length > 0 && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-[40px] mb-[40px]">
                      <KidsBreakdown items={audience.parental} />
                      <MaritalStatusBreakdown items={audience.marital} />
                    </div>
                  )}
                  {audience.countries.length > 0 &&
                    audience.states.length > 0 && (
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-[40px] mb-[40px]">
                        <CountriesBreakdown items={audience.countries} />
                        <StatesBreakdown items={audience.states} />
                      </div>
                    )}
                </div>
              )
            ) : (
              <div className="flex justify-center items-center h-screen">
                <LoadingSpinner />
              </div>
            )}
            {activePost && (
              <GeneralPostModal
                post={activePost}
                isOpen={contentModalOpen}
                onClose={handleContentModalClose}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportProfile;
