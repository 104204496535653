import React, {
  useEffect,
  useState,
  useContext,
  ReactNode,
  ComponentType,
} from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { toast } from "react-hot-toast";

import ProfileNav from "@components/Profile/ProfileNav";
import { ProfileContext } from "@contexts/index";

import { User } from "@types";

import { LazyRouteContent } from "@components/navigation/LazyRouteContent";
import ContentHighlight from "@components/media/content-highlight";

interface ProfileProps {
  user?: User; // Define a more specific type if possible
  isValidProfileHash?: boolean;
  subNavItems: {
    label: string;
    value: string;
    component: ComponentType;
  }[];
  children?: ReactNode;
  highlightRight?: boolean;
  navActions?: React.ReactNode;
}

export default function ProfileLayout({
  user,
  subNavItems,
  isValidProfileHash = false,
  highlightRight = true,
  navActions,
}: ProfileProps) {
  const { contentHighlight, collapsed, setCollapsed, profile } =
    useContext(ProfileContext);

  const { subpage } = useParams();

  const [isScrolled, setIsScrolled] = useState(false);

  const [activeContentIndex, setActiveContentIndex] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  const handleNavChange = (newValue: string) => {
    const item = subNavItems.find((item) => item.value === newValue);

    if (item && item.value !== location.pathname) {
      if (subpage) {
        navigate(`../${item.value}`, { relative: "path" });
      } else {
        navigate(item.value);
      }
    }
  };

  const dropdownOptions = [{ label: "Share", value: "share_profile" }];

  const handleDropdownOptionSelect = (value) => {
    if (value === "share_profile") {
      navigator.clipboard.writeText(window.location.href).then(
        () => {
          toast.success("URL copied to clipboard!");
        },
        (err) => {
          toast.error("Failed to copy URL: ", err);
        }
      );
    }

    if (value === "edit_profile") {
      console.log("edit profile hit");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY; // Updated to use scrollY
      setIsScrolled(position > 90);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const selectedNav =
    subNavItems.find(({ value }) => value === subpage)?.value ||
    subNavItems[0].value;

  return (
    <>
      <div
        className={`grid ${
          highlightRight
            ? collapsed
              ? "grid-cols-[1fr_40px]"
              : "grid-cols-[1fr_370px]"
            : collapsed
              ? "grid-cols-[40px_1fr]"
              : "grid-cols-[370px_1fr]"
        }`}
      >
        {!highlightRight && (
          <ContentHighlight
            data={contentHighlight}
            highlightRight={highlightRight}
            collapsed={collapsed}
            onChangeCollapsed={setCollapsed}
            currentSlide={activeContentIndex}
            onSlideChange={(index) => setActiveContentIndex(index)}
          />
        )}
        <div
          className={`w-full overflow-x-auto mb-[24px] ${user ? "" : "pl-[235px]"}`}
        >
          {/* shows the navigation options for participant review */}
          {navActions}

          <ProfileNav
            isScrolled={isScrolled}
            profile={profile}
            user={user}
            isValidProfileHash={isValidProfileHash}
            items={subNavItems}
            selectedNav={selectedNav}
            handleDropdownOptionSelect={handleDropdownOptionSelect}
            dropdownOptions={dropdownOptions}
            handleNavChange={handleNavChange}
          />
          <div
            // if you experience jank here because you edit these values...
            // it is because of the math on padding top
            // if you increase/decrease the default padding top,
            // you need to increase/decrease the isScrolled pt as well by the same amount
            // Sorry for messing up your life -- Matt W was here

            className={`${isScrolled ? "pt-[204px]" : ""} px-[40px]`}
          >
            {subNavItems.map(({ value, component: Component }, index) => (
              <LazyRouteContent isActive={value === selectedNav} key={index}>
                {Component && <Component />}
              </LazyRouteContent>
            ))}
          </div>
        </div>
        {highlightRight && (
          <ContentHighlight
            data={contentHighlight}
            highlightRight={highlightRight}
            currentSlide={activeContentIndex}
            onSlideChange={(index) => setActiveContentIndex(index)}
          />
        )}
      </div>

      <iframe
        id="presskit-iframe"
        style={{ position: "absolute", top: 1000, display: "none" }}
      ></iframe>
    </>
  );
}
