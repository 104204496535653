import React, { useContext, useState } from "react";
import { useParams, Link } from "react-router-dom";

import FButton from "@components/FButton";
import { CampaignContext } from "@contexts/index";

import { getCampaignBrief } from "@api/CampaignBriefs/CampaignBriefs";

import AddParticipantsModal from "@pages/campaigns/components/AddParticipantsModal";

import ParticipantsByGroup from "./components/ParticipantsByGroup";
import UpdateParticipantGroupModal from "./components/UpdateParticipantGroupModal";
import { Campaign, ParticipantGroup } from "@types";
import toast from "react-hot-toast";

const CampaignParticipants = () => {
  const { campaign, setBriefs, setCampaign } = useContext(CampaignContext);
  const { campaign_id } = useParams<{ campaign_id: string }>();

  const [selectedItems, setSelectedItems] = useState([]);

  const [isCreateGroupModalVisible, setIsCreateGroupModalVisible] =
    useState(false);

  const [showAddParticipantsModal, setShowAddParticipantsModal] =
    useState(false);

  const [addingParticipantsForGroup, setAddingParticipantsForGroup] =
    useState(null);

  const { participants = [] } = campaign || {};

  const handleCreateParticipantGroup = async (updates: Partial<Campaign>) => {
    const campaignBriefs = await getCampaignBrief(campaign_id);

    setCampaign({ ...campaign, ...updates });
    setBriefs(campaignBriefs);

    toast.success("Participant group created");

    setIsCreateGroupModalVisible(false);
  };

  const participantsWithoutGroups = participants.filter(
    ({ participant_group_id }) => !participant_group_id
  );

  return (
    <div className="pt-[40px]">
      <div className="flex justify-between items-center pb-10 border-b border-light_border">
        <h2 className="text-2xl">Participants ({participants.length})</h2>
        <div className="flex gap-4">
          <Link to={`/campaigns/${campaign_id}/participant-review`}>
            {participants.length > 0 ? (
              <FButton
                width="100%"
                label="Review"
                icon={{
                  name: "fancy-arrow-right",
                  size: 14,
                  color: "#000F45",
                  align: "left",
                  className: "ml-2",
                }}
              />
            ) : null}
          </Link>
          <FButton
            label="Add Participants"
            primary
            onClick={() =>
              setShowAddParticipantsModal(!showAddParticipantsModal)
            }
          />
          {participantsWithoutGroups.length ? (
            <FButton
              label="Add Group"
              primary
              onClick={() => setIsCreateGroupModalVisible(true)}
            />
          ) : null}
        </div>
      </div>
      <div className="flex flex-col w-full">
        <ParticipantsByGroup
          campaign={campaign}
          onUpdateCampaign={(updates) =>
            setCampaign({ ...campaign, ...updates })
          }
          selectedItems={selectedItems}
          onChangeSelectedItems={setSelectedItems}
          onAddParticipants={(groupId: number) => {
            setAddingParticipantsForGroup(groupId);

            setShowAddParticipantsModal(true);
          }}
        />
        <div className="flex justify-center">
          <button
            className="text-lg text-neutral_600 underline"
            onClick={() => setIsCreateGroupModalVisible(true)}
          >
            Create Participant Group
          </button>
        </div>
      </div>
      {showAddParticipantsModal ? (
        <AddParticipantsModal
          defaultParticipantGroupId={addingParticipantsForGroup}
          onClose={() => setShowAddParticipantsModal(false)}
        />
      ) : null}
      {isCreateGroupModalVisible ? (
        <UpdateParticipantGroupModal
          campaign={campaign}
          group={{} as ParticipantGroup}
          onClose={() => setIsCreateGroupModalVisible(false)}
          onUpdated={handleCreateParticipantGroup}
        />
      ) : null}
    </div>
  );
};

export default CampaignParticipants;
