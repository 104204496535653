import { FInput } from "@components/FInputs";

import FIcon from "@components/FIcon";

import FMultiSelect from "@components/FMultiSelect";

import { Profile, UploadedFile } from "@types";
import CountryRegionPicker from "@components/Form/CountryRegionPicker";
import { pick } from "lodash";
import BirthdayPicker from "@components/Form/BirthdayPicker";
import FormField from "@components/Form/FormField";
import { ETHNICITIES, GENDERS } from "@constants/demographics";

interface PersonalInfoFormProps {
  isSubmitted?: boolean;
  values: Partial<Profile>;
  image: UploadedFile;
  onUpdate: (updates: Partial<Profile>) => void;
  onUpdateImage: (image: UploadedFile) => void;
}

const PersonalInfoForm = ({
  values,
  onUpdate,
  image,
  onUpdateImage,
  isSubmitted,
}: PersonalInfoFormProps) => {
  const handleUpdate = (updates: Partial<Profile>) =>
    onUpdate({ ...values, ...updates });

  const handleImage = async (e) => {
    const file = e.target.files[0];

    if (file) {
      onUpdateImage({ file, url: URL.createObjectURL(file) });
    }
  };

  return (
    <div className="flex flex-col h-full w-full overflow-auto">
      <div className="flex gap-2 mb-4">
        <FInput
          value={values.first_name}
          onChange={(value) => handleUpdate({ first_name: value })}
          label="First Name"
          required
          validate={isSubmitted}
          width="100%"
        />
        <FInput
          value={values.last_name}
          onChange={(value) => handleUpdate({ last_name: value })}
          label="Last Name"
          required
          validate={isSubmitted}
          width="100%"
        />
      </div>
      <FInput
        className="mb-4"
        value={values.address}
        onChange={(value) => handleUpdate({ address: value })}
        label="Mailing Address"
        required
        validate={isSubmitted}
        width="100%"
      />
      <CountryRegionPicker
        value={pick(values, ["country", "state"])}
        onChange={handleUpdate}
      />
      <div className="flex gap-4 mb-4">
        <FInput
          value={values.city}
          onChange={(value) => handleUpdate({ city: value })}
          label="City"
          required
          validate={isSubmitted}
          width="100%"
        />
        <FInput
          value={values.zipcode}
          onChange={(value) => handleUpdate({ zipcode: value })}
          label="Zipcode"
          required
          validate={isSubmitted}
          width="100%"
        />
      </div>
      <div className="flex gap-4 mb-4">
        <FMultiSelect
          selected={values.gender}
          options={GENDERS}
          onChange={(value) => handleUpdate({ gender: value })}
          label="Gender"
          validate={isSubmitted}
          required
        />
        <FMultiSelect
          selected={values.ethnicity}
          options={ETHNICITIES}
          onChange={(value) => handleUpdate({ ethnicity: value })}
          label="Ethnicity"
          validate={isSubmitted}
          required
        />
      </div>
      <div className="w-full mb-4">
        <BirthdayPicker
          value={values.birthday || ""}
          onChange={(value) =>
            handleUpdate({
              birthday: value,
            })
          }
        />
      </div>
      <FormField
        className="mb-8"
        label="Profile image"
        error={isSubmitted && !image && "Profile image is required"}
        width="100%"
      >
        <div
          className="border border-default_weak border-dashed rounded-lg relative flex all-center h-[150px]
          "
          style={{
            backgroundImage: image ? `url(${image.url})` : "none",
            backgroundSize: "100% 150px",
          }}
        >
          <input
            id="file"
            name="file"
            className="brief-file-input w-full h-full absolute"
            type="file"
            accept="image/gif, image/jpeg, image/png"
            onChange={handleImage}
          />
          <div>
            {!image && (
              <>
                <FIcon
                  icon="upload"
                  size={24}
                  color="#000721"
                  className="mb-6"
                />
                <p className="text-sm w-[200px]">
                  Drag-and-drop file, or browse computer. Only .jpg .png or .gif
                </p>
              </>
            )}
          </div>
        </div>
      </FormField>
    </div>
  );
};

export default PersonalInfoForm;
