import React from "react";

export default function ListRow({ item, children }) {
  const { name, included, total } = item;

  const TableCell = ({ children, classes = "" }) => (
    <td className={`c-table__body__row__cell ${classes}`}>{children}</td>
  );

  const DropDown = ({ children, icon, size }) => {
    const dropDownClass = size
      ? `dropdown-menu c-dropdown-bootstrap__menu c-dropdown-bootstrap__menu--${size}`
      : "dropdown-menu c-dropdown-bootstrap__menu";
    return (
      <div className="dropdown c-dropdown-bootstrap">
        <button
          className="dropdown-toggle c-dropdown-bootstrap__button d-flex align-items-center"
          data-toggle="dropdown"
        >
          <i className={`c-icon--large c-icon-${icon} c-icon--red`} />
        </button>
        <div className={dropDownClass} aria-labelledby="dropdownMenuButton">
          {children}
        </div>
      </div>
    );
  };

  const renderChildren = () => {
    if (children.length > 1) {
      return (
        <DropDown icon="dots" size="">
          {children}
        </DropDown>
      );
    }
    return children;
  };

  return (
    <tr className="c-table__body__row">
      <TableCell>{name}</TableCell>
      <TableCell>
        {included}/{total}
      </TableCell>
      <td
        className="c-table__body__row__cell u-text-align-right"
        style={{ width: "20px" }}
      >
        {renderChildren()}
      </td>
    </tr>
  );
}
