import React, { useCallback } from "react";
import { Sort } from "@types";
import { getReportsList, deleteReport } from "@api/Reports";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";

const filterOptions = [
  { label: "All", value: null },
  { label: "Campaigns", value: "campaign" },
  { label: "Monthly", value: "monthly_default" },
  { label: "Custom", value: "null" },
];

const tableColumns = [
  {
    key: "title",
    label: "Title",
    isLink: true,
    linkURL: "/reports/{id}",
  },
  { key: "brand_name", label: "Company" },
  { key: "source", label: "Source" },
  { key: "content_count", label: "Content Count" },
  { key: "created_at", label: "Created", isDate: true },
];

const tableRowDropDownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Delete", value: "delete" },
];

const actions = {
  delete: async (id: number) => deleteReport(id),
};

export default function ReportsAdmin() {
  const getReports = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;
      const response = await getReportsList(true, page, sort, {
        source_type_eq: filter,
        title_cont: search,
      });

      const { reports: items } = response.data;
      return {
        ...response.data,
        items,
      };
    },
    []
  );

  return (
    <SinglePageLayoutWithData
      entityName="reports"
      pageTitle="Reports"
      pageName="Reports"
      sortPathName="title"
      sortPathDate="date"
      filterOptions={filterOptions}
      filterPath="state"
      tableColumns={tableColumns}
      rowActions={tableRowDropDownOptions}
      tableRowDropDownOptions={tableRowDropDownOptions}
      asyncActions={actions}
      tableOnly
      getItems={getReports}
    />
  );
}
