import FSubNav from "@components/FSubNav";
import Heading from "@components/Heading";
import AmbassadorQuestions from "@pages/ambassadors/ambassador-questions";
import { useState } from "react";
import AmbassadorsOnboardingBrandSettings from "./Branding";

const NAV_ITEMS = [
  {
    name: "Branding",
  },
  {
    name: "Questions",
  },
];

const AmbassadorsOnboardingSettings = () => {
  const [selectedNav, setSelectedNav] = useState(NAV_ITEMS[0].name);

  return (
    <div>
      <div className="flex items-center justify-between mb-8">
        <Heading title="Ambassador onboarding settings" />
      </div>
      <FSubNav
        items={NAV_ITEMS.map(({ name }) => ({
          label: name,
          value: name,
          active: name === selectedNav,
        }))}
        selectedValue={selectedNav}
        onChange={(value) => setSelectedNav(value as string)}
      />
      <div className="pt-8">
        {selectedNav === "Branding" ? (
          <AmbassadorsOnboardingBrandSettings />
        ) : (
          <AmbassadorQuestions />
        )}
      </div>
    </div>
  );
};

export default AmbassadorsOnboardingSettings;
