import React, { useState } from "react";
import FButton from "@components/FButton";
import { FInput } from "@components/FInputs";
import Modal from "@components/Modals/Modal";
import { createReport } from "@api/Reports";
import { toast } from "react-hot-toast";

interface Props {
  filters: any;
  isOpen: boolean;
  onClose: () => void;
}

const CreateReportModal = ({ filters, isOpen, onClose }: Props) => {
  const [title, setTitle] = useState("");

  const handleSubmit = async () => {
    try {
      const response = await toast.promise(createReport(title, filters), {
        loading: "Creating report...",
        success: "Report created successfully!",
        error: "Failed to create report!",
      });

      if (response?.success) {
        window.location.href = "/reports";
      }
    } catch (error) {
      console.error("Failed to create report:", error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Create Report"
      className="w-[800px]"
      hasForm
    >
      <div className="flex flex-col gap-6">
        <FInput
          label="Title"
          width="100%"
          required
          value={title}
          onChange={(value) => setTitle(value)}
        />
      </div>
      <div className="flex justify-end space-x-2 pt-4 mt-10">
        <FButton type="submit" label="Send" onClick={handleSubmit} primary />
      </div>
    </Modal>
  );
};

export default CreateReportModal;
