import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";

const getContractTemplateSection = async (
  contract_template_section_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/contract_template_sections/${contract_template_section_id}`,
    "GET"
  );
};

const createContractTemplateSection = async (
  contract_template_id: number | string,
  params: any
): Promise<ApiResponse<any>> => {
  const requestBody = {
    contract_template_section: {
      contract_template_id,
      ...params,
    },
  };
  return apiWrapper("/contract_template_sections", "POST", requestBody);
};

const updateContractTemplateSection = async (
  contract_template_id: number | string,
  contract_template_section_id: number | string,
  params: any
): Promise<ApiResponse<any>> => {
  const requestBody = {
    contract_template_section: {
      contract_template_id,
      ...params,
    },
  };
  return apiWrapper(
    `/contract_template_sections/${contract_template_section_id}`,
    "PUT",
    requestBody
  );
};

const deleteContractTemplateSection = async (
  contract_template_section_id: number | string
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/contract_template_sections/${contract_template_section_id}`,
    "DELETE"
  );
};

export {
  getContractTemplateSection,
  createContractTemplateSection,
  updateContractTemplateSection,
  deleteContractTemplateSection,
};
