import React, { useState } from "react";
import { CampaignContext } from "@contexts/index";
import { useContext } from "react";
import FButton from "@components/FButton";
import ProductPost from "@components/Campaign/Brief/ProductPost";
import Modal from "@components/Modals/Modal";
import AddProduct from "@components/Campaign/Brief/BriefForms/AddProduct";
import ConfirmModal from "@components/Modals/ConfirmModal";
import { toast } from "react-hot-toast";

import apiClient from "@apiClient";

import BriefPageTemplate, {
  BriefPageTemplateProps,
} from "@components/Campaign/Brief/BriefPageTemplate";

const { delete: deleteProduct } = apiClient.campaign_products;

interface Props extends BriefPageTemplateProps {}

export default function BriefProducts({
  brief,
  onUpdate,
  pageTitle,
  pageDescription,
  pageTitleCMSName,
  customSectionName,
  containerRef,
}: Props) {
  const { campaign, setCampaign } = useContext(CampaignContext);

  const { products = [] } = campaign;

  const [isLocalModalOpen, setIsLocalModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);

  const removeProduct = async (productId) => {
    await deleteProduct(productId);

    setCampaign({
      ...campaign,
      products: products.filter((p) => p.id !== productId),
    });
    setIsDeleteModalOpen(false);

    setProductToEdit(null);

    toast.success("Deleted");
  };

  return (
    <BriefPageTemplate
      brief={brief}
      onUpdate={onUpdate}
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      customSectionName={customSectionName}
      pageTitleCMSName={pageTitleCMSName}
      containerRef={containerRef}
      actionRight={
        <FButton
          label="Add Product"
          className="w-fit whitespace-nowrap"
          onClick={() => setIsLocalModalOpen(true)}
        />
      }
    >
      {products.length > 0 && (
        <div
          className={`flex flex-col gap-4 ${
            products.length > 0 ? "border-t border-light_border" : ""
          }`}
        >
          <div className="flex flex-col mb-8">
            {products
              ?.sort(
                (a, b) =>
                  new Date(b.updated_at).getTime() -
                  new Date(a.updated_at).getTime()
              )
              .map((product) => (
                <ProductPost
                  key={product.id}
                  product={product}
                  deleteProduct={(product) => {
                    setIsDeleteModalOpen(true);
                    setProductToEdit(product);
                  }}
                  editProduct={(product) => {
                    console.log("Render edit", product);

                    setIsLocalModalOpen(true);
                    setProductToEdit(product);
                  }}
                />
              ))}
          </div>
        </div>
      )}
      <ConfirmModal
        title="Delete Product"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onAction={() => removeProduct(productToEdit.id)}
        actionLabel="Delete"
        subtitle="Are you sure you want to delete this product?"
      />
      <Modal
        title="Add Product"
        isOpen={isLocalModalOpen}
        onClose={() => {
          setIsLocalModalOpen(false);
          setProductToEdit(null);
        }}
        actionLabel="Save"
        hasForm
        className="w-[650px]"
        zIndex={9999}
        children={
          <AddProduct
            onClose={() => {
              setProductToEdit(null);
              setIsLocalModalOpen(false);
            }}
            campaign={campaign}
            product={productToEdit}
            onUpdateCampaign={setCampaign}
          />
        }
      />
    </BriefPageTemplate>
  );
}
