import * as React from "react";
import NavyLogo from "../../public/images/fohr-logo-navy.png";

export default function AuthLayout({ children }) {
  // TODO: Revisit this
  const isFullScreen = window.location.href.includes("add-personal-info");

  return (
    <div className="flex h-screen w-full text-dark_night_sky font-sofia_pro">
      <div className="flex flex-col flex-1 items-center h-full overflow-y-auto">
        <div
          className={
            isFullScreen
              ? "py-[100px]"
              : "flex flex-col justify-center items-center h-full"
          }
        >
          <div className="flex h-[100px] mb-[70px] -mt-[70px] w-full left-0 justify-center items-center">
            <a href="https://www.fohr.co/">
              <img src={NavyLogo} className="w-[80px] mx-auto" />
            </a>
          </div>
          <div className="w-[440px]">{children}</div>
          <div className="text-center mt-8">
            <div className="mb-2">
              <p className="text-xs opacity-50">
                Need additional help? Please reach out to{" "}
                <a className="underline" href="mailto:support@fohr.co">
                  support@fohr.co
                </a>
                .
              </p>
            </div>
            <div className="text-xs mb-2 opacity-50 max-w-[400px] mx-auto">
              By continuing, you acknowledge that you understand and agree to
              the{" "}
              <a
                href="https://www.fohr.co/terms"
                target="_blank"
                className="underline"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://www.fohr.co/privacy"
                target="_blank"
                className=" underline"
              >
                Privacy Policy
              </a>
              .
            </div>
          </div>
        </div>
      </div>
      <div className="h-full">
        <video autoPlay muted className="h-full w-full object-cover">
          <source src="/videos/cropped.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  );
}
