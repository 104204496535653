import { updateBrand } from "@api/brands";
import FButton from "@components/FButton";
import FFileUpload from "@components/FFileUpload/FFileUpload";
import { FInput, FTextarea } from "@components/FInputs";
import FormField from "@components/Form/FormField";
import { AmbassadorsContext } from "@contexts/index";
import { Photo, UploadedFile } from "@types";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

const getUploadedPhoto = (photo: Photo) => {
  if (!photo) {
    return null;
  }

  const { original } = photo;

  if (!original || original.endsWith("missing_default.png")) {
    return null;
  }

  return {
    url: original,
    file: null,
  };
};

const AmbassadorsOnboardingBrandSettings = () => {
  const { brand, setBrand } = useContext(AmbassadorsContext);

  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [uploadedBackgroundPhoto, setUploadedBackgroundPhoto] =
    useState<UploadedFile>(null);

  const [uploadedLogoImage, setUploadedLogoImage] =
    useState<UploadedFile>(null);

  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [privacyUrl, setPrivacyUrl] = useState("");

  useEffect(() => {
    const { amp_description, background_photo, logo_photo, url, privacy_url } =
      brand;

    setUploadedBackgroundPhoto(getUploadedPhoto(background_photo));

    setUploadedLogoImage(getUploadedPhoto(logo_photo));

    setDescription(amp_description);
    setUrl(url || "");
    setPrivacyUrl(privacy_url || "");
  }, []);

  const handleSave = async () => {
    setIsSubmitted(true);

    if (!description) {
      return;
    }

    setIsSaving(true);

    const params = {
      amp_description: description,
      background_photo: uploadedBackgroundPhoto?.file || null,
      logo_photo: uploadedLogoImage?.file || null,
      url,
      privacy_url: privacyUrl,
    };

    try {
      await updateBrand(brand.id, params);

      setBrand({ ...brand });

      toast.success("Updated brand onboarding settings");
    } catch (e) {
      console.log("E", e);
      toast.error("Error updating brand");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="flex flex-col gap-8 mb-8 pb-8 w-[400px]">
      <FormField label="Cover image" width="100%">
        <FFileUpload
          onUpload={(files) => setUploadedBackgroundPhoto(files[0])}
          uploadedFiles={[uploadedBackgroundPhoto]}
        />
      </FormField>
      <FormField label="Logo image" width="100%">
        <FFileUpload
          onUpload={(files) => setUploadedLogoImage(files[0])}
          uploadedFiles={[uploadedLogoImage]}
        />
      </FormField>
      <FTextarea
        label="Description"
        value={description}
        onChange={setDescription}
        rows={3}
        required
        validate={isSubmitted}
        width="100%"
      />
      <FInput label="URL" value={url} onChange={setUrl} width="100%" />
      <FInput
        label="Privacy URL"
        value={privacyUrl}
        onChange={setPrivacyUrl}
        width="100%"
      />
      <div className="flex justify-end w-full">
        <FButton label="Save" loading={isSaving} onClick={handleSave} primary />
      </div>
    </div>
  );
};

export default AmbassadorsOnboardingBrandSettings;
