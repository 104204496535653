import FButton from "@components/FButton";
import { capitalize, uniqBy } from "lodash";
import { useEffect, useState } from "react";

import { getCountries, getRegions } from "@api/Geo";
import FMultiSelect from "@components/FMultiSelect";

import { FDropdownOption } from "@components/FDropdown";
import { AdvancedSearchParams } from "@pages/search/types";
import GooglePlacesAutocomplete from "@components/google-places-autocomplete";

const FILTERS = [
  {
    value: "city",
  },
  {
    value: "state",
    label: "US State",
  },
  {
    value: "country",
  },
];

interface LocationFilterProps {
  filters: Partial<AdvancedSearchParams>;
  onFiltersUpdate: (filters: Partial<AdvancedSearchParams>) => void;
}

const LocationFilter = ({ filters, onFiltersUpdate }: LocationFilterProps) => {
  const [activeFilter, setActiveFilter] = useState(FILTERS[0].value);

  const [countries, setCountries] = useState<FDropdownOption[]>([]);

  const [states, setStates] = useState<FDropdownOption[]>([]);

  useEffect(() => {
    const loadCountriesAndStates = async () => {
      const response = await getCountries();

      const { countries } = response;

      const regionsResponse = await getRegions("US");

      setCountries(
        countries.map(([, label]) => ({
          value: label,
          label,
        }))
      );

      setStates(
        uniqBy(
          regionsResponse.regions.map(([, label]) => ({
            value: label,
            label,
          })),
          "value"
        )
      );
    };

    loadCountriesAndStates();
  }, []);

  return (
    <div>
      <h3 className="mb-2">Search by location</h3>
      <div className="flex items-center mb-4 w-full">
        {FILTERS.map(({ value, label }) => (
          <FButton
            key={value}
            primary={value === activeFilter}
            onClick={() => {
              onFiltersUpdate({
                address_country: [],
                address_state: [],
                custom_locations: {},
              });

              setActiveFilter(value);
            }}
            label={label || capitalize(value)}
            className="flex-1"
            height="40px"
          />
        ))}
      </div>
      {activeFilter === "city" ? (
        <GooglePlacesAutocomplete
          location={filters.custom_locations}
          onLocationChange={(value) =>
            onFiltersUpdate({ custom_locations: value })
          }
        />
      ) : null}
      {activeFilter === "country" ? (
        <FMultiSelect
          selected={filters.address_country}
          options={countries}
          onChange={(value) => onFiltersUpdate({ address_country: value })}
          label="Countries"
        />
      ) : null}
      {activeFilter === "state" ? (
        <FMultiSelect
          selected={filters.address_state}
          options={states}
          onChange={(value) => onFiltersUpdate({ address_state: value })}
          label="US States"
        />
      ) : null}
    </div>
  );
};

export default LocationFilter;
