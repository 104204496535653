const getFillColor = (value) => {
  switch (true) {
    case value > 90:
      return "#000F45";
    case value > 75:
      return "#282F45";
    case value > 60:
      return "#40465B";
    case value > 45:
      return "#5C6276";
    case value > 30:
      return "#797E92";
    case value > 15:
      return "#B0B5C4";
    case value > 0:
      return "#C9CDD9";
    default:
      return "#E3E6ED";
  }
};

export default getFillColor;
