import React from "react";
import "./App.css";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import {
  Admin,
  Ambassadors,
  AmbassadorSearch,
  BriefPreview,
  Bulletins,
  BulletinsProfile,
  CampaignsDashboard,
  DirectoryDashboard,
  ForgotPassword,
  Groups,
  Login,
  OutreachList,
  OutreachProfile,
  OutreachMessage,
  OutreachRecipients,
  OutreachTemplateList,
  Stats,
  CampaignDeliverables,
  CampaignProducts,
  CampaignProfile,
  CampaignList,
  CampaignParticipants,
  CampaignReports,
  GroupsProfile,
  GiftingTableList,
  GiftingTable,
  Notifications,
  ParticipantProfile,
  CampaignBrief,
  SignUp,
  ContractTemplates,
  ReportsList,
  ReportProfile,
  ReportInformation,
  ReportContent,
  ContractTemplate,
  BrandsAdmin,
  BrandAdmin,
} from "./pages";
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
import SignedIn from "@components/SignedIn";
import {
  CollaboratorsWrapper,
  CampaignCollaboratorsList,
  OutreachCollaboratorsList,
  BulletinCollaboratorsList,
  OffPlatformCollaboratorsList,
} from "@pages/collaborators/index";

import { UserProvider } from "./hooks/UserProvider";
import NotificationSettings from "./pages/notifications/NotificationSettings";
import {
  ProfileSettings,
  BrandSettings,
  PrivacySettings,
  CreatorEdit,
  CreatorSettings,
  CreatorPrivacySettings,
  SettingsWrapper,
} from "@pages/settings/index";
import EntityAdminLayout from "@layouts/EntityAdminLayout";
import ResetPassword from "@pages/auth/ResetPassword";
import AmbassadorsList from "@pages/ambassadors/AmbassadorsList";
import CampaignContentReview from "@pages/campaigns/content-review";
import AmbassadorQuestions from "@pages/ambassadors/ambassador-questions";

import "./components/highcharts";

import SignUpInfluencers from "@pages/auth/influencer-onboarding/SignUpInfluencers";
import ConnectSocials from "@pages/auth/influencer-onboarding/ConnectSocials";
import AddPersonalInfo from "@pages/auth/influencer-onboarding/AddPersonalInfo";
import ConfirmDetails from "@pages/auth/influencer-onboarding/ConfirmDetails";
import SignUpBrands from "@pages/auth/brand-onboarding/SignUpBrands";
import OutreachTemplate from "@pages/outreach/OutreachTemplate";
import CampaignsAdmin from "@pages/admin/pages/CampaignsAdmin";
import BulletinsAdmin from "@pages/admin/pages/BulletinsAdmin";
import CreatorsAdmin from "@pages/admin/pages/CreatorsAdmin";
import GroupsAdmin from "@pages/admin/pages/GroupsAdmin";
import OutreachAdmin from "@pages/admin/pages/OutreachAdmin";
import ReportsAdmin from "@pages/admin/pages/ReportsAdmin";

import AnswerQuestions from "@pages/ambassadors/onboarding/AnswerQuestions";
import AmbassadorOnboardingLayout from "@layouts/AmbassadorOnboardingLayout";
import CreateOffPlatformCreator from "@pages/off_platform/CreateOffPlatformCreator";
import Profile from "@pages/profile";
import ParticipantReview from "@pages/campaigns/participant-review";
import Search from "@pages/search/searchPage";
import { DashboardProvider } from "@contexts/DashboardProvider";
import Home from "@pages/dashboards/Home";
import DashboardWrap from "@pages/dashboards/DashboardWrap";
import Content from "@pages/dashboards/Content";

export const App = () => {
  return (
    <UserProvider>
      <BrowserRouter>
        <Routes>
          {/* signed out users */}
          <Route
            element={
              <AuthLayout>
                <Outlet />
              </AuthLayout>
            }
          >
            <Route path="login" element={<Login />} />
            <Route path="/signup/brand" element={<SignUpBrands />} />
            <Route path="/signup/influencer" element="">
              <Route index element={<SignUpInfluencers />} />
              <Route path="connect-socials" element={<ConnectSocials />} />
              <Route path="add-personal-info" element={<AddPersonalInfo />} />
              <Route path="confirm-details" element={<ConfirmDetails />} />
            </Route>
            <Route path="signup" element={<SignUp />} />
            <Route path="sign_up" element={<SignUpInfluencers />} />
            <Route path="sign_up_brand" element={<SignUpBrands />} />
            <Route path="forgot_password" element={<ForgotPassword />} />
            <Route path="reset_password" element={<ResetPassword />} />
          </Route>
          <Route
            element={
              <AmbassadorOnboardingLayout>
                <Outlet />
              </AmbassadorOnboardingLayout>
            }
          >
            <Route path=":brand/ambassador-onboarding" element="">
              <Route index element={<SignUpInfluencers />} />
              <Route path="add-personal-info" element={<AddPersonalInfo />} />
              <Route path="confirm-details" element={<ConfirmDetails />} />
              <Route path="submit-application" element={<AnswerQuestions />} />
            </Route>
            <Route
              path="brands/:brand/sign_up_ambassador"
              element={<SignUpInfluencers />}
            ></Route>
          </Route>
          <Route
            path="campaigns/:campaign_id/participant-review"
            element={<ParticipantReview />}
          >
            <Route index element={<ParticipantReview />} />
            <Route
              path=":participant_id/:subpage?"
              element={<ParticipantProfile />}
            />
          </Route>
          <Route path="/creators/:slug/:subpage?" element={<Profile />} />
          {/* signed in users */}
          <Route
            element={
              <SignedIn>
                <MainLayout>
                  <Outlet />
                </MainLayout>
              </SignedIn>
            }
          >
            <Route
              path="off-platform-creator"
              element={<CreateOffPlatformCreator />}
            />
            <Route path="/" element={<DashboardWrap />}>
              <Route index element={<Navigate replace to="home" />} />
              <Route path="home" element={<Home />} />
              <Route path="content" element={<Content />} />
              {/* Add more dashboard routes as needed */}
            </Route>
            <Route path="/card/:slug/:subpage?" element={<Profile />} />

            <Route path="reports" element={<ReportsList />} />
            <Route path="reports/:report_id" element={<ReportProfile />}>
              <Route index element={<Navigate replace to="information" />} />
              <Route path="information" element={<ReportInformation />} />
              <Route path="content" element={<ReportContent />} />
            </Route>
            {/* for testing locally without changing user */}
            {location.host.includes("localhost") && (
              <>
                {/* <Route path="/campaigns" element={<CampaignsDashboard />} /> */}
                <Route path="/directory" element={<DirectoryDashboard />} />
                {/* <Route path="/ambassadors" element={<Ambassadors />} /> */}
              </>
            )}
            <Route path="/stats" element={<Stats />} />
            <Route path="/notifications" element={<Notifications />} />

            <Route
              path="/notifications/settings"
              element={<NotificationSettings />}
            />
            {/* <Route
              path="/opportunities"
              element={
                <MultiPageCardLayout
                  pageName="Opportunities"
                  subNavItems={oppurtunitiesSubNavItems}
                >
                  <Outlet />
                </MultiPageCardLayout>
              }
            >
              <Route index element={<Navigate replace to="briefs" />} />
              <Route path="briefs" element={<BriefsList />} />
              <Route path="bulletins" element={<Bulletins />} />
              <Route
                path="bulletins/:bulletin_id"
                element={<BulletinsProfile />}
              />
            </Route> */}
            <Route path="bulletins" element={<Bulletins />} />
            <Route
              path="bulletins/:bulletin_id"
              element={<BulletinsProfile />}
            />
            <Route path="/campaigns" element={<CampaignList />} />
            <Route path="campaigns/:campaign_id" element={<CampaignProfile />}>
              <Route index element={<Navigate replace to="brief" />} />
              <Route path="overview" element={null} />
              <Route path="participants" element={<CampaignParticipants />} />
              <Route path="reporting" element={<CampaignReports />} />
              <Route
                path="participants/:participant_id/:subpage?"
                element={<ParticipantProfile />}
              />
              <Route
                path="content/:subpage?"
                element={<CampaignContentReview />}
              />
              <Route path="deliverables" element={<CampaignDeliverables />} />
              <Route path="products" element={<CampaignProducts />} />
              <Route
                path="brief/:brief_id?/:settings?"
                element={<CampaignBrief />}
              ></Route>
            </Route>
            <Route
              path="brief-preview/:campaign_id/:participant_group_id?"
              element={<BriefPreview />}
            />
            <Route
              path="participant-brief/:campaign_id/:participant_id"
              element={<BriefPreview />}
            />
            <Route path="/collaborators" element={<CollaboratorsWrapper />}>
              <Route index element={<Navigate replace to="campaigns" />} />
              <Route path="campaigns" element={<CampaignCollaboratorsList />} />
              <Route path="outreach" element={<OutreachCollaboratorsList />} />
              <Route path="bulletins" element={<BulletinCollaboratorsList />} />
              <Route
                path="offPlatform"
                element={<OffPlatformCollaboratorsList />}
              />
            </Route>

            <Route path="/gifting" element={<GiftingTableList />} />
            <Route path="gifting/:table_id" element={<GiftingTable />}>
              <Route index element={<GiftingTableList />} />
            </Route>
            <Route path="/groups" element={<Groups />} />

            <Route path="/groups/:group_id" element={<GroupsProfile />} />

            <Route path="/search" element={<Search />} />
            <Route path="/outreach" element={<OutreachList />} />
            <Route
              path="/outreach/templates"
              element={<OutreachTemplateList />}
            />
            <Route
              path="/outreach/templates/:id"
              element={<OutreachTemplate />}
            />
            <Route path="/outreach/:id" element={<OutreachProfile />}>
              <Route index element={<Navigate replace to="message" />} />
              <Route path="message" element={<OutreachMessage />} />
              <Route path="recipients" element={<OutreachRecipients />} />
            </Route>
            {/* <Route path="/gifting" element={<Gifting />} /> */}

            <Route path="/ambassadors/:subpage?" element={<Ambassadors />}>
              <Route index element={<Navigate replace to="accepted" />} />
              <Route
                path="accepted"
                element={<AmbassadorsList status="accepted" />}
              />
              <Route
                path="applicants"
                element={<AmbassadorsList status="interested" />}
              />
              <Route path="content" element={<AmbassadorSearch />} />
              {/*  <Route
                path="onboarding-settings"
                element={<AmbassadorsOnboardingSettings />}
              /> */}
              <Route
                path="onboarding-questions"
                element={<AmbassadorQuestions />}
              />
              <Route
                path="onboarding-questions/:question_id"
                element={<AmbassadorQuestions />}
              />
            </Route>

            <Route path="/settings" element={<SettingsWrapper />}>
              <Route index element={<Navigate replace to="personal" />} />
              <Route path="personal" element={<ProfileSettings />} />
              <Route path="privacy" element={<PrivacySettings />} />
              <Route path="brand" element={<BrandSettings />} />
            </Route>
            <Route path="/creators" element={<CreatorEdit />}>
              <Route index element={<Navigate replace to="edit" />} />
              <Route path="edit" element={<CreatorSettings />} />
              <Route path="privacy" element={<CreatorPrivacySettings />} />
            </Route>
          </Route>
          <Route
            path="admin"
            element={
              <MainLayout>
                <Outlet />
              </MainLayout>
            }
          >
            <Route index element={<Admin />} />
            <Route path="brands" element={<BrandsAdmin />} />
            <Route path="brands/:brand_id" element={<BrandAdmin />} />
            <Route path="e/:entity_name" element={<EntityAdminLayout />} />
            <Route path="contracts" element={<ContractTemplates />} />
            <Route path="contracts/:id" element={<ContractTemplate />} />
            <Route path="campaigns" element={<CampaignsAdmin />} />
            <Route path="bulletins" element={<BulletinsAdmin />} />
            <Route path="creators" element={<CreatorsAdmin />} />
            <Route path="groups" element={<GroupsAdmin />} />
            <Route path="outreach" element={<OutreachAdmin />} />
            <Route path="reports" element={<ReportsAdmin />} />

            <Route
              path="ambassador_questions"
              element={<AmbassadorQuestions />}
            />
            <Route
              path="ambassador_questions/:question_id"
              element={<AmbassadorQuestions />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
};
