import { useState } from "react";
import toast from "react-hot-toast";
import EditPermalink from "./EditPermalink";
import PermalinkInput from "./PermalinkInput";
import {
  addToParticipantSampleContent,
  deleteParticipantSampleContent,
  updateParticipantSampleContent,
} from "@api/Campaign/CampaignParticipants";
import { Content } from "@types";
import PermalinkContentItem from "./PermalinkContentItem";

interface SampleContentPermalinksProps {
  participantId: number;
  contents: Content[];
  onPostAdded: (post: Content) => void;
  onPostDeleted: (postId: number) => void;
  onPostUpdated: (post: Content) => void;
}

export const SampleContentPermalinks = ({
  participantId,
  contents,
  onPostAdded,
  onPostDeleted,
  onPostUpdated,
}: SampleContentPermalinksProps) => {
  const [editState, setEditState] = useState({
    postId: null,
    pendingUrl: "",
    isSubmitted: false,
  });

  const [newPermalinkState, setNewPermalinkState] = useState({
    url: "",
    isSubmitted: false,
  });

  const [deletingId, setDeletingId] = useState<number | null>(null);

  const handleAddPermalink = async () => {
    if (!newPermalinkState.url) {
      setNewPermalinkState((prev) => ({ ...prev, isSubmitted: true }));

      return;
    }

    try {
      const response = await addToParticipantSampleContent({
        contents: [
          {
            participant_id: participantId,
            post_id: null,
            permalink: newPermalinkState.url,
          },
        ],
      });

      onPostAdded(response.data.data[0]);

      setNewPermalinkState({ url: "", isSubmitted: false });
      toast.success("Link added");
    } catch (e) {
      toast.error("Error adding link");
    }
  };

  const handleSavePermalink = async () => {
    const { postId, pendingUrl } = editState;

    if (!pendingUrl) {
      setEditState((prev) => ({ ...prev, isSubmitted: true }));
      return;
    }

    try {
      const response = await updateParticipantSampleContent(postId, {
        permalink: pendingUrl,
      });
      onPostUpdated(response.data);
      setEditState({ postId: null, pendingUrl: null, isSubmitted: false });
      toast.success("Link updated");
    } catch (e) {
      toast.error("Error updating link");
    }
  };

  const handleDeletePermalink = async (postId: number) => {
    setDeletingId(postId);

    try {
      await deleteParticipantSampleContent(postId);

      onPostDeleted(postId);
      toast.success("Link deleted");
    } catch (e) {
      toast.error("Error deleting link");
    } finally {
      setDeletingId(null);
    }
  };

  return (
    <div className="space-y-4">
      <PermalinkInput
        pendingUrl={newPermalinkState.url}
        isSubmitted={newPermalinkState.isSubmitted}
        onChange={(url) => setNewPermalinkState({ url, isSubmitted: false })}
        onSubmit={handleAddPermalink}
      />
      {contents.map((post) => (
        <div key={post.id}>
          {editState.postId === post.id ? (
            <EditPermalink
              pendingUrl={editState.pendingUrl}
              isSubmitted={editState.isSubmitted}
              onCancel={() =>
                setEditState({
                  postId: null,
                  pendingUrl: null,
                  isSubmitted: false,
                })
              }
              onChange={(url) =>
                setEditState((prev) => ({
                  ...prev,
                  pendingUrl: url,
                }))
              }
              onSubmit={handleSavePermalink}
            />
          ) : (
            <PermalinkContentItem
              content={post}
              onEdit={() =>
                setEditState({
                  postId: post.id,
                  pendingUrl: post.permalink,
                  isSubmitted: false,
                })
              }
              onDelete={() => handleDeletePermalink(post.id)}
              isDeleting={deletingId === post.id}
            />
          )}
        </div>
      ))}
    </div>
  );
};
