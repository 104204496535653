import React, { useState } from "react";
import { FTooltip } from "@components/FTooltip";
import {
  numberWithKMBFormat,
  decimalRound,
  formatDollarValue,
} from "@utils/number_utilities";
import FIcon from "@components/FIcon";
import BasicLineChart from "@components/Graphs/BasicLineChart";
import StackedBarChart from "@components/Graphs/StackedBarChart";

interface CampaignProgressCardProps {
  cardData: Array<{
    cardTitle: string;
    cardStats: Array<{
      statNumber: number | string;
      statName: string;
      statType: string;
      statInfo?: string;
    }>;
  }>;
  graphData: any;
  graphedDataPoint: string; // the key of the data point you want displayed on the graph
  graphLegendLabel?: string;
  chartType: string;
}

const CampaignProgressCard: React.FC<CampaignProgressCardProps> = ({
  cardData,
  graphData,
  graphLegendLabel,
  chartType,
}) => {
  const [dataGrowth] = useState(graphData);
  const [dataGrowthLoading] = useState(false);

  // Chart components map
  const chartComponents = {
    line: (
      <BasicLineChart
        lineColor="#59BBDB"
        yAxisTickSuffix="%"
        legendLabel={graphLegendLabel}
        showLegend={graphLegendLabel ? true : false}
        chartData={graphData}
        height="185"
        pointRadius={0}
      />
    ),
    stackedBar: (
      <StackedBarChart
        data={graphData}
        colors={["#59BBDB", "#FF6384"]}
        height={8}
        showLegend={true}
      />
    ),
  };

  const chartComponent = chartComponents[chartType] || (
    <p>No chart available</p>
  );

  return (
    <div className="p-4 rounded-lg">
      {cardData.map((card, index) => (
        <div key={index} className="mb-4">
          <h3 className="text-[14px] font-sofia-pro pb-[15px]">
            {card.cardTitle}
          </h3>
          {card.cardStats.map((stat, statIndex) => (
            <div
              key={statIndex}
              className="flex justify-between items-center mt-1 border-b border-gray-200 pb-2"
            >
              <div className="flex items-center">
                <p className="text-sm text-gray-600 mr-2">{stat.statName}</p>
                {stat.statInfo && (
                  <FTooltip label={stat.statInfo} style={{ width: 200 }}>
                    <FIcon icon="info" size={16} color="#B0B5C4" />
                  </FTooltip>
                )}
              </div>
              <p className="text-sm font-semibold">
                {stat.statType === "number"
                  ? numberWithKMBFormat(stat.statNumber as number)
                  : stat.statType === "percent"
                    ? `${decimalRound(stat.statNumber, 1)}%`
                    : stat.statType === "dollars"
                      ? formatDollarValue(stat.statNumber as number)
                      : stat.statNumber}
              </p>
            </div>
          ))}
        </div>
      ))}
      {dataGrowth || dataGrowthLoading ? (
        chartComponent
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default CampaignProgressCard;
