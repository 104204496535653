import FButton from "@components/FButton";
import { FTextarea } from "@components/FInputs";

interface CommentInputProps {
  pendingComment: string;
  isSubmitted: boolean;
  onChange: (value: string) => void;
  onSubmit: (value: string) => void;
}

const CommentInput = ({
  pendingComment,
  isSubmitted,
  onChange,
  onSubmit,
}: CommentInputProps) => {
  return (
    <div className="flex items-end gap-2 mb-4">
      <FTextarea
        label="Comment"
        className="w-full"
        value={pendingComment}
        validate={isSubmitted}
        onChange={onChange}
        rows={3}
        required
        width="100%"
      />
      <FButton
        className="rounded-[50%]"
        width={40}
        icon={{
          name: "arrow-up-new",
          color: "#FFFFFF",
          size: 24,
        }}
        onClick={onSubmit}
        primary
      />
    </div>
  );
};

export default CommentInput;
