// Import category images
import fashionImg from "./assets/fashion.jpeg";
import fitnessImg from "./assets/fitness.jpeg";
import foodImg from "./assets/food.jpeg";
import lifestyleImg from "./assets/lifestyle.jpeg";
import travelImg from "./assets/travel.jpeg";
import beautyImg from "./assets/makeup.jpeg";

import gardenImg from "./assets/garden.jpg";
import healthyImg from "./assets/healthy.jpg";
import shoppingImg from "./assets/shopping.jpg";
import travel2Img from "./assets/travel.jpg";
import sportsImg from "./assets/sports.jpg";
import styleImg from "./assets/style.jpg";
// Post categories

export const platformList = [
  { label: "Instagram", value: "instagram", icon: "instagram" },
  { label: "TikTok", value: "tiktok", icon: "tiktok" },
  { label: "YouTube", value: "youtube", icon: "youtube" },
];

export const TARGET_OPPORTUNITIES = [
  ["sponsoredcontent", "Sponsored Content"],

  ["eventattendance", "Event Attendance"],

  ["eventhosting", "Event Hosting"],

  ["brandtakeovers", "Brand Take Overs"],

  ["ugc", "UGC (User Generated Content)"],

  ["gifting", "Gifting"],
];

export const MANAGED_OPTIONS = [
  {
    value: "true",
    label: "Managed",
  },
  { value: "false", label: "Self Managed" },
];

export const PARENTAL_OPTIONS = [
  {
    value: "true",
    label: "Children",
  },
  { value: "false", label: "No Children" },
];

// Types
export interface CategoryImage {
  image: string;
  tag: string;
}

// Search categories
export const allSearchCategories = [
  {
    value: "3",
    label: "Fashion",
    icon: "fashion",
  },
  {
    value: "4",
    label: "Lifestyle",
    icon: "lifestyle",
  },
  {
    value: "37",
    label: "Makeup",
    icon: "makeup",
  },
  {
    value: "24",
    label: "Travel",
    icon: "travel",
  },
  {
    value: "44",
    label: "Sports",
    icon: "sports",
  },
  {
    value: "32",
    label: "Gaming",
    icon: "gaming",
  },
  {
    value: "31",
    label: "Fitness",
    icon: "fitness",
  },
  {
    value: "17",
    label: "Parenting",
    icon: "parenting",
  },
  {
    value: "40",
    label: "Food",
    icon: "food",
  },
  {
    value: "23",
    label: "Art & Design",
    icon: "art",
  },
  {
    value: "10",
    label: "Photography",
    icon: "photography",
  },
  {
    value: "30",
    label: "Fragrance",
    icon: "fragrance",
  },
  {
    value: "33",
    label: "Hair",
    icon: "hair",
  },
  {
    value: "43",
    label: "Outdoors",
    icon: "sun",
  },
  {
    value: "34",
    label: "Wellness & Mindfulness",
    icon: "wellness",
  },
  {
    value: "38",
    label: "Sustainability",
    icon: "sustainability",
  },
  {
    value: "41",
    label: "Music & Entertainment",
    icon: "music",
  },
  {
    value: "35",
    label: "Home Decor & DIY",
    icon: "home-2",
  },
  {
    value: "39",
    label: "Skincare",
    icon: "skincare",
  },
  {
    value: "42",
    label: "Pets",
    icon: "pets",
  },
  {
    value: "45",
    label: "Education",
    icon: "whiteboard",
  },
  {
    value: "46",
    label: "Business Owner",
    icon: "business",
  },
  {
    value: "47",
    label: "Professional",
    icon: "professionals",
  },
  {
    value: "48",
    label: "Jewelry",
    icon: "jewelry",
  },
  {
    value: "49",
    label: "College Students",
    icon: "graduation-cap",
  },
];

// Creator category images
export const categoryImages: CategoryImage[] = [
  { image: fashionImg, tag: "3" }, // Fashion
  { image: fitnessImg, tag: "31" }, // Fitness
  { image: foodImg, tag: "40" }, // Food
  { image: lifestyleImg, tag: "4" }, // Lifestyle
  { image: travelImg, tag: "24" }, // Travel
  { image: beautyImg, tag: "37" }, // Makeup
];

// Content category images
export const contentCategoryImages: CategoryImage[] = [
  { image: gardenImg, tag: "274" }, // Home & Garden
  { image: styleImg, tag: "552" }, // Style & Fashion
  { image: shoppingImg, tag: "476" }, // Shopping
  { image: sportsImg, tag: "483" }, // Sports
  { image: travel2Img, tag: "653" }, // Travel
  { image: healthyImg, tag: "223" }, // Healthy Living
];
