import moment from "moment";
import React from "react";

import { Campaign } from "@types";

interface CampaignPageHeaderProps {
  campaign: Campaign;
}

export default function CampaignPageHeader({
  campaign,
}: CampaignPageHeaderProps) {
  const { brand_name: brandName, brief, title } = campaign;

  const { cover_image, go_live_start, go_live_end } = brief;

  const { large, original, thumb } = cover_image || {};

  const coverImageSrc =
    original || thumb || large || "/images/image_placeholder.png";

  return (
    <div className="flex items-center gap-5">
      <div
        className="w-[72px] h-[72px] bg-cover bg-center relative overflow-hidden rounded-lg"
        style={{ backgroundImage: `url("${coverImageSrc}")` }}
      />
      <div>
        <div className="flex gap-2 h-[40px]">
          <h1 className="h-[30px] leading-[36px] text-[28px] self-center">
            {title}
          </h1>
        </div>

        <div className="flex items-center gap-2 mt-[8px] text-neutral_500">
          {brandName}
          <span className="text-neutral_500 text-[16px] "> • </span>
          {[
            moment(go_live_start).format("MMMM D, YYYY"),
            moment(go_live_end).format("MMMM D, YYYY"),
          ].join(" - ")}
        </div>
      </div>
    </div>
  );
}
