import { updateCampaignParticipant } from "@api/Campaign/CampaignParticipants";
import FButton from "@components/FButton";
import RichTextEditor from "@components/RichTextEditor";
import { Participant } from "@types";
import { useState } from "react";
import toast from "react-hot-toast";

interface RationaleProps {
  profile: Participant;
  onUpdate: (description: string) => void;
}

const Rationale = ({ profile, onUpdate }: RationaleProps) => {
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(true);

  const [pendingDescription, setPendingDescription] = useState("");
  const [isEditingDescription, setEditingDescription] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const handleEditDescription = () => {
    setPendingDescription(profile.creator_review_description);
    setEditingDescription(true);
  };

  const handleSubmitDescription = async () => {
    if (!pendingDescription) {
      return;
    }

    setIsSaving(true);

    try {
      await updateCampaignParticipant(profile.id, {
        creator_review_description: pendingDescription,
      });

      onUpdate(pendingDescription);

      setEditingDescription(false);

      toast.success("Updates saved");
    } catch (error) {
      toast.error("Error submitting description");

      console.error("Failed to submit comment:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div>
      <div className="flex items-center">
        <div className="flex items-center">
          Rationale
          <FButton
            className=""
            icon={{ color: "#000F45", name: "edit", size: 14 }}
            iconButton
            onClick={handleEditDescription}
          />
        </div>
        {profile.creator_review_description ? (
          <>
            <span className="mr-2">•</span>
            <a
              className="cursor-pointer underline"
              onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
            >
              {isDescriptionVisible ? "Hide" : "Show"}
            </a>
          </>
        ) : null}
      </div>
      {isEditingDescription ? (
        <div className="flex flex-col gap-2 w-full">
          <div className="bg-white w-full my-4">
            <RichTextEditor
              value={pendingDescription}
              id={`creatorReviewDescription-${profile.id}`}
              onChange={setPendingDescription}
            />
          </div>
          <div className="flex gap-2 justify-end w-full">
            <FButton
              label="Save"
              onClick={handleSubmitDescription}
              loading={isSaving}
              primary
            />
            <FButton
              label="Cancel"
              onClick={() => setEditingDescription(false)}
            />
          </div>
        </div>
      ) : isDescriptionVisible ? (
        <p
          dangerouslySetInnerHTML={{
            __html: profile.creator_review_description,
          }}
        />
      ) : null}
    </div>
  );
};

export default Rationale;
