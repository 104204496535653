import React from "react";
import { UserNotification, UserNotificationStatus } from "@types";
import { updateUserNotification } from "@api/Notifications/UserNotifications";
import FButton from "@components/FButton";
import moment from "moment";
import FIcon from "@components/FIcon";
import { Link } from "react-router-dom";

interface NotificationGroupProps {
  category: string;
  notifications: UserNotification[];
  onNotificationUpdate: (notificationId: number) => void;
}

const CATEGORY_LABELS = {
  offer: "Campaign Offers",
  content_review: "Content Reviews",
  post: "Posts",
  connections: "Connections",
  live_content_due: "Live Content Due",
};

const CATEGORY_ICONS = {
  offer: "document",
  content_review: "content",
  post: "instagram",
  connections: "user",
  live_content_due: "clock",
};

const STATUS_COLORS = {
  approved: "bg-green",
  accepted: "bg-green",
  soft_approved: "bg-green",
  "client review": "bg-yellow",
  influencer_changes: "bg-blue",
  admin_review: "bg-blue",
  admin_changes: "bg-red",
  viewed: "bg-yellow",
  onboarding: "bg-yellow",
  rejected: "bg-red",
  lapsed: "bg-red",
  declined: "bg-red",
};

const formatNotificationText = (
  details: string,
  category: string
): { action: string; status: string } => {
  // Handle content review cases
  if (details.includes("content for") && details.includes("was updated to")) {
    // Extract the content name and status
    const match = details.match(/content for (.*?) was updated to (.*)/);
    if (match) {
      const [_, contentName, rawStatus] = match;
      const status = rawStatus.replace(/_/g, " "); // Replace underscores with spaces
      return {
        action: `${contentName} in ${status}`, // Include the status in the action
        status: status, // Keep spaces in status
      };
    }
  }

  // Handle attribution case
  if (details.includes("ready for attribution")) {
    const contentName = details.match(/posted\s+(.*?)\s+content/)?.[1] || "";
    return {
      action: `${contentName} in client review`, // Include status in action
      status: "client review",
    };
  }

  // Handle "lapsed" case
  if (details.includes("lapsed")) {
    return {
      action: "connection lapsed",
      status: "lapsed",
    };
  }

  // Handle other "has been updated to" cases
  const parts = details.split(" has been updated to ");
  if (parts.length === 2) {
    const status = parts[1].replace(/_/g, " "); // Keep original case

    switch (category) {
      case "connections":
        return {
          action: `connection in ${status}`,
          status,
        };
      case "offer":
        return {
          action: `offer in ${status}`,
          status,
        };
      default:
        return {
          action: `in ${status}`,
          status,
        };
    }
  }

  return { action: details, status: "" };
};

export default function NotificationGroup({
  category,
  notifications,
  onNotificationUpdate,
}: NotificationGroupProps) {
  const handleMarkAllRead = async () => {
    try {
      await Promise.all(
        notifications.map((notification) =>
          updateUserNotification(notification.id, {
            status: UserNotificationStatus.READ,
          })
        )
      );
      // Update all notifications in this group
      notifications.forEach((notification) => {
        onNotificationUpdate(notification.id);
      });
    } catch (error) {
      console.error("Failed to mark notifications as read:", error);
    }
  };

  const handleMarkAsRead = async (
    e: React.MouseEvent,
    notificationId: number
  ) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await updateUserNotification(notificationId, {
        status: UserNotificationStatus.READ,
      });
      onNotificationUpdate(notificationId);
    } catch (error) {
      console.error("Failed to mark notification as read:", error);
    }
  };

  return (
    <div className="border border-light_border rounded-lg bg-light_red shadow-md">
      <div className="flex justify-between items-center p-4">
        <div className="flex items-center gap-2 ">
          <h3 className="font-medium text-[16px] text-neutral_800">
            {CATEGORY_LABELS[category] || category}
          </h3>
          <span className="text-sm text-neutral_500">
            ({notifications.length})
          </span>
        </div>
        <button
          onClick={handleMarkAllRead}
          className="text-red text-xs flex items-center gap-2"
        >
          Clear all
        </button>
      </div>
      <div className="">
        {notifications.map((notification) => {
          const { action, status } = formatNotificationText(
            notification.details,
            notification.category
          );
          const statusColor =
            STATUS_COLORS[status.toLowerCase()] || "bg-neutral_800";

          return (
            <Link
              to={`/campaigns/${notification.campaign_id}`}
              key={notification.id}
              className="block hover:bg-white px-4 py-2 border-t border-light_border transition-colors group"
            >
              <div className="flex items-top gap-2 relative w-full">
                {notification.photo_url && (
                  <img
                    src={notification.photo_url}
                    alt=""
                    className="w-10 h-10 rounded object-cover"
                  />
                )}
                <div className="flex-shrink-0 w-[6px]">
                  <span
                    className={`block w-[6px] h-[6px] relative top-[5px] rounded-full ${statusColor}`}
                    title={status}
                  />
                </div>
                <div className="">
                  <p className="text-sm items-center relative">
                    <span className="font-medium mr-1">
                      {notification.participant?.full_name?.trim()}
                    </span>
                    <span className="text-neutral_800">{action}</span>
                  </p>
                  <p className="text-xs text-neutral_500">
                    {notification.campaign_name} •{" "}
                    {moment(notification.created_at).fromNow()}
                  </p>
                </div>
                <button
                  onClick={(e) => handleMarkAsRead(e, notification.id)}
                  className="opacity-0 bg-white border border-light_border group-hover:opacity-100 transition-opacity absolute right-[0px] top-1/2 -translate-y-1/2 p-1 hover:bg-neutral_200 rounded"
                  title="Mark as read"
                >
                  <FIcon icon="exit" size={12} color="#000F45" />
                </button>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
