import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import useAuth from "@hooks/useAuth";

export default function SignedIn({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]); // Include navigate in the dependency array

  if (user) {
    return <>{children}</>;
  }
}
