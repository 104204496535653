/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import MultiPageLayout from "@layouts/MultiPageLayout";
import apiClient from "@apiClient";
import { UserContext, CreatorEditContext } from "@contexts/index";
import LoadingSpinner from "@components/LoadingSpinner";

const subNavItems = [
  {
    label: "Personal Settings",
    value: "edit",
    link: "edit",
  },
  {
    label: "Data Privacy",
    value: "data privacy",
    link: "privacy",
  },
];

const CreatorEdit = () => {
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);
  const [creator, setCreator] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const response = await apiClient.creators.get(user.creator_id);

      // --------------- accounts for testing -----------------
      // const response = await apiClient.creators.get(252093);
      // const response = await apiClient.creators.get(265432);
      setCreator(response.data);
      setLoading(false);
    };
    fetchData();
  }, []);

  if (loading) {
    return <LoadingSpinner className="w-full h-[90vh]" />;
  }

  return (
    <MultiPageLayout pageName="My Settings" subNavItems={subNavItems}>
      <CreatorEditContext.Provider
        value={{
          creator,
          setCreator,
        }}
      >
        <div className="pt-[40px]">
          <Outlet />
        </div>
      </CreatorEditContext.Provider>
    </MultiPageLayout>
  );
};

export default CreatorEdit;
