interface Column {
  key: string;
  label: string;
  className?: string;
  isLink?: boolean;
  linkURL?: string;
  isStateBubble?: boolean;
  isDate?: boolean;
  isImage?: boolean;
  isOutsideLink?: boolean;
  isMoney?: boolean;
  isNumber?: boolean;
  imageType?: "asset" | "profile";
  imageKey?: string;
  nestedKey?: string;
  isFirstColumn?: boolean;
  hasParent?: string;
}

const flattenData = (data: any, parentKey = "", result = {}): any => {
  Object.keys(data).forEach((key) => {
    const newKey = key;
    if (
      typeof data[key] === "object" &&
      data[key] !== null &&
      !Array.isArray(data[key])
    ) {
      flattenData(data[key], newKey, result);
    } else {
      result[newKey] = {
        value: data[key],
        hasParent: parentKey || null, // Add hasParent property
      };
    }
  });
  return result;
};

export const generateTableColumns = (
  data: any[],
  rootURL: string
): Column[] => {
  // Log the initial data and rootURL
  // console.log("generateTableColumns called with data:", data);
  // console.log("rootURL:", rootURL);

  if (!data || data.length === 0) {
    console.log("No data provided or data is empty.");
    return [];
  }

  const flattenedData = data.map((item) => flattenData(item));

  const columns: Column[] = Object.keys(flattenedData[0]).map((key) => {
    const column: Column = {
      key,
      label: key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase()),
      hasParent: flattenedData[0][key].hasParent,
    };

    // Customize column properties based on key patterns or specific keys
    if (key === "id") {
      column.isLink = true;
      column.linkURL = `${rootURL}/{id}`;
    } else if (
      key.includes("date") ||
      (key.endsWith("_at") && key !== "auto_content_attribution")
    ) {
      column.isDate = true;
    } else if (
      key.includes("budget") ||
      key === "price" ||
      key.endsWith("_cost") ||
      key.endsWith("_rate")
    ) {
      column.isMoney = true;
    } else if (
      key === "failed_attempts" ||
      key.includes("views") ||
      key.includes("clicks") ||
      key.includes("impressions") ||
      key.includes("conversions") ||
      key.includes("revenue") ||
      key.endsWith("_rank") ||
      key.endsWith("_count")
    ) {
      column.isNumber = true;
    } else if (key.endsWith("_image_url")) {
      column.isImage = true;
      column.imageType = "asset";
      column.imageKey = key;
    } else if (key.includes("url")) {
      column.isLink = true;
      column.imageKey = key;
      column.isOutsideLink = true;
    } else if (
      key.includes("state") ||
      key.includes("kind") ||
      key.includes("confirmed") ||
      key.includes("can_boost_tiktok_posts") ||
      key.includes("auto_content_attribution") ||
      key.includes("_status") ||
      key.includes("price_gifting") ||
      key.includes("price_partnership") ||
      key.includes("eu_based") ||
      key.includes("exclusive_ambassador") ||
      key.includes("off_platform") ||
      key.includes("in_pardot") ||
      key.endsWith("_hidden") ||
      key.includes("status") ||
      key.includes("ethnicity") ||
      key.endsWith("_enabled") ||
      key.includes("archived")
    ) {
      column.isStateBubble = true;
    }

    return column;
  });

  // Ensure the first column is name, title, firstName lastName, or fullName
  const firstColumnKey = [
    "name",
    "title",
    "firstName",
    "lastName",
    "fullName",
  ].find((key) => Object.keys(flattenedData[0]).includes(key));

  if (firstColumnKey) {
    const firstColumn: Column = {
      key: firstColumnKey,
      label: firstColumnKey
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase()),
      isLink: true,
      linkURL: `${rootURL}/{id}`,
      isFirstColumn: true,
    };

    // Check if there is an image key that includes "_image_url"
    const imageKey = Object.keys(flattenedData[0]).find((key) =>
      key.endsWith("_image_url")
    );

    if (imageKey) {
      firstColumn.isImage = true;
      firstColumn.imageType = "profile";
      firstColumn.imageKey = imageKey;
    }

    // Move the first column to the beginning of the array
    return [
      firstColumn,
      ...columns.filter((col) => col.key !== firstColumnKey),
    ];
  }

  console.log(columns, "columns");

  return columns;
};
