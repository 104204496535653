import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { CampaignDeliverable } from "@types";

interface CampaignDeliverableParams {
  participant_group_id_eq?: number;
  kind_eq?: string;
  platform_eq?: string;
}

const getCampaignDeliverables = async (
  campaignId: string | number,
  params?: CampaignDeliverableParams
): Promise<ApiResponse<CampaignDeliverable[]>> => {
  const queryParams = new URLSearchParams();

  // Add the campaignId to the query parameters
  queryParams.append("q[campaign_id_eq]", campaignId.toString());

  // Add other optional parameters to the query string
  if (params?.participant_group_id_eq) {
    queryParams.append(
      "q[participant_group_id_eq]",
      params.participant_group_id_eq.toString()
    );
  }
  if (params?.kind_eq) {
    queryParams.append("q[kind_eq]", params.kind_eq);
  }
  if (params?.platform_eq) {
    queryParams.append("q[platform_eq]", params.platform_eq);
  }

  return apiWrapper(`/campaign_deliverables?${queryParams.toString()}`, "GET");
};

const updateCampaignDeliverable = async (
  deliverable_id: string | number,
  data: any
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/campaign_deliverables/${deliverable_id}`, "PUT", data);
};

const deleteCampaignDeliverable = async (
  deliverable_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/campaign_deliverables/${deliverable_id}`, "DELETE");
};

const createCampaignDeliverable = async (
  campaign_id: string | number,
  data: any
): Promise<ApiResponse<any>> => {
  return apiWrapper("/campaign_deliverables", "POST", {
    ...data,
    campaign_id: campaign_id,
  });
};

export const createParticipantDeliverable = async (
  data: Partial<CampaignDeliverable>
): Promise<ApiResponse<CampaignDeliverable>> => {
  return apiWrapper(`/participant_deliverables`, "POST", data);
};

export const updateParticipantDeliverable = async (
  deliverable_id: string | number,
  data: Partial<CampaignDeliverable>
): Promise<ApiResponse<CampaignDeliverable>> => {
  return apiWrapper(
    `/participant_deliverables/${deliverable_id}`,
    "PATCH",
    data
  );
};

const deleteParticipantDeliverable = async (
  deliverable_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/participant_deliverables/${deliverable_id}`, "DELETE");
};

export {
  getCampaignDeliverables,
  updateCampaignDeliverable,
  deleteCampaignDeliverable,
  createCampaignDeliverable,
  deleteParticipantDeliverable,
};
