import { fetcher } from "@utils/fetcher";

const createCampaignLink = async (campaignId: number, params: any) => {
  return await fetcher(
    `/api/v1/campaigns/${campaignId}/campaign_links`,
    "POST",
    params
  );
};

const updateCampaignLink = async (
  campaignId: number,
  linkId: number,
  params: any
) => {
  return await fetcher(
    `/api/v1/campaigns/${campaignId}/campaign_links/${linkId}`,
    "PUT",
    params
  );
};

const deleteCampaignLink = async (campaignId: number, linkId: number) => {
  return await fetcher(
    `/api/v1/campaigns/${campaignId}/campaign_links/${linkId}`,
    "DELETE"
  );
};

export { createCampaignLink, updateCampaignLink, deleteCampaignLink };
