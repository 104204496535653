import React from "react";

export default function ProgressCardItem({ title, copy, complete }) {
  return (
    <li className="c-page-layout__progress-card__progress-list__item">
      {complete ? (
        <i className="c-page-layout__progress-card__progress-list__item__check c-page-layout__progress-card__progress-list__item__check--checked" />
      ) : (
        <i className="c-page-layout__progress-card__progress-list__item__check" />
      )}
      <p className="c-page-layout__progress-card__progress-list__item__copy">
        <span className="u-weight-600">{title}:</span> {copy}
      </p>
    </li>
  );
}
