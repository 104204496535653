import SocialConnectButton from "@pages/auth/influencer-onboarding/social/SocialConnectButton";
import React from "react";

interface TiktokConnectButtonProps {
  isConnected: boolean;
  iconOnly?: boolean;
  onClick: () => void;
}

const TiktokConnectButton = ({
  isConnected,
  iconOnly,
  onClick,
}: TiktokConnectButtonProps) => {
  const connectTiktok = async () => {
    try {
      // Step 1: Get the current page URL to pass as originUrl

      // Step 2: Redirect the user to Tiktoks's OAuth page to authorize your app
      window.location.href =
        window.location.origin + "/api/v1/tiktok_oauth/callback";
    } catch (error) {
      console.error("Failed to connect to Tiktok:", error);
    }
  };

  return (
    <SocialConnectButton
      onClick={() => (isConnected ? onClick() : connectTiktok())}
      name="Tiktok"
      icon="tiktok"
      iconOnly={iconOnly}
      isConnected={isConnected}
    />
  );
};

export default TiktokConnectButton;
