import { ApiResponse, UserData } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import {
  getQueryParamsForSearchRequest,
  LOCAL_API_ORIGIN,
} from "@constants/api";
import axios, { AxiosHeaders } from "axios";
import { Brand } from "types/brand";

interface BrandUpdateParams
  extends Omit<Partial<Brand>, "background_photo" | "logo_photo"> {
  background_photo: File;
  logo_photo: File;
}

const listBrands = async (params?: {
  id_eq?: number;
  name_eq?: string;
  name_i_cont?: string;
  slug_eq?: string;
  slug_i_cont?: string;
  instagram_handle_eq?: string;
}): Promise<{ brands: Brand[]; total_items: number }> => {
  const queryParams = getQueryParamsForSearchRequest(null, null, params || {});

  const response = await apiWrapper(`/brands?${queryParams.toString()}`, "GET");

  return response.data;
};

export const signupBrand = async (
  params: Partial<Brand>,
  captchaToken: string
): Promise<ApiResponse<Brand>> => {
  const headers = new AxiosHeaders({
    "Fohr-Captcha-Token": captchaToken,
  });
  return apiWrapper("/sign_up_brands/new", "GET", params, headers);
};

export const finishSignupBrand = async (params: {
  token: string;
  ref: string;
  password: string;
  password_confirmation: string;
}): Promise<ApiResponse<Brand>> => {
  return apiWrapper("/sign_up_brands", "POST", params);
};

const createBrand = async (params: Partial<any>): Promise<ApiResponse<any>> => {
  return apiWrapper("/api/v1/brands", "POST", params);
};

const updateBrand = async (
  brand_id: string | number,
  params: BrandUpdateParams
): Promise<ApiResponse<Brand>> => {
  const formData = new FormData();

  console.log("Params", params);

  Object.keys(params).forEach((key) => {
    const value = params[key];

    formData.append(`${key}`, value);
  });

  return apiWrapper(`/brands/${brand_id}`, "PUT", formData);
};

const deleteBrand = async (brand_id: string | number): Promise<ApiResponse> => {
  return apiWrapper(`/brands/${brand_id}`, "DELETE");
};

const showBrand = async (brand_id: string | number): Promise<Brand> => {
  const response = await apiWrapper(`/brands/${brand_id}`, "GET");

  return response.data;
};

export const getBrandBySlugForAmbassadorOnboarding = async (
  brand_slug: string
): Promise<Brand> => {
  const response = await apiWrapper(
    `/ambassador_onboarding/${brand_slug}`,
    "GET"
  );

  return response.data;
};

export const signUpAmbassador = async (
  brand_id: number,
  email,
  captchaToken: string
): Promise<ApiResponse<string>> =>
  await axios.get(
    `${LOCAL_API_ORIGIN}/api/v1/brands/${brand_id}/sign_up_ambassadors/new?email=${encodeURIComponent(email)}`,
    {
      headers: {
        "Content-Type": "application/json",
        "Fohr-Captcha-Token": captchaToken,
      },
    }
  );

export const createBrandAmbassador = async (
  brand_id,
  values
): Promise<ApiResponse<UserData>> =>
  await apiWrapper(`/brands/${brand_id}/sign_up_ambassadors`, "POST", values);

export { listBrands, createBrand, updateBrand, deleteBrand, showBrand };
