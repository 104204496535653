const videoMimeTypes = [
  "video/x-flv",
  "video/x-ms-wmv",
  "video/x-msvideo",
  "video/quicktime",
  "video/3gpp",
  "video/MP2T",
  "application/x-mpegURL",
  "video/mp4",
];

export const isVideo = (file: File) => videoMimeTypes.includes(file?.type);

export const isVideoByExtension = (file_url = "", filename = "") =>
  [".mp4", ".m4a", ".f4v", ".m4b", ".mov"].some(
    (suffix) =>
      file_url.toLowerCase().endsWith(suffix) ||
      (filename && filename.toLowerCase().endsWith(suffix))
  );
