import { updateCampaignParticipant } from "@api/Campaign/CampaignParticipants";
import FDropdown from "@components/FDropdown";
import Modal from "@components/Modals/Modal";
import { Participant, ParticipantStatus } from "@types";
import { useState } from "react";
import toast from "react-hot-toast";
import { CREATOR_REVIEW_STATUSES } from "../constants";

interface UpdateParticipantStatusModalProps {
  onClose: () => void;
  participants: Participant[];
  onUpdated: (status: string) => void;
}

const UpdateParticipantStatusModal = ({
  onClose,
  participants,
  onUpdated,
}: UpdateParticipantStatusModalProps) => {
  const [isWaiting, setWaiting] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(
    CREATOR_REVIEW_STATUSES[0].value
  );

  const handleUpdateStatus = async () => {
    setWaiting(true);

    const updates = { status: selectedStatus };

    const updatePromises = participants.map(async (participant) =>
      updateCampaignParticipant(participant.id, updates)
    );

    try {
      await Promise.all(updatePromises);
    } catch (e) {
      toast.error("Error updating participant", e.message);
    } finally {
      setWaiting(false);
    }

    onUpdated(selectedStatus);

    /*  const newParticipants = participants.map((participant) =>
      selectedItems.includes(participant.id)
        ? { ...participant, ...updates }
        : participant
    );

    setCampaign({
      ...campaign,
      participants: newParticipants,
    });

    setSelectedItems([]);
    setModalOpen(false); */
  };

  const getParticipantNames = () => {
    return participants.map((participant) =>
      participant
        ? `${participant.creator.firstName} ${participant.creator.lastName}`
        : "Unknown"
    );
  };

  const renderParticipantNames = () => {
    const names = getParticipantNames();

    if (names.length > 2) {
      return `${names.slice(0, -1).join(", ")}, & ${names[names.length - 1]}`;
    } else if (names.length === 2) {
      return `${names[0]} & ${names[1]}`;
    } else if (names.length === 1) {
      return names[0];
    }
    return "";
  };

  return (
    <Modal
      title="Update Status"
      isOpen
      waiting={isWaiting}
      onClose={onClose}
      onAction={handleUpdateStatus}
      actionLabel="Update"
    >
      <p className="mb-4">Update {renderParticipantNames()} to:</p>
      <FDropdown
        width="100%"
        className="border-none"
        height={40}
        titleDropDown={false}
        hasNoDefault
        selectedValue={selectedStatus}
        onChange={(value) => setSelectedStatus(value as ParticipantStatus)}
        options={CREATOR_REVIEW_STATUSES}
      />
    </Modal>
  );
};

export default UpdateParticipantStatusModal;
