/* eslint-disable */
// TODO: enable linting when we fully integrate with the BE and can remove all lint errors
import React, { useState, useContext, useEffect } from "react";
import { CreatorEditContext } from "../../contexts/index";
import apiClient from "@apiClient";
import { allSearchCategories, platformList } from "@constants/constants";

import { FInput } from "../../components/FInputs";
import FButton from "../../components/FButton";
import FileUpload from "../../components/FileUpload";
import FIcon from "../../components/FIcon";

import toast from "react-hot-toast";
import ConfirmModal from "@components/Modals/ConfirmModal";
import CountryRegionPicker from "@components/Form/CountryRegionPicker";
import { pick } from "lodash";
import BirthdayPicker from "@components/Form/BirthdayPicker";

const CreatorSettings = () => {
  const { creator, setCreator } = useContext(CreatorEditContext);

  const [basicInfoValues, setBasicInfoValues] = useState({
    email: creator.email,
    firstName: creator.firstName,
    lastName: creator.lastName,
    address_line_1: creator.about_you.address_line_1,
    address_line_2: creator.about_you.address_line_2,
    address_city: creator.about_you.address_city,
    address_state: creator.about_you.address_state,
    address_country: creator.about_you.address_country,
    address_zip: creator.about_you.address_zip,
    phoneNumber: creator.phone,
    birthday: creator.birthday,
  });

  const [ambassadorships, setAmbassadorships] = useState(creator?.ambassadors);
  const [selectedCategories, setSelectedCategories] = useState(creator.tags);
  const [profileImage, setProfileImage] = useState(creator.profile_image_url);
  const [logoImage, setLogoImage] = useState(creator.logo_image_url);
  const [socials, setSocials] = useState({
    facebook: creator?.socials?.facebook,
    instagram: creator?.socials?.instagram,
    tiktok: creator?.socials?.tiktok,
    youtube: creator?.socials?.youtube,
  });
  const [emailPreferences, setEmailPreferences] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {}, []);

  // --------------------- Page Constants ---------------------
  const basicInfoFields = [
    { label: "Email", name: "email", dropdown: false },
    { label: "First Name", name: "firstName", dropdown: false },
    { label: "Last Name", name: "lastName", dropdown: false },
    { label: "Address Line 1", name: "address_line_1", dropdown: false },
    { label: "Address Line 2", name: "address_line_2", dropdown: false },
    { name: "country_state" },
    { label: "State", name: "address_state", dropdown: true },
    { label: "Zip Code", name: "address_zip", dropdown: false },
    { label: "Phone Number", name: "phoneNumber", dropdown: false },
  ];

  const updateBasicInfo = (values) => {
    const {
      address_line_1,
      address_line_2,
      address_city,
      address_country,
      address_state,
      address_zip,
    } = values;
    const updatedValues = {
      ...values,
      about_you: {
        address_line_1,
        address_line_2,
        address_city,
        address_country,
        address_state,
        address_zip,
      },
    };
    updateCreator(updatedValues);
  };
  const updateSelectedCategories = (categoriesValue) => {
    if (selectedCategories.includes(categoriesValue)) {
      setSelectedCategories((prevCategories) =>
        prevCategories.filter((category) => category !== categoriesValue)
      );
    } else {
      setSelectedCategories((prevCategories) => [
        ...prevCategories,
        categoriesValue,
      ]);
    }
  };
  const updateImages = (image, imageType) => {
    console.log(image, imageType);
  };
  const updateEmailPreferences = (preferences) => {
    console.log(preferences);
  };

  // ------------ Functions for updating with api ----------------
  const updateCreator = (values) => {
    apiClient.creators
      .update(creator.id, values)
      .then((res) => {
        console.log(res);
        toast.success("Creator information updated successfully");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Failed to update creator information");
      });
  };
  const updateAmbassadorInfo = (values) => {
    console.log(values);
  };
  const updateSocials = (platform, value) => {
    console.log(platform, value);
  };
  const deleteCreatorAccount = () => {
    apiClient.creators
      .delete(creator.id)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        toast.error("Error deleting creator", err);
      });
  };

  return (
    <div className="max-w-[800px]">
      {/* Basic Info */}
      <div className="py-[40px]">
        <h1 className="mb-4">Basic Info</h1>
        {basicInfoFields.map(({ name, label, dropdown }) => {
          if (name === "country_state") {
            return (
              <CountryRegionPicker
                value={pick(basicInfoValues, ["country", "state"])}
                onChange={(values) => {
                  setBasicInfoValues({
                    ...basicInfoValues,
                    ...values,
                  });
                }}
              />
            );
          } else if (!dropdown) {
            return (
              <FInput
                key={name}
                label={label}
                className="mb-[16px]"
                required={name === "email" ? true : false}
                width="100%"
                value={basicInfoValues[name] || ""}
                onChange={(value) =>
                  setBasicInfoValues({
                    ...basicInfoValues,
                    [name]: value,
                  })
                }
              />
            );
          }
        })}

        <div className="flex flex-col">
          <label className="font-sofia_pro text-dark_night_sky text-sm leading-[18px] mb-[6px] block">
            Birthday
          </label>
          <BirthdayPicker
            value={basicInfoValues.birthday}
            onChange={(value) =>
              setBasicInfoValues({
                ...basicInfoValues,
                birthday: value,
              })
            }
          />
        </div>
        <div className="mt-[16px]">
          <FButton
            primary={true}
            label="Update Basic Info"
            type="submit"
            onClick={() => updateBasicInfo(basicInfoValues)}
          />
        </div>
      </div>
      {/* Ambassador Info */}
      <div className="py-[40px]">
        {/* this section shows current ambassador info i.e. current ambassador applications, etc. */}
        <h1 className="mb-4">Ambassador Info</h1>
        {ambassadorships && ambassadorships.length > 0 ? (
          <div className="space-y-4">
            {ambassadorships.map((ambassador) => {
              return (
                <div key={ambassador.id} className="mb-4">
                  <div className="flex justify-between items-center mb-2">
                    <h2 className="text-lg font-semibold">
                      {ambassador.brand_name} Ambassador Program
                    </h2>
                    {ambassador.status === "onboarding" && (
                      <a
                        href={`/${ambassador.brand_slug}/ambassador-onboarding`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FButton label="Edit" primary={true} />
                      </a>
                    )}
                  </div>
                  <p className="text-sm">
                    Status:{" "}
                    {ambassador.status === "accepted"
                      ? "Application Accepted"
                      : ambassador.status === "rejected"
                        ? "Application Rejected"
                        : ambassador.status === "onboarding"
                          ? "Application Not Complete"
                          : "Application Pending Review"}
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          <p>No ambassador applications</p>
        )}
      </div>
      {/* Categories */}
      <div className="py-[40px]">
        <h1 className="mb-4">Categories</h1>
        <div className="grid grid-cols-3 gap-4 mb-4">
          {allSearchCategories.map((category) => (
            <div key={category.value} className="flex items-start">
              <input
                type="checkbox"
                id={category.value.toString()}
                value={category.value}
                className="w-4 h-4"
                onChange={() => updateSelectedCategories(category.value)}
                checked={selectedCategories.includes(category.value)}
              />
              <label
                htmlFor={category.value.toString()}
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                {category.label}
              </label>
            </div>
          ))}
        </div>
        <div className="mt-[16px]">
          <FButton
            primary={true}
            label="Update Categories"
            type="submit"
            onClick={() => updateCreator({ tags: selectedCategories })}
          />
        </div>
      </div>
      {/* Images */}
      {/* TODO: add functionality for adding/updating images */}
      <div className="py-[40px]">
        <h1 className="mb-4">Images</h1>
        <div className="flex justify-start gap-4 w-full">
          <div className="w-fit">
            <label htmlFor="profile_image">Profile Photo</label>
            <FileUpload
              type="image"
              url={creator.profile_image_url}
              name="profile_image"
              apiFunction={async (image) =>
                updateImages(image, "profile_image")
              }
              resourceName="profile_image"
              resourceId={creator.id}
            />
          </div>
          <div className="w-fit">
            <label htmlFor="logo_image">Logo (Optional)</label>
            <FileUpload
              type="image"
              url={creator.logo_image_url}
              name="logo_image"
              apiFunction={async (image) => updateImages(image, "logo_image")}
              resourceName="logo_image"
              resourceId={creator.id}
            />
          </div>
        </div>
      </div>
      {/* Social Media Accounts */}
      {/* TODO: add functionality for updating connections */}
      <div className="py-[40px]">
        <h1 className="mb-1">Social Media Accounts</h1>
        <p className="text-sm font-medium mb-4">
          Create, manage, and repair connections to all of your social platforms
        </p>
        <div className="space-y-4">
          {platformList.map((platform) => (
            <div key={platform.value}>
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <div className="flex items-center">
                    <FIcon icon={platform.icon} size={24} className="mr-2" />
                    <h2>{platform.label}</h2>
                  </div>
                  <span className="text-sm font-medium">
                    {socials[platform.value] ? "Connected " : "Not Connected"}
                  </span>
                </div>
                <div className="flex space-x-4">
                  {socials[platform.value] &&
                    platform.value != "facebook" &&
                    socials[platform.value] == "manual_username" && (
                      <FButton
                        primary={false}
                        label="Upgrade Connection"
                        onClick={() =>
                          console.log("Upgrade connection: ", platform.value)
                        }
                        className="px-4 py-2 text-sm"
                      />
                    )}
                  <FButton
                    primary={true}
                    label={socials[platform.value] ? "Disconnect" : "Connect"}
                    onClick={() =>
                      console.log(
                        platform.value,
                        socials[platform.value] ? "Disconnect" : "Connect"
                      )
                    }
                    className="px-4 py-2 text-sm"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Email Preferences */}
      {/* TODO: add functionality for updating email preferences */}
      <div className="py-[40px]">
        <h1 className="mb-4">Email Preferences</h1>
        <div className="space-y-2">
          <div className="flex items-center">
            <input type="checkbox" id="generalInfo" className="w-4 h-4" />
            <label htmlFor="generalInfo" className="ml-2">
              Send me general information about events, news, etc.
            </label>
          </div>
          <div className="flex items-center">
            <input type="checkbox" id="accountDisconnect" className="w-4 h-4" />
            <label htmlFor="accountDisconnect" className="ml-2">
              Send me notifications if my social accounts disconnect
            </label>
          </div>
          <div className="flex items-center">
            <input type="checkbox" id="bulletinEmails" className="w-4 h-4" />
            <label htmlFor="bulletinEmails" className="ml-2">
              Send me Bulletin emails
            </label>
          </div>
          <div className="flex items-center">
            <input type="checkbox" id="directMessages" className="w-4 h-4" />
            <label htmlFor="directMessages" className="ml-2">
              Send direct messages from brands
            </label>
          </div>
        </div>
        <div className="mt-[16px]">
          <FButton
            primary={true}
            label="Update Notifications"
            type="submit"
            onClick={() => console.log("update notifications")}
          />
        </div>
      </div>
      {/* Delete Account */}
      <div className="py-[40px]">
        <h1 className="mb-4">Delete Fohr Account</h1>
        <p>
          If you would no longer like to keep your account with Fohr, you are
          always free to delete it. By deleting your account, all of your basic
          information including your name, location, address, phone number,
          birthday, and all of your photos, tweets, videos and stats will be
          completely removed from our databases.
        </p>
        <div className="mt-[16px]">
          <FButton
            primary={true}
            label="Delete Account"
            type="submit"
            onClick={() => setIsDeleteModalOpen(true)}
          />
        </div>
      </div>
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onAction={deleteCreatorAccount}
        actionLabel="Yes, Delete My Account"
        title="Delete Account"
        children={
          <>
            <p className="mb-2">
              We're sad to see you go! We get it though. Sometimes it's good to
              be forgotten.
            </p>
            <p className="mb-2">
              By deleting your account, all of your basic information including
              your name, location, address, phone number, birthday, and all of
              your photos, tweets, videos and stats will be completely removed
              from our databases. Your information will no longer show up on
              fohr.co. It will be as if we never knew you.
            </p>
            <p className="mb-2">
              Are you sure you want to delete your account?
            </p>
          </>
        }
      />
    </div>
  );
};

export default CreatorSettings;
