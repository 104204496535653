export function formatNumber(float) {
  if (float === "") {
    return float;
  }

  if (float == undefined) {
    return "n/a";
  }

  const number = Math.round(float);
  if (isNaN(number)) {
    return float
      .split(",")
      .join("")
      .replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  }
  return number.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,");
}

export function numberWithKMBFormat(num: number, numDecimals?: number) {
  const map = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted =
      (numDecimals
        ? Math.round((100 * num) / found.threshold) / 100
        : Math.round(num / found.threshold)) + found.suffix;
    return formatted;
  }
  return num;
}

export function formatDollarValue(number: number) {
  return `$${formatNumber(number)}`;
}

export function formatEmv(number) {
  if (number <= 500) {
    return `${formatDollarValue(number)}*`;
  }
  return `${formatDollarValue(number)}`;
}

export function formatPrice(base, max) {
  return `$${formatNumber(base)}-$${formatNumber(max)}`;
}

export function formatPercentage(number) {
  return `${number}%`;
}

export function decimalRound(float, decimalPrecision) {
  const multiplier = 10 ** decimalPrecision;
  const round = Math.round(float * multiplier) / multiplier;

  if (isNaN(round)) {
    return "n/a";
  }

  return round;
}

export function formatLargeNumber(float = 0.0) {
  // number formatted like 160K 2M, etc
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(float);
}

export function addCommas(num = 0) {
  if (num || num === 0) {
    const [integerPart, decimalPart] = num.toString().split(".");
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
    return decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  } else {
    return "";
  }
}

export function removeNonNumeric(num) {
  return num.toString().replace(/[^0-9]/g, "");
}

export function calculateAge(birthdate) {
  const today = new Date();
  const birthdateDate = new Date(birthdate);

  let age = today.getFullYear() - birthdateDate.getFullYear();

  // Adjust age if birthday hasn't occurred yet this year
  if (
    today.getMonth() < birthdateDate.getMonth() ||
    (today.getMonth() === birthdateDate.getMonth() &&
      today.getDate() < birthdateDate.getDate())
  ) {
    age--;
  }

  return age;
}

export function formatEngagementRate(engagementRate) {
  if (!engagementRate && engagementRate != 0) {
    return "n/a";
  }
  return Number(engagementRate).toFixed(2) + "%";
}

export function getIsViralPost(
  platform = "instagram",
  type = "FEED",
  followers = 0,
  views = 0
) {
  switch (platform) {
    case "instagram":
      switch (type) {
        case "Feed":
          // follower tiers
          switch (true) {
            case followers >= 5000 && followers <= 10000:
              return views > followers * 0.1558 * 3;
            case followers >= 10000 && followers <= 25000:
              return views > followers * 0.2108 * 3;
            case followers >= 25000 && followers <= 50000:
              return views > followers * 0.1508 * 3;
            case followers >= 50000 && followers <= 100000:
              return views > followers * 0.1431 * 3;
            case followers >= 100000 && followers <= 250000:
              return views > followers * 0.1081 * 3;
            case followers >= 250000 && followers <= 500000:
              return views > followers * 0.1036 * 3;
            case followers >= 500000 && followers <= 1000000:
              return views > followers * 0.0406 * 3;
            case followers >= 1000000:
              return views > followers * 0.0847 * 3;
            default:
              return false;
          }
        case "Reels":
          // follower tiers
          switch (true) {
            case followers >= 5000 && followers <= 10000:
              return views > followers * 0.4372 * 3;
            case followers >= 10000 && followers <= 25000:
              return views > followers * 0.2483 * 3;
            case followers >= 25000 && followers <= 50000:
              return views > followers * 0.215 * 3;
            case followers >= 50000 && followers <= 100000:
              return views > followers * 0.198 * 3;
            case followers >= 100000 && followers <= 250000:
              return views > followers * 0.1518 * 3;
            case followers >= 250000 && followers <= 500000:
              return views > followers * 0.2192 * 3;
            case followers >= 500000 && followers <= 1000000:
              return views > followers * 0.2164 * 3;
            case followers >= 1000000:
              return views > followers * 0.4728 * 3;
            default:
              return false;
          }
        default:
          return false;
      }
    case "tiktok":
    case "Video":
      // follower tiers
      switch (true) {
        case followers >= 5000 && followers <= 10000:
          return views > followers * 0.4372 * 3;
        case followers >= 10000 && followers <= 25000:
          return views > followers * 0.2483 * 3;
        case followers >= 25000 && followers <= 50000:
          return views > followers * 0.215 * 3;
        case followers >= 50000 && followers <= 100000:
          return views > followers * 0.198 * 3;
        case followers >= 100000 && followers <= 250000:
          return views > followers * 0.1518 * 3;
        case followers >= 250000 && followers <= 500000:
          return views > followers * 0.2192 * 3;
        case followers >= 500000 && followers <= 1000000:
          return views > followers * 0.2164 * 3;
        case followers >= 1000000:
          return views > followers * 0.4728 * 3;
        default:
          return false;
      }
    default:
      return false;
  }
}

export const getMinutesFromSeconds = (time: number) => {
  const minutes = Math.floor(time / 60);

  const seconds = time - minutes * 60;

  return `${minutes > 0 ? `${minutes}m ` : ""}${seconds}s`;
};
