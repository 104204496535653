import React from "react";
import { decorationColor } from "@utils/cardUtilities";
import FIcon from "@components/FIcon";
import { PostProps } from "@types";

export default function DecorationBadge({
  decoration,
}: {
  decoration?: string;
}) {
  return decoration ? (
    <div className="gap-1 z-10 pl-1 pr-3 py-1 flex items-center capitalize justify-center">
      <div
        className={`w-[14px] h-[14px] rounded-full flex items-center justify-center ${decorationColor(
          decoration
        )}`}
      >
        {(() => {
          switch (decoration) {
            case "accepted":
              return <FIcon icon="brief-checkmark" color="#fff" size={9} />;
            case "interested":
              return <FIcon icon="eye" color="#fff" size={12} />;
            default:
              return <FIcon icon="arrow-up" color="#fff" size={9} />;
          }
        })()}
      </div>
    </div>
  ) : null;
}

export function HorizontalDecorationBadge({
  decoration,
}: {
  decoration?: string;
}) {
  return decoration ? (
    <div
      className="absolute top-3 right-3 shadow-sm gap-1 z-10 px-3 py-1 flex items-center capitalize justify-center"
      style={{
        borderRadius: "var(--corner-round, 100px)",
        background: "var(--bg-default, #FFFAF9)",
        backdropFilter: "blur(4.915055751800537px)",
      }}
    >
      <div
        className={`w-[14px] h-[14px] rounded-full flex items-center justify-center ${decorationColor(
          decoration
        )}`}
      >
        {(() => {
          switch (decoration) {
            case "active":
              return <FIcon icon="brief-checkmark" color="#fff" size={9} />;
            case "proposal":
              return <FIcon icon="arrow-up" color="#fff" size={10} />;
            default:
              return <FIcon icon="arrow-up" color="#fff" size={9} />;
          }
        })()}
      </div>
      <p className="text-[15px] text-black drop-shadow-2xl">{decoration}</p>
    </div>
  ) : null;
}

const contentTypeBannerText = (post) => {
  if (
    post.platform === "instagram" &&
    (post.content_type === "image" || post.content_type === "video")
  ) {
    return "In-feed";
  } else if (
    post.platform === "instagram" ||
    post.platform === "tiktok" ||
    post.platform === "youtube"
  ) {
    return post.content_type.includes("_")
      ? post.content_type
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      : post.content_type.charAt(0).toUpperCase() + post.content_type.slice(1);
  } else {
    return "Post";
  }
};

export function ContentTypeBanner({
  post,
  permalink,
}: {
  post: PostProps;
  permalink: string;
}) {
  if (post.content_type) {
    return (
      <a
        href={permalink}
        target="_blank"
        className="flex items-center justify-center flex-row h-[24px] z-10 rounded-3xl bg-light_red border border-black"
      >
        <span className="inline-flex items-center justify-center pl-[10px] pr-[6px]">
          <p className="text-black  text-[12px] font-medium capitalize">
            {contentTypeBannerText(post)}
          </p>
        </span>
        <div className="flex items-center justify-center h-[24px] w-[24px] bg-black rounded-full">
          <FIcon icon={`${post.platform}`} size={16} color="#fff" />
        </div>
      </a>
    );
  }
  if (!post.content_type && post.platform) {
    return (
      <a
        href={permalink}
        target="_blank"
        className="flex items-center justify-center flex-row h-[30px] z-10"
      >
        <div className="flex items-center justify-center h-[30px] w-[30px]">
          <FIcon icon={`${post.platform}`} size={24} color="#fff" />
        </div>
      </a>
    );
  }
}
