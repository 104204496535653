import React, { ReactNode } from "react";
import FIcon from "../FIcon";
import { Link } from "react-router-dom";

export interface FormFieldProps {
  children?: ReactNode | undefined;
  className?: string;
  id?: string;
  label?: string;
  error?: string | string[];
  validate?: boolean;
  required?: boolean;
  success?: string;
  width?: number | string;
  helperLink?: { text: string; href: string };
  helperAction?: { text: string; onClick?: () => void };
  description?: string;
}

const FormField = ({
  id,
  className = "",
  label = "",
  width = 250,
  error = null,
  required,
  success = "",
  helperLink,
  helperAction,
  description,
  children,
}: FormFieldProps) => {
  return (
    <div
      className={className}
      style={{
        width: width,
      }}
    >
      <div className="flex flex-col">
        {label && (
          <div className="flex mb-[6px] justify-between items-center w-full">
            <div className="flex items-center">
              <label className="text-sm leading-[18px]" htmlFor={id}>
                {label}
                {required ? "*" : ""}
              </label>
            </div>
            {helperLink && (
              <Link
                to={helperLink.href}
                className="text-red text-xs"
                tabIndex={-1}
              >
                {helperLink.text}
              </Link>
            )}
            {helperAction ? (
              <p
                onClick={() => {
                  helperAction.onClick();
                }}
                className="text-red px-2 text-xs cursor-pointer hover:underline"
                tabIndex={-1}
              >
                {helperAction.text}
              </p>
            ) : null}
          </div>
        )}
        {children}
        {description && !error ? (
          <p className="text-xs mt-[6px]">{description}</p>
        ) : null}
        {error && typeof error === "string" && error.length > 0 && (
          <p
            className="text-red text-xs font-normal  tracking-[0.12px] mt-[6px]"
            dangerouslySetInnerHTML={{ __html: error }}
          ></p>
        )}
        {success && (
          <div className="flex items-center text-neutral_600 text-xs font-normal  tracking-[0.12px] mt-[6px]">
            <FIcon icon="success-input" size={16} color="#47B37F" />
            <p className="ml-[8px]">{success}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormField;
