import React from "react";
import { getFlagEmoji } from "../../utils/EmojiUtilities";

const CountriesTable = ({ countries = [], style = {} }) => (
  <table className="table w-full w-100" style={style}>
    <tbody>
      {countries.map((country, i) => (
        <tr
          className=" text-sm text-neutral_900"
          key={country.name}
          style={{
            borderBottom:
              i < countries.length - 1 ? "1px solid #E3E6ED" : "none",
          }}
        >
          <td>{getFlagEmoji(country.name)}</td>
          <td className="py-[8px] u-py-8">{country.name}</td>
          <td className="text-right">{Math.round(country.percentage)}%</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default CountriesTable;
