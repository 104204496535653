import React, { useEffect, useState } from "react";
import { getReportContentSummary } from "../../api/Reports";
import { formatNumber } from "@utils/number_utilities";
import StackedBarChart from "@components/Graphs/StackedBarChart";

const ReportContentSummary = ({ report_id }) => {
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState();

  useEffect(() => {
    const fetchOverview = async () => {
      setLoading(true);
      const response = await getReportContentSummary(report_id);
      setSummary(response.data);
      setLoading(false);
    };

    fetchOverview();
  }, [report_id]);

  const totalImpressions =
    summary && summary.organic_impressions && summary.paid_impressions
      ? summary.organic_impressions + summary.paid_impressions
      : 0;
  const totalPosts =
    summary && summary.organic_posts && summary.paid_posts
      ? summary.organic_posts + summary.paid_posts
      : 0;
  const totalEngagements =
    summary && summary.organic_engagements && summary.paid_engagements
      ? summary.organic_engagements + summary.paid_engagements
      : 0;

  const calculatePercentage = (value, total) => {
    return total > 0 ? (value / total) * 100 : 0;
  };

  if (loading || !summary) {
    return null;
  }

  return (
    <div>
      <h1 className="my-6">Content Summary</h1>
      <div className="flex justify-between">
        <div className="flex-1 text-center border-r border-[#EDEFF4] px-[16px]">
          <h3 className="text-xl">Followers</h3>
          <p className="text-2xl border-b border-[#EDEFF4] pb-4">
            {formatNumber(summary.followers)}
          </p>
          <div>
            <div className="flex justify-between">
              <div>
                <h4 className="text-lg font-medium pt-4">Organic Views</h4>
                <span className="text-sm text-gray-600">
                  {formatNumber(summary.organic_impressions)}
                </span>
              </div>
              <div>
                <h4 className="text-lg font-medium pt-4">Paid Views</h4>
                <span className="text-sm text-gray-600">
                  {formatNumber(summary.paid_impressions)}
                </span>
              </div>
            </div>
            {summary.organic_impressions && summary.paid_impressions && (
              <StackedBarChart
                data={[
                  {
                    name: "Organic Impressions",
                    percentage: calculatePercentage(
                      summary.organic_impressions,
                      totalImpressions
                    ),
                  },
                  {
                    name: "Paid Impressions",
                    percentage: calculatePercentage(
                      summary.paid_impressions,
                      totalImpressions
                    ),
                  },
                ]}
                showLegend={false}
                height={8}
                colors={["#59BBDB", "#FF6384"]}
              />
            )}
          </div>
        </div>
        <div className="flex-1 text-center border-r border-[#EDEFF4] px-[16px]">
          <h3 className="text-xl">Posts</h3>
          <p className="text-2xl border-b border-[#EDEFF4] pb-4">
            {formatNumber(summary.total_posts)}
          </p>
          <div className="flex justify-between">
            <div>
              <h4 className="text-lg font-medium pt-4">Organic Posts</h4>
              <span className="text-sm text-gray-600">
                {formatNumber(summary.organic_posts)}
              </span>
            </div>
            <div>
              <h4 className="text-lg font-medium pt-4">Paid Posts</h4>
              <span className="text-sm text-gray-600">
                {formatNumber(summary.paid_posts)}
              </span>
            </div>
          </div>
          {summary.organic_posts && summary.paid_posts && (
            <StackedBarChart
              data={[
                {
                  name: "Organic Posts",
                  percentage: calculatePercentage(
                    summary.organic_posts,
                    totalPosts
                  ),
                },
                {
                  name: "Paid Posts",
                  percentage: calculatePercentage(
                    summary.paid_posts,
                    totalPosts
                  ),
                },
              ]}
              showLegend={false}
              height={8}
              colors={["#1F8855", "#FF6384"]}
            />
          )}
        </div>
        <div className="flex-1 text-center px-[16px]">
          <h3 className="text-xl">Engagements</h3>
          <p className="text-2xl border-b border-[#EDEFF4] pb-4">
            {formatNumber(summary.engagements)}
          </p>
          <div className="flex justify-between">
            <div>
              <h4 className="text-lg font-medium pt-4">Organic Engagement</h4>
              <span className="text-sm text-gray-600">
                {formatNumber(summary.organic_engagements)}
              </span>
            </div>
            <div>
              <h4 className="text-lg font-medium pt-4">Paid Engagement</h4>
              <span className="text-sm text-gray-600">
                {formatNumber(summary.paid_engagements)}
              </span>
            </div>
          </div>
          <StackedBarChart
            data={[
              {
                name: "Organic Engagements",
                percentage: calculatePercentage(
                  summary.organic_engagements,
                  totalEngagements
                ),
              },
              {
                name: "Paid Engagements",
                percentage: calculatePercentage(
                  summary.paid_engagements,
                  totalEngagements
                ),
              },
            ]}
            showLegend={false}
            height={8}
            colors={["#FBD45B", "#FF6384"]}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportContentSummary;
