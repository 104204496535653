import { formatLargeNumber } from "@utils/number_utilities";
import Highcharts from "highcharts";

Highcharts.setOptions({
  chart: {
    backgroundColor: "transparent",
    type: "scatter",
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  title: {
    text: null,
  },
  xAxis: {
    title: {
      text: null,
    },
    labels: {
      style: {
        color: "#797E92",
        fontSize: "12",
      },
    },
  },
  yAxis: {
    tickColor: "#797E92",
    title: {
      text: null,
    },
    labels: {
      formatter: function () {
        return formatLargeNumber(parseInt(this.value as string, 10));
      },
      style: {
        color: "#797E92",
        fontSize: "12",
      },
    },
  },
  plotOptions: {
    scatter: {
      marker: {
        radius: 4,
        symbol: "circle",
        states: {
          hover: {
            enabled: true,
          },
        },
      },

      jitter: {
        x: 0.005,
      },
    },
  },
});
