import { ParticipantStatus } from "@types";

export const CREATOR_REVIEW_STATUSES = [
  {
    label: "Pending",
    value: ParticipantStatus.REVIEW_PENDING,
  },
  {
    label: "Approved",
    value: ParticipantStatus.REVIEW_APPROVED,
  },
  {
    label: "Rejected",
    value: ParticipantStatus.REVIEW_REJECTED,
  },
];
