import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { FCheckbox, FInput } from "@components/FInputs";

import { Group } from "@types";

import Modal from "@components/Modals/Modal";
import { createGroup, updateGroup } from "@api/Groups/Groups";
import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";

interface Props {
  entity?: Group;
  onClose: () => void;
  onUpdated?: (group: Group) => void;
  onCreated?: (group: Group) => void;
}

export default function CreateOrUpdateGroupModal({
  onClose,
  onUpdated,
  onCreated,
  entity,
}: Props) {
  const [loading, setLoading] = useState(false);

  const [isSubmitted, setSubmitted] = useState(false);

  const [values, setValues] = useState<Partial<Group>>({
    name: "",
    archived: false,
    featured: false,
  });

  const { can, user } = useAuth();

  useEffect(() => {
    if (entity) {
      setValues((values) => ({ ...values, ...entity }));
    }
  }, [entity]);

  const handleUpdate = (updates: Partial<Group>) =>
    setValues({ ...values, ...updates });

  const handleNext = async () => {
    setSubmitted(true);

    const { name, featured } = values;

    if (!name) {
      return;
    }

    setLoading(true);

    try {
      if (entity?.id) {
        const response = await updateGroup(entity.id, values);

        onUpdated(response.data);

        toast.success("Group Updated");
      } else {
        const response = await createGroup({
          name,
          brand_id: user.brand_id,
          user_id: user.user_id,
          featured,
        });

        onCreated(response.data);
      }
    } catch (e) {
      console.log("E", e);
      toast.error(`Error ${entity?.id ? "updating" : "creating"} group`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      actionLabel="Save"
      className="w-[500px] overflow-auto"
      isOpen
      onClose={onClose}
      onAction={handleNext}
      title={`${entity?.id ? "Edit" : "Add"} Group`}
      waiting={loading}
    >
      <div className="flex flex-col gap-6 m-auto w-full">
        <FInput
          name="name"
          onChange={(value) => handleUpdate({ name: value })}
          label="Group name"
          value={values.name}
          required
          validate={isSubmitted}
          width="100%"
        />
        {entity?.id ? (
          <FCheckbox
            label="Archived"
            id="group_modal-archived"
            onChange={(isChecked) =>
              handleUpdate({
                archived: isChecked,
              })
            }
            checked={values.archived}
          />
        ) : null}
        {can(Permission.FEATURE_GROUPS) ? (
          <FCheckbox
            label="Featured"
            id="group_modal-featured"
            onChange={(isChecked) =>
              handleUpdate({
                featured: isChecked,
              })
            }
            checked={values.featured}
          />
        ) : null}
      </div>
    </Modal>
  );
}
