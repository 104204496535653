import { updateUserNotification } from "@api/Notifications/UserNotifications";
import FButton from "@components/FButton";
import { UserNotification, UserNotificationStatus } from "@types";
import { formatDateMessage } from "@utils/DateUtilities";
import moment from "moment";

import * as React from "react";

interface NotificationProps {
  notification: UserNotification;
  className?: string;
  onNotificationUpdated: (updates: Partial<UserNotification>) => void;
}

export default function Notification({
  notification,
  className = "",
  onNotificationUpdated,
}: NotificationProps) {
  const handleUpdateNotification = async (
    updates: Partial<UserNotification>
  ) => {
    try {
      await updateUserNotification(notification.id, updates);

      onNotificationUpdated(updates);
    } catch (error) {
      console.error("Failed to update notification:", error);
    }
  };

  const highlightWords = (details) => {
    // Define common color codes
    const greenText = "text-[#1F8855]";
    const redText = "text-[#CB4039]";
    const blueNightSkyText = "text-[#000F45]";

    const statusMap = {
      approved: {
        color: greenText,
        label: "approved",
      },
      accepted: {
        color: greenText,
        label: "accepted",
      },
      soft_approved: {
        color: greenText,
        label: "brand approved",
      },
      client_review: {
        color: blueNightSkyText,
        label: "client review",
      },
      influencer_changes: {
        color: blueNightSkyText,
        label: "pending changes",
      },
      admin_review: {
        color: blueNightSkyText,
        label: "admin review",
      },
      admin_changes: {
        color: redText,
        label: "rejected - needs action",
      },
      viewed: {
        color: greenText,
        label: "viewed",
      },
      onboarding: {
        color: greenText,
        label: "onboarding",
      },
      rejected: {
        color: redText,
        label: "rejected",
      },
      lapsed: {
        color: redText,
        label: "lapsed",
      },
      declined: {
        color: redText,
        label: "declined",
      },
    };

    // Split the input details by spaces, map over the words, and apply styling
    return details.split(" ").map((word, index) => {
      const colorClass = statusMap[word]?.color || "";
      const classes = colorClass ? `font-medium ${colorClass}` : "";
      return (
        <span key={index} className={classes}>
          {statusMap[word]?.label || word}{" "}
        </span>
      );
    });
  };

  const { created_at: createdAt, status } = notification;

  return (
    <div
      className={`notification-container flex justify-between items-center bg-[#fff] p-[16px] mb-[16px] border border-neutral_300 rounded-lg ${className}`}
    >
      <div className="flex justify-between w-full gap-[8px]">
        <div className="flex">
          {notification.photo_url && (
            <div className="h-[101px] w-[101px] min-w-[101px] mr-[8px] rounded-lg overflow-hidden">
              <img src={notification.photo_url} alt="preview" />
            </div>
          )}
          <div>
            <p>
              <span className="font-medium">
                {notification.participant?.full_name}{" "}
              </span>
              {highlightWords(notification.details)}{" "}
              <span className="text-gray-500">
                {moment(createdAt).fromNow()}
              </span>
            </p>
            <div>
              <h4>
                {notification.campaign_name} |{" "}
                {createdAt ? formatDateMessage(new Date(createdAt)) : ""}
              </h4>
            </div>
            <div className="flex items-center gap-[6px] mt-[8px]"></div>
          </div>
        </div>
        <div className="flex items-center gap-[8px]">
          <a target="_blank" href={notification.link}>
            <FButton label="View" primary />
          </a>
          {status === UserNotificationStatus.UNREAD ? (
            <FButton
              label="Mark as Read"
              onClick={() =>
                handleUpdateNotification({
                  status: UserNotificationStatus.READ,
                })
              }
            ></FButton>
          ) : (
            <FButton
              label="Mark as Unread"
              onClick={() =>
                handleUpdateNotification({
                  status: UserNotificationStatus.UNREAD,
                })
              }
            ></FButton>
          )}
        </div>
      </div>
    </div>
  );
}
