import React from "react";

interface Props {
  width?: number;
  height?: number;
}

function FohrLogo({ width = 56, height = 20 }: Props) {
  return (
    <svg
      width={JSON.stringify(width)}
      height={JSON.stringify(height)}
      viewBox="0 0 56 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.652 7.42093L55.175 9.96472C54.4458 9.63667 53.7728 9.52679 53.2391 9.52679C50.8551 9.52679 49.7332 11.9604 49.7332 15.4061V20H46.9563V7.23026H49.7332V10.1007C50.4062 7.8596 51.7242 6.95654 53.4076 6.95654C54.3047 6.95654 55.2312 7.17522 55.652 7.42093Z"
        fill="#000F45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.6083 12.5835V20H39.8786V12.7783C39.8786 10.6664 38.5973 9.44442 36.9254 9.44442C35.2259 9.44442 33.1652 10.3885 33.1652 13.0837V20H30.4347V0H33.1652V8.99972C33.917 7.41648 36.0066 6.74998 37.3985 6.74998C40.6862 6.74998 42.6362 8.88861 42.6083 12.5835Z"
        fill="#000F45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.484 13.4381C23.484 11.0278 21.6766 9.42062 19.5518 9.42062C17.4264 9.42062 15.6469 11.0278 15.6469 13.4381C15.6469 15.8755 17.4264 17.5096 19.5518 17.5096C21.6766 17.5096 23.484 15.8755 23.484 13.4381ZM13.0437 13.4381C13.0437 9.47397 16.0188 6.95654 19.5518 6.95654C23.0842 6.95654 26.0871 9.47397 26.0871 13.4381C26.0871 17.4014 23.0842 20 19.5518 20C16.0188 20 13.0437 17.4014 13.0437 13.4381Z"
        fill="#000F45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.869629V20.0001H2.86041V11.8925H8.74658V9.28325H2.86041V3.4803H11.3043V0.869629H0Z"
        fill="#000F45"
      />
    </svg>
  );
}

export default FohrLogo;
