import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { ParticipantGroup } from "@types";

const getParticipantGroups = async (
  campaignId: string | number,
  title?: string,
  status?: string
): Promise<ApiResponse<{ participant_groups: ParticipantGroup[] }>> => {
  const queryParams = new URLSearchParams();

  // Add the campaignId to the query parameters
  queryParams.append("q[campaign_id_eq]", campaignId.toString());

  // Add other optional parameters to the query string
  if (title) {
    queryParams.append("q[title_i_cont]", title);
  }
  if (status) {
    queryParams.append("q[status_eq]", status);
  }

  return apiWrapper(`/participant_groups?${queryParams.toString()}`, "GET");
};

const createParticipantGroup = async (
  campaign_id: string | number,
  params: { parent_id?: number; name: string; status: string }
): Promise<ApiResponse<any>> => {
  return apiWrapper("/participant_groups", "POST", {
    ...params,
    campaign_id: campaign_id,
  });
};

const updateParticipantGroup = async (
  participant_group_id: string | number,
  params: any
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/participant_groups/${participant_group_id}`,
    "PUT",
    params
  );
};

export { getParticipantGroups, createParticipantGroup, updateParticipantGroup };
