import React, { useState } from "react";

import FInput from "./FInputs/FInput";
import RichTextEditor from "./RichTextEditor";

import FButton from "./FButton";
import FDropdown from "./FDropdown";
import { FCheckbox } from "./FInputs";

export default function FormConstructor({
  formValues,
  onClose,
  loading,
  initialData,
  onSubmit,
}) {
  const [values, setValues] = useState(initialData);

  const getNestedValue = (obj, path) => {
    return path.reduce(
      (current, part) =>
        current && current[part] !== undefined ? current[part] : undefined,
      obj
    );
  };

  const handleUpdate = (field, value) => {
    const { hasParent, name, type } = field;

    // Convert value to boolean for boolean fields
    const updatedValue =
      type === "select" && typeof value === "string"
        ? value.toLowerCase() === "true"
        : value;

    const updates = { [name]: updatedValue };

    if (hasParent) {
      setValues({
        ...values,
        [hasParent]: {
          ...values[hasParent],
          ...updates,
        },
      });
    } else {
      setValues({ ...values, ...updates });
    }
  };

  const renderField = (field) => {
    const { label, name, options, required, type } = field;

    const value = getNestedValue(values, name.split("."));

    if (type === "cms") {
      return (
        // Miha, please update
        <div>
          <label
            className="mb-[6px] inline-block text-sm font-medium leading-[18px]"
            htmlFor="notes"
          >
            {label}
          </label>
          <div className="bg-white rounded-lg">
            <RichTextEditor
              value={value}
              id="notes"
              onChange={(value) => handleUpdate(field, value)}
            />
          </div>
        </div>
      );
    } else if (field.type === "checkbox") {
      return (
        <FCheckbox
          className="w-full"
          label={label}
          id={name}
          onChange={(value) => handleUpdate(field, value)}
          checked={values.overwrite}
        />
      );
    } else if (field.type === "select") {
      return (
        <FDropdown
          width="100%"
          label={label}
          options={options.map((option) => ({
            value: String(option),
            label:
              String(option).charAt(0).toUpperCase() + String(option).slice(1),
          }))}
          selectedValue={String(value)}
          onChange={(value) => handleUpdate(field, value)}
        />
      );
    } else {
      return (
        <FInput
          label={label}
          required={required}
          value={value || ""}
          width="100%"
          onChange={(value) => handleUpdate(field, value)}
        />
      );
    }
  };

  return (
    <div className="flex flex-col gap-4">
      {formValues.map((field) => (
        <div key={field.id} className="w-full flex flex-col gap-2">
          {renderField(field)}
        </div>
      ))}
      <div className="flex justify-end space-x-2 mt-8 pb-3 sticky bottom-0 bg-light_red pt-4">
        <FButton onClick={onClose} label="Cancel" width="100%" height="40px" />
        <FButton
          onClick={() => onSubmit(values)}
          label="Save"
          loading={loading}
          width="100%"
          height="40px"
          primary
          type="submit"
        />
      </div>
    </div>
  );
}
