import React from "react";
import { FRadio } from "./FRadio";

interface Props {
  options: {
    label: string;
    value: string;
  }[];
  required?: boolean;
  name: string;
  onChange: (e: string) => void;
  style?: any;
  direction?: "row" | "column";
  value: string;
}

export const FRadioGroup = ({
  options = [],
  name = "",
  onChange,
  direction = "row",
  value,
  ...props
}: Props) => {
  return (
    <div
      style={{
        ...props?.style,
      }}
    >
      <div
        className="flex"
        style={{
          flexDirection: direction,
        }}
      >
        {options.map((o, i) => {
          console.log();
          return (
            <FRadio
              key={o.value}
              value={o.value}
              label={o.label}
              id={`${name}-${o.value}`}
              name={name}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              checked={o.value === value}
              style={{
                margin:
                  i !== options.length - 1
                    ? `0 ${direction === "row" ? "8" : "0"}px ${direction === "column" ? "8" : "0"}px 0`
                    : "0",
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
