import React from "react";
import AssetCard from "./HorizontalCardTypes.tsx/AssetCard";

interface AssetCardProps {
  id: string;
  cardType: string;
  className?: string;
  cardHeight?: number;
  cardWidth?: number;
  cover_image_url?: string;
  imageUrl?: string;
  brief?: {
    go_live_start?: Date;
    go_live_end?: Date;
  };
  decoration?: string;
  title?: string;
  participants_count?: number;
  date?: string;
  cardDropDownOptions?: { label: string; value: string }[];
  onOptionSelect?: (value: string, id: string) => void;
}

const HorizontalCard = (props: AssetCardProps) => {
  if (props.cardType === "asset") {
    return <AssetCard {...props} />;
  } else {
    return <AssetCard {...props} />;
  }
};

export default HorizontalCard;
