import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";

const getAmbassadorContent = async (params?: {
  q?: {
    creator_id_eq?: number;
  };
}): Promise<ApiResponse<any>> => {
  const queryParams = new URLSearchParams();

  if (params?.q?.creator_id_eq) {
    queryParams.append("q[creator_id_eq]", params.q.creator_id_eq.toString());
  }

  return apiWrapper(`/search/ambassador_content`, "GET");
};

export { getAmbassadorContent };
