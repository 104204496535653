export function useBriefData(campaignContext, params) {
  const { participant_id } = params;
  const { activeBrief, briefs = [], campaign } = campaignContext;

  const { participants, participant_groups: participantGroups } = campaign;

  const getPageName = () => {
    if (!activeBrief) return "Master Template";

    if (participant_id) {
      const participant = participants.find(
        (p) => p.id === parseInt(participant_id)
      );
      return participant?.creator
        ? `${participant.creator.firstName} ${participant.creator.lastName}`
        : "Unknown Participant";
    }

    if (activeBrief.participant_group_id) {
      const group = participantGroups?.find(
        (group) => group.id === activeBrief.participant_group_id
      );
      return group?.name || `Group ${activeBrief.participant_group_id}`;
    }

    return "Master Template";
  };

  const pageName = getPageName();

  const getGroupBriefDetails = () => {
    if (!participant_id) return {};

    const participant = participants.find(
      (p) => p.id.toString() === participant_id
    );
    const participantFirstName = participant?.creator?.firstName;
    const participantGroupId = participant?.participant_group_id;
    const participantGroup = participantGroups.find(
      (group) => group.id === participantGroupId
    );
    const groupBriefName = participantGroupId
      ? participantGroup?.name || `Group ${participantGroupId}`
      : "Master Template";
    const groupBriefId = participantGroupId
      ? briefs.find((b) => b.participant_group_id === participantGroupId)?.id
      : briefs.find((b) => b.participant_group_id === null)?.id;

    return { participantFirstName, groupBriefName, groupBriefId };
  };

  return {
    pageName,
    groupBriefDetails: getGroupBriefDetails(),
  };
}
