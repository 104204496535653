import React, { useContext, useState } from "react";

import { CampaignDeliverable } from "@types";

import ConfirmModal from "@components/Modals/ConfirmModal";

import SinglePageLayout from "@layouts/SinglePageLayout";
import { CampaignContext } from "@contexts/index";
import FIcon from "@components/FIcon";
import { platformList } from "@constants/constants";
import { capitalize } from "lodash";

import FButton from "@components/FButton";
import AddDeliverableModal from "./components/AddDeliverableModal";
import { deleteCampaignDeliverable } from "@api/Campaign/CampaignDeliverables";
import toast from "react-hot-toast";
import { ButtonDropDown } from "@components/ButtonDropDown";

const cardDropDownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Delete", value: "delete" },
];

const TABLE_COLUMNS = [
  {
    key: "platform",
    label: "",
    getContent: (deliverable: CampaignDeliverable) => {
      const { platform } = deliverable;

      const { icon } =
        platformList.find(({ value }) => platform === value) || {};

      return <FIcon icon={icon} size={16} color="#000721" />;
    },
  },
  {
    key: "name",
    label: "Deliverable Name",
  },
  {
    key: "media_type",
    label: "Type",
    getValue: (deliverable: CampaignDeliverable) => {
      return capitalize(deliverable.media_type);
    },
  },
  {
    key: "hashtags",
    label: "Hashtags",
  },
  {
    key: "mentions",
    label: "Mentions",
  },
  {
    key: "tagged_users",
    label: "Tagged users",
  },
];

const CampaignDeliverablesPage = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [deliverableToEdit, setDeliverableToEdit] =
    useState<CampaignDeliverable>(null);

  const [deliverablesToDelete, setDeliverablesToDelete] =
    useState<CampaignDeliverable[]>(null);
  const [isDeleting, setDeleting] = useState(false);

  const [isUpdateDeliverableModalVisible, setUpdateDeliverableModalVisible] =
    useState(false);

  const { campaign, setCampaign } = useContext(CampaignContext);

  const { deliverables = [] } = campaign;

  const handleBulkAction = (value) => {
    if (value === "remove") {
      setDeliverablesToDelete(
        selectedItems.map((id) =>
          deliverables.find((deliverable) => id === deliverable.id)
        )
      );
    }
  };

  const handleDelete = async () => {
    const successfullyDeleted = [];

    setDeleting(true);

    for (const deliverable of deliverablesToDelete) {
      try {
        await deleteCampaignDeliverable(deliverable.id);

        successfullyDeleted.push(deliverable.id);
      } catch (e) {
        toast.error(`Error archiving deliverable ${deliverable.name}`);
      }
    }

    setDeleting(false);

    setDeliverablesToDelete(null);

    const newDeliverables = deliverables.filter(
      (deliverable) => !successfullyDeleted.includes(deliverable.id)
    );

    setCampaign({
      ...campaign,
      deliverables: newDeliverables,
    });
  };

  const handleTableRowDropdownSelect = (value: string, id: number) => {
    const deliverable = deliverables.find((item) => item.id === id);

    if (value === "edit") {
      setDeliverableToEdit(deliverable);
      setUpdateDeliverableModalVisible(true);
    } else if (value === "delete") {
      setDeliverablesToDelete([
        deliverables.find((deliverable) => deliverable.id === id),
      ]);
    }
  };

  const tableColumns = TABLE_COLUMNS.map((column) => {
    if (column.key === "name") {
      return {
        ...column,
        getContent: (deliverable: CampaignDeliverable) => (
          <span
            className="cursor-pointer"
            onClick={() => {
              setDeliverableToEdit(deliverable);
              setUpdateDeliverableModalVisible(true);
            }}
          >
            {deliverable.name}
          </span>
        ),
      };
    }

    return column;
  });

  return (
    <>
      <div className="flex justify-between items-center pb-10 pt-[40px] mb-[40px] border-b border-light_border">
        <h2 className="text-2xl">Deliverables ({deliverables.length})</h2>
        {deliverables.length > 0 ? (
          <div className="flex gap-4">
            <FButton
              primary
              onClick={() => setUpdateDeliverableModalVisible(true)}
              label="Add deliverable"
              type="button"
              iconLeft={{
                name: "add",
                size: 12,
                color: "#fff",
                className: "mr-2",
              }}
            />
          </div>
        ) : null}
      </div>
      <SinglePageLayout
        cardData={deliverables}
        pageName="Deliverables"
        tableOnly
        sortPathName="name"
        sortPathDate="created_at"
        tableColumns={tableColumns}
        rowActions={cardDropDownOptions}
        cardDropDownOptions={cardDropDownOptions}
        createWithEntityCrud={() => setUpdateDeliverableModalVisible(true)}
        subNavMainButton={
          selectedItems.length > 0 && (
            <ButtonDropDown
              zIndex={99999}
              label={`Edit (${selectedItems.length})`}
              options={[{ label: "Remove", value: "remove" }]}
              onChange={(value) => handleBulkAction(value)}
            />
          )
        }
        selectedItems={selectedItems}
        onChangeSelectedItems={setSelectedItems}
        onTableRowDropDownSelect={handleTableRowDropdownSelect}
      />
      {isUpdateDeliverableModalVisible ? (
        <AddDeliverableModal
          campaign={campaign}
          deliverable={deliverableToEdit}
          onClose={() => setUpdateDeliverableModalVisible(false)}
          onUpdateCampaign={setCampaign}
        />
      ) : null}
      {deliverablesToDelete ? (
        <ConfirmModal
          title="Delete deliverable"
          isOpen
          onClose={() => setDeliverablesToDelete(null)}
          onAction={handleDelete}
          actionLabel="Delete"
          isWaiting={isDeleting}
          subtitle={`Are you sure you want to delete ${deliverablesToDelete.length === 1 ? "this deliverable" : "these deliverables"}?`}
        />
      ) : null}
    </>
  );
};

export default CampaignDeliverablesPage;
