import FButton from "@components/FButton";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import CommentInput from "@components/comments/CommentInput";
import Comment from "@components/comments/Comment";
import { useParams } from "react-router-dom";
import {
  createCreatorReviewComment,
  deleteCreatorReviewComment,
  getCreatorReviewComments,
  updateCreatorReviewComment,
} from "@api/CreatorReview/Comments";

import LoadingSpinner from "@components/LoadingSpinner";

const CreatorReviewComments = () => {
  const { participant_id } = useParams();

  const [comments, setComments] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [commentEditedId, setCommentEditedId] = useState<number>(null);
  const [pendingComment, setPendingComment] = useState<string>(null);
  const [isEditCommentSubmitted, setIsEditCommentSubmitted] = useState(false);

  const [deletingCommentId, setDeletingCommentId] = useState<number>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        // Fetch comments
        const commentsResponse = await getCreatorReviewComments(
          parseInt(participant_id, 10)
        );

        setComments(commentsResponse.data.comments);
      } catch (error) {
        console.error("Failed to fetch data:", error);

        setComments([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [participant_id]);

  const handleAddComment = async () => {
    setIsSubmitted(true);

    if (!pendingComment) {
      return;
    }

    try {
      const response = await createCreatorReviewComment({
        body: pendingComment,
        participant_id: parseInt(participant_id),
      });

      const newComment = response.data;

      setPendingComment("");

      toast.success("Comment added");

      setIsSubmitted(false);

      setComments([newComment, ...comments]);
    } catch {
      toast.error("Error adding comment");
    }
  };

  const handleCommentUpdated = (id: number, body: string) => {
    const newComments = comments.map((comment) => {
      if (id === comment.id) {
        return {
          ...comment,
          body,
        };
      }

      return comment;
    });

    setComments(newComments);
  };

  const handleCommentDeleted = (id: number) => {
    const newComments = comments.filter((comment) => id !== comment.id);

    setComments(newComments);
  };

  const handleSaveCommentUpdates = async () => {
    if (!pendingComment) {
      setIsEditCommentSubmitted(true);

      return;
    }

    try {
      await updateCreatorReviewComment(commentEditedId, {
        body: pendingComment,
      });

      handleCommentUpdated(commentEditedId, pendingComment);

      setCommentEditedId(null);
      setPendingComment("");

      toast.success("Comment updated");
    } catch (e) {
      toast.error("Error updating comment");
    }
  };

  const handleEditComment = (comment) => {
    setCommentEditedId(comment.id);

    setPendingComment(comment.body);
  };

  const handleDeleteComment = async (comment) => {
    setDeletingCommentId(comment.id);

    try {
      await deleteCreatorReviewComment(comment.id);

      toast.success("Comment deleted");
    } catch (e) {
      toast.error("Error deleting comment");
    } finally {
      setDeletingCommentId(null);
    }

    handleCommentDeleted(comment.id);
  };

  if (isLoading) {
    return <LoadingSpinner className="h-[400px] w-full" />;
  }

  return (
    <div className="pt-8">
      {comments.length > 0 ? (
        comments.map((comment) => {
          if (commentEditedId === comment.id) {
            return (
              <div className="my-8">
                <CommentInput
                  pendingComment={pendingComment}
                  isSubmitted={isEditCommentSubmitted}
                  onChange={setPendingComment}
                  onSubmit={handleSaveCommentUpdates}
                />
                <FButton
                  label="Cancel"
                  onClick={() => {
                    setPendingComment(null);
                    setIsEditCommentSubmitted(false);
                    setCommentEditedId(null);
                  }}
                />
              </div>
            );
          }

          return (
            <Comment
              comment={comment}
              isDeleting={deletingCommentId === comment.id}
              onDelete={() => handleDeleteComment(comment)}
              onEdit={() => handleEditComment(comment)}
            />
          );
        })
      ) : (
        <div className="flex items-center justify-center w-full my-8">
          <span className="text-neutral_400">No comments yet</span>
        </div>
      )}
      {!commentEditedId ? (
        <CommentInput
          pendingComment={pendingComment}
          isSubmitted={isSubmitted}
          onChange={setPendingComment}
          onSubmit={handleAddComment}
        />
      ) : null}
    </div>
  );
};

export default CreatorReviewComments;
