import { updateContentReviewAsset } from "@api/Campaign/ContentReview";
import FButton from "@components/FButton";
import { FInput } from "@components/FInputs";
import Modal from "@components/Modals/Modal";
import { ContentReviewAsset } from "@types";
import { useEffect, useState } from "react";

interface UpdateUploadedAssetModalProps {
  asset: ContentReviewAsset;
  onClose: () => void;
  onUpdate: (updates: { position: number }) => void;
}

const UpdateUploadedAssetModal = ({
  asset,
  onClose,
  onUpdate,
}: UpdateUploadedAssetModalProps) => {
  const [position, setPosition] = useState(0);

  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    setPosition(asset.position || 0);
  }, []);

  const handleSubmit = async () => {
    setSaving(true);

    await updateContentReviewAsset(asset.id, { asset: { position } });

    setSaving(false);

    onUpdate({ position });
  };

  return (
    <Modal
      className="w-[550px] max-h-screen"
      title="Reorder asset"
      hasForm
      zIndex={9999}
      onClose={onClose}
      isOpen
    >
      <>
        <p className="mb-4 text-sm text-default_weak">
          This is the order in which the content will appear in the carousel
          when reviewed. The asset with position value of 1 will be displayed
          first.
        </p>
        <FInput
          label="Position"
          value={`${position || ""}`}
          onChange={(value) => setPosition(value ? parseInt(value, 10) : 0)}
          type="number"
          width="100%"
        />
        <div className="flex mt-8 justify-end space-x-2 w-full">
          <FButton
            onClick={onClose}
            label="Cancel"
            width="100%"
            height="40px"
          />
          <FButton
            onClick={handleSubmit}
            loading={isSaving}
            label="Submit"
            width="100%"
            height="40px"
            primary
          />
        </div>
      </>
    </Modal>
  );
};

export default UpdateUploadedAssetModal;
