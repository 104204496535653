import ProfileAudienceDemographics from "./audience/ProfileAudienceDemographics";
import ProfileBasics from "./Basics";
import ProfileContent from "./content";
import ProfileDetails from "./ProfileDetails";
import ProfileResponses from "./ProfileResponses";
import ProfileStatsPage from "./stats/ProfileStats";

const PROFILE_ROUTES = [
  {
    label: "Basics",
    value: "basics",
    component: ProfileBasics,
  },
  {
    label: "Content",
    value: "content",
    component: ProfileContent,
  },

  { label: "Stats", value: "stats", component: ProfileStatsPage },
  {
    label: "Audience",
    value: "audience_demographics",
    component: ProfileAudienceDemographics,
  },
  /*  {
      label: "Testimonials",
      value: "testimonials",
      link: `testimonials`,
      component: ProfileTestimonials,
    }, */
  {
    label: "Details",
    value: "details",
    component: ProfileDetails,
  },
  {
    label: "Responses",
    value: "ambassador_responses",
    component: ProfileResponses,
  },
];

export default PROFILE_ROUTES;
