import React, { useState } from "react";

import { FInput } from "../../components/FInputs";
import FButton from "../../components/FButton";

const PrivacySettings = () => {
  const [values, setValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    title: "",
  });

  const formValueField = [{ label: "Email", name: "email", type: "email" }];

  return (
    <div className="max-w-[800px]">
      <div className="py-[40px]">
        <h1 className="text-[24px] mb-[24px]">GDPR Privacy Policy</h1>
        <p>
          To make our platform useful to you, we keep records of all of the
          things you do on Fohr, including all of your groups, briefs, outreach
          and reports. We don't share that data with anyone - ever. Only you
          have control over who you choose to share that data with. As long as
          you have a Fohr account, we'll keep your information on file. Got
          questions about any of this? Shoot us an email at
          <a href="mailto:support@fohr.co">support@fohr.co</a>
          and we'll be more than happy to help.
        </p>
      </div>
      {
        // below needs a test for if the user is an admin and/or eu-based to be displayed
      }
      <div className="py-[40px]">
        <h1 className="text-[24px] mb-[24px]">
          Request a record of all of the content you've created on Fohr
        </h1>
        <p>
          We'll email you a link to a file with all of your basic profile
          information, as well as all of your groups, briefs, outreach, and
          reports that you've created on Fohr. It may take 30 business days for
          us to collect this data and send it to you.
        </p>

        {formValueField.map(({ name, label }) => (
          <FInput
            className="mb-[16px] mt-[32px] max-w-[300px]"
            key={name}
            required
            label={label}
            width="100%"
            value={values[name]}
            onChange={(value) => setValues({ ...values, [name]: value })}
          />
        ))}
        <div className="mt-[16px] max-w-[300px]">
          <FButton
            primary={true}
            label="Request Content Record"
            width="100%"
            type="submit"
          />
        </div>
      </div>
    </div>
  );
};

export default PrivacySettings;
