import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { maxBy, uniq } from "lodash";
import { DemographicsBreakdownItem } from "@types";

import WidgetContainer from "./WidgetContainer";

const getOptions = (showCounts) => ({
  chart: {
    height: 300,
  },
  tooltip: {
    pointFormatter: function () {
      return `<b>${this.y}%</b>${showCounts ? ` (${this.count} user${this.count === 1 ? "" : "s"})` : ``}`;
    },
  },
  xAxis: {
    type: "category",
  },
  yAxis: {
    labels: {
      formatter: function () {
        return `${this.value}%`;
      },
      style: {
        color: "#797E92",
        fontSize: "12",
      },
    },
  },
});

interface AgeBreakdownChartProps {
  items: DemographicsBreakdownItem[];
  showCounts?: boolean;
}

export default function AgeBreakdownChart({
  items,
  showCounts,
}: AgeBreakdownChartProps) {
  const maxValueItem = maxBy(items, "percentage");

  const series = [
    {
      categories: uniq(items.map((item) => item.name)),
      data: items.map(({ name, percentage, count }) => ({
        name,
        y: percentage,
        color: name === maxValueItem.name ? "#1F8855" : "#C9CDD9",
        count,
      })),
      name: "Age range percentage",
      type: "column",
    },
  ];

  const options = {
    ...getOptions(showCounts),
    series,
  };

  return (
    <WidgetContainer title="Age" value={maxValueItem?.name}>
      <HighchartsReact highcharts={Highcharts} options={options} width="100%" />
    </WidgetContainer>
  );
}
