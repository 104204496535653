import React from "react";

interface LegendProps {
  labels: { color: string; label: string }[];
  spacing?: number;
}

export default function Legend({ labels = [], spacing = 2 }: LegendProps) {
  return (
    <div
      className={`grid  gap-${spacing} justify-center`}
      style={{
        gridTemplateColumns: labels?.length > 2 ? "repeat(2, 1fr)" : "1fr",
        margin: "0 auto",
        width: "max-content",
      }}
    >
      {labels.map((item, index) => (
        <div
          className="text-sm text-neutral_900 items-center font-light flex gap-2"
          key={index}
        >
          <span
            className="h-[12px] w-[12px]"
            style={{ backgroundColor: item.color }}
          />
          <span className="mr-[8px]">{item.label}</span>
        </div>
      ))}
    </div>
  );
}
