import React, { useContext } from "react";
import { DashboardContext } from "@contexts/DashboardProvider";
import PostSnapshot from "./components/PostSnapshot";
import GeneralPostModal from "@components/Modals/GeneralPostModal";
import PostSnapshotSkeleton from "./components/PostSnapshotSkeleton";

export default function Content() {
  const {
    isLoading,
    campaignContent,
    activePost,
    contentModalOpen,
    handleContentModalClick,
    handleContentModalClose,
  } = useContext(DashboardContext);

  if (isLoading) {
    return (
      <div className="space-y-8 pt-10">
        <PostSnapshotSkeleton maxPosts={50} title="Campaign Content" />
      </div>
    );
  }

  return (
    <div className="space-y-8 pt-10">
      <PostSnapshot
        campaignContent={campaignContent}
        isLoading={isLoading}
        title="Campaign Content"
        maxPosts={50}
        contentType="latest"
        onCardClick={handleContentModalClick}
      />

      {activePost && (
        <GeneralPostModal
          post={activePost}
          isOpen={contentModalOpen}
          onClose={handleContentModalClose}
        />
      )}
    </div>
  );
}
