import React, { useCallback, useContext } from "react";

import { Sort } from "@types";
import { listAmbassadors } from "@api/Ambassadors/Ambassadors";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import BlankState from "@components/BlankState";
import { useNavigate } from "react-router-dom";
import { AmbassadorsContext } from "@contexts/index";

const cardDropDownOptions = [{ label: "Profile", value: "view_profile" }];

const tableColumns = [
  {
    key: "full_name",
    label: "Name",
    isLink: true,
    linkURL: "/card/{slug}",
    isImage: true,
    imageType: "profile" as "asset" | "profile",
    imageKey: "profile_image_url",
  },
  { key: "gender", label: "Gender" },
  { key: "location", label: "Location" },
  { key: "overall_followers", label: "Followers" },
  { key: "created_at", label: "Added", isDate: true },
  { key: "status", label: "Status", isStateBubble: true },
];

const AmbassadorsList = ({ status }: { status: string }) => {
  const { brand } = useContext(AmbassadorsContext);

  const navigate = useNavigate();

  const getAmbassadors = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;

      const data = await listAmbassadors(page, sort, {
        status_eq: filter || status,
        creator_firstName_or_creator_lastName_or_creator_slug_cont: search,
      });

      const { ambassadors: items } = data;

      return {
        ...data,
        items: items.map((item) => {
          const { creator } = item;

          return {
            ...item,
            ...item.creator,
            full_name: `${creator.firstName} ${creator.lastName}`,
          };
        }),
      };
    },
    [status]
  );

  const handleCreateSettings = () =>
    navigate("/ambassadors/onboarding-settings");

  if (!brand?.amp_description) {
    return (
      <BlankState
        title="No onboarding settings found"
        subtitle="Create onboarding settings to start accepting applicants"
        icon="user"
        onActionClick={handleCreateSettings}
        actionLabel="Create Settings"
      />
    );
  }

  return (
    <SinglePageLayoutWithData
      entityName="ambassador"
      pageName="Ambassadors"
      cardType="individual"
      sortPathName="full_name"
      sortPathDate="created_at"
      filterPath="state"
      tableColumns={tableColumns}
      cardDropDownOptions={cardDropDownOptions}
      rowActions={cardDropDownOptions}
      createWithEntityCrud={() => {}}
      getItems={getAmbassadors}
    />
  );
};

export default AmbassadorsList;
