import { useEffect, useRef, useState } from "react";

export function useIntersectionObserver() {
  const [activeSection, setActiveSection] = useState("description");

  const sectionRefs = {
    description: useRef(null),
    about: useRef(null),
    images: useRef(null),
    creative_direction: useRef(null),
    products: useRef(null),
    deliverables: useRef(null),
    guidelines: useRef(null),
    contract: useRef(null),
    payment: useRef(null),
    settings: useRef(null),
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { rootMargin: "-50% 0px -50% 0px", threshold: 0 }
    );

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [sectionRefs]);

  const handleNavClick = (section) => {
    console.log("Section", section);
    sectionRefs[section].current?.scrollIntoView({ behavior: "smooth" });
    setActiveSection(section);
  };

  return { activeSection, sectionRefs, handleNavClick };
}
