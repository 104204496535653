// Refactor

import React, { useState, useEffect, useRef } from "react";
import FIcon from "./FIcon";
import { FInput } from "./FInputs";

export interface FDropdownOption {
  value: string | number;
  label: string;
  static?: string;
}

interface DropdownProps {
  dark?: boolean;
  className?: string;
  selectedValue?: string | number | boolean;
  onChange?: (val: string | number | boolean, event?: React.MouseEvent) => void;
  width?: number | string;
  options: FDropdownOption[];
  style?: any;
  iconColor?: string;
  iconOnly?: boolean;
  disabled?: boolean;
  leftLabel?: string;
  icon?: string;
  height?: string | number;
  titleDropDown?: boolean; // Makes the dropdown act as a title
  label?: string;
  required?: boolean;
  inputName?: string;
  zIndex?: number;
  hasNoDefault?: boolean;
  menuUp?: boolean;
  hardClose?: boolean;
  placeholder?: string;
  transparent?: boolean;
  validate?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const FDropdown = ({
  hasNoDefault,
  className = "",
  options,
  selectedValue,
  onChange,
  placeholder,
  width = 202,
  iconOnly = false,
  leftLabel = "",
  disabled = false,
  iconColor = "#000F45",
  icon = "",
  height = 40,
  titleDropDown = false,
  label = "",
  hardClose = false,
  required = false,
  menuUp = false,
  inputName = "",
  zIndex = 1,
  transparent = false,
  validate,
  onOpenChange,
  ...props
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropDownTop, setDropDownTop] = useState("44px");

  const [searchTerm, setSearchTerm] = useState("");

  const dropdownRef = useRef<HTMLDivElement>(null);

  const displayedOption = options.find((o) => o.value === selectedValue);

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
    onOpenChange && onOpenChange(isOpen);
  };

  useEffect(() => {
    const numericHeight = Number(height);
    const calculatedTop = isNaN(numericHeight)
      ? "44px"
      : `${numericHeight + 4}px`;
    setDropDownTop(calculatedTop);
  }, [height]);

  if (disabled) {
    iconColor = "#C9CDD9"; // disabled iconColor
  }

  const handleToggleDropdown = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!disabled) {
      handleOpenChange(!isOpen);
    }
  };

  const handleOptionClick = (
    event: React.MouseEvent,
    value: string | number
  ) => {
    event.stopPropagation();

    onChange(value, event);
    handleOpenChange(false);
  };

  useEffect(() => {
    if (hardClose) {
      handleOpenChange(false);
    }
  }, [hardClose]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        handleOpenChange(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  const optionsMatchingSearch = searchTerm
    ? options.filter(
        ({ label }) =>
          label && label.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : options;

  const showSearch = options.length > 25;

  return (
    <div
      ref={dropdownRef}
      className={`${className} flex ${
        leftLabel ? "flex-row items-center" : "flex-col items-center"
      } ${isOpen ? "z-50" : ""}`}
      style={{
        width: window.innerWidth < 640 ? "100%" : width,
        ...props.style,
      }}
    >
      {leftLabel && (
        <label className=" text-sm font-normal mr-[12px] whitespace-nowrap">
          {leftLabel}
        </label>
      )}
      {label && (
        <div className="flex mb-[6px] justify-between items-center w-full">
          <div className="flex items-center">
            <label className="text-sm leading-[18px]" htmlFor={inputName}>
              {label}
              {required ? "*" : ""}
            </label>
          </div>
        </div>
      )}
      <div
        className={`relative w-full  ${
          isOpen ? "" : ""
        } ${transparent || iconOnly ? "bg-transparent" : "bg-white"}`}
      >
        <div
          className={`flex items-center w-full gap-2 ${
            height ? `h-[${height}px]` : "h-[40px]"
          }
            ${
              iconOnly
                ? "bg-transparent border-none"
                : "bg-transparent border border-neutral_400"
            }
            ${
              disabled
                ? "text-neutral_500"
                : "cursor-pointer hover:border-neutral_600"
            }
            ${
              titleDropDown
                ? "border-none px-[0px] w-auto justify-start"
                : "justify-between px-[12px]"
            }
            ${isOpen ? "border-neutral_600" : ""}`}
          onClick={handleToggleDropdown}
        >
          {icon && (
            <FIcon
              icon={icon}
              size={14}
              color={iconColor}
              className="mr-1 -mt-[2px]"
            />
          )}
          {titleDropDown ? (
            <h3 className="font-sofia-pro text-dark mr-2">
              {displayedOption?.label}
              {options[0].static ? ` ${options[0].static}` : ""}
            </h3>
          ) : iconOnly ? (
            ""
          ) : (
            <span className=" text-sm font-normal">
              {displayedOption?.label || placeholder}
            </span>
          )}
          {!iconOnly && (
            <FIcon
              icon="chevron"
              size={10}
              color={!disabled ? "#000F45" : "#C9CDD9"}
              className={`-mt-[2px] ${isOpen ? "rotate-180" : ""}`}
            />
          )}
        </div>
        {isOpen && (
          <div
            className={`bg-white absolute border border-neutral_400 cursor-pointer  text-sm font-normal ${showSearch ? "" : "overflow-y-auto"} ${menuUp ? `bottom-[20px]` : `top-[${dropDownTop}]`} ${
              iconOnly ? "right-0" : "left-0"
            }`}
            style={{
              boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.05)",
              whiteSpace: "nowrap",
              zIndex: zIndex || 60,
              minWidth: "100%",
              maxHeight: "400px",
              width: "max-content",
            }}
          >
            {showSearch ? (
              <div className="p-2 h-[50px]">
                <FInput
                  value={searchTerm}
                  placeholder="Search..."
                  width="100%"
                  type="text"
                  onChange={setSearchTerm}
                  required
                />
              </div>
            ) : null}
            <div
              className={`flex flex-col flex-nowrap ${showSearch ? " overflow-y-auto h-[350px]" : ""}`}
            >
              {optionsMatchingSearch.map((o, index) => (
                <div
                  key={index}
                  className="px-[12px] py-[8px] hover:bg-neutral_100 text-[15px] whitespace-nowrap"
                  onClick={(e) => handleOptionClick(e, o.value)}
                >
                  {o.label}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {validate && required && !selectedValue && (
        <p
          className="bg-transparent text-red text-xs font-normal  tracking-[0.12px] mt-[6px] self-start"
          dangerouslySetInnerHTML={{ __html: `${label} is required` }}
        ></p>
      )}
    </div>
  );
};

export default FDropdown;
