import React from "react";
import VerticalCard from "./CardTypes/VerticalCard";
import HorizontalCard from "./CardTypes/HorizontalCard";
import { Card } from "@types";
import { PostProps } from "@types";
import { Creator } from "@apiTypes";

interface BasicCardProps {
  pageName: string;
  id: string;
  cardType: string;
  className?: string;
  title?: string;
  cardHeight?: number;
  cardWidth?: number;
  ownerUser?: string;
  company?: string;
  influencers?: number;
  date?: string;
  state?: string;
  imageUrl?: string;
  decoration?: string;
  statHelperText?: string;
  checkBoxClickList: number[];
  checkbox: boolean;
  statIcon?: string;
  icons?: { icon: string; action: () => void }[];
  cardDropDownOptions?: { label: string; value: string }[];
  onCheckBoxClick: (id: number) => void;
  onOptionSelect?: (value: string) => void;
  onCardClick: (item: any) => void;
}

export interface IndividualCardProps extends Card {
  cardType: string;
  className?: string;
  cardHeight?: number;
  cardWidth?: number;
  decoration?: string;
  decorationPath?: string;
  statHelperText?: string;
  statIcon?: string;
  dropdownOptions?: { label: string; value: string }[];
  checkBoxClickList: number[];
  checkbox: boolean;
  onCheckBoxClick: (id: number) => void;
  onOptionSelect?: (value: string) => void;
  creator?: Creator;
  profile_image_url?: string;
  onCardClick: (item: any) => void;
}

interface ContentCardProps extends IndividualCardProps {
  isProfile?: boolean;
  imageOnly?: boolean;
  isPreview?: boolean;
  stats?: {
    label: string;
    value: number;
    icon?: {
      name: string;
      size: number;
    };
  }[];
  topLeft?: any;
  style?: any;
  contentModalClick?: (content: PostProps) => void;
  onViewStatsScreenshot: () => void;
}

const BasicCard = (props: BasicCardProps) => {
  const renderCardType = () => {
    switch (props.cardType) {
      case "participant":
        return <VerticalCard {...(props as unknown as IndividualCardProps)} />;
      case "individual":
        return <VerticalCard {...(props as unknown as IndividualCardProps)} />;
      case "asset":
        return <HorizontalCard {...(props as BasicCardProps)} />;
      case "content":
        return <VerticalCard {...(props as unknown as ContentCardProps)} />;
      case "contentReview":
        return <VerticalCard {...(props as unknown as ContentCardProps)} />;
      default:
        return <HorizontalCard {...(props as BasicCardProps)} />;
    }
  };

  return renderCardType();
};

export default BasicCard;
