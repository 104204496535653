import React from "react";

import SocialConnectButton from "@pages/auth/influencer-onboarding/social/SocialConnectButton";

interface InstagramConnectButtonProps {
  isConnected: boolean;
  iconOnly: boolean;
  onClick: () => void;
}

const InstagramConnectButton = ({
  isConnected,
  iconOnly,
  onClick,
}: InstagramConnectButtonProps) => {
  const connectInstagram = async () => {
    try {
      // Step 1: Get the current page URL to pass as originUrl

      // Step 2: Redirect the user to Instagram's OAuth page to authorize your app
      const clientId = "1225636931264268";
      const redirectUri = encodeURIComponent(
        `https://frontend.staging.fohr.co/api/v1/instagram_oauth/callback?origin=creator-onboarding`
      ); // Add originUrl to redirectUri
      console.log(redirectUri);
      const instagramOAuthUrl = `https://www.facebook.com/v14.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=instagram_basic,pages_show_list,pages_read_engagement,business_management`;

      // Redirect the user to the Instagram OAuth URL
      window.location.href = instagramOAuthUrl;
    } catch (error) {
      console.error("Failed to connect to Instagram:", error);
    }
  };

  return (
    <SocialConnectButton
      onClick={() => (isConnected ? onClick() : connectInstagram())}
      name="Instagram"
      icon="instagram"
      iconOnly={iconOnly}
      isConnected={isConnected}
    />
  );
};

export default InstagramConnectButton;
