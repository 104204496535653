import React from "react";

interface SkeletonLoaderProps {
  type: "text" | "metric" | "sparkline";
  width?: string;
  height?: string;
}

export default function SkeletonLoader({
  type,
  width,
  height,
}: SkeletonLoaderProps) {
  const baseClasses =
    "animate-pulse bg-gradient-to-r from-neutral_200 to-neutral_300 rounded";

  switch (type) {
    case "text":
      return <div className={`${baseClasses} h-6 ${width || "w-3/4"} mb-2`} />;
    case "metric":
      return <div className={`${baseClasses} h-10 ${width || "w-24"}`} />;
    case "sparkline":
      return (
        <div
          className={`${baseClasses} ${width || "w-32"} ${height || "h-16"}`}
        />
      );
    default:
      return null;
  }
}
