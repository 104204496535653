import FRangePicker from "@components/FDatePicker/FRangePicker";
import ModalSection from "./ModalSection";

import { AdvancedSearchParams } from "@pages/search/types";

interface InfluencerContentProps {
  filters: Partial<AdvancedSearchParams>;
  onFiltersUpdate: (filters: Partial<AdvancedSearchParams>) => void;
}

const InfluencerContent = ({
  filters,
  onFiltersUpdate,
}: InfluencerContentProps) => {
  const { date_from, date_to } = filters;

  return (
    <ModalSection icon="user" title="Influencer Content">
      <FRangePicker
        value={{
          from: date_from,
          to: date_to,
        }}
        onChange={({ from, to }) =>
          onFiltersUpdate({
            date_from: from,
            date_to: to,
          })
        }
      />
    </ModalSection>
  );
};

export default InfluencerContent;
