import BlankState from "@components/BlankState";
import { ReactNode } from "react";

interface WidgetContainerProps {
  children: ReactNode;
  className?: string;
  title: string;
  value: string;
  showMobile?: boolean;
}

const WidgetContainer = ({
  children,
  className = "",
  title = "",
  value = "",
  showMobile = true,
}: WidgetContainerProps) => {
  return (
    <div
      className={` ${showMobile ? "block" : "hidden sm:block"} ${className}`}
    >
      <p className="font-sm text-default_weak mb-0.5">{title}</p>
      <p className="mb-4 text-2xl">{value}</p>
      {value ? (
        children
      ) : (
        <BlankState
          title="No Data"
          subtitle="We have no data for this breakdown. Try changing the platform or time range."
          icon="user"
        />
      )}
    </div>
  );
};

export default WidgetContainer;
