import React, { useEffect, useState } from "react";

import { DragDropContext, Draggable } from "react-beautiful-dnd";

import QuestionForm from "./QuestionForm";
import {
  AmbassadorQuestion,
  AmbassadorQuestionRequest,
  FieldType,
} from "types/ambassador-question";
import QuestionCard from "./QuestionCard";
import Heading from "@components/Heading";
import FButton from "@components/FButton";
import {
  createAmbassadorQuestion,
  deleteAmbassadorQuestion,
  getAmbassadorQuestionsWithChildren,
  listAmbassadorQuestions,
  updateAmbassadorQuestion,
} from "@api/Ambassadors/AmbassadorQuestions";
import { omit, sortBy } from "lodash";
import toast from "react-hot-toast";
import ConfirmModal from "@components/Modals/ConfirmModal";
import { useNavigate, useParams } from "react-router-dom";
import Droppable from "@components/drag-and-drop/Droppable";
import { getItemStyle, getListStyle, reorder } from "@components/drag-and-drop";
import useAuth from "@hooks/useAuth";

const defaultQuestion = {
  question: "",
  required: true,
  position: 1,
  data: {
    options: [],
  },
  field_type: FieldType.SINGLE_SELECT,
};

const AmbassadorQuestions = () => {
  const { question_id } = useParams<{
    question_id: string;
  }>();

  const [questions, setQuestions] = useState<AmbassadorQuestion[]>([]);

  const { user } = useAuth();

  const [questionToDelete, setQuestionToDelete] =
    useState<AmbassadorQuestion>(null);

  const [isDeleting, setDeleting] = useState(false);

  const [waiting, setWaiting] = useState(false);

  const [activeQuestion, setActiveQuestion] =
    useState<AmbassadorQuestion>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!question_id) {
      setActiveQuestion(null);
    } else if (question_id === "new") {
      setActiveQuestion(defaultQuestion);
    } else {
      const allQuestions = questions.reduce((result, item) => {
        const { children } = item;

        if (!children) {
          return [...result, item];
        }

        return [...result, item, ...Object.values(children)];
      }, []);

      const question = allQuestions.find(
        ({ id }) => id === parseInt(question_id, 10)
      );

      setActiveQuestion(question);
    }
  }, [question_id, questions]);

  useEffect(() => {
    const loadQuestions = async () => {
      const questions = await listAmbassadorQuestions({
        q: { brand_id_eq: user.brand_id },
      });

      setQuestions(getAmbassadorQuestionsWithChildren(questions));
    };

    loadQuestions();
  }, []);

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newQuestions = reorder(
      questions,
      result.source.index,
      result.destination.index
    ).map((item, index) => ({
      ...item,
      order: index,
    }));

    setQuestions(newQuestions);
  };

  const handleSave = async (newQuestion: AmbassadorQuestionRequest) => {
    setWaiting(true);

    try {
      if (!newQuestion.id) {
        const response = await createAmbassadorQuestion({
          ...newQuestion,
          brand_id: user.brand_id,
        });

        const createdQuestion = response.data;

        const parentQuestionId = newQuestion.question_id;

        if (parentQuestionId) {
          const newQuestions = questions.map((question) => {
            if (question.id !== parentQuestionId) {
              return question;
            }

            const { children } = question;

            const { conditional_answer } = newQuestion;

            return {
              ...question,
              children: {
                ...(children || {}),
                [conditional_answer]: createdQuestion,
              },
            };
          });

          setQuestions(newQuestions);

          return;
        }

        setQuestions(sortBy([createdQuestion, ...questions], "position"));
      } else {
        const response = await updateAmbassadorQuestion(
          newQuestion.id,
          newQuestion
        );

        const updatedQuestion = response.data;

        const { conditional_answer, question_id: parentQuestionId } =
          updatedQuestion;

        const newQuestions = questions.map((question) => {
          if (parentQuestionId === question.id) {
            return {
              ...question,
              children: {
                ...question.children,
                [conditional_answer]: updatedQuestion,
              },
            };
          }

          return question.id === updatedQuestion.id
            ? updatedQuestion
            : question;
        });

        console.log("Updated questions", newQuestions);

        setQuestions(newQuestions);
      }

      toast.success("Question created");
    } catch (e) {
      toast.error("Error saving changes");
    } finally {
      navigate("/admin/ambassador_questions");

      setWaiting(false);
    }
  };

  console.log("Questions", questions);

  return activeQuestion ? (
    <QuestionForm
      question={activeQuestion}
      questions={questions}
      onSave={handleSave}
      waiting={waiting}
    />
  ) : (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="flex items-center justify-between">
        <Heading title={`Questions (${questions.length})`} />
        <FButton
          primary={true}
          onClick={() => navigate("/admin/ambassador_questions/new")}
          label="Add question"
          type="button"
          iconLeft={{
            name: "add",
            size: 12,
            color: "#fff",
            className: "mr-2",
          }}
        />
      </div>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <div className="flex flex-col gap-8 mt-16 pb-16">
              {questions.map((question, index) => (
                <Draggable
                  key={`key-${question.id}`}
                  draggableId={`ID-${question.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <div {...provided.dragHandleProps}>
                        <QuestionCard
                          question={question}
                          key={question.id}
                          onDelete={(question) => setQuestionToDelete(question)}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
            {questionToDelete ? (
              <ConfirmModal
                title="Delete question"
                isOpen
                onClose={() => setQuestionToDelete(null)}
                onAction={async () => {
                  setDeleting(true);

                  console.log("Question to delete", questionToDelete);

                  try {
                    await deleteAmbassadorQuestion(questionToDelete.id);

                    setQuestionToDelete(null);

                    if (questionToDelete.question_id) {
                      setQuestions(
                        questions.map((question) => {
                          if (question.id !== questionToDelete.question_id) {
                            return question;
                          }

                          return {
                            ...question,
                            children: omit(question.children, [
                              questionToDelete.conditional_answer,
                            ]),
                          };
                        })
                      );
                    } else {
                      setQuestions(
                        questions.filter(
                          (question) => question.id !== questionToDelete.id
                        )
                      );
                    }
                  } catch (e) {
                    console.log("e", e);
                    toast.error("Error deleting question");
                  } finally {
                    setDeleting(false);
                  }
                }}
                actionLabel="Delete"
                isWaiting={isDeleting}
                subtitle={`Are you sure you want to delete this question?`}
              />
            ) : null}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
export default AmbassadorQuestions;
