import apiWrapper from "@apiWrapper";

export const getCountries = async (): Promise<{
  countries: [string, string][];
}> => {
  const response = await apiWrapper(`/geo/countries`, "GET");

  return response.data;
};

export const getRegions = async (
  countryCode: string
): Promise<{ regions: [string, string][] }> => {
  const response = await apiWrapper(
    `/geo/regions?country=${countryCode}`,
    "GET"
  );

  return response.data;
};
