import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { Testimonial } from "@types";

const getTestimonialsList = async (
  cardId: number | string
): Promise<ApiResponse<{ testimonials: Testimonial[] }>> => {
  return apiWrapper(`/testimonials?q[card_id_eq]=${cardId}`, "GET");
};

const getTestimonial = async (
  slug: string
): Promise<ApiResponse<Testimonial>> => {
  return apiWrapper(`/testimonials/${slug}`, "GET");
};

export { getTestimonialsList, getTestimonial };
