import React from "react";
import {
  numberWithKMBFormat,
  formatDollarValue,
} from "@utils/number_utilities";
import { FTooltip } from "@components/FTooltip";
import FIcon from "@components/FIcon";

interface StatsBarProps {
  stats: Array<{
    cellTitle: string;
    statNumber: number | string;
    statType: string;
    statInfo?: string;
  }>;
}

export default function StatsBar({ stats }: StatsBarProps) {
  function handleStat(statNumber: number | string, statType: string) {
    if (statType === "number") {
      return numberWithKMBFormat(Number(statNumber));
    } else if (statType === "percent") {
      return `${statNumber}%`;
    } else if (statType === "dollars") {
      return formatDollarValue(Number(statNumber));
    }
    return statNumber;
  }
  return (
    <div className="flex justify-around items-center">
      <div className="flex w-full rounded-lg py-[16px]">
        {stats.map(({ cellTitle, statNumber, statType, statInfo }, index) => (
          <div key={index} className={`flex flex-col justify-center flex-grow`}>
            <div className="text-[16px]">{cellTitle}</div>
            <div className="flex items-center">
              <div className="text-[28px]">
                {handleStat(statNumber, statType)}
              </div>
              {statInfo && (
                <FTooltip label={statInfo} style={{ width: 200 }}>
                  <div style={{ margin: "2px 0 0 4px" }}>
                    <FIcon icon="info" size={16} color="#B0B5C4" />
                  </div>
                </FTooltip>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
