import React, { useEffect, useState, useRef } from "react";
import BasicCard from "@components/Cards/BasicCard";
import FIcon from "@components/FIcon";
import { Campaign, Participant } from "@types";
import RichTextEditor from "@components/RichTextEditor";
import { updateParticipantGroup } from "@api/Campaign/CampaignParticipantGroups";
import toast from "react-hot-toast";
import FButton from "@components/FButton";

interface CarouselViewProps {
  campaign: Campaign;
  currentState: string;
  selectedGroupId: number;
  transitionDirection: string;
  cardData: any;
  selectedItems: number[];
  onChangeSelectedItems: (id: number[]) => void;
  onSelectedCreator: (participant: Participant) => void;
  onUpdateCampaign: (campaign: Partial<Campaign>) => void;
}

export default function CarouselView({
  campaign,
  cardData,
  currentState,
  transitionDirection,
  selectedGroupId,
  selectedItems,
  onChangeSelectedItems,
  onSelectedCreator,
  onUpdateCampaign,
}: CarouselViewProps) {
  const scrollContainerRef = useRef(null);
  const [cardOpacities, setCardOpacities] = useState([]);
  const [textAnimation, setTextAnimation] = useState("");

  const [isEditingDescription, setEditingDescription] = useState(false);
  const [pendingDescription, setPendingDescription] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  const { participant_groups = [] } = campaign;

  const selectedGroup = participant_groups.find(
    ({ id }) => id === selectedGroupId
  );

  const handleEditDescription = () => {
    setPendingDescription(selectedGroup?.creator_review_description);
    setEditingDescription(true);
  };

  const handleSubmitDescription = async () => {
    if (!pendingDescription) {
      return;
    }

    setIsSaving(true);

    try {
      await updateParticipantGroup(selectedGroup.id, {
        creator_review_description: pendingDescription,
      });

      const updatedParticipantGroups = participant_groups.map((p) =>
        p.id === selectedGroup.id
          ? {
              ...p,
              creator_review_description,
            }
          : p
      );

      onUpdateCampaign({ participant_groups: updatedParticipantGroups });

      setEditingDescription(false);

      toast.success("Updates saved");
    } catch (error) {
      toast.error("Error submitting description");

      console.error("Failed to submit comment:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleToggleItemSelection = (id) => {
    let newSelectedItems = selectedItems || [];

    if (selectedItems.includes(id)) {
      newSelectedItems = newSelectedItems.filter((item) => item !== id);
    } else {
      newSelectedItems = [...selectedItems, id];
    }

    onChangeSelectedItems(newSelectedItems);
  };

  const updateCardOpacities = () => {
    if (!scrollContainerRef.current) {
      console.log("Container not available");
      return;
    }

    const container = scrollContainerRef.current;
    const newOpacities = cardData.map((item, index) => {
      const cardElement = container.querySelector(`.card-${index}`);
      if (!cardElement) return 0.5; // Set minimum opacity when card is not found

      const cardRect = cardElement.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      // Calculate the center of the card
      const cardCenter = cardRect.left + cardRect.width / 2;
      // Calculate the center of the container
      const containerCenter = containerRect.left + containerRect.width / 2;

      // Determine the distance from the center of the container
      const distanceFromCenter = Math.abs(cardCenter - containerCenter);
      // Normalize the distance based on container width
      const normalizedDistance = distanceFromCenter / (containerRect.width / 2);

      // Calculate opacity: Closer to center means higher opacity
      const opacity = Math.max(1 - normalizedDistance, 0.5); // Ensure minimum opacity of 0.5
      return opacity;
    });

    setCardOpacities(newOpacities);
  };

  useEffect(() => {
    setTextAnimation(`fade-in-${transitionDirection}`);
    const timer = setTimeout(() => setTextAnimation(""), 500); // Reset animation class after it completes
    return () => clearTimeout(timer);
  }, [currentState, selectedGroup, transitionDirection]);

  useEffect(() => {
    const handleScroll = () => {
      updateCardOpacities();
    };

    const handleResize = () => {
      updateCardOpacities();
    };

    window.addEventListener("resize", handleResize);
    scrollContainerRef.current?.addEventListener("scroll", handleScroll);

    // Initial update
    updateCardOpacities();

    return () => {
      window.removeEventListener("resize", handleResize);
      scrollContainerRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [cardData]); // Depend on card data to re-attach listeners if it changes

  const { creator_review_description } = selectedGroup || {};

  return (
    <div className="participant-review-content flex flex-col justify-between">
      <div className="h-full">
        <div
          className={`flex overflow-x-auto w-full gap-4 h-screen py-[40px] carousel-sizing ${textAnimation}`}
          ref={scrollContainerRef}
        >
          <div className={`group-intro-sizing flex items-center h-full`}>
            {selectedGroup ? (
              <>
                {isEditingDescription ? (
                  <div className="flex flex-col gap-2 w-[400px]">
                    <div className="bg-white w-full my-4">
                      <RichTextEditor
                        value={pendingDescription}
                        id={`creatorReviewDescription-${selectedGroup?.id}`}
                        onChange={setPendingDescription}
                      />
                    </div>
                    <div className="flex gap-2 justify-end w-full">
                      <FButton
                        label="Save"
                        onClick={handleSubmitDescription}
                        loading={isSaving}
                        primary
                      />
                      <FButton
                        label="Cancel"
                        onClick={() => setEditingDescription(false)}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={`flex gap-4 items-end justify-center w-[${creator_review_description ? 250 : 150}px]`}
                  >
                    {creator_review_description ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: creator_review_description,
                        }}
                      />
                    ) : (
                      <p>No description</p>
                    )}
                    <FButton
                      className=""
                      icon={{ color: "#000F45", name: "edit", size: 14 }}
                      iconButton
                      onClick={handleEditDescription}
                    />
                  </div>
                )}
              </>
            ) : null}
          </div>
          {cardData.length === 0 ? (
            <div className="flex h-full w-full items-center justify-center">
              <div className="h-full w-full carousel-card-sizing bg-highlight_red rounded-xl bg-gray-100 flex items-center justify-center">
                <div className="flex w-[200px] h-[200px] rounded-full items-center justify-center p-10 bg-light_red">
                  <FIcon
                    icon="audience"
                    className=""
                    size={100}
                    color="#F9E9E7"
                  />
                </div>
              </div>
            </div>
          ) : (
            cardData.map((item, index) => {
              const { creator } = item;

              const { firstName, lastName } = creator || {};

              return (
                <div
                  key={index}
                  className={`card-${index} cursor-pointer`}
                  style={{
                    opacity: cardOpacities[index] || 0.5,
                  }}
                >
                  <BasicCard
                    {...item}
                    creator={creator}
                    full_name={`${firstName} ${lastName}`}
                    decoration={item.status}
                    cardType="individual"
                    className={`carousel-card-sizing rounded-xl card-visible`}
                    onCheckBoxClick={() => handleToggleItemSelection(item.id)}
                    checkBoxClickList={selectedItems}
                    onCardClick={(item) => onSelectedCreator(item)}
                  />
                </div>
              );
            })
          )}
          {cardData.length > 0 && (
            <div className="group-intro-sizing h-full">
              <div className="flex h-full flex-col gap-4 group-intro-sizing-end items-left justify-center pr-[20px]"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
