import React, { useContext } from "react";

import MessageView from "./MessageView";
import EditMessageForm from "./EditMessageForm";
import { OutreachContext } from "@contexts/index";

const OutreachMessage = () => {
  const { outreach, setOutreach } = useContext(OutreachContext);

  return (
    <div>
      {outreach.sent ? (
        <MessageView outreach={{ ...outreach }} />
      ) : (
        <div className="pt-[20px]">
          <EditMessageForm
            outreach={outreach}
            onUpdated={(updates) => setOutreach({ ...outreach, ...updates })}
          />
        </div>
      )}
    </div>
  );
};

export default OutreachMessage;
