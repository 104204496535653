import React from "react";
import SinglePageLayout from "../../../src/layouts/SinglePageLayout";

const sampleData = [
  {
    id: 1,
    title: "Q2 Bali",
    pageName: "Groups",
    ownerUser: "Carly Roesen",
    company: "Fohr Inc",
    influencers: 1,
    date: "May 22, 2024",
    decoration: "Group",
    state: "active",
    statIcon: "audience",
    imageUrl:
      "https://cdn-d35wth38dfye85.fohr.co/card/cards/profile_images/000/005/153/card/0527.jpg?1575626063",
    cardType: "asset",
  },
  {
    id: 2,
    title: "Q3 Tokyo",
    pageName: "Groups",
    ownerUser: "John Doe",
    company: "Acme Corp",
    influencers: 5,
    date: "June 15, 2024",
    state: "featured",
    decoration: "Group",
    statIcon: "audience",
    cardType: "asset",
    imageUrl:
      "https://cdn-d35wth38dfye85.fohr.co/card/cards/profile_images/000/005/153/card/0527.jpg?1575626063",
  },
  {
    id: 3,
    title: "Q4 Paris",
    ownerUser: "Jane Smith",
    company: "Global Inc",
    decoration: "Group",
    influencers: 3,
    date: "July 10, 2024",
    state: "archived",
    statIcon: "audience",
    cardType: "asset",
    imageUrl:
      "https://cdn-d35wth38dfye85.fohr.co/card/cards/profile_images/000/005/153/card/0527.jpg?1575626063",
  },
];

const cardDropDownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Delete", value: "delete" },
  { label: "Archive", value: "archive" },
];

const tableColumns = [
  {
    key: "title",
    label: "Title",
    isLink: true,
    linkURL: "/groups/{id}",
    isImage: true,
    imageType: "asset" as "asset" | "profile",
    imageKey: "imageUrl",
  },
  { key: "ownerUser", label: "Owner" },
  { key: "company", label: "Company" },
  { key: "influencers", label: "Influencers" },
  { key: "date", label: "Date" },
  { key: "state", label: "State", isStateBubble: true },
];

const actions = {
  // create: apiClient.groups.create,
  // read: apiClient.groups.read,
  // Define update, delete when available
};

const filterOptions = [
  { label: "All", static: "Groups", value: null },
  { label: "Active", static: "Groups", value: "active" },
  { label: "Featured", static: "Groups", value: "featured" },
  { label: "Archived", static: "Groups", value: "archived" },
];

const GiftingTableList = () => {
  return (
    <div className="pt-[40px]">
      <SinglePageLayout
        pageName="Gifting Table List"
        pageTitle="My Gifting Tables"
        cardData={sampleData}
        className="pt-[0px]"
        sortPathName="title"
        sortPathDate="date"
        filterPath="state"
        filterOptions={filterOptions}
        tableColumns={tableColumns}
        actions={actions}
        cardDropDownOptions={cardDropDownOptions}
      />
    </div>
  );
};

export default GiftingTableList;
