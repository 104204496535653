import React from "react";

import FormField, { FormFieldProps } from "../Form/FormField";
import FBaseInput, { FBaseInputProps } from "./FBaseInput";

interface Props extends FormFieldProps, FBaseInputProps {}

const FInput = ({
  id,
  className = "",
  label = "",
  width = 250,
  error = null,
  success = "",
  helperLink,
  helperAction,
  required,
  value,
  description,
  validate,
  ...props
}: Props) => {
  return (
    <FormField
      id={id}
      className={className}
      error={
        error || (validate && required && !value && `${label} is required`)
      }
      label={label}
      helperAction={helperAction}
      helperLink={helperLink}
      required={required}
      success={success}
      width={width}
      description={description}
    >
      <FBaseInput value={value} {...props} />
    </FormField>
  );
};

export default FInput;
