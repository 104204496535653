import React, { useEffect, ReactNode } from "react";
import FButton from "@components/FButton";
import FIcon from "@components/FIcon";

interface ModalProps {
  title: string;
  titleRight?: ReactNode;
  subtitle?: string;
  isOpen: boolean;
  isWaiting?: boolean;
  onClose: () => void;
  onAction: () => void;
  actionLabel: string;
  children?: ReactNode;
  className?: string;
}

const ConfirmModal: React.FC<ModalProps> = ({
  title,
  subtitle,
  isOpen,
  isWaiting,
  onClose,
  onAction,
  actionLabel,
  children,
  className,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  // Handle the escape key
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, [onClose]);

  // Prevent body scroll when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className={`fixed z-[9999] inset-0 bg-dark_night_sky bg-opacity-70 flex items-center justify-center p-4 ${className}`}
      onClick={onClose}
    >
      <div
        className={`bg-highlight_red rounded-lg shadow-2xl p-10 pb-5 w-[400px] relative`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-center items-center">
          <h2
            className={`${
              subtitle ? "mb-1" : "mb-6"
            } text-[2rem] font-medium w-full text-center`}
          >
            {title}
          </h2>
        </div>

        {subtitle && (
          <p className="text-[15px] mt-[0.5rem] mb-[1.5rem] text-center">
            {subtitle}
          </p>
        )}
        {children && <div className="flex flex-col w-full">{children}</div>}
        <div className="flex justify-center space-x-4 mt-10 pb-5">
          <FButton
            onClick={onClose}
            label="Cancel"
            width="100%"
            height="40px"
          />
          <FButton
            onClick={onAction}
            label={actionLabel}
            width="100%"
            height="40px"
            primary
            loading={isWaiting}
            type="button"
          />
        </div>
        <div
          className="absolute top-5 right-7 cursor-pointer opacity-50 hover:opacity-100"
          onClick={onClose}
        >
          <FIcon size={20} color="#000F45" icon="exit-2" />
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
