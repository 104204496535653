import React, { useEffect, useState } from "react";
import ProfilePlaceholder from "@public/images/profile_placeholder.png";
import FIcon from "@components/FIcon";
import { FChip } from "@components/FChip";
import FDropdown from "@components/FDropdown";
import FSubNav from "@components/FSubNav";
import { formatLargeNumber } from "@utils/number_utilities";
import { FTooltip } from "@components/FTooltip";

import FButton from "@components/FButton";
import AddCreatorsToGroupModal from "./AddCreatorsToGroupModal";
import { Profile } from "@types";
import toast from "react-hot-toast";

interface ProfileNavProps {
  profile: Profile;
  user?: any; // Define a more specific type if possible
  isValidProfileHash?: boolean;
  isScrolled?: boolean;
  handleDropdownOptionSelect?: (value: string) => void;
  dropdownOptions?: any; // Define a more specific type if possible
  selectedNav?: string;
  items?: any[];
  handleNavChange?: (value: string) => void;
  readOnly?: boolean;
}

const validateImageUrl = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
};

const getProfileImage = async (profileImage, profileImageUrl) => {
  const imageUrl = profileImage || profileImageUrl;

  if (imageUrl) {
    const isValid = await validateImageUrl(imageUrl);
    return isValid ? imageUrl : ProfilePlaceholder;
  }
  return ProfilePlaceholder;
};

export default function ProfileNav({
  isScrolled,
  profile,
  items,
  handleNavChange,
  selectedNav,
  user,
  isValidProfileHash,
  handleDropdownOptionSelect,
  dropdownOptions,
  readOnly,
}: ProfileNavProps) {
  const [profileImage, setProfileImage] = useState(ProfilePlaceholder);

  const [isAddToGroupModalVisible, setAddToGroupModalVisible] = useState(false);

  const formatConnectionType = (platform, type) => {
    const instagramConnectionMap = {
      // instagram_business: "Instagram Business Connection",
      // facebook_instagram_basic: "Instagram Basic Connection",
      // instagram_v1: "V1 (old) Connection",
      // v1_incomplete_basic: "V1 (old) but Basic Connection failed",
      // incomplete_basic: "Basic Connection failed",
      // "": "V1 Missing access token",
      // instagram_display: "Instagram Display",
      // off_platform: "Off Platform",
      // manual_username: "Manual Connection",
      instagram_business: "Business API Connected",
      facebook_instagram_basic: "Connected",
      instagram_v1: "Not Connected",
      v1_incomplete_basic: "Not Connected",
      incomplete_basic: "Not Connected",
      "": "Not Connected",
      instagram_display: "Connected",
      off_platform: "Off Platform",
      manual_username: "Discovery API",
    };

    if (platform === "instagram") {
      return instagramConnectionMap[type];
    }

    if (platform === "tiktok") {
      return type === "api" ? "Connected" : "Not Connected";
    }
  };

  useEffect(() => {
    const fetchProfileImage = async () => {
      const imageUrl = await getProfileImage(
        profile.profile_image,
        profile.profile_image_url
      );
      setProfileImage(imageUrl);
    };

    fetchProfileImage();
  }, [profile.profile_image, profile.profile_image_url]);

  const handleAddToGroup = () => setAddToGroupModalVisible(true);

  return (
    <div
      className={`${
        isScrolled
          ? "fixed bg-light_red top-0 flex flex-col px-[40px] z-[100]"
          : "relative flex flex-col px-[40px] z-49"
      } w-full`}
    >
      <div
        className={`${
          isScrolled ? "pt-[28px] pb-[0px]" : "pt-[38px] pb-[24px]"
        } flex relative w-full justify-start items-center `}
      >
        <div
          className={`${
            isScrolled
              ? " min-w-[56px] w-[56px] h-[56px] mr-1"
              : " mr-2 w-[90px] min-w-[90px] h-[90px] "
          } relative bg-center bg-cover bg-dark rounded-full `}
          style={{
            backgroundImage: `url(${profileImage})`,
          }}
        ></div>

        <div className="flex flex-col gap-[4px] ml-[16px] -mt-[8px] flex w-full">
          <div className="flex justify-between w-full">
            <div className="flex gap-[4px] w-full items-center">
              <a href={`/card/${profile?.slug}`} target="_blank">
                <h1
                  className={`${
                    isScrolled
                      ? "text-[22px] leading-[24px]"
                      : "text-[28px] leading-[32px]"
                  }  font-sofia-pro text-dark`}
                >
                  {profile.first_name} {profile.last_name}{" "}
                </h1>
              </a>
              {profile.off_platform && (
                <FChip
                  className="text-xs bg-black text-white p-0 h-[24px] w-[90px] flex items-center justify-center"
                  label={`Off Platform`}
                  icon={{
                    name: "",
                    size: 15,
                    color: "#fff",
                  }}
                  category={true}
                />
              )}

              {profile.managed && (
                <FTooltip label={"Managed"} style={{ width: "auto" }} key={2}>
                  <FChip
                    className="text-xs bg-black text-white p-0 h-[24px] w-[24px] flex items-center justify-center"
                    label={`M`}
                    icon={{
                      name: "",
                      size: 15,
                      color: "#fff",
                    }}
                    category={true}
                  />
                </FTooltip>
              )}
            </div>
            {user && !readOnly ? (
              <div className="flex gap-2 items-center">
                {/* Need to come back to this, will render wrong */}
                {/*   <ProfileButtons
                  card={profile}
                  setView={setView}
                  membership={membership}
                  isBrandUser={isBrandUser}
                /> */}
                <FDropdown
                  options={dropdownOptions}
                  onChange={handleDropdownOptionSelect}
                  width="auto"
                  height="28"
                  iconOnly
                  iconColor="#000F45"
                  icon="ellipsis"
                />
                <FButton
                  onClick={handleAddToGroup}
                  label="Add to group"
                  width="max-content"
                ></FButton>
              </div>
            ) : null}
          </div>
          {!isScrolled && (
            <div className="flex gap-[8px] flex-wrap items-center justify-start  text-neutral_500 text-[16px]">
              {profile?.postCategories
                ?.sort((a, b) => b.total - a.total)
                ?.slice(0, 3)
                ?.map((c, i) => (
                  <span key={`${c.category}-${i}`} className="">
                    {c.category}
                    {i < 2 && " • "}
                  </span>
                ))}
              {profile?.postCategories?.length > 0 && <p> • </p>}
              <p className="">
                {user || isValidProfileHash
                  ? profile.location
                  : "New York, NY 10001"}
              </p>
            </div>
          )}

          <div className="flex flex-wrap gap-[16px] items-center">
            {profile.platforms
              ?.filter((platform) => platform.followers > 0)
              .map((platform) => {
                const key = `${platform.name}-${platform.followers}`;
                const href = platform.url.includes("http")
                  ? platform.url
                  : `https://${platform.url}`;
                if (["instagram", "tiktok"].indexOf(platform.name) === -1) {
                  return (
                    <a
                      href={href}
                      target="_blank"
                      className="flex gap-[6px] items-center text-[16px]"
                      key={key}
                    >
                      <FIcon icon={platform.name} size={16} color="#000F45" />
                      <p>{formatLargeNumber(platform.followers)}</p>
                    </a>
                  );
                } else {
                  return (
                    <FTooltip
                      label={formatConnectionType(
                        platform.name,
                        platform.connection_type
                      )}
                      style={{ width: "auto" }}
                      key={key}
                    >
                      <a
                        href={href}
                        target="_blank"
                        className="flex gap-[6px] items-center text-[16px]"
                      >
                        <FIcon icon={platform.name} size={16} color="#000F45" />
                        <p>{formatLargeNumber(platform.followers)}</p>
                      </a>
                    </FTooltip>
                  );
                }
              })}
          </div>
        </div>
      </div>

      {items ? (
        <div
          className={`${
            isScrolled ? "border-t border-light_border mt-[24px]" : ""
          } hidden sm:block w-full overflow-x-auto`}
        >
          <FSubNav
            items={items.map((item) => ({
              ...item,
              active: item.value === selectedNav,
            }))}
            onChange={handleNavChange}
            selectedValue={selectedNav}
          />
        </div>
      ) : null}
      {isAddToGroupModalVisible ? (
        <AddCreatorsToGroupModal
          creatorIds={[profile.id]}
          onClose={() => setAddToGroupModalVisible(false)}
        />
      ) : null}
    </div>
  );
}
