import * as React from "react";

const FSpinner = ({
  width = 50,
  height = 50,
  backgroundColor = "#f3f3f3",
  borderColor = "#000F45",
  borderWidth = 10,
  style = {},
}: {
  width?: number;
  height?: number;
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  className?: string;
  style?: any;
}) => {
  return (
    <div style={style}>
      <div className="flex items-center justify-center">
        <div
          className="animate-spin rounded-full"
          style={{
            height,
            width,
            border: `${borderWidth}px solid ${backgroundColor}`,
            borderTop: `${borderWidth}px solid ${borderColor}`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default FSpinner;
