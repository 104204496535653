import { FChip } from "@components/FChip";

import { isEmpty } from "lodash";

interface Category {
  label: string;
  value: string;
}

interface ChipsSectionProps {
  title: string;
  items: Category[];
}

const ChipsSection = ({ title, items }: ChipsSectionProps) => {
  if (isEmpty(items)) {
    return null;
  }

  return (
    <div className="py-8">
      <h4 className="text-sm text-default_weak mb-2">{title}</h4>
      <div className="">
        {items.map((category, i) => (
          <FChip
            label={"@" + category.label}
            className="border-neutral_400 font-sofia-pro text-sm mb-2"
            key={i}
            labelRight={<p className="ml-2"> {category.value + "x"}</p>}
          />
        ))}
      </div>
    </div>
  );
};

export default ChipsSection;
