import IconTick from "./icons/IconTick";

interface GradientBarProps {
  relativeDifference: number;
}

const GradientBar = ({ relativeDifference }: GradientBarProps) => {
  let left = 50 + (relativeDifference * 100) / 2;

  if (left < 0) {
    left = 0;
  } else if (left > 100) {
    left = 100;
  }

  return (
    <div className="relative w-full">
      <div
        className="absolute top-[-8px]"
        style={{ left: `calc(${left}% - 5.5px)` }}
      >
        <IconTick />
      </div>
      <div
        className="h-[5.5px] w-full"
        style={{
          background:
            "linear-gradient(270deg, #47B37F 0%, #97E2BE 49%, #FFF 50%, #FFF 50%, #FFC6BF 51%, #E47667 100%)",
        }}
      />
    </div>
  );
};

export default GradientBar;
