import React, { useContext, useState } from "react";

import { CampaignProduct } from "@types";

import ConfirmModal from "@components/Modals/ConfirmModal";

import SinglePageLayout from "@layouts/SinglePageLayout";
import { CampaignContext } from "@contexts/index";
import FIcon from "@components/FIcon";

import FButton from "@components/FButton";
// import AddDeliverableModal from "./components/AddDeliverableModal";
// import { deleteCampaignDeliverable } from "@api/Campaign/CampaignDeliverables";
import toast from "react-hot-toast";
import { deleteCampaignProduct } from "@api/Campaign/CampaignProducts";
import Modal from "@components/Modals/Modal";
import AddProduct from "@components/Campaign/Brief/BriefForms/AddProduct";

const cardDropDownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Delete", value: "delete" },
];

const TABLE_COLUMNS = [
  {
    key: "name",
    label: "Product Name",
    isImage: true,
    imageType: "asset" as "asset" | "profile",
    imageKey: "image_url",
  },
  {
    key: "participant_group_id",
    label: "Brief group",
  },
  {
    key: "notes",
    label: "Description",
  },
  {
    key: "url",
    label: "URL",
    getContent: (product: CampaignProduct) => (
      <a
        className="flex items-start"
        href={product.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FIcon className="mr-2" icon="link-arrow-2" size={14} color="#000721" />
        {product.url}
      </a>
    ),
  },
];

const CampaignProductsPage = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [productToEdit, setProductToEdit] = useState<CampaignProduct>(null);

  const [productToDelete, setProductToDelete] = useState<number>(null);
  const [isDeleting, setDeleting] = useState(false);

  const [isUpdateProductModalVisible, setUpdateProductModalVisible] =
    useState(false);

  const { campaign, setCampaign } = useContext(CampaignContext);

  const { products = [] } = campaign;

  const handleTableRowDropdownSelect = (value: string, id: number) => {
    const product = products.find((item) => item.id === id);

    if (value === "edit") {
      setProductToEdit(product);
      setUpdateProductModalVisible(true);
    } else if (value === "delete") {
      setProductToDelete(id);
    }
  };

  const tableColumns = TABLE_COLUMNS.map((column) => {
    if (column.key === "name") {
      return {
        ...column,
        getContent: (deliverable: CampaignProduct) => (
          <span
            className="cursor-pointer"
            onClick={() => {
              setProductToEdit(deliverable);
              setUpdateProductModalVisible(true);
            }}
          >
            {deliverable.name}
          </span>
        ),
      };
    }

    return column;
  });

  return (
    <>
      <div className="flex justify-between items-center pb-10 pt-[40px] mb-[40px] border-b border-light_border">
        <h2 className="text-2xl">Products ({products.length})</h2>
        <div className="flex gap-4">
          <FButton
            primary
            onClick={() => setUpdateProductModalVisible(true)}
            label="Add product"
            type="button"
            iconLeft={{
              name: "add",
              size: 12,
              color: "#fff",
              className: "mr-2",
            }}
          />
        </div>
      </div>
      <SinglePageLayout
        cardData={products}
        pageName="Products"
        tableOnly
        sortPathName="name"
        sortPathDate="created_at"
        tableColumns={tableColumns}
        rowActions={cardDropDownOptions}
        cardDropDownOptions={cardDropDownOptions}
        selectedItems={selectedItems}
        onChangeSelectedItems={setSelectedItems}
        onTableRowDropDownSelect={handleTableRowDropdownSelect}
        createWithEntityCrud={() => setUpdateProductModalVisible(true)}
      />
      {isUpdateProductModalVisible ? (
        <Modal
          title={`${productToEdit ? "Edit" : "Add"} Product`}
          isOpen
          onClose={() => {
            setUpdateProductModalVisible(false);
            setProductToEdit(null);
          }}
          actionLabel="Save"
          hasForm
          className="h-[w-[650px]"
          children={
            <AddProduct
              onClose={() => {
                setProductToEdit(null);
                setUpdateProductModalVisible(false);
              }}
              campaign={campaign}
              product={productToEdit}
              onUpdateCampaign={setCampaign}
            />
          }
        />
      ) : null}
      {productToDelete ? (
        <ConfirmModal
          title="Delete product"
          isOpen
          isWaiting={isDeleting}
          onClose={() => setProductToDelete(null)}
          onAction={async () => {
            setDeleting(true);

            try {
              await deleteCampaignProduct(productToDelete);

              toast.success("Product deleted");
            } catch (e) {
              toast.error("Error deleting entity");
            } finally {
              setDeleting(false);
            }

            setProductToDelete(null);

            const newProducts = products.filter(
              (item) => item.id !== productToDelete
            );

            setCampaign({
              ...campaign,
              products: newProducts,
            });
          }}
          actionLabel="Delete"
          subtitle={`Are you sure you want to delete this product?`}
        />
      ) : null}
    </>
  );
};

export default CampaignProductsPage;
