import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { CampaignProduct } from "@types";

const updateCampaignProduct = async (
  product_id: string | number,
  params: any
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/campaign_products/${product_id}`, "PUT", params);
};

const deleteCampaignProduct = async (
  product_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/campaign_products/${product_id}`, "DELETE");
};

const createCampaignProduct = async (
  campaign_id: string | number,
  params: any
): Promise<ApiResponse<any>> => {
  return apiWrapper("/campaign_products", "POST", {
    ...params,
    campaign_id: campaign_id,
  });
};

const getCampaignProductList = async (
  campaign_id: string | number
): Promise<ApiResponse<CampaignProduct[]>> => {
  return apiWrapper(
    `/campaign_products?q[campaign_id_eq]=${campaign_id}`,
    "GET"
  );
};

export {
  updateCampaignProduct,
  deleteCampaignProduct,
  createCampaignProduct,
  getCampaignProductList,
};
