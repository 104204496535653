import React, { useEffect } from "react";
import FDropdown, { FDropdownOption } from "@components/FDropdown";
import FButton from "@components/FButton";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "@hooks/useAuth";
import { capitalize } from "lodash";
import ArrowButton from "@components/buttons/ArrowButton";

const VIEW_OPTIONS = ["carousel", "list", "stats"];

interface EventTopNavProps {
  viewType: string;
  onViewTypeChange: (viewType: string) => void;
  onGroupUp: () => void;
  onGroupDown: () => void;
  groupOptions: FDropdownOption[];
  onSelectedGroup: (groupId: string) => void;
  onUpdateStatus: () => void;
  selectedGroup: string;
  selectedParticipantIds: number[];
}

export default function EventTopNav({
  viewType,
  onViewTypeChange,
  onGroupUp,
  onGroupDown,
  groupOptions,
  onSelectedGroup,
  selectedGroup,
  selectedParticipantIds,
  onUpdateStatus,
}: EventTopNavProps) {
  const { campaign_id } = useParams<{
    campaign_id: string;
  }>();

  const { user } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "ArrowUp") {
        onGroupUp();
      } else if (event.key === "ArrowDown") {
        onGroupDown();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [onGroupDown, onGroupUp]);

  return (
    <div className="grid grid-cols-[245px_auto] fixed bg-light_red w-full z-10">
      <div className="flex flex-col w-[245px] bg-transparent" />
      <div className="py-[24px] px-[48px] w-full">
        {user ? (
          <FButton
            className="bg-[transparent] border-neutral_400 hover:border-neutral_600 mb-[14px]"
            label="Back"
            iconLeft={{ name: "undo", size: 18 }}
            onClick={() => navigate(`/campaigns/${campaign_id}/participants`)}
          />
        ) : null}
        <div className="flex items-center justify-between w-full">
          <div className="w-full flex gap-5 items-center justify-left">
            <div className="flex gap-2 items-center justify-center">
              <ArrowButton direction="up" onClick={onGroupUp} />
              <ArrowButton direction="down" onClick={onGroupDown} />
            </div>
            <div className="flex gap-3 items-left justify-start">
              <FDropdown
                width=""
                className="border-none mr-2 text-[15px]"
                titleDropDown={true}
                selectedValue={selectedGroup}
                onChange={onSelectedGroup}
                options={groupOptions}
                transparent
              />
            </div>
          </div>
          <div className="w-full flex flex-row gap-4 items-center text-[15px] justify-end">
            {VIEW_OPTIONS.map((name) => (
              <button
                className={`text-black ${
                  viewType === name ? "opacity-100 underline" : "opacity-50"
                }`}
                key={name}
                onClick={() => onViewTypeChange(name)}
              >
                {capitalize(name)}
              </button>
            ))}
            <div
              className={`${
                selectedParticipantIds.length > 0 ? "opacity-100" : "opacity-20"
              } w-[150px] justify-end flex`}
            >
              <FButton
                primary={selectedParticipantIds.length > 0}
                label={`Update Status (${selectedParticipantIds.length})`}
                onClick={onUpdateStatus}
                disabled={!selectedParticipantIds.length}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
