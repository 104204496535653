import React from "react";
import SkeletonLoader from "@components/Dashboard/SkeletonLoader";

export default function AnalyticsMessageSkeleton() {
  return (
    <div className="space-y-2 max-w-[800px]">
      <SkeletonLoader type="text" width="w-3/4" />
      <SkeletonLoader type="text" width="w-1/2" />
    </div>
  );
}
