import apiWrapper from "@apiWrapper";
import { ApiResponse, CampaignContent } from "@apiTypes";
// Note:
// TypeScript does not accomodate the implicit type coercion within URLSearchParams
// so there appears to be an error, but it is not problematic

import { LOCAL_API_ORIGIN } from "../../constants/api";
import { CampaignBrief } from "@types";

const API_BASE_URL = `${LOCAL_API_ORIGIN}/api/v1`;

const getCampaignBrief = async (
  campaignId: string | number
): Promise<CampaignBrief[]> => {
  const response = await apiWrapper(
    `/campaign_briefs?q[campaign_id_eq]=${campaignId}`,
    "GET"
  );

  return response.data.campaign_briefs;
};

const updateCampaignBrief = async (
  brief_id: string | number,
  params: Partial<CampaignBrief>
): Promise<ApiResponse<CampaignContent[]>> => {
  return apiWrapper(`/campaign_briefs/${brief_id}`, "PUT", params);
};

const createCampaignBrief = async (
  campaign_id: string | number,
  params: any
): Promise<ApiResponse<CampaignContent[]>> => {
  return apiWrapper(`/campaign_briefs`, "POST", { ...params, campaign_id });
};

// const getCampaignBriefs = async (
// 	params: {
// 		campaign_id: number;
// 		order_column: string;
// 		order_direction: string;
// 		page: number;
// 		page_size: number;
// 	},
// 	method?: string
// ) => {
// 	const paramStr = new URLSearchParams();
// 	Object.keys(params).forEach((key) => {
// 		paramStr.append(key, String(params[key as keyof typeof params]));
// 	});
// 	const data = await fetcher(`/api/v1/campaign_briefs?${paramStr}`, method);

// 	return data;
// };

// const getCampaignBriefById = async (id: number, params = {}) => {
// 	const searchParams = new URLSearchParams();
// 	if (params["campaign_brief"]) {
// 		for (const key of Object.keys(params["campaign_brief"])) {
// 			if (params["campaign_brief"][key]) {
// 				searchParams.append(
// 					`campaign_brief[${key}]`,
// 					params["campaign_brief"][key]
// 				);
// 			}
// 		}
// 	}

// 	if (searchParams.toString()) {
// 		return await fetcher(
// 			`/api/v1/campaign_briefs/${id}?${searchParams.toString()}`
// 		);
// 	}

// 	return await fetcher(`/api/v1/campaign_briefs/${id}`);
// };

// const updateCampaignBrief = async (id: number, params: any) => {
// 	return await fetcher(`/api/v1/campaign_briefs/${id}`, "PUT", params);
// };

const uploadCampaignBriefFiles = async (id: number, formData: FormData) => {
  const response = await fetch(`${API_BASE_URL}/campaign_briefs/${id}`, {
    method: "PUT",
    body: formData,
    credentials: "include",
  });
  const json = await response.json();
  return json;
};

export {
  getCampaignBrief,
  updateCampaignBrief,
  uploadCampaignBriefFiles,
  createCampaignBrief,
};
