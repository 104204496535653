import React, { useContext, useState } from "react";
import Modal from "@components/Modals/Modal";
import Table from "@components/Table";
import FButton from "@components/FButton";

import { useParams } from "react-router-dom";
import { CampaignContext } from "@contexts/index";
import { CampaignBrief } from "@types";

const tableColumns = [
  {
    key: "brief_name",
    label: "Name",
    isLink: true,
    linkURL: "/campaigns/{campaign_id}/brief/{id}",
    isFirstColumn: true,
    linkTarget: "_blank",
  },
  { key: "type", label: "Type", isStateBubble: true },
  { key: "participant_count", label: "Participants" },
];

interface SaveOverrideModalProps {
  onClose: () => void;
  onSaveAndApply: (
    selectedBriefs: number[],
    allBriefs: CampaignBrief[]
  ) => void;
  waiting: boolean;
  pageName: string;
}

const SaveOverrideModal = ({
  pageName,
  onClose,
  onSaveAndApply,
  waiting,
}: SaveOverrideModalProps) => {
  const [selectedBriefs, setSelectedBriefs] = useState<number[]>([]);
  const [step, setStep] = useState(1);

  const { activeBrief, briefs, campaign, participantBriefs } =
    useContext(CampaignContext);

  const { participants, participant_groups: participantGroups } = campaign;

  const { campaign_id } = useParams<{
    brief_id: string;
    campaign_id: string;
    participant_id: string;
  }>();

  const allBriefs = [...briefs, ...participantBriefs]
    .map((b) => {
      let brief_name, type, participant_count;

      if (b.participant_id) {
        const { creator } =
          participants.find((p) => p.id === b.participant_id) || {};

        // Participant Brief
        brief_name = creator
          ? `${creator?.firstName} ${creator?.lastName}`
          : "Unknown Participant";
        type = "Participant";
        participant_count = 1;
      } else if (b.participant_group_id) {
        // Group Brief
        brief_name =
          participantGroups.find((g) => g.id === b.participant_group_id)
            ?.name || `Group ${b.participant_group_id}`;
        type = "Group";
        participant_count = participants.filter(
          (p) => p.participant_group_id === b.participant_group_id
        ).length;
      } else {
        // Master Brief
        brief_name = "Master Brief";
        type = "Master";
        participant_count = participants.length;
      }

      return {
        ...b,
        brief_name,
        type,
        participant_count,
        campaign_id: campaign_id,
      };
    })
    .filter((b) => b.id !== activeBrief?.id);

  const filteredBriefList = allBriefs.filter((b) =>
    selectedBriefs.includes(b.id)
  );

  return (
    <Modal
      className="w-[850px]"
      title={`Apply ${pageName} to Other Briefs`}
      subtitle={
        step === 1
          ? `Override a current brief with the content from ${pageName}`
          : `Are you sure you want to replace the content of the these brief(s) with ${pageName}?`
      }
      hasForm
      zIndex={9999}
      onClose={onClose}
      isOpen
    >
      <div className="flex flex-col gap-2">
        <Table
          selectedItems={selectedBriefs}
          onChangeSelectedItems={setSelectedBriefs}
          data={step === 1 ? allBriefs : filteredBriefList}
          columns={tableColumns}
        />
      </div>
      <div className="flex justify-between space-x-2 mt-8 pb-3 sticky bottom-0 z-50 bg-light_red pt-4">
        <div>
          {step === 2 && (
            <FButton
              onClick={() => setStep(1)}
              label="Go Back"
              width="100%"
              height="40px"
            />
          )}
        </div>
        {step === 2 && (
          <div className="flex items-center justify-center h-[40px] bg-light_yellow px-4 rounded-sm border border-dark_yellow">
            <p className="text-black">
              Saving will replace these briefs and cannot be undone.
            </p>
          </div>
        )}
        <div className="flex justify-end space-x-2">
          <FButton
            onClick={onClose}
            label="Cancel"
            width="100%"
            height="40px"
          />
          {step === 1 ? (
            <FButton
              onClick={() => setStep(2)}
              label={`Next (${selectedBriefs.length})`}
              icon={{
                name: "fancy-arrow-right",
                size: 16,
                color: "white",
                className: "ml-2",
              }}
              width="100%"
              height="40px"
              disabled={selectedBriefs.length === 0}
              primary
            />
          ) : (
            <FButton
              onClick={() => onSaveAndApply(selectedBriefs, allBriefs)}
              primary
              loading={waiting}
              label="Save"
              width="100%"
              height="40px"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SaveOverrideModal;
