import React from "react";

import { maxBy } from "lodash";
import { DemographicsBreakdownItem } from "@types";

import WidgetContainer from "./WidgetContainer";
import DoughnutGraph from "@components/Graphs/DoughnutGraph";

interface GenderBreakdownProps {
  items: DemographicsBreakdownItem[];
  showCounts?: boolean;
}

export default function GenderBreakdownChart({
  items,
  showCounts,
}: GenderBreakdownProps) {
  const highestItem = maxBy(items, "percentage");

  return (
    <WidgetContainer title="Gender" value={highestItem?.name}>
      <DoughnutGraph
        data={items}
        entityName="Percentage"
        colors={["#E47667", "#FFC6BF", "#EFE4E2"]}
        showLegend
        showCounts={showCounts}
        subtitle={`${highestItem?.percentage}%`}
      />
    </WidgetContainer>
  );
}
