import * as React from "react";
import Summary from "./components/Summary";

export default function DirectoryDashboard({ brandHandle = "fohr" }) {
  return (
    <>
      <Summary brandHandle={brandHandle} />
    </>
  );
}
