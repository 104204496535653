import FDropdown from "@components/FDropdown";
import { FInput, FTextarea } from "@components/FInputs";
import FMultiSelect from "@components/FMultiSelect";

import { AmbassadorQuestion, FieldType } from "types/ambassador-question";

interface QuestionProps {
  answer: string | string[];
  question: AmbassadorQuestion;
  onUpdateAnswer: (answer: string | string[]) => void;
  validate: boolean;
}

const Question = ({
  question,
  answer,
  onUpdateAnswer,
  validate,
}: QuestionProps) => {
  const { question: questionLabel, required, data, field_type } = question;

  const commonProps = {
    label: questionLabel,
    required,
    validate,
    width: "100%",
  };

  if (field_type === FieldType.TEXT) {
    return (
      <FInput
        onChange={onUpdateAnswer}
        value={`${answer || ""}`}
        {...commonProps}
      />
    );
  }

  if (field_type === FieldType.TEXTAREA) {
    return (
      <FTextarea
        onChange={onUpdateAnswer}
        rows={3}
        value={`${answer || ""}`}
        {...commonProps}
      />
    );
  }

  const dropdownProps = {
    ...commonProps,
    options: data.options.map((value) => ({
      value,
      label: value,
    })),
  };

  if (field_type === FieldType.SINGLE_SELECT) {
    return (
      <FDropdown
        hasNoDefault
        selectedValue={answer as string}
        onChange={(value) => onUpdateAnswer(value as string)}
        required={required}
        {...dropdownProps}
      />
    );
  }

  if (field_type === FieldType.MULTI_SELECT) {
    return (
      <FMultiSelect
        selected={answer as string[]}
        onChange={(value) => onUpdateAnswer(value as string[])}
        required={required}
        {...dropdownProps}
      />
    );
  }
};

export default Question;
