import { useState, useEffect } from "react";
import resolvePath from "@utils/resolvePath"; // Utility function to access nested properties

const useSortAndFilter = (
  initialData,
  filterOptions,
  sortPathName,
  sortPathDate,
  filterPath,
  onSortChange
) => {
  const [data, setData] = useState(initialData);
  const [sortCriteria, setSortCriteria] = useState({
    [sortPathDate]: "none", // Default sorting for date
    title: "none", // Default sorting for title
  });
  const [selectedFilter, setSelectedFilter] = useState(
    filterOptions?.[0]?.value || null
  );

  useEffect(() => {
    let sortedFilteredData = [...initialData];

    // Sorting logic
    sortedFilteredData.sort((a, b) => {
      if (sortCriteria[sortPathDate] !== "none" && sortPathDate) {
        const aValue = resolvePath(a, sortPathDate);
        const bValue = resolvePath(b, sortPathDate);
        const dateA = new Date(aValue);
        const dateB = new Date(bValue);
        if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
          const dateDiff = dateA.getTime() - dateB.getTime(); // Explicitly using getTime()
          if (dateDiff !== 0)
            return sortCriteria[sortPathDate] === "asc" ? dateDiff : -dateDiff;
        }
      }
      if (sortCriteria.title !== "none" && sortPathName) {
        const aValue = resolvePath(a, sortPathName);
        const bValue = resolvePath(b, sortPathName);
        const titleDiff = aValue.trim().localeCompare(bValue.trim());

        return sortCriteria.title === "asc" ? titleDiff : -titleDiff;
      }
      return 0;
    });

    // Filtering logic
    sortedFilteredData = filterPath
      ? sortedFilteredData.filter((item) => {
          const itemValue = resolvePath(item, filterPath);
          return selectedFilter === null || itemValue === selectedFilter;
        })
      : sortedFilteredData;

    setData(sortedFilteredData);
  }, [
    initialData,
    sortCriteria,
    selectedFilter,
    sortPathName,
    sortPathDate,
    filterPath,
  ]);

  return {
    data: onSortChange ? initialData : data,
    setSortCriteria,
    setSelectedFilter,
    sortCriteria,
    selectedFilter,
  };
};

export default useSortAndFilter;
