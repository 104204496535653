import React from "react";
import SkeletonLoader from "./SkeletonLoader";

export default function KPIWithSparklineSkeleton() {
  return (
    <div className="flex items-center py-4">
      <div className="mr-4 min-w-0">
        <SkeletonLoader type="text" width="w-24" />
        <SkeletonLoader type="metric" width="w-32" />
      </div>
      <div className="flex-shrink-0">
        <SkeletonLoader type="sparkline" />
      </div>
    </div>
  );
}
