import React from "react";

function StackedBarChart({
  data = [],
  colors = [],
  height = 20,
  showLegend,
  display = "",
}) {
  let gridStyle = {};
  if (display === "grid") {
    gridStyle = {
      display: "grid",
      gridTemplateColumns: "repeat(2, auto)",
      gap: "8px",
      marginTop: 60,
    };
  }

  return (
    <div>
      <div className="chart-container">
        <div className="stacked-bar-row">
          <div className="stacked-bar" style={{ height: `${height}px` }}>
            {data.map((item, index) => (
              <div
                key={item.name}
                className="stacked-bar-segment"
                style={{
                  width: `${item.percentage}%`,
                  backgroundColor: colors[index],
                }}
              />
            ))}
          </div>
        </div>
      </div>
      {showLegend && (
        <div
          className="chart-legend justify-center justify-content-center"
          style={gridStyle}
        >
          {data.map((item, index) => (
            <div key={item} className="legend-item">
              <div className="legend-text flex d-flex">
                <span
                  className="legend-color-block"
                  style={{ backgroundColor: colors[index] }}
                />
                <span className="mr-[8px] u-mr-8">
                  {item.name} {Math.round(item.percentage)}%
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default StackedBarChart;
