import Modal from "@components/Modals/Modal";
import { Campaign, Participant, ParticipantGroup } from "@types";
import ParticipantGroupEditForm from "../forms/ParticipantGroupEditForm";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  createParticipantGroup,
  updateParticipantGroup,
} from "@api/Campaign/CampaignParticipantGroups";
import { updateCampaignParticipant } from "@api/Campaign/CampaignParticipants";
import toast from "react-hot-toast";
import Table from "@components/Table";

const TABLE_COLUMNS = [
  {
    key: "full_name",
    label: "Name",
    isLink: true,
    linkURL: "{id}",
    isImage: true,
    imageType: "profile" as "asset" | "profile",
    imageKey: "profile_image",
    isFirstColumn: true,
  },
];

interface UpdateParticipantGroupModalProps {
  onClose: () => void;
  campaign: Campaign;
  group: ParticipantGroup;
  onUpdated: (campaign: Partial<Campaign>) => void;
}

const UpdateParticipantGroupModal = ({
  campaign,
  group,
  onClose,
  onUpdated,
}: UpdateParticipantGroupModalProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const { campaign_id } = useParams<{ campaign_id: string }>();

  const { participants, participant_groups } = campaign;

  const [selectedParticipants, setSelectedParticipants] = useState<
    Participant[]
  >([]);

  const updateGroup = async (groupData: ParticipantGroup) => {
    let groupToUpdate = group;

    let updatedParticipantGroups;
    let updatedParticipants = participants;

    try {
      if (!groupData.id) {
        if (!groupData.name || selectedParticipants.length !== 0) {
          setSubmitted(true);

          return;
        }

        setIsSaving(true);

        // Create new group
        const newGroupResponse = await createParticipantGroup(campaign_id, {
          name: groupData.name,
          status: groupData.status,
        });

        groupToUpdate = newGroupResponse.data;

        updatedParticipantGroups = [groupToUpdate, ...participant_groups];
      } else {
        if (!groupData.name) {
          setSubmitted(true);

          return;
        }

        setIsSaving(true);

        await updateParticipantGroup(group.id, {
          ...groupToUpdate,
          ...groupData,
        });

        if (groupData.status === "archived") {
          const participantsToArchive = participants.filter(
            ({ participant_group_id }) => participant_group_id === groupData.id
          );

          for (const participant of participantsToArchive) {
            await updateCampaignParticipant(participant.id, {
              status: groupData.status,
            });
          }

          updatedParticipants = participants.map((p) =>
            participantsToArchive.find(({ id }) => id === p.id)
              ? { ...p, status: groupData.status }
              : p
          );
        }

        updatedParticipantGroups = participant_groups.map((p) =>
          p.id === groupData.id
            ? {
                ...p,
                ...groupData,
              }
            : p
        );
      }

      if (selectedParticipants.length) {
        const updatePromises = selectedParticipants.map((p) =>
          updateCampaignParticipant(p.id, {
            participant_group_id: groupToUpdate.id,
          })
        );

        await Promise.all(updatePromises);

        updatedParticipants = participants.map((p) =>
          selectedParticipants.find(({ id }) => id === p.id)
            ? { ...p, participant_group_id: groupToUpdate.id }
            : p
        );
      }

      onUpdated({
        participant_groups: updatedParticipantGroups,
        participants: updatedParticipants,
      });
    } catch (e) {
      toast.error("Error updating group");
    } finally {
      setIsSaving(false);
    }
  };

  const participantsWithoutGroups =
    participants &&
    participants.filter(({ participant_group_id }) => !participant_group_id);

  const reformedParticipants = participantsWithoutGroups.map((participant) => {
    const { creator } = participant;

    const {
      firstName,
      lastName,
      profile_image_url: profileImageUrl,
    } = creator || {};

    return {
      ...participant,
      full_name: creator ? `${firstName} ${lastName}` : "Unknown",
      profile_image: creator ? profileImageUrl : "",
    };
  });

  return (
    <Modal
      isOpen
      onClose={onClose}
      zIndex={9000}
      title={group.id ? `Edit Group - ${group.name}` : "Create Group"}
      hasForm
      actionLabel="Save"
    >
      <ParticipantGroupEditForm
        isSaving={isSaving}
        group={group}
        isSubmitted={isSubmitted}
        onSubmit={updateGroup}
        onClose={onClose}
      >
        {reformedParticipants.length ? (
          <div className="mt-8">
            <label className=" text-sm leading-[18px]">Add Participants</label>
            {isSubmitted && !group.id && !selectedParticipants.length ? (
              <p className="text-red text-xs font-normal tracking-[0.12px] mt-[6px]">
                Please select at least one participant
              </p>
            ) : null}
            <Table
              data={reformedParticipants}
              columns={TABLE_COLUMNS}
              onChangeSelectedItems={(ids) =>
                setSelectedParticipants(
                  participants.filter(({ id }) => ids.includes(id))
                )
              }
              selectedItems={selectedParticipants.map(({ id }) => id)}
            />
          </div>
        ) : null}
      </ParticipantGroupEditForm>
    </Modal>
  );
};

export default UpdateParticipantGroupModal;
