import React, { useState } from "react";
import { Link } from "react-router-dom";

interface Props {
  className?: string;
  name?: string;
  underSectionData?: React.ReactNode;
  underSectionTitle?: string;
  selectedValue?: string | number;
  items: {
    label: string;
    value?: any;
    link?: string;
    active?: boolean;
    disabled?: boolean;
  }[];
  onChange?: (value: string | number) => void;
  style?: any;
}

const FSubNav = ({ className = "", items = [], onChange, ...props }: Props) => {
  const [showUnder, setShowUnder] = useState(true);

  const toggleUnderSection = () => {
    setShowUnder(!showUnder);
  };

  const getItemColors = (item) => {
    if (item.disabled) {
      return "text-neutral_400";
    } else if (item.active) {
      return "cursor-pointer border-b-2 border-black";
    } else {
      return "cursor-pointer border-b-2 border-white";
    }
  };

  return (
    <div
      style={{
        width: "100%",
        ...props.style,
      }}
      className={className}
    >
      <div className={`w-full border-b  border-light_border`}>
        <div className="flex h-full items-center font-normal  text-base overflow-x-auto gap-[40px]">
          {items.map((item, index) => {
            const key = `${item.value}-${index}`;
            if (item.link) {
              return (
                <Link
                  key={key}
                  to={item.link}
                  className={`pt-[16px] pb-[14px] text-center whitespace-nowrap ${getItemColors(
                    item
                  )}`}
                  onClick={() => onChange && onChange(item.value)}
                >
                  {item.label}
                </Link>
              );
            } else {
              return (
                <p
                  key={key}
                  className={`pt-[16px] pb-[14px] text-center whitespace-nowrap ${getItemColors(
                    item
                  )}`}
                  onClick={() => {
                    if (item.disabled) return;
                    if (onChange) onChange(item.value);
                  }}
                >
                  {item.label}
                </p>
              );
            }
          })}
        </div>
      </div>
      {props.underSectionData && (
        <div className="flex flex-col items-start justify-start py-[16px] border-b border-neutral_400 gap-[10px]">
          <div className="flex flex-row items-center gap-[8px] justify-between text-black text-[16px]">
            <p className="">{props.underSectionTitle}</p>
            <p className="">•</p>
            <p
              className="cursor-pointer underline"
              onClick={toggleUnderSection}
            >
              {showUnder ? "Hide" : "Show"}
            </p>
          </div>

          {showUnder && props.underSectionData}
        </div>
      )}
    </div>
  );
};

export default FSubNav;
