import FIcon from "@components/FIcon";
import VideoPlayer from "@components/Post/VideoPlayer";
import { Content } from "@types";

interface ContentPreviewProps {
  content: Partial<Content>;
  isPaused: boolean;
  onTogglePlayPause?: () => void;
  onVideoEnded?: () => void;
  showVideoControls?: boolean;
  videoRef;
}

const ContentPreview = ({
  content,
  isPaused,
  onVideoEnded,
  onTogglePlayPause,
  videoRef,
  showVideoControls,
}: ContentPreviewProps) => {
  const { photo_url, permalink, permalink_url, video_url } = content;

  return (
    <>
      {video_url && onTogglePlayPause && (
        <div
          className="absolute top-3 right-3 z-10 bg-black opacity-50 hover:opacity-100 p-2 rounded-full cursor-pointer"
          onClick={onTogglePlayPause}
        >
          {isPaused ? (
            <FIcon icon="play-button" color="white" size={12} />
          ) : (
            <FIcon icon="pause" color="white" size={12} />
          )}
        </div>
      )}
      <a href={permalink} target="_blank">
        <div
          className={`slide relative h-full ${showVideoControls ? "pb-[70px]" : ""}`}
        >
          {permalink_url ? (
            <VideoPlayer
              className="bg-white justify-center w-full"
              post={content}
            />
          ) : video_url ? (
            <video
              ref={videoRef}
              src={video_url}
              autoPlay={!isPaused}
              muted
              onEnded={onVideoEnded}
              className="w-full h-full object-cover"
              controls={showVideoControls}
            />
          ) : (
            <div
              className="w-full h-full bg-cover bg-center flex justify-center items-center"
              style={{
                backgroundImage: `url(${photo_url})`,
              }}
            />
          )}
          {isPaused && onTogglePlayPause && (
            <div className="absolute inset-0 flex justify-center items-center bg-light_red bg-opacity-30">
              <FIcon icon="pause" color="white" size={32} />
            </div>
          )}
        </div>
      </a>
    </>
  );
};

export default ContentPreview;
