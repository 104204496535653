import React from "react";
import Legend from "./components/Legend";
import { isEmpty } from "lodash";

interface TriangleChart {
  data: { name: string; percentage: number }[];
  height: number;
}

function TriangleChart({ data = [], height = 200 }) {
  const colors = ["#E47667", "#F8DEDA"];

  if (isEmpty(data)) {
    return null;
  }

  const largerPercentage = data[0].percentage;
  const smallerPercentage = data[1].percentage;

  // Calculate the widths of the triangles based on the percentages
  const largerTriangleSize = 126 * (largerPercentage / 100);
  const smallerTriangleWidth = 126 * (smallerPercentage / 100);

  return (
    <div className="w-full">
      <div className="flex items-center justify-center mb-[40px] w-full">
        <div className="flex items-center ml-4" style={{ height: height }}>
          <div className="flex items-end d-flex align-items-end">
            <svg
              height="0"
              width="0"
              className="svg-clip"
              style={{ position: "absolute" }}
            >
              <defs>
                <clipPath id="clip" clipPathUnits="objectBoundingBox">
                  <path d="M1,.21 Q1,0 .81,.09L.187,.4 Q0,.5 .187,.59L.81,.90 Q1,1 1,.79Z" />
                </clipPath>
              </defs>
            </svg>
            <div
              className="triangle-chart"
              style={{
                width: `${largerTriangleSize}px`,
                height: `${largerTriangleSize}px`,
                backgroundColor: "#FDA667",
              }}
            >
              <span className="triangle-chart-text">{largerPercentage}%</span>
            </div>
            <div
              className="triangle-chart"
              style={{
                width: `${smallerTriangleWidth}px`,
                height: `${smallerTriangleWidth}px`,
                backgroundColor: "#F8DEDA",
                marginLeft: "-12px",
              }}
            />
          </div>
        </div>
      </div>
      <Legend
        labels={data.map((item, index) => ({
          color: colors[index],
          label: `${Math.round(item.percentage)}% ${item.name}`,
        }))}
      />
    </div>
  );
}

export default TriangleChart;
