import React, { useState } from "react";
import FIcon from "../FIcon";
import VideoPlayer from "./VideoPlayer";

function PostComplete({ post, height, width, hidePlatformIcon, showPostType }) {
  const getContentType = () => {
    if (
      post.is_video ||
      post.media_type == "Reels" ||
      post.platform === "youtube" ||
      (post.image_content_type && post.image_content_type.includes("video"))
    ) {
      return "video";
    }
    return "image";
  };

  const photo_url = post.photo_url || post.image?.large;

  const contentType = getContentType();
  const permalink =
    post.permalink && post.permalink !== "http://" ? post.permalink : null;
  const [validImage, setValidImage] = useState(
    photo_url && !photo_url.includes("missing.png") ? true : false
  );

  const handleError = () => {
    setValidImage(false);
  };

  const renderVideo = () => {
    const { image } = post;

    const video_url = post.video_url || post.photo_url || image.original;

    return (
      <VideoPlayer
        hidePlatformIcon={hidePlatformIcon}
        post={{ ...post, video_url }}
      />
    );
  };

  const renderImagePost = () => (
    <div className="h-full w-full">
      {photo_url && validImage && (
        <img
          className="post__image h-full w-full object-contain"
          src={photo_url}
          alt=" "
          onError={handleError}
        />
      )}
      {(!validImage || !photo_url) && (
        <div className="post__image flex justify-center items-center w-full h-full">
          <FIcon
            icon="photo"
            size={35}
            color="#000F45"
            className="inline-block vertical-align-middle"
          />
        </div>
      )}
      {post.platform === "facebook" && !post.photo_url && post.caption && (
        <div
          className="absolute z-10 w-full px-[18px] top-[70px] overflow-auto"
          style={{ height: "calc(100% - 78px)" }}
        >
          <strong>Text Post:</strong>
          <p>{post.caption}</p>
        </div>
      )}
      {showPostType && (
        <div className="post-type-tag">
          <span className="text-on-overlay">{post.media_type}</span>
        </div>
      )}
      {!hidePlatformIcon && (
        <a href={permalink} target="_blank" rel="noreferrer">
          <span className="post__icon-container">{post.platform}</span>
        </a>
      )}
    </div>
  );
  return (
    <div className="post__image-container" style={{ height, width }}>
      {contentType === "image" && renderImagePost()}
      {contentType === "video" && renderVideo()}
    </div>
  );
}

export default PostComplete;
