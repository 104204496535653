import apiWrapper from "@apiWrapper";
import { ParticipantOffer } from "@types";

export const getParticipantOffers = async (
  participantId: number
): Promise<ParticipantOffer[]> => {
  const queryParams = new URLSearchParams();

  queryParams.append("q[participant_id_eq]", participantId.toString());

  const response = await apiWrapper(
    `/participant_offers?${queryParams.toString()}`,
    "GET"
  );

  return response.data.participant_offers;
};
