import React from "react";
import FIcon from "@components/FIcon";
import TitleUnder from "@components/TitleUnder";

interface HeaderProps {
  coverImage?: boolean;
  coverImageSrc?: string;
  fullScreen?: boolean;
  className?: string;
  highLevel?: {
    icon: string;
    name: string;
    value: string;
  }[];
  title: string;
  pageOwner?: {
    icon: string;
    name: string;
    value: string;
  };
  subTitle?: string;
  pageIcon?: string;
  dashBoardItem?: React.ReactNode;
  headerButtons?: React.ReactNode;
}

const Heading: React.FC<HeaderProps> = ({
  className = "",
  fullScreen,
  coverImage,
  coverImageSrc,
  title,
  pageIcon,
  subTitle,
  highLevel,
  dashBoardItem,
  pageOwner,
  headerButtons,
}) => {
  const isValidCoverImage =
    coverImage &&
    coverImageSrc &&
    !coverImageSrc.includes("missing_default") &&
    coverImageSrc.trim() !== "";

  return (
    <div
      className={`${fullScreen ? "px-[15px] sm:px-[40px] z-10" : className}`}
    >
      {pageIcon ? (
        <div className="flex items-center gap-4">
          <div className="w-[40px] h-[40px] bg-highlight_red  border-black rounded flex items-center justify-center">
            <FIcon icon={pageIcon} color="#000F45" size={20} />
          </div>
          <h1 className="h-[30px] leading-[36px] text-black text-[28px] self-center">
            {title}
          </h1>
        </div>
      ) : (
        <div className="flex flex-row justify-between w-full gap-4">
          <div className="flex flex-col">
            {pageOwner && <TitleUnder details={[pageOwner]} />}
            <h1 className="text-black text-[28px]">{title}</h1>
          </div>

          {headerButtons && (
            <div className="flex flex-row gap-2">{headerButtons}</div>
          )}
        </div>
      )}

      {subTitle && <h6 className="text-[14px] ">{subTitle}</h6>}
      {highLevel && <TitleUnder details={highLevel} />}
      {dashBoardItem && <>{dashBoardItem}</>}
      {coverImage &&
        (isValidCoverImage ? (
          <div
            className="w-[220px] mt-8 h-[220px] bg-cover bg-center relative overflow-hidden rounded-2xl"
            style={{ backgroundImage: `url("${coverImageSrc}")` }}
          />
        ) : (
          <div className="w-full mt-8 h-[220px] flex items-center justify-center bg-highlight_red rounded-2xl">
            <FIcon icon="image" size={40} color="#000F45" />
          </div>
        ))}
    </div>
  );
};

export default Heading;
