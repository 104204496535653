import { CampaignContext } from "@contexts/index";

import { Participant } from "@types";
import { useContext, useState } from "react";

import AgeBreakdown from "./components/AgeBreakdown";
import GenderBreakdown from "./components/GenderBreakdown";
import EthnicityBreakdown from "./components/EthnicityBreakdown";
import StatCardWithGradientBar from "@pages/profile/components/StatCardWithGradientBar";
import { sumBy } from "lodash";
import SexualOrientationBreakdown from "./components/SexualOrientationBreakdown";
import FSubNav from "@components/FSubNav";

const FILTERS = [
  {
    value: "",
    label: "All",
  },
  {
    value: "instagram",
    label: "Instagram",
  },
  {
    value: "tiktok",
    label: "TikTok",
  },
];

const getTotal = (
  participants: Participant[],
  metric: string,
  filter: string,
  isPercentage = false
) =>
  sumBy(participants, (originalParticipant) => {
    let participant = originalParticipant;

    if (originalParticipant.instagram_stats) {
      const { reels_views_percentage, stories_views_percentage } =
        originalParticipant.instagram_stats;

      participant = {
        ...originalParticipant,
        instagram_stats: {
          ...originalParticipant.instagram_stats,
          views_percentage:
            (reels_views_percentage + stories_views_percentage) / 2,
        },
      };
    }

    const { instagram_stats = {}, tiktok_stats = {} } = participant;

    const value = filter
      ? (participant[`${filter}_stats`] || {})[metric] || 0
      : sumBy(
          Object.values({ instagram_stats, tiktok_stats }),
          (value) => (value[metric] || 0) / (isPercentage ? 2 : 1)
        );

    return value;
  });

const getTopStats = (participants, filter) => {
  return [
    {
      title: "Total Reach",
      value: getTotal(participants, "followers", filter),
      tier_average: null,
    },
    {
      title: "Engagement Rate",
      value:
        (100 * getTotal(participants, "engagement_percentage", filter, true)) /
        participants.length,
      tier_average: null,
      isPercentage: true,
    },
    {
      title: "Video Viewership Rate",
      value:
        (100 * getTotal(participants, "views_percentage", filter, true)) /
        participants.length,
      tier_average: null,
      isPercentage: true,
    },
    {
      title: "Video Views",
      value: getTotal(participants, "views", filter),
      tier_average: null,
    },
  ];
};

interface ParticipantReviewStatsProps {
  selectedGroup: string;
}

const ParticipantReviewStats = ({
  selectedGroup,
}: ParticipantReviewStatsProps) => {
  const { campaign } = useContext(CampaignContext);

  const [activeFilter, setActiveFilter] = useState(FILTERS[0].value);

  const {
    participants: allParticipants,
    participant_groups: participantGroups,
  } = campaign;

  const selectedGroupId = parseInt(selectedGroup, 10);

  const participants = allParticipants.filter(
    (participant) =>
      !selectedGroup || selectedGroupId === participant.participant_group_id
  );

  const title = selectedGroup
    ? participantGroups.find(({ id }) => id === selectedGroupId).name
    : "All Participants";

  const statsForCards = getTopStats(participants, activeFilter);

  return (
    <div>
      <h3 className="text-xl mb-12">{title}</h3>
      <FSubNav
        selectedValue={activeFilter}
        items={FILTERS.map((item) => ({
          ...item,
          active: item.value === activeFilter,
        }))}
        onChange={(value) => setActiveFilter(value as string)}
      ></FSubNav>
      <div className="grid grid-cols-4 gap-4 mb-[48px] mt-[40px]">
        {statsForCards.map((card, index) => (
          <StatCardWithGradientBar key={index} {...card} hideComparison />
        ))}
      </div>
      <div className="border-t border-neutral_400 grid grid-cols-1 sm:grid-cols-2 gap-8 mb-8 pt-6">
        <AgeBreakdown participants={participants} />
        <GenderBreakdown participants={participants} />
        <EthnicityBreakdown participants={participants} />
        <SexualOrientationBreakdown participants={participants} />
      </div>
    </div>
  );
};

export default ParticipantReviewStats;
