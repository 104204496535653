import React, { useState, useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import MultiPageLayout from "@layouts/MultiPageLayout";
import apiClient from "@apiClient";
import { BrandEditContext, UserContext } from "@contexts/index";

const subNavItems = [
  {
    label: "Personal",
    value: "personal",
    link: "personal",
  },
  {
    label: "Brand",
    value: "brand",
    link: "brand",
  },
  {
    label: "Data Privacy",
    value: "data privacy",
    link: "privacy",
  },
];

const SettingsWrapper = () => {
  // const { brand_id } = useParams();
  const { user } = useContext(UserContext);

  const [brandSettings, setBrandSettings] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const brandPromises = user.memberships.map((membership) =>
        apiClient.brands.get(membership.brand_id)
      );

      const responses = await Promise.all(brandPromises);
      console.log(responses, "brandsettings");
      setBrandSettings(responses);
    };
    fetchData();
  }, [user.memberships]);

  return (
    <MultiPageLayout pageName="Settings" subNavItems={subNavItems}>
      <BrandEditContext.Provider
        value={{
          brandSettings,
          setBrandSettings,
        }}
      >
        <div className="pt-[40px]">
          <Outlet />
        </div>
      </BrandEditContext.Provider>
    </MultiPageLayout>
  );
};

export default SettingsWrapper;
