import * as React from "react";

function Spinner({
  width = 50,
  height = 50,
  backgroundColor = "#f3f3f3",
  borderColor = "#000F45",
  borderWidth = 10,
  className = "",
  style = {},
}: {
  width?: number;
  height?: number;
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  className?: string;
  style?: any;
}) {
  return (
    <div className={`flex all-center ${className}`} style={style}>
      <div
        className="animate-spin rounded-full"
        style={{
          height,
          width,
          border: `${borderWidth}px solid ${backgroundColor}`,
          borderTop: `${borderWidth}px solid ${borderColor}`,
        }}
      ></div>
    </div>
  );
}

export default Spinner;
