import React, { useState, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import apiClient from "@apiClient";
import { CampaignContext } from "@contexts/index";

import FButton from "@components/FButton";
import FDropdown from "@components/FDropdown";
import { FCheckbox, FInput } from "@components/FInputs";

import { toast } from "react-hot-toast";

import SelectCreatorsTable from "@components/Creator/SelectCreatorsTable";

import { Creator, GroupData } from "@apiTypes";

import SelectGroupsTable from "@components/Creator/SelectGroupsTable";
import { createCampaignParticipantFromGroups } from "@api/Campaign/CampaignParticipants";

import SelectEntitiesModal from "@components/SelectEntities/SelectEntitiesModal";
import { ParticipantStatus } from "@types";

const { campaign_participant_group, campaign_participant } = apiClient;

enum SOURCE {
  CREATORS = "creators",
  OFF_PLATFORM = "off-platform",
  GROUPS = "groups",
}

const SOURCES = [
  { value: SOURCE.CREATORS, label: "Add On-Platform" },
  { value: SOURCE.OFF_PLATFORM, label: "Add Off-Platform" },
  { value: SOURCE.GROUPS, label: "Add by Group" },
];

interface Props {
  defaultParticipantGroupId?: number;
  onClose: () => void;
}

const AddParticipantsModal = ({
  defaultParticipantGroupId,
  onClose,
}: Props) => {
  const { campaign_id } = useParams<{ campaign_id: string }>();
  const { campaign, setCampaign } = useContext(CampaignContext);

  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(null);

  const [selectedSource, setSelectedSource] = useState(SOURCES[0].value);
  const [step, setStep] = useState(1);

  const [selectedCreators, setSelectedCreators] = useState<Creator[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<GroupData[]>([]);

  const [newGroup, setNewGroup] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [addToCreatorReview, setAddToCreatorReview] = useState(false);

  const { participants, participant_groups: participantGroups } = campaign;

  const [selectedParticipantGroupId, setSelectedParticipantGroupId] =
    useState<number>(defaultParticipantGroupId || participantGroups[0]?.id);

  const handleSubmit = async () => {
    let participantGroupId = selectedParticipantGroupId;

    setWaiting(true);
    setError(null);

    if (newGroupName) {
      try {
        const newGroup = await campaign_participant_group.create(campaign_id, {
          name: newGroupName,
          status: "active",
        });
        participantGroupId = newGroup.data.id;

        setCampaign({
          ...campaign,
          participant_groups: [...participantGroups, newGroup.data],
        });
      } catch (error) {
        setError("Failed to create group");

        setWaiting(false);

        return;
      }
    }

    if (selectedSource === SOURCE.GROUPS) {
      try {
        const response = await createCampaignParticipantFromGroups(
          parseInt(campaign_id, 10),
          {
            participant_group_id: participantGroupId,
            group_ids: selectedGroups.map(({ id }) => id),
            status: "pending",
          }
        );

        setCampaign({
          ...campaign,
          participants: [...participants, response.data.participants],
        });

        toast.success("Saved");

        onClose();
      } catch (error) {
        console.error("Error adding participants:", error);
        setError("Failed to add participants");
      }

      return;
    }

    let newCreator;

    const creatorsToAdd = newCreator ? [newCreator] : selectedCreators;

    const newParticipants = [];

    try {
      for (const creator of creatorsToAdd) {
        const response = await campaign_participant.create(
          parseInt(campaign_id, 10),
          {
            participant_group_id: participantGroupId,
            creator_id: creator.id,
            status: addToCreatorReview
              ? ParticipantStatus.REVIEW_PENDING
              : ParticipantStatus.NON,
          }
        );

        newParticipants.push(response.data);
      }

      setCampaign({
        ...campaign,
        participants: [...participants, ...newParticipants],
      });

      toast.success("Saved");

      setWaiting(false);

      onClose();
    } catch (error) {
      setError("Failed to add participants");

      setWaiting(false);
    }
  };

  const extraCreatorColumns = [
    {
      key: "isInCampaign",
      label: "In Campaign",
      isStateBubble: true,
      getValue: (creator) =>
        participants.some(
          (participant) =>
            participant.campaign_influencer_id ===
            creator.campaign_influencer_id
        ),
    },
  ];

  const selectedItems =
    selectedSource === SOURCE.CREATORS ? selectedCreators : selectedGroups;

  const excludeIds = useMemo(
    () => participants.map(({ creator_id: creatorId }) => creatorId),
    [participants]
  );

  return (
    <SelectEntitiesModal
      title="Add Participants"
      subtitle={
        step === 2 && selectedSource === SOURCE.CREATORS
          ? " Place your selection into a specific participant group"
          : null
      }
      onClose={onClose}
      headerContent={
        step === 1 ? (
          <div className="flex">
            {SOURCES.map(({ value, label }) => (
              <FButton
                key={value}
                primary={value === selectedSource}
                onClick={() => setSelectedSource(value)}
                label={label}
                width="274px"
                height="40px"
              />
            ))}
          </div>
        ) : null
      }
      onBack={step === 2 ? () => setStep(1) : null}
      error={step === 2 && error}
      actionButton={
        step === 1 ? (
          <FButton
            onClick={() => setStep(2)}
            label={`Next ${selectedSource === SOURCE.OFF_PLATFORM ? "" : `(${selectedItems.length})`}`}
            icon={{
              name: "fancy-arrow-right",
              size: 16,
              color: "white",
              className: "ml-2",
            }}
            loading={waiting}
            height="40px"
            disabled={
              selectedSource !== SOURCE.OFF_PLATFORM &&
              selectedItems.length === 0
            }
            primary
          />
        ) : (
          <FButton
            loading={waiting}
            onClick={handleSubmit}
            primary
            label="Save"
            height="40px"
          />
        )
      }
    >
      <>
        {step === 1 ? (
          <div style={{ height: "calc(100% - 70px)" }}>
            {selectedSource === SOURCE.CREATORS ? (
              <SelectCreatorsTable
                excludeIds={excludeIds}
                extraColumns={extraCreatorColumns}
                selectedItems={selectedCreators}
                onChangeSelectedItems={setSelectedCreators}
                offPlatform={false}
              />
            ) : null}
            {selectedSource === SOURCE.GROUPS ? (
              <SelectGroupsTable
                selectedItems={selectedGroups}
                onChangeSelectedItems={setSelectedGroups}
              />
            ) : null}
            {selectedSource === SOURCE.OFF_PLATFORM ? (
              <SelectCreatorsTable
                excludeIds={excludeIds}
                extraColumns={extraCreatorColumns}
                selectedItems={selectedCreators}
                onChangeSelectedItems={setSelectedCreators}
                offPlatform
              />
            ) : null}
          </div>
        ) : null}
        {step === 2 ? (
          <>
            <div className="relative flex gap-4">
              {newGroup ? (
                <FInput
                  value={newGroupName}
                  onChange={(value) => setNewGroupName(value)}
                  height="40px"
                  width="100%"
                  className="text-[15px] mb-0"
                  placeholder="Enter a group name"
                />
              ) : (
                <FDropdown
                  options={participantGroups.map((group) => ({
                    label: group.name,
                    value: group.id,
                  }))}
                  onChange={(value) => {
                    setSelectedParticipantGroupId(value as number);
                  }}
                  zIndex={500}
                  height="40"
                  width="100%"
                  selectedValue={selectedParticipantGroupId}
                />
              )}
              <FButton
                onClick={
                  newGroup
                    ? () => {
                        setNewGroup(false);
                        setNewGroupName("");
                      }
                    : () => setNewGroup(true)
                }
                label={newGroup ? "Cancel" : "Create Group"}
                width="160px"
                height="40px"
                className="flex-fit whitespace-nowrap"
              />
            </div>
            <FCheckbox
              className="my-4 w-full"
              label="Add to creator review"
              id="trial"
              onChange={(value) => setAddToCreatorReview(value)}
              checked={addToCreatorReview}
            />
            {selectedSource === SOURCE.CREATORS ? (
              <div className="flex flex-col gap-2">
                <SelectCreatorsTable
                  extraColumns={extraCreatorColumns}
                  selectedItems={selectedCreators}
                  showJustSelected
                />
              </div>
            ) : null}
            {selectedSource === SOURCE.GROUPS ? (
              <div className="flex flex-col gap-2">
                <SelectGroupsTable
                  selectedItems={selectedGroups}
                  showJustSelected
                />
              </div>
            ) : null}
          </>
        ) : null}
      </>
    </SelectEntitiesModal>
  );
};

export default AddParticipantsModal;
