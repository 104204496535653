import React from "react";
import { numberWithKMBFormat } from "@utils/number_utilities";

// Add new interface for derived metrics
interface DerivedMetrics {
  impressionsPerPost: number;
  impressionsPerInfluencer: number;
  engagementsPerPost: number;
  averagePostsPerCampaign: number;
}

interface AnalyticsMessageProps {
  campaigns: number;
  dateRange: string;
  engagementRate: number;
  impressions: number;
  posts: number;
  influencers: number;
  totalEngagements?: number;
}

const getTimeframeText = (dateRange: string) => {
  switch (dateRange.toLowerCase()) {
    case "7 days":
    case "last 7 days":
      return "the last week";
    case "30 days":
    case "last 30 days":
      return "this month";
    case "60 days":
    case "last 60 days":
      return "the past two months";
    case "90 days":
    case "last 90 days":
      return "the past three months";
    default:
      return dateRange;
  }
};

const getDayGreeting = () => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return `Happy ${days[new Date().getDay()]}`;
};

// Only show greeting on first visit or 90-day view
const shouldShowGreeting = (dateRange: string) => {
  return dateRange.includes("90");
};

export default function AnalyticsMessage({
  campaigns,
  dateRange,
  engagementRate,
  impressions,
  posts,
  influencers,
  totalEngagements,
}: AnalyticsMessageProps) {
  const greeting = shouldShowGreeting(dateRange) ? `${getDayGreeting()}! ` : "";

  // Calculate derived metrics
  const derivedMetrics: DerivedMetrics = {
    impressionsPerPost: posts > 0 ? impressions / posts : 0,
    impressionsPerInfluencer: influencers > 0 ? impressions / influencers : 0,
    engagementsPerPost:
      posts > 0 && totalEngagements ? totalEngagements / posts : 0,
    averagePostsPerCampaign: campaigns > 0 ? posts / campaigns : 0,
  };

  // Welcome message for new users
  if (dateRange.includes("90") && campaigns === 0) {
    return (
      <p className="text-[18px] font-light max-w-[800px]">
        {greeting}Welcome to your brand new Fohr dashboard. Here we talk about
        your campaigns, ambassadors, and more.
      </p>
    );
  }

  // No campaigns message
  if (campaigns === 0) {
    return (
      <p className="text-[18px] font-light max-w-[800px]">
        You don't have any active campaigns in {getTimeframeText(dateRange)}.
        Launch a new campaign to start tracking your performance.
      </p>
    );
  }

  const messages = [
    {
      // Only show if we have significant impressions and non-zero engagement
      condition: () =>
        derivedMetrics.impressionsPerPost > 10000 && engagementRate > 0,
      message: () => (
        <>
          {greeting}Your content is reaching wide audiences in{" "}
          {getTimeframeText(dateRange)}. Posts are averaging{" "}
          <span className="text-red">
            {numberWithKMBFormat(derivedMetrics.impressionsPerPost)} impressions
          </span>{" "}
          across <span className="text-red">{campaigns} active campaigns</span>
          {engagementRate > 0.1
            ? `, with a healthy engagement rate of ${engagementRate}%`
            : ""}
          .
        </>
      ),
    },
    {
      condition: () => derivedMetrics.averagePostsPerCampaign > 5,
      message: () => (
        <>
          {greeting}Your <span className="text-red">{campaigns} campaigns</span>{" "}
          are generating lots of content in {getTimeframeText(dateRange)}, with{" "}
          <span className="text-red">
            {numberWithKMBFormat(derivedMetrics.averagePostsPerCampaign)} posts
          </span>{" "}
          per campaign, reaching{" "}
          <span className="text-red">
            {numberWithKMBFormat(impressions)} people
          </span>
          .
        </>
      ),
    },
    {
      condition: () => derivedMetrics.impressionsPerInfluencer > 100000,
      message: () => (
        <>
          {greeting}Your creators are making an impact in{" "}
          {getTimeframeText(dateRange)}. Each influencer averages{" "}
          <span className="text-red">
            {numberWithKMBFormat(derivedMetrics.impressionsPerInfluencer)}{" "}
            impressions
          </span>{" "}
          across <span className="text-red">{campaigns} active campaigns</span>.
        </>
      ),
    },
    {
      // Only show if we have meaningful engagement numbers
      condition: () => derivedMetrics.engagementsPerPost > 1000,
      message: () => (
        <>
          {greeting}Your content is resonating with audiences in{" "}
          {getTimeframeText(dateRange)}, generating{" "}
          <span className="text-red">
            {numberWithKMBFormat(derivedMetrics.engagementsPerPost)}{" "}
            interactions
          </span>{" "}
          per post across{" "}
          <span className="text-red">{numberWithKMBFormat(posts)} pieces</span>{" "}
          of content.
        </>
      ),
    },
    {
      condition: () => true,
      message: () => (
        <>
          {greeting}Managing{" "}
          <span className="text-red">{campaigns} active campaigns</span> in{" "}
          {getTimeframeText(dateRange)}, your content has reached{" "}
          <span className="text-red">
            {numberWithKMBFormat(impressions)} people
          </span>
          .
        </>
      ),
    },
  ];

  const applicableMessage =
    messages.find((m) => m.condition())?.message() || "";

  return (
    <p className="text-[18px] font-light max-w-[800px]">{applicableMessage}</p>
  );
}
