import React, { useEffect, useState } from "react";
import { createAdminBrandUser } from "@api/Admin/AdminBrands";
import { toast } from "react-hot-toast";
import { FInput } from "@components/FInputs";

import Modal from "@components/Modals/Modal";

interface CreateBrandUserModalProps {
  onClose: () => void;
  onCreated: () => void;
  isOpen: boolean;
  brandId: number;
}

export default function CreateBrandUserModal({
  isOpen,
  onClose,
  onCreated,
  brandId,
}: CreateBrandUserModalProps) {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const handleCreateBrandUser = async () => {
    if (!brandId || !firstName || !lastName || !email) {
      toast.error("All fields are required");
      return;
    }
    try{
      setLoading(true);
      const response = await createAdminBrandUser({
        brand_id: brandId,
        first_name: firstName,
        last_name: lastName,
        email: email,
      });
      if (response.success) {
        toast.success("Brand user created successfully");
        onCreated();
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to create brand user");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      actionLabel="Create Brand User"
      className="w-[327px] overflow-auto"
      isOpen={isOpen}
      onClose={onClose}
      onAction={handleCreateBrandUser}
      title="Add Brand User"
      waiting={loading}
    >
      <div className="flex flex-col gap-6 w-full">
        <FInput 
          label="First Name"
          className="w-full"
          value={firstName}
          onChange={(value) => setFirstName(value)}
        />
        <FInput
          label="Last Name"
          className="w-full"
          value={lastName}
          onChange={(value) => setLastName(value)}
        />
        <FInput
          label="Email"
          value={email}
          onChange={(value) => setEmail(value)}
          className="w-full"
        />
      </div>
    </Modal>
  );
}
