import React from "react";
import SkeletonLoader from "@components/Dashboard/SkeletonLoader";

export default function NotificationSkeleton() {
  return (
    <div className="pr-4">
      <div className="flex justify-between items-center mb-4">
        <SkeletonLoader type="text" width="w-48" />
        <SkeletonLoader type="text" width="w-16" />
      </div>

      {/* Notification Groups */}
      <div className="space-y-6">
        {[...Array(2)].map((_, groupIndex) => (
          <div
            key={groupIndex}
            className="border border-light_border rounded-lg bg-light_red shadow-md"
          >
            {/* Group Header */}
            <div className="flex justify-between items-center p-4">
              <div className="flex items-center gap-2">
                <SkeletonLoader type="text" width="w-32" />
                <SkeletonLoader type="text" width="w-8" />
              </div>
              <SkeletonLoader type="text" width="w-16" />
            </div>

            {/* Notification Items */}
            <div>
              {[...Array(3)].map((_, itemIndex) => (
                <div
                  key={itemIndex}
                  className="px-4 py-2 border-t border-light_border"
                >
                  <div className="flex items-center gap-3">
                    <SkeletonLoader
                      type="sparkline"
                      width="w-10"
                      height="h-10"
                    />
                    <div className="flex-1">
                      <div className="flex items-center gap-2 mb-1">
                        <SkeletonLoader type="text" width="w-32" />
                        <SkeletonLoader
                          type="sparkline"
                          width="w-2"
                          height="h-2"
                        />
                      </div>
                      <SkeletonLoader type="text" width="w-48" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
