import React from "react";
import SkeletonLoader from "@components/Dashboard/SkeletonLoader";

interface PostSnapshotSkeletonProps {
  maxPosts?: number;
  title: string;
}

export default function PostSnapshotSkeleton({
  maxPosts = 4,
  title,
}: PostSnapshotSkeletonProps) {
  return (
    <div className="">
      <SkeletonLoader type="text" width="w-48" />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
        {[...Array(maxPosts)].map((_, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow overflow-hidden aspect-[4/3]"
          >
            <div className="h-full flex flex-col">
              <div className="relative pt-[75%]">
                {" "}
                {/* 4:3 aspect ratio */}
                <div className="absolute inset-0">
                  <SkeletonLoader
                    type="sparkline"
                    width="w-full"
                    height="h-full"
                  />
                </div>
              </div>
              <div className="p-4 space-y-2">
                <SkeletonLoader type="text" width="w-1/2" />
                <div className="flex justify-between">
                  <SkeletonLoader type="text" width="w-1/4" />
                  <SkeletonLoader type="text" width="w-1/4" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
