import React, { useState } from "react";
import AddedNav from "@components/AddedNav";
import { useParams } from "react-router-dom";
import { CampaignBrief } from "@types";

interface AddedNavLayout {
  activeBrief: CampaignBrief;
  subNavItems: {
    label: string;
    value: string;
  }[];
  titleSection?: React.ReactNode;
  children?: React.ReactNode;
  activeSection?: string;
  onNavClick?: (section: string) => void;
}

const AddedNavLayout = ({
  activeBrief,
  children,
  subNavItems = [],
  titleSection,
  activeSection,
  onNavClick,
}: AddedNavLayout) => {
  const { participant_id } = useParams<{
    participant_id: string;
  }>();
  const [selectedNav, setSelectedNav] = useState<string>();

  const handleNavChange = (newValue: string) => {
    setSelectedNav(newValue);
  };

  return (
    <div
      className={`w-full ${participant_id ? "max-w-full" : "max-w-[1200px]"}`}
    >
      <div className="flex flex-row w-full">
        <div className="max-w-[calc(100%-212px)] flex flex-col flex-grow">
          {titleSection}
          {children}
        </div>
        <div className="pl-16 w-[212px] font-sofia_pro">
          <AddedNav
            activeBrief={activeBrief}
            className="pt-0"
            items={subNavItems.map((item) => ({
              ...item,
              active: item.value === (activeSection || selectedNav),
            }))}
            selectedValue={activeSection || selectedNav}
            onChange={(value) => {
              if (onNavClick) {
                onNavClick(value as string);
              } else {
                handleNavChange(value as string);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddedNavLayout;
