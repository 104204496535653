import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Legend from "./components/Legend";

const getOptions = (showCounts) => ({
  chart: {
    width: 282,
    height: 50,
    type: "bar",
  },
  tooltip: {
    pointFormatter: function () {
      return `<b>${this.y}%</b>${showCounts ? ` (${this.count} user${this.count === 1 ? "" : "s"})` : ``}`;
    },
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    max: 100,
    min: 0,
    title: {
      text: "",
    },
    visible: false,
  },
  plotOptions: {
    series: {
      stacking: "normal",
    },
    bar: {
      pointWidth: 20,
    },
  },
});

interface StackedBarGraphProps {
  data: { name: string; value: number; count?: number }[];
  colors: string[];
  showCounts?: boolean;
}

export default function StackedBarGraph({
  data = [],
  colors = [],
  showCounts,
}: StackedBarGraphProps) {
  const reversedColors = colors.slice(0, data.length).reverse();

  const options = {
    ...getOptions(showCounts),
    colors: reversedColors,
    series: data
      .map(({ name, value, count }) => ({
        name,
        data: [
          {
            y: value,
            name: name,
            count,
          },
        ],
      }))
      .reverse(),
  };

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div className="mt-14" />
      <Legend
        labels={data.map((item, index) => ({
          color: colors[index],
          label: `${item.value}% ${item.name}`,
        }))}
      />
    </div>
  );
}
