import FIcon from "@components/FIcon";
import Table from "@components/Table";
import { MonthlyStats, Platform } from "@types";

const TABLE_COLUMNS = [
  {
    key: "metric",
    label: "",
  },
  {
    key: "value",
    label: "",
    getValue: (row) =>
      `${(row.value || "").toLocaleString()}${row.isPercentage ? "%" : ""}`,
  },
  {
    key: "change_number",
    label: "30-Day +/-",
    isDifference: true,
  },
  {
    key: "change_percentage",
    label: "30-Day %",
    isDifference: true,
  },
];

const getData = (monthlyStats: MonthlyStats) => {
  const { engagement, followed_by, media, likes, comments } =
    monthlyStats.stats || {};

  return [
    {
      metric: "Followers",
      value: followed_by,
    },
    {
      metric: "Posts",
      value: media,
    },
    {
      metric: "Likes",
      value: likes,
    },
    {
      metric: "Comments",
      value: comments,
    },
    {
      metric: "Engagement %",
      value: engagement,
    },
  ].map(({ metric, value }) => ({
    metric,
    value: value?.value,
    change_number: value?.change_number,
    change_percentage: value?.change_percentage,
  }));
};

interface MonthlyComparisonTableProps {
  monthlyStats: MonthlyStats;
  platform?: Platform | null;
}

const MonthlyComparisonTable = ({
  monthlyStats,
  platform,
}: MonthlyComparisonTableProps) => {
  const data = getData(monthlyStats);

  return (
    <div className="">
      {platform ? (
        <a
          className="font-sm text-default_weak mb-0.5"
          href={
            platform?.url.includes("http")
              ? platform?.url
              : `https://${platform?.url}`
          }
        >
          <div className="flex">
            <div className="mr-[8px] content-center">
              <FIcon icon={platform.name} size={16} color="#9398AC" />
            </div>
            <p>{platform?.handle}</p>
          </div>
        </a>
      ) : (
        <p className="font-sm text-default_weak mb-0.5">All platforms</p>
      )}
      <p className="text-2xl"></p>
      <Table columns={TABLE_COLUMNS} data={data} />
    </div>
  );
};

export default MonthlyComparisonTable;
