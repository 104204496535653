import FButton from "@components/FButton";
import { Content } from "@types";

interface PermalinkContentItemProps {
  content: Content;
  onEdit: () => void;
  onDelete: () => void;
  isDeleting: boolean;
}

const PermalinkContentItem = ({
  content,
  onEdit,
  onDelete,
  isDeleting,
}: PermalinkContentItemProps) => {
  const { permalink } = content;

  return (
    <div className="relative p-4 border rounded-lg">
      <div className="flex items-center justify-between">
        <a
          href={permalink}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline break-all mr-8"
        >
          {permalink}
        </a>
        <div className="flex gap-2">
          <FButton
            icon={{ color: "#000F45", name: "edit", size: 14 }}
            iconButton
            onClick={onEdit}
          />
          <FButton
            icon={{ color: "#000F45", name: "delete", size: 14 }}
            iconButton
            loading={isDeleting}
            onClick={onDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default PermalinkContentItem;
