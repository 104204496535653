import React from "react";

interface Props {
  style?: any;
  label: string;
  children: any;
}

export const FTooltip = ({ style = {}, label = "", children }: Props) => {
  const baseClass =
    "transition duration-300 opacity-0 pointer-events-none absolute z-[100] text-white  text-[14px] bottom-[105%]";
  const hoverClass = "group-hover:opacity-100";

  const tooltipContent = (
    <div className="group relative flex justify-center">
      {children}
      <div
        className={`${baseClass} bg-dark_night_sky rounded-lg px-[12px] py-[8px] pb-[6px] ${hoverClass}`}
        style={style}
      >
        {label}
      </div>
    </div>
  );

  return <div>{tooltipContent}</div>;
};
