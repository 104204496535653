import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { getQueryParamsForSearchRequest } from "@constants/api";
import { UserNotification } from "@types";

const getUserNotificationList = async (
  page: number,
  params?: {
    per_page?: number;
    notification_id_eq?: number;
    user_id_eq?: number;
    status_eq?: string;
    notification_category_eq?: string;
  }
): Promise<
  ApiResponse<{ user_notifications: UserNotification[]; total_items: number }>
> => {
  const queryParams = getQueryParamsForSearchRequest(page, null, params);

  return apiWrapper(`/user_notifications?${queryParams.toString()}`, "GET");
};

const getUserNotification = async (
  notification_id: number | string
): Promise<ApiResponse<UserNotification>> => {
  return apiWrapper(`/user_notifications/${notification_id}`, "GET");
};

const createUserNotification = async (
  params: Partial<UserNotification>
): Promise<ApiResponse<UserNotification>> => {
  return apiWrapper("/api/v1/user_notifications", "POST", params);
};

const updateUserNotification = async (
  notification_id: number | string,
  params: Partial<UserNotification>
): Promise<ApiResponse<UserNotification>> => {
  return apiWrapper(`/user_notifications/${notification_id}`, "PUT", params);
};

export {
  getUserNotificationList,
  getUserNotification,
  createUserNotification,
  updateUserNotification,
};
