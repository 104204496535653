// How the progressCardItems should look coming in:
// const data = [
//   { copy: 'Add report information', title: 'Overview', complete: start_date }...
// ];

import React from "react";

import ProgressCardItem from "./ProgressCardItem";

export default function ProgressCard({
  header,
  subheader,
  progressCardItems,
  children,
}) {
  const renderProgressCardItems = () =>
    progressCardItems.map(({ complete, title, copy }) => (
      <ProgressCardItem
        key={title}
        complete={complete}
        title={title}
        copy={copy}
      />
    ));

  const renderNumberComplete = () => {
    const numberComplete = progressCardItems.filter(
      (item) => item.complete
    ).length;
    const total = progressCardItems.length;
    return `${numberComplete}/${total}`;
  };

  return (
    <div className="c-page-layout__progress-card">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-12">
          <div className="c-page-layout__progress-card__title">
            <h3 className="c-page-layout__progress-card__title__text">
              {header}
            </h3>
            <h3 className="c-page-layout__progress-card__title__number">
              {renderNumberComplete()}
            </h3>
          </div>
          <p className="c-page-layout__progress-card__copy">{subheader}</p>
          <ul className="c-page-layout__progress-card__progress-list list-unstyled">
            {renderProgressCardItems()}
          </ul>
        </div>
        <div className="col-12 col-md-6 col-lg-12">{children}</div>
      </div>
    </div>
  );
}
