import React from "react";
import "chart.js/auto";
import "../../../public/styles/chart.css";
import Legend from "./components/Legend";
import { maxBy } from "lodash";

const MAX_CIRCLE_SIZE = 83;

const getCircleSize = (percentage, maxPercentage) =>
  (percentage / maxPercentage) * MAX_CIRCLE_SIZE;

interface CircleChartProps {
  data: { name: string; percentage: number }[];
}

function CircleChart({ data }: CircleChartProps) {
  const maxValue = maxBy(data, (item) => item.percentage)?.percentage;

  const colors = ["#DBB200", "#FBD45B", "#FCECBA", "#FEF6DE"];

  return (
    <div>
      <div className={`flex items-center justify-center mt-4 mb-[50px]`}>
        {data.map((item, index) => {
          const circleSize = getCircleSize(item.percentage, maxValue);

          let overlap = 8;
          if (circleSize / 2 < 8) {
            overlap = circleSize / 2;
          }
          return (
            <div
              key={index}
              className={`circle-chart ${index === 0 ? "has-overlay" : ""}`}
              style={{
                width: `${circleSize}px`,
                height: `${circleSize}px`,
                borderRadius: "50%",
                backgroundColor: colors[index],
                marginLeft: index !== 0 ? `-${overlap}px` : "0", // Add negative margin to overlap circles
              }}
            >
              {index === 0 && (
                <span
                  className="circle-chart-overlay"
                  style={{ color: "white" }}
                >
                  {Math.round(item.percentage)}%
                </span>
              )}
            </div>
          );
        })}
      </div>
      <Legend
        labels={data.map((item, index) => ({
          color: colors[index] || "#DBB200",
          label: `${item.name} ${Math.round(item.percentage)}%`,
        }))}
      />
    </div>
  );
}

export default CircleChart;
