import React from "react";

import { maxBy } from "lodash";
import { DemographicsBreakdownItem } from "@types";

import CircleChart from "@components/Graphs/CircleChart";
import WidgetContainer from "@components/charts/demographics/WidgetContainer";

interface ReligionBreakdownProps {
  items: DemographicsBreakdownItem[];
}

export default function ReligionBreakdown({ items }: ReligionBreakdownProps) {
  return (
    <WidgetContainer title="Religion" value={maxBy(items, "percentage")?.name}>
      <CircleChart data={items || []} />
    </WidgetContainer>
  );
}
