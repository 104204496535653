import React, { useEffect, useState } from "react";
import StatsBar from "@components/Dashboard/StatsBar";
import { useParams } from "react-router-dom";
import {
  getCampaignReportSummary,
  getCampaignReportPerformanceGraphs,
  getCampaignPostsEngagementGraph,
  getCampaignReportsAudienceData,
  getReport,
} from "../../api/Campaign/CampaignReports";
import CampaignProgressCard from "@components/Campaign/CampaignProgressCard";
import PerformanceTiles from "@components/PerformanceTiles";
import { capitalize } from "@utils/string_utilities";
import LoadingSpinner from "@components/LoadingSpinner";
import PostsEngagementsGraph from "@pages/campaigns/components/PostsEngagementGraph";
import BlankState from "@components/BlankState";
import ReportContentSummary from "../reports/ReportContentSummary";
import ReportCreatorsTable from "../reports/ReportCreatorsTable";
import ReportInfluencerBreakdown from "../reports/ReportInfluencerBreakdown";
import ReportContentTable from "../reports/ReportContentOverview";
import GeneralPostModal from "@components/Modals/GeneralPostModal";

import FDropdown from "@components/FDropdown";

import EthnicityBreakdown from "../../components/charts/demographics/EthnicityBreakdownChart";
import ReligionBreakdown from "../profile/audience/fragments/ReligionBreakdown";
import KidsBreakdown from "../profile/audience/fragments/KidsBreakdown";
import MaritalStatusBreakdown from "../profile/audience/fragments/MaritalStatusBreakdown";
import toast from "react-hot-toast";
import CountriesBreakdown from "../profile/audience/fragments/CountriesBreakdown";
import StatesBreakdown from "../profile/audience/fragments/StatesBreakdown";
import FButton from "@components/FButton";
import AgeBreakdownChart from "@components/charts/demographics/AgeBreakdownChart";
import GenderBreakdownChart from "@components/charts/demographics/GenderBreakdownChart";

const CampaignReports: React.FC<any> = () => {
  const { campaign_id } = useParams<{ campaign_id: string | any }>();
  const [loading, setLoading] = useState(true);
  const [engagementGraphData, setEngagementGraphData] = useState<any>({
    posts: null,
    reach: null,
    views: null,
  });
  const [engagementDataFetched, setEngagementDataFetched] = useState(false);

  const [SummaryStats, setSummaryStats] = useState<
    {
      cellTitle: string;
      statNumber: number | string;
      statType: string;
      statInfo?: string;
    }[]
  >([]);
  const [performanceGraphs, setPerformanceGraphs] = useState<any>({});

  const [audience, setAudience] = useState<any>({});
  const [selectedPlatform, setSelectedPlatform] = useState<string>("");
  const [audienceDataFetched, setAudienceDataFetched] = useState(false);
  const [availablePlatforms, setAvailablePlatforms] = useState<any>([]);
  const [overview, setOverview] = useState<any>({});
  const [audienceSectionLoading, setAudienceSectionLoading] = useState(true);

  const [activePost, setActivePost] = useState(null);
  const [contentModalOpen, setContentModalOpen] = useState(false);

  const handleContentModalClick = (content) => {
    setActivePost(content);
    setContentModalOpen(true);
  };

  const handleContentModalClose = () => {
    setContentModalOpen(false);
    setActivePost(null);
  };

  const fetchPostsEngagementsGraph = async () => {
    setLoading(true);
    setEngagementDataFetched(false);

    const response = await getCampaignPostsEngagementGraph(
      campaign_id,
      selectedPlatform
    );
    setEngagementGraphData(response.data);
    setLoading(false);
    setEngagementDataFetched(true);
  };

  const fetchAudienceData = async () => {
    setAudienceSectionLoading(true);
    const audienceResponse = await getCampaignReportsAudienceData(
      campaign_id,
      selectedPlatform
    );
    setAudience(audienceResponse.data);
    setAudienceDataFetched(true);
    setAudienceSectionLoading(false);
  };

  const fetchOverview = async () => {
    setLoading(true);

    const response = await getReport(campaign_id);
    if (response && response.data && response.data.reports[0].platforms) {
      setOverview(response.data.reports[0]);

      const allPlatforms = response.data.reports[0].platforms.map(
        (platform) => ({
          label: platform,
          value: platform,
        })
      );

      setAvailablePlatforms(allPlatforms);
    } else {
      console.error("Failed to retrieve platforms data:", response);
      toast.error("Failed to load platforms data.");
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchSummary = async () => {
      setLoading(true);

      const response = await getCampaignReportSummary(campaign_id);

      if (response.success) {
        const formattedStats = Object.entries(response.data).reduce(
          (
            acc: {
              cellTitle: string;
              statNumber: number | string;
              statType: string;
            }[],
            [key, value]
          ) => {
            if (typeof value === "number" || typeof value === "string") {
              const title = key
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
              acc.push({
                cellTitle: title,
                statNumber: value,
                statType: typeof value === "number" ? "number" : "text",
              });
            }
            return acc;
          },
          []
        );
        setSummaryStats(formattedStats);
      }

      setLoading(false);
    };

    const fetchPerformanceGraphs = async () => {
      setLoading(true);

      const response = await getCampaignReportPerformanceGraphs(campaign_id);
      setPerformanceGraphs(response.data);
      setLoading(false);
    };

    fetchSummary();
    fetchPerformanceGraphs();
    fetchPostsEngagementsGraph();
    fetchAudienceData();
    fetchOverview();
  }, [campaign_id, selectedPlatform]);

  const hasPerformanceGraphs =
    performanceGraphs && Object.keys(performanceGraphs).length > 0;

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  if (audienceSectionLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner />
      </div>
    );
  } else {
    return (
      <div className="p-4">
        <div>
          <div className="flex mb-4">
            <h1 className="text-2xl font-bold">Campaign Performance</h1>
          </div>
          <StatsBar stats={SummaryStats} />
        </div>
        <div className="mt-[48px] pb-[48px]">
          <ReportContentSummary report_id={overview.id} />
        </div>
        <div className="my-[48px]">
          {loading || !engagementDataFetched ? (
            <LoadingSpinner />
          ) : Object.values(engagementGraphData).every(
              (value) => value === null
            ) ? (
            <BlankState
              title="No Data Available"
              subtitle="No engagement data is currently available to display. Please try again later."
              icon="info"
              actionLabel="Refresh"
              onActionClick={() => fetchPostsEngagementsGraph()}
            />
          ) : (
            <PostsEngagementsGraph postsEngagement={engagementGraphData} />
          )}
        </div>
        {hasPerformanceGraphs && (
          <div className="flex justify-between">
            <div className="flex flex-col justify-between w-full h-full">
              {/* Reach and Viewership Cards */}
              <div className="flex py-4 px-0flex-1 justify-between">
                {performanceGraphs.reach && (
                  <div className="h-[100%] w-80">
                    <CampaignProgressCard
                      cardData={[
                        {
                          cardTitle: "How many users you're Reaching:",
                          cardStats: performanceGraphs.reach.stat_data.map(
                            (data) => ({
                              statNumber: data.value,
                              statName: data.label,
                              statType: "number",
                              statInfo: data.tooltip,
                            })
                          ),
                        },
                      ]}
                      graphData={performanceGraphs.reach.chart_data || []}
                      graphedDataPoint="Reach"
                      chartType="line"
                    />
                  </div>
                )}
                {performanceGraphs.viewership && (
                  <div className="h-[100%] w-80">
                    <CampaignProgressCard
                      cardData={[
                        {
                          cardTitle: "How much your content is being viewed:",
                          cardStats: performanceGraphs.viewership.stat_data.map(
                            (data) => ({
                              statNumber: data.value,
                              statName: data.label,
                              statType: "number",
                              statInfo: data.tooltip,
                            })
                          ),
                        },
                      ]}
                      graphData={performanceGraphs.viewership.chart_data || []}
                      graphedDataPoint="Views"
                      chartType="line"
                    />
                  </div>
                )}
                {/* Engagement and EMV Cards */}
                {performanceGraphs.engagement && (
                  <div className="h-[100%] w-80">
                    <CampaignProgressCard
                      cardData={[
                        {
                          cardTitle:
                            "How users are interacting with your brand:",
                          cardStats: performanceGraphs.engagement.stat_data.map(
                            (data) => ({
                              statNumber: data.value,
                              statName: data.label,
                              statType: "number",
                              statInfo: data.tooltip,
                            })
                          ),
                        },
                      ]}
                      graphData={performanceGraphs.engagement.chart_data || []}
                      graphedDataPoint="Engagement"
                      chartType="line"
                    />
                  </div>
                )}
              </div>

              <div className="border-t border-[#9398AC] my-4"></div>

              <div className="flex py-4 flex-1 flex-row justify-between w-full h-full">
                {performanceGraphs.emv.stat_data !== undefined && (
                  <div className="h-[100%] w-80">
                    <CampaignProgressCard
                      cardData={[
                        {
                          cardTitle:
                            "What is your earned media value over time:",
                          cardStats: [
                            {
                              statNumber: performanceGraphs.emv.stat_data,
                              statName: "EMV over time",
                              statType: "dollars",
                            },
                          ],
                        },
                      ]}
                      graphData={performanceGraphs.emv.chart_data || []}
                      graphedDataPoint="Dollars"
                      chartType="line"
                    />
                  </div>
                )}
                {/* Content and Viral Posts Cards */}
                {performanceGraphs.content_chart_data &&
                  performanceGraphs.posts && (
                    <div className="h-[100%] w-80">
                      <CampaignProgressCard
                        cardData={[
                          {
                            cardTitle: "What Type of Content is Being Posted:",
                            cardStats: Object.entries(
                              performanceGraphs.posts
                            ).map(([key, value]) => ({
                              statNumber: value,
                              statName: capitalize(key),
                              statType: "number",
                            })),
                          },
                        ]}
                        graphData={performanceGraphs.content_chart_data || []}
                        graphedDataPoint="Posts"
                        chartType="stackedBar"
                      />
                    </div>
                  )}
                {performanceGraphs.viral_posts && (
                  <div className="h-[100%] w-80">
                    <PerformanceTiles
                      viralPosts={performanceGraphs.viral_posts}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="mt-[48px]">
          <ReportCreatorsTable report_id={overview.id} />
        </div>
        <div className="mt-[48px]">
          <ReportInfluencerBreakdown report_id={overview.id} />
        </div>
        <div className="mt-[48px]">
          <ReportContentTable
            report_id={overview.id}
            onCardClick={(content) => handleContentModalClick(content)}
          />
        </div>
        {audienceDataFetched ? (
          audience && (
            <div className="mt-[48px]">
              <h1 className="my-6">Audience Demographics</h1>
              {Object.values(audience).some((data) =>
                Array.isArray(data) ? data.length > 0 : data
              ) && (
                <div className="flex justify-between w-full mb-5">
                  <FDropdown
                    options={availablePlatforms}
                    selectedValue={selectedPlatform}
                    onChange={(value) => setSelectedPlatform(value as string)}
                  />
                </div>
              )}
              {audience.age.length > 0 && audience.gender.length > 0 && (
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[40px] mb-[32px]">
                  <AgeBreakdownChart
                    items={audience.age.map((item) => ({
                      ...item,
                      name: item.name.split("Age ")[1],
                    }))}
                  />
                  <GenderBreakdownChart items={audience.gender} />
                </div>
              )}
              {audience.ethnicity.length > 0 &&
                audience.religion.length > 0 && (
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-[40px] mb-[40px]">
                    <EthnicityBreakdown items={audience.ethnicity} />
                    <ReligionBreakdown items={audience.religion} />
                  </div>
                )}
              {audience.parental && audience.marital.length > 0 && (
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[40px] mb-[40px]">
                  <KidsBreakdown items={audience.parental} />
                  <MaritalStatusBreakdown items={audience.marital} />
                </div>
              )}
              {audience.countries.length > 0 && audience.states.length > 0 && (
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[40px] mb-[40px]">
                  <CountriesBreakdown items={audience.countries} />
                  <StatesBreakdown items={audience.states} />
                </div>
              )}
            </div>
          )
        ) : (
          <div className="flex justify-center items-center h-screen">
            <LoadingSpinner />
          </div>
        )}
        {activePost && (
          <GeneralPostModal
            post={activePost}
            isOpen={contentModalOpen}
            onClose={handleContentModalClose}
          />
        )}
      </div>
    );
  }
};

export default CampaignReports;
