import React, { ReactNode, useEffect, useState } from "react";

import { debounce } from "lodash";
import FSearch from "@components/FSearch";

import LoadingSpinner from "@components/LoadingSpinner";
import FPagination from "@components/FPagination";
import Table from "@components/Table";

import { Column, Pagination, Sort } from "@types";

interface SelectEntitiesTableProps<T> {
  singleSelection?: boolean;
  excludeIds?: number[];
  tableColumns: Column[];
  onChangeSelectedItems?: (items: T[]) => void;
  hasSingleSelection?: boolean;
  selectedItems?: T[];
  showJustSelected?: boolean;
  getItems: (
    page: number,
    sort: Sort,
    params
  ) => Promise<{ total_items: number; items: T[] }>;
  topRightContent?: ReactNode;
}

export default function SelectEntitiesTable<T extends { id: number }>({
  hasSingleSelection,
  excludeIds,
  tableColumns,
  onChangeSelectedItems,
  selectedItems = [],
  showJustSelected,
  getItems,
  topRightContent,
}: SelectEntitiesTableProps<T>) {
  const [items, setItems] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    pageSize: 30,
    total: null,
  });
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState<Sort>(null);

  const { currentPage, pageSize, total } = pagination || {};

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);

      const { currentPage } = pagination;

      try {
        const params = {
          search,
        };

        const result = await getItems(currentPage, sort, params);

        const { items, total_items: total } = result;

        const newItems = excludeIds
          ? items.filter(({ id }) => !excludeIds.includes(id))
          : items;

        setItems(newItems);

        pagination.total = total;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [getItems, pagination, search, sort, excludeIds]);

  const handlePageChange = (page: number) => {
    setPagination({ ...pagination, currentPage: page });
  };

  const handleSearchChange = (term: string) => {
    setPagination({ ...pagination, currentPage: 1 });
    setSearch(term);
  };

  const handleSortChange = (sort: Sort) => {
    setPagination({ ...pagination, currentPage: 1 });
    setSort(sort);
  };

  const allItems = hasSingleSelection
    ? items
    : [
        ...selectedItems,
        ...items.filter(
          (item) => !selectedItems.find(({ id }) => id === item.id)
        ),
      ];

  if (showJustSelected) {
    return (
      <Table
        data={allItems.filter((item) =>
          selectedItems.map(({ id }) => id).includes(item.id)
        )}
        columns={tableColumns}
      />
    );
  }

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex gap-2 w-full">
        <FSearch
          onChange={debounce(handleSearchChange, 500)}
          className="w-full h-[40px] sticky top-0 relative z-50"
          placeholder={`Search...`}
          value={search}
        />
        {topRightContent || null}
      </div>

      {loading ? (
        <LoadingSpinner className="flex-1" />
      ) : (
        <div className="flex-1 overflow-auto">
          <Table
            data={allItems}
            columns={tableColumns}
            onSortChange={handleSortChange}
            hasSingleSelection={hasSingleSelection}
            sort={sort}
            onChangeSelectedItems={(ids) =>
              onChangeSelectedItems(
                allItems.filter(({ id }) => ids.includes(id))
              )
            }
            selectedItems={selectedItems.map(({ id }) => id)}
          />
        </div>
      )}
      <FPagination
        page={currentPage}
        pageSize={pageSize}
        total={total}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
