import FDropdown from "../../../components/FDropdown";
import FIcon from "../../../components/FIcon";
import * as React from "react";
import { formatNumber } from "../../../utils/number_utilities";

export default function Summary({ brandHandle = "fohr" }) {
  const loading = false;

  const stats = [
    { label: "Influencers", value: 20, color: "#E47667", icon: "audience" },
    { label: "Total Posts", value: 20, color: "#797E92", icon: "image" },
    { label: "Impressions", value: 20, color: "#797E92", icon: "impression" },
  ];

  return (
    <div className="relative mb-[16px] py-[20px] px-[24px] rounded-lg bg-white ">
      <div className="flex items-center justify-between pb-[16px]">
        <div className="flex items-center pb-[24px]">
          <div className="flex text-[22px] text-dark leading-[26px] tracking-[0.66px]">
            Brand Mentions
          </div>
          <div className=" text-[#797E92] text-[16px] leading-[22px] ml-[8px]">
            {brandHandle && (
              <p>
                Who's using{" "}
                <span style={{ color: "#E47667" }}>@{brandHandle}</span>
              </p>
            )}
          </div>
        </div>
        <FDropdown
          options={[
            { label: "Option 1", value: "test1" },
            { label: "Option 2", value: "test2" },
            { label: "Option 3", value: "test3" },
          ]}
          onChange={() => false}
          width={168}
        />
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="grid items-center grid-cols-[4fr_3fr_3fr]">
          {stats.map((stat, i) => {
            return (
              <div key={i} className="flex items-center">
                <FIcon icon={stat.icon} size={40} color={stat.color} />
                <div className="ml-[24px]">
                  <p className="text-dark text-[28px] leading-[32px] tracking-[0.84px]">
                    {formatNumber(stat.value)}
                  </p>
                  <p className="font-medium text-[#797E92] text-[14px] leading-[18px] tracking-[0.42px]">
                    {stat.label}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
