import AgeBreakdownChart from "@components/charts/demographics/AgeBreakdownChart";

import { DemographicsBreakdownItem, Participant } from "@types";
import { last } from "lodash";
import moment from "moment";

const UNKNOWN = "N/A";

const RANGES = [
  {
    name: "17 and under",
  },
  {
    name: "18 to 20",
  },
  {
    name: "21 to 24",
  },
  {
    name: "25 to 29",
  },
  {
    name: "30 to 34",
  },
  {
    name: "35 to 44",
  },
  {
    name: "45 to 54",
  },
  {
    name: "55 to 64",
  },
  {
    name: "65 and over",
  },
  {
    name: UNKNOWN,
  },
];

const getAgeRangeStats = (
  range: string,
  index: number,
  participantAges: number[],
  total: number
): { count: number; percentage: number } => {
  if (range === UNKNOWN) {
    const count = participantAges.filter((age) => !age).length;

    return {
      count,
      percentage: Math.round((100 * count) / total),
    };
  }

  const first = parseInt(range.split(" ")[0], 10);
  const second = parseInt(last(range.split(" ")), 10);

  const low = index === 0 ? 0 : first;
  const high = index === 0 ? first : second;

  const participantCount = participantAges.filter(
    (age) => age && age >= low && (!high || age < high)
  ).length;

  return {
    count: participantCount,
    percentage: Math.round((100 * participantCount) / total),
  };
};

const getAgeBreakdown = (
  participants: Participant[]
): DemographicsBreakdownItem[] => {
  const total = participants.length;

  const participantAges = participants.map(({ creator: { birthday } }) =>
    birthday ? moment().diff(birthday, "years", false) : null
  );

  return RANGES.map((range, index) => ({
    ...range,
    ...getAgeRangeStats(range.name, index, participantAges, total),
  }));
};

interface AgeBreakdownProps {
  participants: Participant[];
}

const AgeBreakdown = ({ participants }: AgeBreakdownProps) => (
  <AgeBreakdownChart items={getAgeBreakdown(participants)} showCounts />
);

export default AgeBreakdown;
