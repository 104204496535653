import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  numberWithKMBFormat,
  formatEngagementRate,
} from "@utils/number_utilities";

interface TimeSeriesPoint {
  date: Date;
  value: number;
}

interface KPIWithSparklineProps {
  title: string;
  value: number | string;
  valueType: "number" | "percent";
  timeSeriesData: TimeSeriesPoint[];
}

export default function KPIWithSparkline({
  title,
  value,
  valueType,
  timeSeriesData,
}: KPIWithSparklineProps) {
  const formatValue = (val: number | string) => {
    if (valueType === "percent") {
      return formatEngagementRate(val);
    }
    return numberWithKMBFormat(Number(val));
  };

  const getYAxisMinMax = () => {
    const values = timeSeriesData.map((point) => point.value);
    const min = Math.min(...values);
    const max = Math.max(...values);
    const range = max - min;

    // If all values are the same, create artificial range
    if (range === 0) {
      return {
        min: min * 0.5,
        max: max * 1.5,
      };
    }

    // Add more padding at bottom to ensure line visibility
    return {
      min: min - range * 0.3, // 30% padding below the minimum
      max: max + range * 0.1, // 10% padding above the maximum
    };
  };

  const { min, max } = getYAxisMinMax();

  const chartOptions: Highcharts.Options = {
    chart: {
      type: "spline",
      height: 60,
      width: 120,
      backgroundColor: "transparent",
      margin: [5, 2, 5, 2],
      spacing: [0, 0, 0, 0],
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      visible: false,
      type: "datetime",
      min: timeSeriesData[0]?.date.getTime(),
      max: timeSeriesData[timeSeriesData.length - 1]?.date.getTime(),
    },
    yAxis: {
      visible: false,
      min,
      max,
      startOnTick: false,
      endOnTick: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        type: "spline",
        data: timeSeriesData.map((point) => [
          point.date.getTime(),
          point.value,
        ]),
        color: "#E47667",
      },
    ],
  };

  return (
    <div className="flex items-center py-4">
      <div className="mr-4 min-w-0">
        <div className="text-[16px] mb-1">{title}</div>
        <div className="text-3xl truncate">{formatValue(value)}</div>
      </div>
      <div className="flex-shrink-0">
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    </div>
  );
}
