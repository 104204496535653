import React, { useState } from "react";

import { toast } from "react-hot-toast";

import { FInput } from "@components/FInputs";

import FDropdown from "@components/FDropdown";

import { CampaignContent, Participant, UploadedFile } from "@types";

import Modal from "@components/Modals/Modal";

import moment from "moment";
import { capitalize, isEmpty } from "lodash";

import FFileUpload from "@components/FFileUpload/FFileUpload";
import FormField from "@components/Form/FormField";
import { platformList } from "@constants/constants";
import FDatePicker from "@components/FDatePicker/FDatePicker";
import { createParticipantContent } from "@api/Campaign/CampaignParticipantContent";

const MEDIA_TYPES = ["image", "video", "text"];

interface AddParticipantContentModalProps {
  onClose: () => void;
  onCreated: (campaign: CampaignContent) => void;
  participant: Participant;
}

export default function AddParticipantContentModal({
  onClose,
  onCreated,
  participant,
}: AddParticipantContentModalProps) {
  const [loading, setLoading] = useState(false);

  const [isSubmitted, setSubmitted] = useState(false);

  const [uploadedImage, setUploadedImage] = useState<UploadedFile>(null);
  const [uploadedStatsScreenshots, setUploadedStatsScreenshots] = useState<
    UploadedFile[]
  >([]);

  const { campaign_id, deliverables } = participant;

  const [values, setValues] = useState({
    caption: "",
    type: MEDIA_TYPES[0],
    platform:
      participant.deliverables.find(({ platform }) => !!platform)?.platform ||
      platformList[0].value,
    follower_count: 0,
    interactions: 0,
    published_at: moment().format(),
    deliverable_id: deliverables[0]?.id,
    data: { permalink: "" },
  });

  const handleUpdate = (updates: Partial<CampaignContent>) =>
    setValues({ ...values, ...updates });

  const handleNext = async () => {
    setSubmitted(true);

    const stats_screenshot = uploadedStatsScreenshots[0]?.file;

    const { caption } = values;

    if (!caption || !stats_screenshot) {
      return;
    }

    setLoading(true);

    try {
      const newContentResponse = await createParticipantContent({
        campaign_id: campaign_id,
        participant_id: participant.id,
        image: uploadedImage?.file,
        stats_screenshot: uploadedStatsScreenshots[0]?.file,
        ...values,
      });

      const newContent = newContentResponse.data;

      onCreated(newContent);
    } catch (e) {
      console.log("Content", e);
      toast.error(`Error creating content`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      actionLabel="Save"
      className="h-[700px] overflow-auto"
      isOpen
      onClose={onClose}
      onAction={handleNext}
      title="Add Content"
      waiting={loading}
    >
      <div className="flex flex-col gap-6">
        <div className="flex gap-4">
          <FormField
            label="Asset"
            error={isSubmitted && !uploadedImage ? "Image is required" : ""}
            width="100%"
          >
            <FFileUpload
              className="w-full"
              onUpload={(files) => setUploadedImage(files[0])}
              uploadedFiles={[uploadedImage]}
            />
          </FormField>
          <FInput
            onChange={(value) => handleUpdate({ caption: value })}
            label="Caption"
            value={values.caption}
            required
            validate={isSubmitted}
            width="100%"
          />
        </div>
        <div className="flex gap-4 w-full">
          <FDropdown
            label="Type"
            selectedValue={values.type}
            onChange={(value) =>
              handleUpdate({
                type: value as string,
              })
            }
            options={MEDIA_TYPES.map((type) => ({
              value: type,
              label: capitalize(type),
            }))}
            width="100%"
          />
          <FDropdown
            label="Platform"
            selectedValue={values.platform}
            onChange={(value) =>
              handleUpdate({
                platform: value as string,
              })
            }
            options={platformList}
            width="100%"
          />
        </div>
        <div className="flex gap-4 w-full">
          <FInput
            label="Follower count"
            onChange={(value) =>
              handleUpdate({
                follower_count: parseInt(value, 10) || 0,
              })
            }
            value={`${values.follower_count}`}
            type="number"
            width="100%"
          />
          <FInput
            label="Interactions"
            onChange={(value) =>
              handleUpdate({
                interactions: parseInt(value, 10) || 0,
              })
            }
            value={`${values.interactions}`}
            type="number"
            width="100%"
          />
        </div>
        <div className="flex gap-4 w-full">
          <FormField
            label="Stats screenshot"
            required
            error={
              isSubmitted && isEmpty(uploadedStatsScreenshots)
                ? "Stats screenshot is required"
                : ""
            }
            width="100%"
          >
            <FFileUpload
              className="w-full"
              onUpload={(files) =>
                setUploadedStatsScreenshots([
                  ...uploadedStatsScreenshots,
                  ...files,
                ])
              }
              multiple
              uploadedFiles={uploadedStatsScreenshots}
              onDelete={(fileName) => {
                const filteredFiles = uploadedStatsScreenshots.filter(
                  (f) => f.file.name !== fileName
                );

                setUploadedStatsScreenshots(filteredFiles);
              }}
            />
          </FormField>
          <FDatePicker
            label="Published at"
            value={values.published_at}
            onSelectedValue={(value) => handleUpdate({ published_at: value })}
          />
        </div>
        <FInput
          label="Permalink"
          onChange={(value) =>
            handleUpdate({
              data: { ...values.data, permalink: value },
            })
          }
          required
          validate={isSubmitted}
          value={`${values.data.permalink}`}
          width="100%"
        />
        <FDropdown
          label="Deliverable"
          selectedValue={values.deliverable_id}
          onChange={(value) =>
            handleUpdate({
              deliverable_id: value as number,
            })
          }
          options={deliverables.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
          width="100%"
        />
      </div>
    </Modal>
  );
}
