import React, { useState } from "react";

import FButton from "@components/FButton";
import { toast } from "react-hot-toast";

import { GroupData } from "@apiTypes";
import SelectGroupsTable from "@components/Creator/SelectGroupsTable";

import apiClient from "@apiClient";
import { isEmpty } from "lodash";
import SelectEntitiesModal from "@components/SelectEntities/SelectEntitiesModal";

const { add_creator } = apiClient.groups;

interface Props {
  creatorIds: number[];
  onClose: () => void;
}

const AddCreatorsToGroupModal = ({ creatorIds, onClose }: Props) => {
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(null);

  const [selectedGroups, setSelectedGroups] = useState<GroupData[]>([]);

  const handleSubmit = async () => {
    setWaiting(true);
    setError(null);

    try {
      await add_creator(selectedGroups[0].id, creatorIds);

      toast.success("Added creators to group");
      onClose();
    } catch (error) {
      console.error("Failed to add creators to group:", error);
      setError("Failed to add creators");
    }
    return;
  };

  return (
    <SelectEntitiesModal
      title="Add to group"
      onClose={onClose}
      error={error}
      actionButton={
        <FButton
          loading={waiting}
          disabled={isEmpty(selectedGroups)}
          onClick={handleSubmit}
          primary
          label="Save"
          width="100%"
          height="40px"
        />
      }
    >
      <div style={{ height: "calc(100% - 70px)" }}>
        <SelectGroupsTable
          hasSingleSelection
          selectedItems={selectedGroups}
          onChangeSelectedItems={setSelectedGroups}
        />
      </div>
    </SelectEntitiesModal>
  );
};

export default AddCreatorsToGroupModal;
