import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";

const listAssets = async (params?: {
  q?: {
    assetable_id_eq?: number;
    assetable_type_eq?: string;
  };
}): Promise<ApiResponse<any>> => {
  const queryParams = new URLSearchParams();

  if (params?.q?.assetable_id_eq) {
    queryParams.append(
      "q[assetable_id_eq]",
      params.q.assetable_id_eq.toString()
    );
  }
  if (params?.q?.assetable_type_eq) {
    queryParams.append("q[assetable_type_eq]", params.q.assetable_type_eq);
  }

  return apiWrapper(`/assets?${queryParams.toString()}`, "GET");
};

const createAsset = async (params: Partial<any>): Promise<ApiResponse<any>> => {
  return apiWrapper("/api/v1/assets", "POST", params);
};

const updateAsset = async (
  asset_id: string | number,
  params: Partial<any>
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/assets/${asset_id}`, "PUT", params);
};

const deleteAsset = async (asset_id: string | number) => {
  return apiWrapper(`/assets/${asset_id}`, "DELETE");
};

const showAsset = async (
  asset_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/assets/${asset_id}`, "GET");
};

export { listAssets, createAsset, updateAsset, deleteAsset, showAsset };
