import React, { useEffect, useState } from "react";
import FInput from "@components/FInputs/FInput";
import FButton from "@components/FButton";
import FDropdown from "@components/FDropdown";
import { Participant, ParticipantGroup } from "@types";
import { STATUS_OPTIONS } from "../participants";

interface ParticipantEditFormProps {
  participant?: Participant;
  participantGroups?: ParticipantGroup[];
  isUpdating: boolean;
  onSubmit: (values: any) => void;
  onClose: () => void;
  editType: string;
}

const ParticipantEditForm = ({
  participant,
  participantGroups,
  isUpdating,
  onSubmit,
  onClose,
  editType,
}: ParticipantEditFormProps) => {
  const [values, setValues] = useState({
    name: "",
    participant_group_id: null,
    status: "accepted",
    offer: "0",
  });

  useEffect(() => {
    if (participant) {
      setValues((values) => ({
        ...values,
        status: participant.status,
        participant_group_id: participant.participant_group_id,
      }));
    }
  }, [participant]);

  const handleUpdate = (updates) => setValues({ ...values, ...updates });

  const { name, participant_group_id: participantGroupId, status } = values;

  return (
    <div className="max-h-[60vh] overflow-y-auto">
      <div className="space-y-4">
        <div className="flex mb-[6px] gap-4 flex-col items-start w-full">
          {editType === "offer" ? (
            <FInput
              type="text"
              required
              label="Name"
              onChange={(value) => handleUpdate({ name: value })}
              value={name}
              width="100%"
            />
          ) : (
            <div className="flex flex-col w-full items-start">
              <FDropdown
                height="40"
                width="100%"
                selectedValue={status}
                onChange={(value) => handleUpdate({ status: value })}
                options={STATUS_OPTIONS}
              />
            </div>
          )}
        </div>
        {participant ? (
          <div className="flex flex-col w-full items-start">
            <FDropdown
              height="40"
              width="100%"
              selectedValue={participantGroupId}
              onChange={(value) =>
                handleUpdate({ participant_group_id: value })
              }
              options={participantGroups.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            />
          </div>
        ) : null}
      </div>
      <div className="flex justify-end space-x-2 mt-8 pb-3 sticky bottom-0 bg-light_red pt-6 border-t border-light_border">
        <FButton onClick={onClose} label="Cancel" width="100%" height="40px" />
        <FButton
          onClick={() => onSubmit(values)}
          label="Save"
          loading={isUpdating}
          width="100%"
          height="40px"
          primary
          type="submit"
        />
      </div>
    </div>
  );
};

export default ParticipantEditForm;
