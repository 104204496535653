import React, { useCallback, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { Sort, SortOrder } from "@types";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import { getAdminBrandList } from "@api/Admin/AdminBrands";
import CreateOrUpdateBrandModal from "../components/CreateOrUpdateBrandModal";
import { UserContext } from "@contexts/index";

// Default sorting configuration
const defaultSort: Sort = {
  key: "created_at",
  direction: "asc",
};

const BrandsAdmin = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  // Fetch brands with sorting, filtering, and pagination
  const getBrands = useCallback(async (page?: number, sort?: Sort, params = {}) => {
    const { search, filter } = params;
    const sortToUse = sort || defaultSort;

    // Adjust sort key for status column
    if (sortToUse.key === "status") {
      sortToUse.key = "expires_at";
    }

    const today = moment().format("YYYY-MM-DD");
    const query: Record<string, any> = { name_i_cont: search };

    if (filter === "active") query.expires_at_gt = today;
    if (filter === "expired") query.expires_at_lt = today;

    const response = await getAdminBrandList(page, sortToUse, query);
    return {
      ...response.data,
      items: response.data.brands,
    };
  }, []);

  const filterOptions = [
    { label: "All Brands", static: "Brands", value: null },
    { label: "Active", static: "Brands", value: "active" },
    { label: "Expired", static: "Brands", value: "expired" },
  ];

  const tableColumns = [
    {
      key: "name",
      label: "Name",
      isLink: true,
      linkURL: "{id}",
    },
    {
      key: "created_at",
      label: "Created At",
      isDate: true,
    },
    {
      key: "expires_at",
      label: "Expires At",
      isDate: true,
    },
    {
      key: "status",
      label: "Status",
      getValue: (item: any) =>
        moment().isBefore(item.expires_at) ? "Active" : "Expired",
    },
    {
      key: "membership",
      label: "Membership",
      getValue: (item: any) => {
        const membership = user.memberships.find(
          (membership) => membership.brand_id === item.id
        );
        return membership ? "User" : "";
      },
    },
  ];

  const handleBrandCreated = (newBrand: any) => {
    setModalVisible(false);
    navigate(`/admin/brands/${newBrand.id}`);
  };

  return (
    <>
      <SinglePageLayoutWithData
        entityName="brand"
        pageTitle="Brands"
        pageName="Brands"
        cardType="asset"
        sortPathName="name"
        sortPathDate="created_at"
        filterOptions={filterOptions}
        filterPath="status"
        tableOnly
        tableColumns={tableColumns}
        getItems={getBrands}
        createWithEntityCrud={() => setModalVisible(true)}
        updateModal={CreateOrUpdateBrandModal}
      />
      {isModalVisible && (
        <CreateOrUpdateBrandModal
          onClose={() => setModalVisible(false)}
          onCreated={handleBrandCreated}
        />
      )}
    </>
  );
};

export default BrandsAdmin;
