export enum Role {
  SUPER_ADMIN = "super_admin",
  FOHR_ADMIN = "fohr_admin",
  BRAND_OWNER = "brand_owner",
  BRAND_MANAGER = "brand_manager",
  BRAND_USER = "brand_user",
  INFLUENCER = "influencer",
  INFLUENCER_REP = "influencer_rep",
  OPEN_LINKS = "open_links",
}

export enum Permission {
  VIEW_TOGGLE_BRANDS_ACCOUNTS = "view_toggle_brands_accounts",
  USE_SEARCH_INFLUENCERS = "use_search_influencers",
  VIEW_ALL_CAMPAIGNS = "view_all_campaigns",
  EDIT_ALL_CAMPAIGNS = "edit_all_campaigns",
  CREATE_ALL_CAMPAIGNS = "create_all_campaigns",
  DELETE_ALL_CAMPAIGNS = "delete_all_campaigns",
  VIEW_OVERVIEW = "view_overview",
  VIEW_BRIEF = "view_brief",
  EDIT_BRIEF = "edit_brief",
  CREATE_BRIEF = "create_brief",
  DELETE_BRIEF = "delete_brief",
  VIEW_ABOUT = "view_about",
  EDIT_ABOUT = "edit_about",
  CREATE_SUB_SECTIONS_ABOUT = "create_sub_sections_about",
  DELETE_SUB_SECTIONS_ABOUT = "delete_sub_sections_about",
  VIEW_GUIDELINES = "view_guidelines",
  EDIT_GUIDELINES = "edit_guidelines",
  VIEW_CREATIVE_DIRECTION = "view_creative_direction",
  EDIT_CREATIVE_DIRECTION = "edit_creative_direction",
  CREATE_SUB_SECTIONS_CREATIVE_DIRECTION = "create_sub_sections_creative_direction",
  DELETE_SUB_SECTIONS_CREATIVE_DIRECTION = "delete_sub_sections_creative_direction",
  VIEW_PRODUCTS = "view_products",
  EDIT_PRODUCTS = "edit_products",
  CREATE_PRODUCTS = "create_products",
  EDIT_PRODUCTS_PRODUCTS = "edit_products_products",
  DELETE_PRODUCTS = "delete_products",
  VIEW_DELIVERABLES = "view_deliverables",
  EDIT_DELIVERABLES = "edit_deliverables",
  CREATE_DELIVERABLES = "create_deliverables",
  DELETE_DELIVERABLES = "delete_deliverables",
  VIEW_CONTRACT = "view_contract",
  EDIT_CONTRACT = "edit_contract",
  CREATE_CONTRACT = "create_contract",
  DELETE_CONTRACT = "delete_contract",
  VIEW_PAYMENT_TERMS = "view_payment_terms",
  EDIT_PAYMENT_TERMS = "edit_payment_terms",
  VIEW_PARTICIPANTS = "view_participants",
  EDIT_PARTICIPANTS = "edit_participants",
  ADD_PARTICIPANTS = "add_participants",
  DELETE_PARTICIPANTS = "delete_participants",
  VIEW_OFFERS = "view_offers",
  EDIT_OFFERS = "edit_offers",
  VIEW_BRIEFS = "view_briefs",
  EDIT_BRIEFS = "edit_briefs",
  VIEW_CONTENT = "view_content",
  EDIT_CONTENT = "edit_content",
  ADD_CONTENT = "add_content",
  DELETE_CONTENT = "delete_content",
  VIEW_CAMPAIGN_GROUPS = "view_campaign_groups",
  EDIT_CAMPAIGN_GROUPS = "edit_campaign_groups",
  ADD_GROUPS = "add_groups",
  DELETE_GROUPS = "delete_groups",
  VIEW_PARTICIPANT_REVIEW = "view_participant_review",
  EDIT_STATUS_PARTICIPANT_REVIEW = "edit_status_participant_review",
  VIEW_CONTENT_CAMPAIGNS = "view_content_campaigns",
  EDIT_CONTENT_CAMPAIGNS = "edit_content_campaigns",
  ADD_CONTENT_CAMPAIGNS = "add_content_campaigns",
  DELETE_CONTENT_CAMPAIGNS = "delete_content_campaigns",
  VIEW_ADMIN_REVIEW = "view_admin_review",
  EDIT_ADMIN_REVIEW = "edit_admin_review",
  ADD_ADMIN_REVIEW = "add_admin_review",
  DELETE_ADMIN_REVIEW = "delete_admin_review",
  VIEW_CLIENT_REVIEW = "view_client_review",
  EDIT_CLIENT_REVIEW = "edit_client_review",
  ADD_CLIENT_REVIEW = "add_client_review",
  DELETE_CLIENT_REVIEW = "delete_client_review",
  VIEW_REJECTED_NEEDS_ACTION = "view_rejected_needs_action",
  EDIT_REJECTED_NEEDS_ACTION = "edit_rejected_needs_action",
  ADD_REJECTED_NEEDS_ACTION = "add_rejected_needs_action",
  DELETE_REJECTED_NEEDS_ACTION = "delete_rejected_needs_action",
  VIEW_BRAND_APPROVED = "view_brand_approved",
  EDIT_BRAND_APPROVED = "edit_brand_approved",
  VIEW_OVERALL_APPROVED = "view_overall_approved",
  EDIT_OVERALL_APPROVED = "edit_overall_approved",
  CONTENT_REVIEW_VIEW_COMMENT = "view_comment",
  CONTENT_REVIEW_EDIT_COMMENT = "edit_comment",
  CONTENT_REVIEW_ADD_COMMENT = "add_comment",
  CONTENT_REVIEW_DELETE_COMMENT = "delete_comment",
  CONTENT_REVIEW_VIEW_FEEDBACK = "view_feedback",
  CONTENT_REVIEW_EDIT_FEEDBACK = "edit_feedback",
  CONTENT_REVIEW_ADD_FEEDBACK = "add_feedback",
  CONTENT_REVIEW_DELETE_FEEDBACK = "delete_feedback",
  VIEW_ATTRIBUTE = "view_attribute",
  EDIT_ATTRIBUTE = "edit_attribute",
  ADD_ATTRIBUTE = "add_attribute",
  VIEW_CAMPAIGN_DELIVERABLES = "view_campaign_deliverables",
  EDIT_CAMPAIGN_DELIVERABLES = "edit_campaign_deliverables",
  VIEW_ALL_PRODUCTS = "view_all_products",
  EDIT_ALL_PRODUCTS = "edit_all_products",
  VIEW_REPORTING = "view_reporting",
  EDIT_REPORTING = "edit_reporting",
  AMBASSADORS_VIEW_ACCEPTED = "view_accepted_ambassadors",
  EDIT_ACCEPTED = "edit_accepted",
  VIEW_APPLICANTS = "view_applicants",
  EDIT_APPLICANTS = "edit_applicants",
  DELETE_APPLICANTS = "delete_applicants",
  SEARCH_CONTENT = "search_content",
  VIEW_QUESTIONS = "view_questions",
  EDIT_QUESTIONS = "edit_questions",
  ADD_QUESTIONS = "add_questions",
  DELETE_QUESTIONS = "delete_questions",
  VIEW_ONBOARDING_SETTINGS = "view_onboarding_settings",
  EDIT_ONBOARDING_SETTINGS = "edit_onboarding_settings",
  ADD_ONBOARDING_SETTINGS = "add_onboarding_settings",
  DELETE_ONBOARDING_SETTINGS = "delete_onboarding_settings",
  VIEW_BULLETINS = "view_bulletins",
  EDIT_BULLETINS = "edit_bulletins",
  CREATE_BULLETINS = "create_bulletins",
  DELETE_BULLETINS = "delete_bulletins",
  VIEW_GROUPS = "view_groups",
  EDIT_GROUPS = "edit_groups",
  CREATE_GROUPS = "create_groups",
  FEATURE_GROUPS = "feature_groups",
  ARCHIVE_USERS_GROUPS = "archive_users_groups",
  VIEW_OUTREACH = "view_outreach",
  CREATE_OUTREACH = "create_outreach",
  EDIT_OUTREACH = "edit_outreach",
  DELETE_OUTREACH = "delete_outreach",
  VIEW_REPORTS = "view_reports",
  EDIT_REPORTS = "edit_reports",
  CREATE_REPORTS = "create_reports",
  DELETE_REPORTS = "delete_reports",
  VIEW_NOTIFICATIONS = "view_notifications",
  EDIT_NOTIFICATIONS = "edit_notifications",
  VIEW_EDUCATION_CENTER = "view_education_center",
  ACCESS_FOHR_HELP_CENTER = "access_fohr_help_center",
  ACCESS_BRAND_HELP_CENTER = "access_brand_help_center",
  ACCESS_INFLUENCER_HELP_CENTER = "access_influencer_help_center",
  VIEW_INFLUENCER_PROFILES = "view_influencer_profiles",
  EDIT_INFLUENCER_PROFILES = "edit_influencer_profiles",
  CREATE_INFLUENCER_PROFILES = "create_influencer_profiles",
  DELETE_INFLUENCER_PROFILES = "delete_influencer_profiles",
  VIEW_BRAND_SETTINGS = "view_brand_settings",
  EDIT_BRAND_SETTINGS = "edit_brand_settings",
  CREATE_BRAND_SETTINGS = "create_brand_settings",
  DELETE_BRAND_SETTINGS = "delete_brand_settings",
}
