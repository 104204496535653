import React, { useCallback } from "react";

import { Sort } from "@types";
import { Campaign } from "@types";
import { getCampaignList } from "@api/Campaign/Campaigns";
import SelectEntitiesTable from "@components/SelectEntities/SelectEntitiesTable";

const TABLE_COLUMNS = [
  {
    key: "title",
    label: "Title",
  },
  {
    key: "participants_count",
    label: "Participants",
  },
];

interface CampaignsTableProps {
  onChangeSelectedItems?: (campaigns: Campaign[]) => void;
  selectedItems?: Campaign[];
  showJustSelected?: boolean;
}

export default function SelectCampaignsTable({
  onChangeSelectedItems,
  selectedItems,
  showJustSelected,
}: CampaignsTableProps) {
  const getCampaigns = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;

      const response = await getCampaignList(page, sort, false, {
        status_eq: filter,
        title_i_cont: search,
      });

      const { campaigns: items } = response.data;

      return {
        ...response.data,
        items,
      };
    },
    []
  );

  return (
    <SelectEntitiesTable
      tableColumns={TABLE_COLUMNS}
      onChangeSelectedItems={onChangeSelectedItems}
      selectedItems={selectedItems}
      showJustSelected={showJustSelected}
      getItems={getCampaigns}
    />
  );
}
