import { useEffect, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";

const apiKey = import.meta.env.VITE_GOOGLE_API_KEY;

type UsePlacesServiceRes = {
  autoCompleteService?: google.maps.places.AutocompleteService;
  placesService: google.maps.places.PlacesService;
  sessionToken?: google.maps.places.AutocompleteSessionToken;
};

const usePlacesService = (): UsePlacesServiceRes => {
  const [autoCompleteService, setAutoCompleteService] = useState<
    google.maps.places.AutocompleteService | undefined
  >(undefined);

  const [placesService, setPlacesService] = useState<
    google.maps.places.PlacesService | undefined
  >(undefined);

  const [sessionToken, setSessionToken] = useState<
    google.maps.places.AutocompleteSessionToken | undefined
  >(undefined);

  const initializeService = () => {
    if (!window.google?.maps?.places) {
      throw new Error("Google maps places script not loaded");
    }

    setAutoCompleteService(new window.google.maps.places.AutocompleteService());
    setPlacesService(
      new window.google.maps.places.PlacesService(
        document.getElementById(
          "google-autocomplete-attribution"
        ) as HTMLDivElement
      )
    );

    setSessionToken(new google.maps.places.AutocompleteSessionToken());
  };

  useEffect(() => {
    const init = async () => {
      if (!apiKey) {
        return;
      }

      try {
        if (
          !window.google ||
          !window.google.maps ||
          !window.google.maps.places
        ) {
          await new Loader({
            apiKey,
            ...{ libraries: ["places"] },
          }).load();
        }
        initializeService();
      } catch (error) {
        console.error("Error loading Google Places", error);
      }
    };

    init();
  }, []);

  return { autoCompleteService, placesService, sessionToken };
};

export default usePlacesService;
