import React from "react";

interface Props {
  className?: string;
  percent: number;
  progressBarColor?: string;
  showPercent?: boolean;
  completed?: number;
  total?: number;
  style?: any;
  label?: string;
  width?: string | number;
  unit?: string;
}

const getDefaultColor = (progress: number) => {
  if (progress > 0 && progress < 33) {
    return "#E47667";
  } else if (progress < 66) {
    return "#DBB200";
  }

  return "#47B37F";
};

const FProgressBar = ({
  className,
  percent = 0,
  progressBarColor,
  showPercent = false,
  completed,
  total,
  label = "",
  width = 300,
  unit,
  ...props
}: Props) => {
  return (
    <div
      className={className}
      style={{
        width: "100%",
        maxWidth: width,
        ...props?.style,
      }}
    >
      {label && (
        <div className="flex justify-between mb-[6px]">
          <div className="text-[14px] ">{label}</div>
          {showPercent && (
            <div className="text-[14px] tracking-[0.42px]">{percent}%</div>
          )}
        </div>
      )}
      <div
        className="bg-neutral_300 relative rounded-[10px] w-full h-[8px] overflow-hidden"
        style={{
          margin: label ? "6px 0 0 0" : "0 0 6px 0",
        }}
      >
        <div
          className="h-full transition ease-in duration-300 rounded-[10px]"
          style={{
            backgroundColor: progressBarColor || getDefaultColor(percent),
            width: `${percent}%`,
          }}
        ></div>
      </div>
      {!label && (
        <div className="flex justify-between mt-[4px]">
          {showPercent && (
            <div className="text-[12px] text-neutral_600  tracking-0075">
              {Math.round(percent)}%
            </div>
          )}
          {total && (
            <div className="text-[12px]  tracking-0075 text-right w-full">
              {Math.round(completed)}/{Math.round(total)} {unit || ""}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FProgressBar;
