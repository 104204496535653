import FIcon from "@components/FIcon";
import React, { useEffect, useRef, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

import "./FDatePicker.css";
import moment from "moment";

const DATE_FORMAT = "MM/DD/YYYY";

interface Props {
  label: string;
  value: string;
  onSelectedValue: (date: string) => void;
  disabled?: {
    after?: Date;
    before: Date;
  };
}

const FDatePicker = ({ label, value, onSelectedValue, disabled }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div className="relative w-full">
      {label ? <label className="text-sm leading-[18px]">{label}</label> : null}
      <div
        className={`relative w-full flex h-[40px] items-center cursor-pointer bg-white border border-neutral_400 px-[12px] ${isOpen ? "border-neutral_600" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className=" text-sm font-normal">
          {value ? moment(value).format(DATE_FORMAT) : DATE_FORMAT}
        </span>
        <FIcon
          icon="calendar"
          color="#000F45"
          size={24}
          className="absolute right-[8px] top-0 bottom-0 m-auto"
        />
      </div>
      {isOpen ? (
        <div
          className={`bg-white absolute cursor-pointer flex justify-center  text-sm font-normal overflow-y-auto p-[16px] w-full`}
          ref={dropdownRef}
          style={{
            boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.05)",
            borderRadius: "8px",
            zIndex: 50,
          }}
        >
          <DayPicker
            disabled={disabled}
            mode="single"
            selected={moment(value).toDate()}
            showOutsideDays
            onSelect={(value) =>
              onSelectedValue(moment(value).format("YYYY-MM-DD"))
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export default FDatePicker;
