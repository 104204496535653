import React, { useContext, useState } from "react";
import { FSidebar } from "../components/FSidebar";
import { useLocation } from "react-router-dom";
import { LayoutContext, UserContext } from "@contexts/index";
import FDropdown from "@components/FDropdown";
import { Permission, Role } from "@constants/roles";
import MOCK_PERMISSIONS from "@api/MOCK_PERMISSIONS";
import Toaster from "@components/Toaster";

const roleOptions = [
  {
    value: Role.SUPER_ADMIN,
    label: "Super Admin",
  },
  {
    value: Role.FOHR_ADMIN,
    label: "Fohr Admin",
  },
  {
    value: Role.BRAND_OWNER,
    label: "Brand Owner",
  },
  {
    value: Role.BRAND_MANAGER,
    label: "Brand Manager",
  },
  {
    value: Role.BRAND_USER,
    label: "Brand User",
  },
  {
    value: Role.INFLUENCER,
    label: "Influencer",
  },
  {
    value: Role.INFLUENCER_REP,
    label: "Influencer Representative",
  },
  {
    value: Role.OPEN_LINKS,
    label: "Open Links",
  },
];

const ambassadorSection = {
  fixedBottom: false,
  collapsible: true,
  mainLink: {
    icon: "crown",
    name: "Community",
  },
  items: [
    { icon: "audience", name: "Collaborators", href: "/collaborators" },
    {
      icon: "content",
      name: "Applicants",
      href: "/ambassadors",
      isSubmenuItem: true,
    },
  ],
};

export default function MainLayout({ children }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  const isFullScreenRoute = [
    "/card/",
    "/participants/",
    "/brief/",
    "/bulletins/",
  ].some((path) => location.pathname.startsWith(path));

  const switchCollapsed = (state) => {
    setIsCollapsed(state);
  };

  const { can, user, setUser } = useContext(UserContext);

  const handleUpdateRole = (role) =>
    setUser({ ...user, role, permissions: MOCK_PERMISSIONS[role] });

  const settingsSection = {
    fixedBottom: true,
    collapsible: false,
    items: [
      { name: "Admin", href: "/admin", icon: "influencers" },
      can(Permission.VIEW_EDUCATION_CENTER)
        ? {
            name: "Education Center",
            href: "https://fast.wistia.com/embed/channel/tpj8ksqyb0",
            icon: "star",
            target: "_blank",
          }
        : null,
    ].filter(Boolean),
  };

  return (
    <>
      <Toaster />
      <LayoutContext.Provider
        value={{
          collapsed: isCollapsed,
          setCollapsed: setIsCollapsed,
          fullNavWidth: "244px",
          collapsedNavWidth: "66px",
        }}
      >
        <div className="flex relative max-w-full overscroll-x-auto overscroll-none">
          {user.testMode ? (
            <div className="border rounded-lg border-default_weak bg-white absolute p-[8px] top-[10px] right-[100px]">
              <FDropdown
                label="Change user role"
                options={roleOptions}
                selectedValue={user.role}
                onChange={handleUpdateRole}
              />
            </div>
          ) : null}
          <FSidebar
            className="static sm:sticky top-0 self-start z-20 relative"
            bubbleCollapsed={(state) => {
              switchCollapsed(state);
            }}
            sections={[
              {
                fixedBottom: false,
                items: [
                  { name: "Home", href: "/home", icon: "home" },
                  { icon: "search", name: "Search", href: "/search" },
                  can(Permission.VIEW_ALL_CAMPAIGNS)
                    ? {
                        icon: "bookmark",
                        name: "Campaigns",
                        href: "/campaigns",
                      }
                    : null,
                  // { icon: "bulletins", name: "Bulletins", href: "/opportunities" },
                  // { icon: "email", name: "Briefs", href: "/briefs" },
                ].filter(Boolean),
              },
              // discoverySection,
              //   campaignsSection(),

              can(Permission.AMBASSADORS_VIEW_ACCEPTED) && ambassadorSection,
              {
                fixedBottom: false,
                items: [
                  {
                    icon: "gifts",
                    name: "Bulletins",
                    href: "/bulletins",
                  },
                  can(Permission.VIEW_GROUPS) && {
                    icon: "bulletins",
                    name: "Groups",
                    href: "/groups",
                  },

                  can(Permission.VIEW_OUTREACH)
                    ? { icon: "email", name: "Outreach", href: "/outreach" }
                    : null,
                  can(Permission.VIEW_REPORTS)
                    ? {
                        icon: "graph",
                        name: "Reports",
                        href: `/reports`,
                      }
                    : null,
                  {
                    icon: "chat",
                    name: "Notifications",
                    href: `/notifications`,
                  },
                ].filter(Boolean),
              },
              settingsSection,
            ].filter(Boolean)}
          />
          <div
            className={`w-full overscroll-none ${
              isCollapsed
                ? "max-w-[calc(100vw-66px)]"
                : "max-w-[calc(100vw-244px)]"
            }`}
          >
            <div
              className={`w-full overscroll-none ${
                isFullScreenRoute ? "" : "px-[15px] sm:px-[40px] mt-[40px] z-10"
              }`}
            >
              {children}
            </div>
          </div>
        </div>
      </LayoutContext.Provider>
    </>
  );
}
