import React, { useState, useContext, useEffect } from "react";
import { BrandEditContext, UserContext } from "@contexts/index";

import { FInput } from "@components/FInputs";
import FDropdown from "@components/FDropdown";

import FButton from "@components/FButton";

const BrandSettings = () => {
  const { brandSettings } = useContext(BrandEditContext);

  const [selectedBrandId, setSelectedBrandId] = useState(
    brandSettings?.[0]?.id || null
  );

  const currentBrand = brandSettings?.find(
    (brand) => brand.id === selectedBrandId
  );

  const [values, setValues] = useState({
    companyName: currentBrand?.name || "",
    primaryContact: currentBrand?.primary_contact || "",
    primaryEmail: currentBrand?.email || "",
    mainWebsite: currentBrand?.url || "",
    instagramHandle: currentBrand?.instagram_handle || "",
    tiktokHandle: "", // Not in API response
    youtubeHandle: "", // Not in API response
    facebookHandle: "", // Not in API response
  });

  useEffect(() => {
    if (currentBrand) {
      setValues({
        companyName: currentBrand.name || "",
        primaryContact: currentBrand.primary_contact || "",
        primaryEmail: currentBrand.email || "",
        mainWebsite: currentBrand.url || "",
        instagramHandle: currentBrand.instagram_handle || "",
        tiktokHandle: "",
        youtubeHandle: "",
        facebookHandle: "",
      });
    }
  }, [currentBrand]);

  const brandSelection =
    brandSettings?.map((brand) => ({
      label: brand.name,
      value: brand.id,
    })) || [];

  const brandInfoFields = [
    { label: "Company Name", name: "companyName", type: "companyName" },

    { label: "Main Website", name: "mainWebsite", type: "mainWebsite" },
    {
      label: "Instagram Handle",
      name: "instagramHandle",
      type: "instagramHandle",
    },
  ];

  return (
    <div className="max-w-[800px]">
      {brandSelection.length > 0 && (
        <div className="py-[40px]">
          <h1 className="text-[24px] mb-[24px]">Your Brands</h1>
          <div className="mt-[30px] max-w-[200px]">
            <FDropdown
              options={brandSelection}
              selectedValue={
                brandSelection.find((b) => b.value === selectedBrandId)
                  ?.value || ""
              }
              onChange={(value) => setSelectedBrandId(Number(value))}
              width="auto"
              height="32"
              className="bg-white border-black border"
            />
          </div>
        </div>
      )}
      <div className="py-[40px]">
        <h1 className="text-[24px] mb-[24px]">Basic Info</h1>
        {brandInfoFields.map(({ name, label }) => (
          <FInput
            label={label}
            className="mb-[16px]"
            key={name}
            required={true}
            width="100%"
            value={values[name]}
            onChange={(value) => setValues({ ...values, [name]: value })}
          />
        ))}
        <div className="mt-[30px] flex justify-end max-w-[200px]">
          <FButton primary={true} label="Save" width="100%" type="submit" />
        </div>
      </div>
    </div>
  );
};

export default BrandSettings;
