import React, { useMemo, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { decorationColor, constructURL } from "@utils/cardUtilities";
import FIcon from "@components/FIcon";
import moment from "moment";
7;
import FDropdown from "./FDropdown";
import { addCommas, formatDollarValue } from "@utils/number_utilities";
import { Sort, SortOrder } from "@types";

import TableTotals from "@components/Tables/TableTotals";
import { Column } from "@types";
import { isObject } from "lodash";
import { platformList } from "@constants/constants";
import { getResizedImageURL } from "@utils/image";

const getPlatformIcon = (platform) => {
  const { icon } = platformList.find(({ value }) => platform === value) || {};

  return <FIcon icon={icon} size={16} color="#000721" />;
};

interface TableProps<T> {
  data: any[];
  columns: Column[];
  onOptionSelect?: (value: string, id: number | string) => void;
  hideRowDropDown?: boolean;
  rowActions?: { label: string; value: string | boolean }[];
  getRowActions?: (id: number) => { label: string; value: string | boolean }[];
  onSortChange?: (sort: Sort) => void;
  sort?: Sort;
  showTotals?: boolean;
  selectedItems?: T[];
  onChangeSelectedItems?: (selectedItems: T[]) => void;
  hasSingleSelection?: boolean;
}

const Table = function <T>({
  data,
  columns,
  onOptionSelect,
  onSortChange,
  rowActions,
  getRowActions,
  showTotals,
  sort,
  selectedItems,
  onChangeSelectedItems,
  hasSingleSelection,
}: TableProps<T>) {
  const [sortConfig, setSortConfig] = useState<Sort>(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );

  const tableRef = useRef<HTMLDivElement>(null);
  const columnRefs = useRef<(HTMLDivElement | null)[]>([]);
  const parentRef = useRef<HTMLDivElement>(null);
  const [columnWidths, setColumnWidths] = useState<number[]>([]);
  const [tableWidth, setTableWidth] = useState<number>(0);
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const [isWidthsCalculated, setIsWidthsCalculated] = useState<boolean>(false);

  const showTableCheckbox = !!onChangeSelectedItems;

  const extendedColumns = useMemo(() => {
    if (rowActions || getRowActions) {
      return [
        ...columns,
        {
          key: "actions",
          label: "",
          className: "text-center",
        },
      ];
    }
    return columns;
  }, [columns, rowActions, getRowActions]);

  useEffect(() => {
    const calculateColumnWidths = () => {
      const widths = extendedColumns.map((column, columnIndex) => {
        const headerCell = columnRefs.current[columnIndex];
        const cells = Array.from(
          tableRef.current?.querySelectorAll(
            `[data-column-index="${columnIndex}"]`
          ) || []
        ) as HTMLDivElement[];

        const maxContentWidth =
          Math.max(
            ...cells.map((cell) => {
              const textContent = cell.textContent || "";
              const specialCharCount = (
                textContent.match(/[^a-zA-Z0-9\s]/g) || []
              ).length;
              return (textContent.length + specialCharCount) * 12;
            })
          ) + 1;
        const headerText = headerCell?.textContent || "";
        const headerSpecialCharCount = (
          headerText.match(/[^a-zA-Z0-9\s]/g) || []
        ).length;
        const headerWidth =
          (headerText.length + headerSpecialCharCount) * 10 + 40;
        const additionalWidth = column.isFirstColumn && column.isImage ? 50 : 0;

        if (column.type === "platform") {
          return 60;
        }

        const width = Math.max(
          80,
          Math.min(
            400,
            Math.max(maxContentWidth, headerWidth) +
              (column.isStateBubble && headerWidth < 120 ? 40 : 0) +
              additionalWidth
          )
        );
        return width;
      });
      setColumnWidths(widths);
      setIsWidthsCalculated(true);
      // // Please keep this setTimeout here. This is a hack to make the table widths calculated
      // // after the table is rendered. This is a workaround for visual jank in the table widths
      // // calculation.
      // setTimeout(() => {
      //   setIsWidthsCalculated(true);
      // }, 1);
    };

    calculateColumnWidths();
    const debouncedCalculateColumnWidths = debounce(calculateColumnWidths, 200);
    window.addEventListener("resize", debouncedCalculateColumnWidths);

    return () =>
      window.removeEventListener("resize", debouncedCalculateColumnWidths);
  }, [columns]);

  const sortedData = useMemo(() => {
    const sortableItems = [...data];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        // Find the column configuration for the sorted field
        const column = columns.find((col) => col.key === sortConfig.key);

        // Get values, handling nested keys if needed
        const aValue = column?.nestedKey
          ? a[column.nestedKey]?.[column.key]
          : a[sortConfig.key];
        const bValue = column?.nestedKey
          ? b[column.nestedKey]?.[column.key]
          : b[sortConfig.key];

        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig, columns]);

  const requestSort = (key: string) => {
    let direction = "asc" as SortOrder;

    const sortCriteria = sort || sortConfig;

    if (
      sortCriteria &&
      sortCriteria.key === key &&
      sortCriteria.direction === "asc"
    ) {
      direction = "desc";
    } else if (
      sortCriteria &&
      sortCriteria.key === key &&
      sortCriteria.direction === "desc"
    ) {
      direction = "none";
    } else {
      direction = "asc";
    }

    setSortConfig({ key, direction });

    if (onSortChange) {
      onSortChange({ key, direction });
    }
  };

  const handleToggleRowSelection = (id: T) => {
    if (hasSingleSelection) {
      onChangeSelectedItems([id]);

      return;
    }

    let newSelectedItems = selectedItems || [];

    if (selectedItems.includes(id)) {
      newSelectedItems = newSelectedItems.filter((item) => item !== id);
    } else {
      newSelectedItems = [...selectedItems, id];
    }

    onChangeSelectedItems(newSelectedItems);
  };

  const handleTogleSelectAll = () => {
    const allIds = sortedData.map(({ id }) => id);

    let newSelectedItems = selectedItems || [];

    if (allIds.every((id) => selectedItems.includes(id))) {
      newSelectedItems = [];
    } else {
      newSelectedItems = allIds.reduce((result, id) => {
        if (result.includes(id)) {
          return result;
        }

        return [...result, id];
      }, newSelectedItems);
    }

    onChangeSelectedItems(newSelectedItems);
  };

  useEffect(() => {
    const tableWidthCalc =
      columnWidths.reduce((acc, width) => acc + width, 0) +
      (showTableCheckbox ? 50 : 0);
    const parentContainer = parentRef.current?.getBoundingClientRect();
    setTableWidth(
      parentContainer && parentContainer.width > tableWidthCalc
        ? parentContainer.width
        : tableWidthCalc
    );
  }, [columnWidths, extendedColumns]);

  const sortCriteria = sort || sortConfig;

  const showSelectAll = showTableCheckbox && !hasSingleSelection;

  return (
    <main className="flex-grow overflow-hidden">
      <div
        className="relative overflow-x-auto cursor-default"
        ref={parentRef}
        style={{
          willChange: "transform",
          contain: "content",
        }}
      >
        <div className="z-10 bg-light_red">
          <div className="text-sm text-left text-black">
            <div
              className="text-xs border-b text-black uppercase flex"
              style={{ width: `${tableWidth}px` }}
            >
              {showSelectAll ? (
                <div className="p-4 whitespace-nowrap flex items-center sticky left-0 bg-light_red_opacity backdrop-blur z-50">
                  <>
                    <input
                      type="checkbox"
                      className="w-4 h-4 text-black border-natural_400 rounded focus:ring-none cursor-pointer hover:border-black"
                      checked={
                        selectedItems &&
                        sortedData.every(({ id }) => selectedItems.includes(id))
                      }
                      onChange={handleTogleSelectAll}
                    />
                    <label className="sr-only">checkbox</label>
                  </>
                </div>
              ) : null}
              {extendedColumns.map((column, columnIndex) => (
                <div
                  key={`${column.nestedKey ? column.nestedKey : column.key}-${columnIndex}`}
                  ref={(el) => (columnRefs.current[columnIndex] = el)}
                  className={`${
                    columnIndex === 0
                      ? `sticky left-${
                          showSelectAll ? "12" : "0"
                        } bg-light_red_opacity backdrop-blur z-50`
                      : column.key === "actions"
                        ? "sticky right-0 bg-light_red_opacity backdrop-blur z-50"
                        : ""
                  } ${
                    column.key !== "actions"
                      ? "cursor-pointer hover:bg-[#FAF5F4]"
                      : ""
                  } py-[16px] z-10 bg-light_red ${
                    !isWidthsCalculated ? "" : ""
                  }`}
                  onClick={() => requestSort(column.key)}
                  style={{
                    minWidth: `${columnWidths[columnIndex]}px`,
                    width: "100%",
                    left:
                      columnIndex === 0
                        ? showSelectAll
                          ? "48px"
                          : "0px"
                        : undefined,
                  }}
                >
                  <div
                    className={`whitespace-nowrap flex items-center ${column.type === "platform" ? "justify-center" : "justify-start"} `}
                    style={{
                      minWidth: `${columnWidths[columnIndex]}px`,
                      width: "100%",
                      padding: "0px 20px",
                    }}
                  >
                    {column.label}
                    {column.key === "actions" ? null : (
                      <span className="flex ml-[8px] flex-col items-center gap-[1px]">
                        <FIcon
                          icon="sort-up"
                          color={
                            sortCriteria &&
                            sortCriteria.key === column.key &&
                            sortCriteria.direction === "asc"
                              ? `#000F45`
                              : `#797E92`
                          }
                          size={7}
                          className="relative -top-[-1px]"
                        />
                        <FIcon
                          icon="sort-down"
                          color={
                            sortCriteria &&
                            sortCriteria.key === column.key &&
                            sortCriteria.direction === "desc"
                              ? `#000F45`
                              : `#797E92`
                          }
                          size={7}
                        />
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {showTotals && (
          <TableTotals
            data={sortedData}
            columns={extendedColumns}
            columnWidths={columnWidths}
            tableWidth={tableWidth}
            showTableCheckbox={showTableCheckbox}
          />
        )}
        <div
          className="relative"
          ref={tableRef}
          style={{
            width: `${tableWidth}px`,
            opacity: isWidthsCalculated ? 1 : 0,
            transition: "opacity 0.2s ease-in-out",
          }}
        >
          <div className="min-w-full text-[14px] text-left text-black">
            {sortedData.map((item, index) => (
              <div
                key={item.id}
                className={`flex relative w-full border-b border-black transition-opacity duration-100 ${
                  !isWidthsCalculated ? "" : ""
                }`}
                style={{
                  zIndex:
                    hoveredRowIndex === index
                      ? 1000 + sortedData.length + 100
                      : 1000 + sortedData.length - index,
                }}
                onMouseEnter={() => setHoveredRowIndex(index)}
                onMouseLeave={() => setHoveredRowIndex(null)}
              >
                {showTableCheckbox && (
                  <div className="px-4 sticky left-0 bg-light_red_opacity backdrop-blur z-50 flex items-center">
                    <input
                      type="checkbox"
                      className="w-4 h-4 text-white border-gray-300 rounded focus:ring-black checked:bg-black focus:ring-none cursor-pointer hover:border-black"
                      checked={selectedItems && selectedItems.includes(item.id)}
                      onChange={() => handleToggleRowSelection(item.id)}
                    />
                  </div>
                )}
                {extendedColumns.map((column, columnIndex) => {
                  let dataValue;

                  if (column.getValue) {
                    dataValue = column.getValue(item);
                  } else {
                    dataValue = column.nestedKey
                      ? item[column.nestedKey] &&
                        item[column.nestedKey][column.key]
                      : item[column.key];
                  }

                  if (isObject(dataValue) && !Array.isArray(dataValue)) {
                    dataValue = "";
                  } else if (Array.isArray(dataValue)) {
                    dataValue = dataValue.join(", ");
                  }

                  return (
                    <div
                      key={`${item.id}-${column.nestedKey ? `${column.nestedKey}-${column.key}` : column.key}`}
                      className={`${column.getContent ? "" : "capitalize"} flex items-center ${
                        columnIndex === 0
                          ? `sticky left-${
                              showTableCheckbox ? "12" : "0"
                            } bg-light_red_opacity backdrop-blur z-50`
                          : column.key === "actions"
                            ? "sticky right-0 bg-light_red_opacity backdrop-blur z-50"
                            : ""
                      } ${
                        column.key !== "actions" ? "" : ""
                      } py-[16px] z-10 bg-light_red`}
                      style={{
                        minWidth: `${columnWidths[columnIndex]}px`,
                        width: "100%",
                        left:
                          columnIndex === 0
                            ? showTableCheckbox
                              ? "48px"
                              : "0px"
                            : undefined,
                      }}
                      data-column-index={columnIndex}
                    >
                      <div
                        className={`justify-center ${
                          column.key === "actions" &&
                          (rowActions || getRowActions)
                            ? ""
                            : "overflow-hidden text-ellipsis"
                        }`}
                        style={{
                          minWidth: `${columnWidths[columnIndex]}px`,
                          width: "100%",
                          padding: "2px 20px",
                        }}
                      >
                        {column.type === "platform" ? (
                          getPlatformIcon(item.platform)
                        ) : column.getContent ? (
                          column.getContent(item)
                        ) : column.isDifference ? (
                          <span
                            className={`${dataValue > 0 ? "text-dill" : dataValue < 0 ? "text-red" : ""}`}
                          >
                            {dataValue > 0 ? "+" : ""}
                            {dataValue}
                            {column.isPercentage ? "%" : ""}
                          </span>
                        ) : column.key === "actions" &&
                          (rowActions || getRowActions) ? (
                          <div
                            className={`flex justify-end relative `}
                            style={{
                              zIndex:
                                hoveredRowIndex === index
                                  ? 1000 + sortedData.length + index
                                  : 1000 + sortedData.length - index,
                            }}
                          >
                            <FDropdown
                              icon="ellipsis"
                              iconColor="#000F45"
                              width="auto"
                              height="28px"
                              iconOnly={true}
                              menuUp={index === sortedData.length - 1}
                              options={
                                getRowActions
                                  ? getRowActions(item.id)
                                  : rowActions
                              }
                              hardClose={hoveredRowIndex !== index}
                              onChange={(value, e) => {
                                onOptionSelect(String(value), item.id);
                                e.stopPropagation();
                                setOpenDropdownIndex(
                                  openDropdownIndex === index ? null : index
                                );
                              }}
                              zIndex={
                                hoveredRowIndex === index ? 1002 : undefined
                              }
                              className="cursor-pointer mx-1 relative"
                            />
                          </div>
                        ) : columnIndex === 0 && column.isImage ? (
                          <div className="flex items-center">
                            <div
                              className={`bg-center bg-cover w-[36px] h-[36px] mr-3 ${
                                column.imageType === "profile"
                                  ? "rounded-full"
                                  : "rounded-sm"
                              }`}
                              style={{
                                backgroundImage: `url(${getResizedImageURL(item[column.imageKey], 36, 36)})`,
                              }}
                            />
                            {column.isLink ? (
                              <Link
                                to={constructURL(column.linkURL, item)}
                                className="text-black font-medium underline hover:underline"
                                target={
                                  column.linkTarget ||
                                  (column.imageType === "profile" &&
                                    !column.linkOpensInModal &&
                                    "_blank") ||
                                  undefined
                                }
                              >
                                {item[column.key]}
                              </Link>
                            ) : (
                              <span>{item[column.key]}</span>
                            )}
                          </div>
                        ) : column.isDate ? (
                          <span>
                            {dataValue
                              ? moment(dataValue).format("MMM DD, YYYY")
                              : "--"}
                          </span>
                        ) : column.isCategories ? (
                          <>
                            <span>{dataValue}</span>
                          </>
                        ) : column.isMoney ? (
                          <span>
                            {dataValue
                              ? `${formatDollarValue(dataValue)}`
                              : column.defaultLabel || "--"}
                          </span>
                        ) : column.isNumber ? (
                          <span>
                            {dataValue
                              ? `${addCommas(dataValue)}`
                              : column.defaultLabel || "--"}
                          </span>
                        ) : column.isLink ? (
                          column.isOutsideLink ? (
                            <a
                              href={
                                column.linkURL
                                  ? constructURL(column.linkURL, item)
                                  : dataValue
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-black font-medium underline hover:underline normal-case"
                            >
                              {dataValue ? dataValue : "--"}
                            </a>
                          ) : (
                            <Link
                              to={constructURL(column.linkURL, item)}
                              className="text-black font-medium underline hover:underline  normal-case"
                              target={column.linkTarget || "_blank"}
                            >
                              {dataValue ? dataValue : "--"}
                            </Link>
                          )
                        ) : column.isStateBubble ? (
                          <span
                            className={`px-3 py-1 text-xs rounded-full text-white ${decorationColor(
                              dataValue
                            )}`}
                          >
                            {dataValue === null ||
                            dataValue === undefined ||
                            dataValue === "n/a"
                              ? "--"
                              : typeof dataValue === "boolean"
                                ? dataValue
                                  ? "Yes"
                                  : "No"
                                : dataValue}
                          </span>
                        ) : (
                          <span
                            className="normal-case overflow-hidden text-ellipsis"
                            style={{
                              maxWidth: `${columnWidths[columnIndex]}px`,
                              width: `${columnWidths[columnIndex]}px`,
                            }}
                          >
                            {column.defaultLabel && !dataValue
                              ? column.defaultLabel
                              : dataValue === null ||
                                  dataValue === "" ||
                                  dataValue === undefined
                                ? "--"
                                : dataValue}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
};

function debounce(func: (...args: any) => any, wait: number) {
  let timeout: NodeJS.Timeout;
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default Table;
