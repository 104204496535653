import { ParticipantStatus } from "@types";

const {
  ACCEPTED,
  ARCHIVED,
  DECLINED,
  ONBOARDING,
  NON,
  REVIEW_APPROVED,
  REVIEW_PENDING,
  REVIEW_REJECTED,
  VIEWED,
} = ParticipantStatus;

export const STATUS_OPTIONS = [
  { label: "Viewed", value: VIEWED },
  { label: "Onboarding", value: ONBOARDING },
  { label: "Accepted", value: ACCEPTED },
  { label: "Declined", value: DECLINED },
  { label: "Archived", value: ARCHIVED },
  { label: "N/A", value: NON },
  { label: "Approved", value: REVIEW_APPROVED },
  { label: "Rejected", value: REVIEW_REJECTED },
  { label: "Pending", value: REVIEW_PENDING },
];
