import React from "react";
import Table from "@components/Table";
import { EngagementGrowthDailyStats } from "@types";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import moment from "moment";
import { sortBy } from "lodash";

const TABLE_COLUMNS = [
  {
    key: "date",
    label: "Month",
    getValue: (row) => moment(row.date).format("MMM YY'"),
  },
  {
    key: "engagement_avg",
    label: "Engagement",
    getValue: (row) => `${row.engagement_avg}%`,
  },
  {
    key: "engagement_change_percent",
    label: "MoM Diff",
    isDifference: true,
    isPercentage: true,
  },
];

const OPTIONS = {
  yAxis: {
    labels: {
      formatter: function () {
        return this.value;
      },
    },
  },
  xAxis: [
    {
      type: "datetime",
      labels: {
        formatter: function () {
          return moment(this.value).format("MMM");
        },
      },
      startOnTick: true,
      endOnTick: true,
    },
  ],
};

interface EngagementGrowthRateProps {
  data: EngagementGrowthDailyStats[];
}

const EngagementGrowthRate = ({ data }: EngagementGrowthRateProps) => {
  const dataSortedByTime = sortBy(data, ({ date }) => moment(date).valueOf());

  const options = {
    ...OPTIONS,
    series: [
      {
        data: dataSortedByTime.map(({ date, engagement_avg }) => [
          moment(date).valueOf(),
          engagement_avg,
        ]),
        name: "Engagement average",
        type: "line",
        marker: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <div>
      <p className="mb-8">Engagement growth rate</p>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div className="mt-9" />
      <Table
        columns={TABLE_COLUMNS}
        data={[...dataSortedByTime].reverse()}
      ></Table>
    </div>
  );
};

export default EngagementGrowthRate;
