import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { getQueryParamsForSearchRequest } from "@constants/api";
import { Sort } from "@types";

const getCollaboratorsList = async (
  page?: number,
  sort?: Sort,
  params?: object
): Promise<ApiResponse<any>> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);
  return apiWrapper(`/collaborators/campaign_participants?${queryParams.toString()}`, "GET");
};

const getOutreachCollaboratorsList = async (
  page?: number,
  sort?: Sort,
  params?: object
): Promise<ApiResponse<any>> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);

  return apiWrapper(`/collaborators/outreach_recipients?${queryParams.toString()}`, "GET");
};

const getBulletinCollaboratorsList = async (
  page?: number,
  sort?: Sort,
  params?: object
): Promise<ApiResponse<any>> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);

  return apiWrapper(`/collaborators/bulletin_recipients?${queryParams.toString()}`, "GET");
};

const getOffPlatformCollaboratorsList = async (
  page?: number,
  sort?: Sort,
  params?: object
): Promise<ApiResponse<any>> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);

  return apiWrapper(`/collaborators/off_platform?${queryParams.toString()}`, "GET");
};

export {
  getCollaboratorsList,
  getOutreachCollaboratorsList,
  getBulletinCollaboratorsList,
  getOffPlatformCollaboratorsList
};
