import { Sort } from "@types";

const useApiProxy = import.meta.env.VITE_FOHR_API_PROXY === '1';
export const LOCAL_API_ORIGIN: string = useApiProxy ? '' : import.meta.env.VITE_FOHR_API_URL;

export const errorsToMarkup = (errors: { [key: string]: string[] }) => {
  return Object.keys(errors).reduce((acc, val) => {
    acc[val] = `<ul><li>${capitalize(val)} ${errors[val].join(", ")}</li></ul>`;
    return acc;
  }, {});
};

// uppercase the first letter of a string
const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getQueryParamsForSearchRequest = (
  page?,
  sort?: Sort,
  params?: object
) => {
  const queryParams = new URLSearchParams();

  if (page) {
    queryParams.append("page", `${page}`);
  }

  const { per_page: perPage, ...rest } = (params || { per_page: null }) as {
    per_page: number;
  };

  queryParams.append("per_page", `${perPage !== undefined ? perPage : 24}`);

  const allParams = params ? { ...rest, s: null } : { s: null };

  if (sort?.key) {
    const s = `${sort.key} ${sort.direction}`;

    allParams.s = s;
  }

  if (allParams) {
    Object.keys(allParams).forEach((param) => {
      if (allParams[param]) {
        queryParams.append(`q[${param}]`, allParams[param]);
      }
    });
  }

  return queryParams;
};
