import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { BriefSection } from "@types";

const getCampaignBriefSections = async (
  campaign_brief_id: number | string,
  kind?: string,
  title?: string,
  custom?: boolean
): Promise<BriefSection[]> => {
  const queryParams = new URLSearchParams();
  queryParams.append("q[campaign_brief_id_eq]", campaign_brief_id.toString());

  if (kind) {
    queryParams.append("q[kind_eq]", kind);
  }
  if (title) {
    queryParams.append("q[title_i_cont]", title);
  }
  if (custom !== undefined) {
    queryParams.append("q[custom_eq]", custom.toString());
  }

  const response = await apiWrapper(
    `/campaign_brief_sections?${queryParams.toString()}`,
    "GET"
  );

  return response.data;
};

const createCampaignBriefSection = async (
  params: any
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/campaign_brief_sections`, "POST", params);
};

const updateCampaignBriefSection = async (
  campaign_brief_section_id: number | string,
  params: any
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/campaign_brief_sections/${campaign_brief_section_id}`,
    "PUT",
    params
  );
};

const deleteCampaignBriefSection = async (
  campaign_brief_section_id: number | string
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/campaign_brief_sections/${campaign_brief_section_id}`,
    "DELETE"
  );
};

export {
  createCampaignBriefSection,
  updateCampaignBriefSection,
  deleteCampaignBriefSection,
  getCampaignBriefSections,
};
