import FDropdown from "@components/FDropdown";
import { times } from "lodash";

const DAYS = times(30, (index) => ({
  value: `${index + 1}`.padStart(2, "0"),
  label: `${index + 1}`,
}));

const MONTHS = [
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

const getYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i < 100; i++) {
    years.push({ label: currentYear - i, value: currentYear - i });
  }
  return years;
};

const YEARS = getYears();

interface BirthdayPicker {
  value: string;
  onChange: (value: string) => void;
}

const BirthdayPicker = ({ value, onChange }) => {
  console.log("Value", value);
  const updateBirthday = (segment, valueType) => {
    const [year, month, day] = value.split("-");

    let updatedBirthday = value;

    switch (valueType) {
      case "year":
        updatedBirthday = `${segment}-${month}-${day}`;
        break;
      case "month":
        updatedBirthday = `${year}-${segment}-${day}`;
        break;
      case "day":
        updatedBirthday = `${year}-${month}-${segment}`;
        break;
    }

    onChange(updatedBirthday);
  };

  return (
    <>
      <label className="text-sm leading-[18px]">Birthday</label>
      <div className="flex gap-2 w-full">
        <FDropdown
          className="flex-1"
          selectedValue={value.split("-")[1]}
          options={MONTHS}
          onChange={(value) => updateBirthday(value, "month")}
        />
        <FDropdown
          className="flex-1"
          selectedValue={value.split("-")[2]}
          options={DAYS}
          onChange={(value) => updateBirthday(value, "day")}
        />
        <FDropdown
          className="flex-1"
          selectedValue={parseInt(value.split("-")[0], 10)}
          options={YEARS}
          onChange={(value) => updateBirthday(value, "year")}
        />
      </div>
    </>
  );
};

export default BirthdayPicker;
