import React from "react";

import { maxBy, sortBy } from "lodash";
import { DemographicsBreakdownItem } from "@types";

import HalfPieChart from "@components/Graphs/HalfPieChart";
import WidgetContainer from "@components/charts/demographics/WidgetContainer";

interface MaritalStatusBreakdownProps {
  items: DemographicsBreakdownItem[];
}

export default function MaritalStatusBreakdown({
  items,
}: MaritalStatusBreakdownProps) {
  const maxValueItem = maxBy(items, "percentage");

  return (
    <WidgetContainer title="Marital Status" value={maxValueItem?.name}>
      <HalfPieChart
        data={sortBy(items || [], "percentage").reverse()}
        colors={["#1F8855", "#D6EFDF"]}
        subtitle={`${maxValueItem?.percentage}%`}
      />
    </WidgetContainer>
  );
}
