import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";

import EventDeliverable from "@components/Campaign/Brief/BriefForms/EventDeliverable";
import SocialDeliverable from "@components/Campaign/Brief/BriefForms/SocialDeliverable";
import Modal from "@components/Modals/Modal";

import { Campaign, CampaignDeliverable } from "@types";

import {
  createCampaignDeliverable,
  createParticipantDeliverable,
  updateCampaignDeliverable,
  updateParticipantDeliverable,
} from "@api/Campaign/CampaignDeliverables";
import FButton from "@components/FButton";
import FDropdown from "@components/FDropdown";

enum SOURCE {
  SOCIAL = "social",
  EVENT = "event",
  OTHER = "other",
}

const SOURCES = [
  { value: SOURCE.SOCIAL, label: "Social" },
  { value: SOURCE.EVENT, label: "Event" },
  { value: SOURCE.OTHER, label: "Other" },
];

interface AddDeliverableModalProps {
  campaign: Campaign;
  deliverable: CampaignDeliverable;
  onClose: () => void;
  participantGroupId: number;
  participantId: number;
  onUpdateCampaign: (campaign: Campaign) => void;
}

const AddDeliverableModal = ({
  campaign,
  deliverable,
  onClose,
  participantGroupId,
  participantId,
  onUpdateCampaign,
}: AddDeliverableModalProps) => {
  const [deliverableType, setDeliverableType] = useState(SOURCES[0].value);

  const [waiting, setWaiting] = useState(false);

  const {
    deliverables: campaignDeliverables,
    participants,
    participant_groups,
  } = campaign;

  const participant =
    participantId && participants.find(({ id }) => id === participantId);

  const [selectedParticipantGroup, setSelectedParticipantGroup] = useState(
    participant_groups[0]?.id
  );

  useEffect(() => {
    if (deliverable) {
      const specificDeliverable = (
        participantId ? participant.deliverables : campaignDeliverables
      ).find((d: CampaignDeliverable) => d.id === deliverable.id);

      if (specificDeliverable) {
        setDeliverableType(specificDeliverable.kind as SOURCE);
      }
    }
  }, [
    deliverable,
    campaignDeliverables,
    participantId,
    participant?.deliverables,
  ]);

  const handleSubmit = async (updates) => {
    setWaiting(true);

    try {
      if (deliverable) {
        await (
          participantId
            ? updateParticipantDeliverable
            : updateCampaignDeliverable
        )(deliverable.id, {
          participant_deliverable: updates,
        });

        let campaignUpdates;

        if (participantId) {
          const newParticipants = participants.map((participant) => {
            if (participant.id === deliverable.participant_id) {
              const newDeliverables = participant.deliverables.map((p) =>
                p.id === deliverable.id ? { ...p, ...updates } : p
              );

              return {
                ...participant,
                deliverables: newDeliverables,
              };
            }

            return participant;
          });

          campaignUpdates = {
            participants: newParticipants,
          };
        } else {
          campaignUpdates = {
            deliverables: campaignDeliverables.map((p) =>
              p.id === deliverable.id ? { ...p, ...updates } : p
            ),
          };
        }

        onUpdateCampaign({
          ...campaign,
          ...campaignUpdates,
        });
      } else {
        let campaignUpdates;

        let response;

        if (participantId) {
          response = await createParticipantDeliverable({
            ...updates,
            kind: deliverableType,
            participant_id: participantId,
          });
        } else {
          response = await createCampaignDeliverable(campaign.id, {
            campaign_deliverable: {
              ...updates,
              kind: deliverableType,
              campaign_id: campaign.id,
              participant_group_id:
                participantGroupId || selectedParticipantGroup,
            },
          });
        }

        console.log("Response", response.data);

        if (participantId) {
          const newParticipants = participants.map((participant) => {
            if (participant.id === participantId) {
              return {
                ...participant,
                deliverables: [...participant.deliverables, response.data],
              };
            }

            return participant;
          });

          campaignUpdates = {
            participants: newParticipants,
          };
        } else {
          campaignUpdates = {
            deliverables: [...campaignDeliverables, response.data],
          };
        }

        onUpdateCampaign({
          ...campaign,
          ...campaignUpdates,
        });
      }

      onClose();

      toast.success("Saved");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setWaiting(false);
    }
  };

  const formProps = {
    onClose,
    deliverable,
    onSubmit: handleSubmit,
    waiting,
  };

  return (
    <Modal
      hasForm
      isOpen
      title={deliverable ? "Edit Deliverable" : "Add Deliverable"}
      onClose={onClose}
      actionLabel={deliverable ? "Save" : "Add"}
      className="w-[650px] overflow-auto"
    >
      <div className="flex mb-[32px] w-full">
        {SOURCES.map(({ value, label }) => (
          <FButton
            key={value}
            primary={value === deliverableType}
            onClick={() => setDeliverableType(value)}
            label={label}
            className="flex-1"
            width="190px"
            height="40px"
          />
        ))}
      </div>
      {!participantId ? (
        <FDropdown
          className="mb-[16px]"
          label="Participant Group"
          options={participant_groups.map((group) => ({
            label: group.name,
            value: group.id,
          }))}
          onChange={(value) => {
            setSelectedParticipantGroup(value as number);
          }}
          height="40"
          width="100%"
          selectedValue={selectedParticipantGroup}
        />
      ) : null}
      <div className="max-h-[60vh] overflow-y-auto">
        {deliverableType === "social" && <SocialDeliverable {...formProps} />}
        {deliverableType === "event" && <EventDeliverable {...formProps} />}
        {deliverableType === "other" && <EventDeliverable {...formProps} />}
      </div>
    </Modal>
  );
};

export default AddDeliverableModal;
