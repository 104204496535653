import GenderBreakdownChart from "@components/charts/demographics/GenderBreakdownChart";
import { GENDERS } from "@constants/demographics";
import { DemographicsBreakdownItem, Participant } from "@types";
import { groupBy, isEmpty } from "lodash";

const getGenderBreakdown = (
  participants: Participant[]
): DemographicsBreakdownItem[] => {
  const total = participants.length;

  const allGenders = participants.map((participant) => {
    if (isEmpty(participant.gender)) {
      return { value: "unknown" };
    }

    return { value: participant.gender[0] };
  }, []);

  const groupedGenders = groupBy(allGenders, "value");

  return [...GENDERS, { value: "unknown", label: "Unknown" }].reduce(
    (result, item) => {
      const count = groupedGenders[item.value]?.length;

      if (!count) {
        return result;
      }

      return [
        ...result,
        {
          name: item.label,
          count,
          percentage: Math.round((100 * count) / total),
        },
      ];
    },
    []
  );
};

interface GenderBreakdownProps {
  participants: Participant[];
}

const GenderBreakdown = ({ participants }: GenderBreakdownProps) => (
  <GenderBreakdownChart items={getGenderBreakdown(participants)} showCounts />
);

export default GenderBreakdown;
