import React from "react";
import { Outreach } from "@types";
import { sumBy } from "lodash";

const getStats = (outreach) => {
  const { recipients } = outreach;

  const totalParticipants = recipients.length;

  const deliveredCount = recipients.filter(({ delivered }) => delivered).length;
  const totalOpens = sumBy(recipients, "opens");
  const totalClicks = sumBy(recipients, "clicks");

  return [
    {
      label: "Total Influencers",
      value: totalParticipants,
    },
    {
      label: "Total Delivered",
      value: deliveredCount,
      percentage: Math.round((100 * deliveredCount) / totalParticipants),
    },
    {
      label: "Total Opened",
      value: totalOpens,
    },
    {
      label: "Total Clicked",
      value: totalClicks,
    },
  ];
};

const MessageView = ({ outreach }: { outreach: Outreach }) => {
  return (
    <div>
      <div className="flex justify-around mt-8">
        {getStats(outreach).map(({ value, label, percentage }) => (
          <div className="text-center" key={label}>
            <h2 className="text-lg font-semibold">{label}</h2>
            <p className="text-2xl">{value}</p>
            {percentage ? <p className="text-2xl">{percentage}%</p> : null}
          </div>
        ))}
      </div>
      <div className="mt-8">
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Reply To:</h2>
          <p>{outreach.reply_to || "N/A"}</p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Message</h2>
          <div className="mb-6">
            <h3 className="text-lg font-semibold">Subject:</h3>
            <p>{outreach.message.subject || "N/A"}</p>
          </div>
          <div className="mb-6">
            <h3 className="text-lg font-semibold">Message:</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: outreach.message.body || "N/A",
              }}
            />
          </div>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold">Attachments:</h2>
          {outreach.assets?.length > 0 ? (
            <ul className="list-unstyled">
              {outreach.assets.map((attachment, index) => (
                <li key={index} className="flex items-center mt-[10px]">
                  <a href={attachment.file_url} target="_blank">
                    {attachment.file_url}
                  </a>
                  <p> - {attachment.file_name}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No attachments</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageView;
