import React, { useState, useEffect, ReactNode } from "react";
import FSubNav from "@components/FSubNav";
import { useNavigate, useLocation } from "react-router-dom";
import Heading from "@components/Heading";

interface MultiPagedLayoutProps {
  customHeader?: ReactNode;
  pageName: string;
  fullScreen?: boolean;
  highLevel?: {
    icon: string;
    name: string;
    value: string;
  }[];
  subNavItems: {
    label: string;
    value: string;
    link: string;
    active?: boolean;
    disabled?: boolean;
    matchPattern?: string;
  }[];
  coverImageSrc?: string;
  coverImage?: boolean;
  children?: React.ReactNode;
  dashBoardItem?: React.ReactNode | boolean;
  pageOwner?: {
    icon: string;
    name: string;
    value: string;
  };
  headerButtons?: React.ReactNode;
  className?: string;
  subNavClassName?: string;
}

const MultiPageLayout = ({
  customHeader,
  pageName = "",
  fullScreen,
  highLevel,
  children,
  subNavItems = [],
  dashBoardItem,
  pageOwner,
  headerButtons,
  coverImageSrc,
  coverImage,
  className,
  subNavClassName,
}: MultiPagedLayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedNav, setSelectedNav] = useState<string>();

  useEffect(() => {
    const match = subNavItems.find((item) => {
      if (item.matchPattern) {
        return location.pathname.startsWith(item.matchPattern);
      }

      return location.pathname.includes(item.link);
    });

    if (match) {
      setSelectedNav(match.value);
    } else {
      setSelectedNav(null);
    }
  }, [location.pathname, subNavItems]);

  const handleNavChange = (newValue: string) => {
    const item = subNavItems.find((item) => item.value === newValue);
    if (item && item.link !== location.pathname) {
      navigate(item.link);
    }
    setSelectedNav(newValue);
  };

  return (
    <div className={className}>
      {customHeader || (
        <Heading
          title={pageName}
          coverImage={coverImage}
          coverImageSrc={coverImageSrc}
          highLevel={highLevel}
          dashBoardItem={dashBoardItem}
          pageOwner={pageOwner}
          headerButtons={headerButtons}
          fullScreen={fullScreen}
        />
      )}

      <div
        className={`w-full ${fullScreen ? "px-[15px] sm:px-[40px] z-10" : ""}`}
      >
        <FSubNav
          className={`${dashBoardItem || coverImage ? "pt-4" : "pt-[80px]"} ${subNavClassName || ""}`}
          items={subNavItems.map((item) => ({
            ...item,
            active: item.value === selectedNav,
          }))}
          selectedValue={selectedNav}
          onChange={handleNavChange}
        />
        <div className="pb-40">{children}</div>
      </div>
    </div>
  );
};

export default MultiPageLayout;
