import React, { useState } from "react";
import { Link } from "react-router-dom";
import FDropdown from "../../../FDropdown";
import DecorationBadge from "../../CardAssets";

import FIcon from "../../../FIcon";
import { formatLargeNumber } from "../../../../utils/number_utilities";

const ParticipantCard = ({
  decoration,
  dropdownOptions,
  cardHeight,
  cardWidth,
  decorationPath,
  className,
  onOptionSelect,
  checkBoxClickList,
  onCheckBoxClick,
  ...props
}) => {
  const [hover, setHover] = useState(false);

  // Removed mock data for platforms

  if (decorationPath) {
    decoration = props[decorationPath];
  }

  // Use platforms data from props
  const platforms = props.platforms;

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`card-parent relative h-full rounded-xl z-10 overflow-hidden ${className} ${
        checkBoxClickList && checkBoxClickList.includes(props.id)
          ? "border-2 border-black"
          : "border-2 border-light_red"
      }`}
      style={{
        minHeight: `${cardHeight}px`,
        minWidth: `${cardWidth}px`,
      }}
      key={props.id}
    >
      <Link
        to={`card/${props.influencer_id}`}
        className={`w-full h-full absolute top-0 left-0 z-10`}
        key={props.id}
      />
      <div
        className={`w-full h-full bg-cover bg-center absolute transition-all duration-500 ease-out ${
          hover ? "scale-105" : "scale-100"
        }`}
        style={{
          backgroundImage: `url(${props.profile_image})`,
          backgroundBlendMode: "overlay",
        }}
      >
        <div
          className="absolute top-0 left-0 w-full h-8"
          style={{
            background:
              "linear-gradient(to bottom, rgba(116, 116, 116, 0.5), transparent)",
          }}
        />
        <div
          className="absolute bottom-0 left-0 w-full h-8"
          style={{
            background:
              "linear-gradient(to top, rgba(116, 116, 116, 0.5), transparent)",
          }}
        />
      </div>
      <div className="absolute flex flex-row top-2 left-4 z-20">
        {dropdownOptions && (
          <FDropdown
            options={dropdownOptions}
            onChange={(value) => onOptionSelect(`${value}-${props.id}`)}
            width="auto"
            height="28"
            iconOnly={true}
            iconColor="#fff"
            icon="ellipsis"
          />
        )}
        {onCheckBoxClick && (
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              className="w-5 h-5 text-white border-light_red focus:ring-black checked:bg-black focus:ring-none cursor-pointer hover:border-black"
              checked={
                checkBoxClickList && checkBoxClickList.includes(props.id)
              }
              onChange={() => {
                onCheckBoxClick && onCheckBoxClick(props.id);
              }}
            />
          </div>
        )}
        {(props.decoration || props.status) && (
          <div className="relative">
            <DecorationBadge decoration={props.decoration || props.status} />
          </div>
        )}
      </div>
      <div
        className={
          "bottom-0 h-[68px] absolute w-full transition-all duration-200 ease-in-out bg-gradient-to-t from-[rgba(116,116,116,0.5)] to-transparent flex flex-col justify-between items-start"
        }
      >
        <div className="h-14 bg-gradient-to-t from-[rgba(116,116,116,0.5)] to-transparent relative left-0" />
        <div
          className={`w-full pt-2 bottom-0 transition-all duration-200 ease-in-out 
          bg-light_red_opacity backdrop-blur-xl flex flex-col justify-between items-start ${
            hover ? "bg-light_red bg-opacity-100" : "bg-light_red bg-opacity-80"
          }`}
        >
          <p className="pb-1 leading-6 z-20 relative left-2 text-[20px] text-blue_night_sky font-sofia-pro truncate w-[95%]">
            {props.full_name}
          </p>
          {platforms && (
            <div className="relative left-2 pb-2">
              {[...platforms]
                .filter((platform: any) => platform.followers_count > 0)
                .reduce((unique: any[], item: any) => {
                  if (
                    !unique.some((platform: any) => platform.name === item.name)
                  ) {
                    unique.push(item);
                  }
                  return unique;
                }, [])
                .sort((a: any, b: any) => b.followers_count - a.followers_count)
                .slice(0, 1)
                .map((platform: any) => (
                  <div className="flex items-center gap-2" key={platform.name}>
                    <FIcon
                      size={13}
                      icon={platform.name.toLowerCase()}
                      color="black"
                    />
                    <p className="text-sm truncate text-black whitespace-nowrap">
                      {formatLargeNumber(platform.followers_count)} followers
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ParticipantCard;
