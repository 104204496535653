import SocialConnectButton from "@pages/auth/influencer-onboarding/social/SocialConnectButton";
import React from "react";

interface YoutubeConnectButtonProps {
  isConnected: boolean;
  iconOnly: boolean;
  onClick: () => void;
}

const YoutubeConnectButton = ({
  isConnected,
  iconOnly,
  onClick,
}: YoutubeConnectButtonProps) => {
  const connectYoutube = async () => {
    try {
      // Step 1: Get the current page URL to pass as originUrl

      // Step 2: Redirect the user to Youtube's OAuth page to authorize your app
      const youtubeOAuthUrl =
        "https://www.ytstats.social/youtube-redirect?source=frontend-staging";

      window.location.href = youtubeOAuthUrl;
    } catch (error) {
      console.error("Failed to connect to YouTube:", error);
    }
  };

  return (
    <SocialConnectButton
      onClick={() => (isConnected ? onClick() : connectYoutube())}
      name="YouTube"
      icon="youtube"
      iconOnly={iconOnly}
      isConnected={isConnected}
    />
  );
};

export default YoutubeConnectButton;
