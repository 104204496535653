import EthnicityBreakdownChart from "@components/charts/demographics/EthnicityBreakdownChart";
import { ETHNICITIES } from "@constants/demographics";
import { DemographicsBreakdownItem, Participant } from "@types";
import { groupBy, isEmpty, sortBy } from "lodash";

const getEthnicityBreakdown = (
  participants: Participant[]
): DemographicsBreakdownItem[] => {
  const allEthnicities = participants.reduce((result, participant) => {
    const { ethnicity } = participant;

    let value;

    if (isEmpty(ethnicity)) {
      value = "other";
    } else if (ethnicity.length > 1) {
      value = "two_or_more";
    } else {
      [value] = ethnicity;
    }

    return [...result, { value }];
  }, []);

  const total = allEthnicities.length;

  const groupedEthnicities = groupBy(allEthnicities, "value");

  const result = ETHNICITIES.reduce((result, item) => {
    const count = groupedEthnicities[item.value]?.length;

    if (!count) {
      return result;
    }

    return [
      ...result,
      {
        name: item.label,
        count,
        percentage: Math.round((100 * count) / total),
      },
    ];
  }, []);

  return sortBy(result, "percentage").reverse();
};

interface EthnicityBreakdownProps {
  participants: Participant[];
}

const EthnicityBreakdown = ({ participants }: EthnicityBreakdownProps) => (
  <EthnicityBreakdownChart
    items={getEthnicityBreakdown(participants)}
    showCounts
  />
);

export default EthnicityBreakdown;
