const getAutoCompletionRequest = (
  input: string,
  sessionToken?: google.maps.places.AutocompleteSessionToken
): google.maps.places.AutocompletionRequest => {
  return {
    input,
    sessionToken,
  };
};

type UseFetchSuggestionsArg = {
  autoCompleteService?: google.maps.places.AutocompleteService;
  sessionToken?: google.maps.places.AutocompleteSessionToken;
};

const getSuggestions = (
  arg: UseFetchSuggestionsArg,
  value: string,
  callback: (suggestions: any[]) => void
) => {
  const { autoCompleteService, sessionToken } = arg;

  if (value.length < 2) {
    return callback([]);
  }

  autoCompleteService.getPlacePredictions(
    getAutoCompletionRequest(value, sessionToken),
    (suggestions) => {
      callback(
        (suggestions || []).map((suggestion) => ({
          label: suggestion.description,
          value: suggestion,
          place_id: suggestion.place_id,
        }))
      );
    }
  );
};

export default getSuggestions;
