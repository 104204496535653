export async function fetcher(url: string, method: string = "GET", data?: any) {
  let response: Response | null = null;

  if (["POST", "PATCH", "PUT"].indexOf(method) !== -1) {
    response = await fetch(url, {
      method: method,
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      const error = await response.json();
      throw {
        error: new Error(),
        details: error,
      };
    }
  } else {
    response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      if (method !== "DELETE") {
        const json = await response.json();
        return json;
      } else {
        return true;
      }
    } else {
      const error = await response.json();
      throw new Error(error);
    }
  }
}
