import DoughnutGraph, { DataItem } from "@components/Graphs/DoughnutGraph";
import Table from "@components/Table";
import { SponsoredContent } from "@types";

const TABLE_COLUMNS = [
  {
    key: "type",
    label: "Type",
  },
  {
    key: "engagementRate",
    label: "Engagement Rate",
    getValue: (value) =>
      value.engagementRate ? `${value.engagementRate}%` : "",
  },
];

interface SponsoredContentBreakdownProps {
  sponsored: SponsoredContent;
}

const SponsoredContentBreakdown = ({
  sponsored,
}: SponsoredContentBreakdownProps) => {
  const { organic_percentage, sponsored_percentage } = sponsored;

  const data = [
    { name: "Sponsored", percentage: sponsored_percentage },
    { name: "Organic", percentage: organic_percentage },
  ] as DataItem[];

  const tableData = [
    {
      type: "Sponsored",
      engagementRate: sponsored_percentage,
    },
    {
      type: "Organic",
      engagementRate: organic_percentage,
    },
  ];

  return (
    <div className="">
      <p className="font-sm text-default_weak mb-0.5">Sponsored Content</p>
      <p className="text-2xl">{sponsored_percentage || 0}%</p>
      <DoughnutGraph
        data={data}
        entityName="Engagement"
        colors={["#E47667", "#FFC6BF"]}
        subtitle={sponsored_percentage ? `${sponsored_percentage}%` : null}
      />
      <Table columns={TABLE_COLUMNS} data={tableData}></Table>
    </div>
  );
};

export default SponsoredContentBreakdown;
