import React from "react";
import { Outlet } from "react-router-dom";
import MultiPageLayout from "@layouts/MultiPageLayout";

const subNavItems = [
  {
    label: "Campaigns",
    value: "campaigns",
    link: "campaigns",
  },
  {
    label: "Outreach",
    value: "outreach",
    link: "outreach",
  },
  {
    label: "Bulletins",
    value: "bulletins",
    link: "bulletins",
  },
  {
    label: "Off-Platform",
    value: "offPlatform",
    link: "offPlatform",
  },
];

const CollaboratorsWrapper = () => {
  return (
    <MultiPageLayout pageName="Collaborators" subNavItems={subNavItems}>
      <div className="pt-[40px]">
        <Outlet />
      </div>
    </MultiPageLayout>
  );
};

export default CollaboratorsWrapper;
