import Table from "@components/Table";
import {
  Campaign,
  CampaignContentReview,
  ParticipantContentReview,
} from "@types";
import STATUSES from "../statuses";
import { isEmpty } from "lodash";

const getParticipantsWithNotSubmittedContent = (
  participants: ParticipantContentReview[]
) =>
  participants.reduce((result, participant) => {
    const { deliverables } = participant;

    const deliverablesWithNotSubmittedContent = deliverables.filter(
      ({ content }) => content?.status === "not_submitted"
    );

    if (isEmpty(deliverablesWithNotSubmittedContent)) {
      return result;
    }

    return [
      ...result,
      {
        ...participant,
        deliverables: deliverablesWithNotSubmittedContent,
      },
    ];
  }, []);

const TABLE_COLUMNS = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "platform",
    label: "",
    type: "platform",
  },
  {
    key: "submission_due_date",
    label: "Due date",
    isDate: true,
  },
  {
    key: "hashtags",
    label: "Hashtags",
  },
  {
    key: "mentions",
    label: "Mentions",
  },
  {
    key: "status",
    label: "Status",
    getValue: (item) => {
      const statusValue = item.content?.status || "not_submitted";

      const status = STATUSES.find(({ value }) => value === statusValue);

      return status.label;
    },
  },
  {
    key: "updated_at",
    label: "Modified",
    isDate: true,
  },
];

interface ParticipantDeliverablesProps {
  campaign: Campaign;
  contentReview: CampaignContentReview;
  showNotSubmitted?: boolean;
}

const ParticipantDeliverables = ({
  campaign,
  contentReview,
  showNotSubmitted,
}: ParticipantDeliverablesProps) => {
  const { participants } = campaign;

  if (!contentReview) {
    return null;
  }

  const { participants: contentReviewParticipants } = contentReview;

  const participantsToShow = showNotSubmitted
    ? getParticipantsWithNotSubmittedContent(contentReviewParticipants)
    : contentReviewParticipants;

  return (
    <div className="flex flex-col gap-8">
      {participantsToShow.map((participant) => {
        const { deliverables, fohr_campaigns_participant_id, id } = participant;

        const campaignParticipant = participants.find(
          ({ id }) => id === fohr_campaigns_participant_id
        );

        const {
          creator: { firstName, lastName, profile_image_url },
        } = campaignParticipant;

        const contentSubmitted = deliverables.reduce((result, deliverable) => {
          const { content } = deliverable;

          if (content?.status === "not_submitted") {
            return result;
          }

          return [...result, content];
        }, []);

        return (
          <div className="border rounded-lg" key={id}>
            <div className="border-b flex items-center p-7">
              <div className="flex items-center">
                <div
                  className={`bg-center bg-cover w-[46px] h-[46px] mr-3 rounded-full`}
                  style={{
                    backgroundImage: `url(${profile_image_url})`,
                  }}
                />
                <div>
                  <p className="text-lg">
                    {firstName} {lastName}
                  </p>
                  <p className="text-default_weak">
                    {deliverables.length} Deliverable
                    {deliverables.length === 1 ? "" : "s"} |{" "}
                    {contentSubmitted.length} Submitted
                  </p>
                </div>
              </div>
            </div>
            <div className="p-4">
              <Table columns={TABLE_COLUMNS} data={deliverables} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ParticipantDeliverables;
