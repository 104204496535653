import React, { useEffect, useState } from "react";
import { useParams, Outlet } from "react-router-dom";
import { Campaign, CampaignBrief } from "@types";
import apiClient from "@apiClient";
import BlankState from "@components/BlankState";
import LoadingSpinner from "@components/LoadingSpinner";
import MultiPageLayout from "@layouts/MultiPageLayout";
import { CampaignContext } from "@contexts/index";
import { formatDollarValue, formatLargeNumber } from "@utils/number_utilities";
import FButton from "@components/FButton";
import CampaignPageHeader from "./components/CampaignPageHeader";
import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";

const { campaigns, campaign_brief, participant_briefs } = apiClient;

export default function CampaignProfile() {
  const { campaign_id, brief_id, participant_id } = useParams<{
    campaign_id: string;
    brief_id: string;
    participant_id: string;
  }>();
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState<Campaign>(null);
  const [briefs, setBriefs] = useState<CampaignBrief[]>([]);
  const [activeBrief, setActiveBrief] = useState<CampaignBrief>(null);

  const [participantBriefs, setParticipantBriefs] = useState<any[] | null>([]);

  const { participants, participant_groups } = campaign || {};

  const { can } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const campaignResponse = await campaigns.get(campaign_id);

        const campaignBriefs = await campaign_brief.get(campaign_id);

        const participantBriefs = await participant_briefs.index({
          participant_campaign_id_eq: campaign_id,
        });

        const campaign = campaignResponse.data;

        campaign.deliverables = campaign.deliverables.map((item) => ({
          ...item,
          ...item.data,
        }));

        setCampaign(campaignResponse.data);
        setBriefs(campaignBriefs);
        setParticipantBriefs(participantBriefs);
      } catch (e) {
        console.log("E", e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [campaign_id]);

  useEffect(() => {
    let activeBrief = participant_id
      ? participantBriefs.find(
          (brief) => brief.participant_id === parseInt(participant_id, 10)
        )
      : briefs.find((brief) =>
          brief_id
            ? brief.id === parseInt(brief_id, 10)
            : brief.participant_group_id === null
        );

    const isBriefBeingCreated =
      !activeBrief &&
      brief_id &&
      (participant_groups || []).find(
        ({ id }) => id === parseInt(brief_id, 10)
      );

    const isParticipantBriefBeingLoaded = !activeBrief && participant_id;

    if (isBriefBeingCreated || isParticipantBriefBeingLoaded) {
      activeBrief = {
        loading: true,
        participant_id,
      };
    }

    setActiveBrief(activeBrief);
  }, [participant_id, participant_groups, brief_id, participantBriefs, briefs]);

  const highLevel = [
    {
      icon: "",
      name: "Brand",
      value: campaign?.brand_name,
    },
  ];

  const overviewBrief = briefs?.find(
    (brief) => brief.participant_group_id === null
  );

  const subNavItems = [
    can(Permission.VIEW_OVERVIEW) && {
      label: "Overview",
      value: "overview",
      link: `/campaigns/${campaign_id}/overview`,
    },
    {
      label: "Brief",
      value: "brief",
      link: `/campaigns/${campaign_id}/brief/${
        overviewBrief && overviewBrief.id
      }`,
      matchPattern: `/campaigns/${campaign_id}/brief`,
    },
    {
      label: "Participants",
      value: "participants",
      link: `/campaigns/${campaign_id}/participants`,
    },
    {
      label: "Content",
      value: "content",
      link: `/campaigns/${campaign_id}/content`,
    },
    {
      label: "Deliverables",
      value: "deliverables",
      link: `/campaigns/${campaign_id}/deliverables`,
    },
    {
      label: "Products",
      value: "products",
      link: `/campaigns/${campaign_id}/products`,
    },
    {
      label: "Reporting",
      value: "reporting",
      link: `/campaigns/${campaign_id}/reporting`,
    },
  ].filter(Boolean);

  if (loading || !activeBrief) {
    return <LoadingSpinner className="w-full h-[90vh]" />;
  }

  if (!campaign) {
    return (
      <BlankState
        title={"No Campaign Found"}
        subtitle={"Please try another link or reach out to support"}
        icon={"warning-2"}
        onActionClick={() => {
          window.location.href = "/";
        }}
        actionLabel={"Go Home"}
      />
    );
  }

  const campaignDetails = [
    {
      label: "Accepted",
      value: `${
        participants?.filter((p) => p.status === "accepted").length
      } / ${participants?.length}`,
    },
    {
      label: "Budget",
      value: formatDollarValue(Number(campaign?.budget)),
    },
    {
      label: "Impressions",
      value: formatLargeNumber(Number(campaign?.guaranteed_impressions)),
    },
    {
      label: "Status",
      value: campaign?.status,
    },
  ];

  const campaignDash = (
    <div className="flex items-center w-full mt-10 rounded-xl">
      <div className="flex flex-row flex-grow gap-2 w-full border-light_border">
        {campaignDetails.map((detail) => (
          <div
            key={detail.label}
            className="flex flex-col py-[4px] pl-[20px] w-full border-l border-light_border last:border-r-0"
          >
            <span className="text-[15px] text-neutral_600">{detail.label}</span>
            <span className="text-[15px] mt-[6px] font-semibold capitalize">
              {detail.value}
            </span>
          </div>
        ))}
      </div>
    </div>
  );

  const headerButtons = (
    <div className="flex flex-row gap-2">
      <FButton label="Edit" width={"w-[100px]"} height={"h-[30px]"} />
    </div>
  );

  return (
    <>
      <MultiPageLayout
        className="max-w-[1200px] m-auto pb-[100px]"
        pageName={campaign.title}
        dashBoardItem={campaignDash}
        highLevel={highLevel}
        subNavItems={subNavItems}
        headerButtons={headerButtons}
        coverImage={true}
        coverImageSrc={campaign.cover_image_url}
        customHeader={
          <CampaignPageHeader
            campaign={{ ...campaign, brief: overviewBrief }}
          />
        }
        subNavClassName="border-light_border"
      >
        <CampaignContext.Provider
          value={{
            campaign,
            briefs,
            activeBrief,
            participantBriefs,
            setActiveBrief,
            setCampaign,
            setBriefs,
            setParticipantBriefs,
          }}
        >
          <Outlet />
        </CampaignContext.Provider>
      </MultiPageLayout>
    </>
  );
}
