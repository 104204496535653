import React from "react";

interface Props {
  value: string | number;
  name: string;
  id: string;
  label: string;
  required?: boolean;
  style?: any;
  checked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

export const FRadio = ({
  value,
  name,
  label = "",
  id,
  required = false,
  onChange,
  checked = false,
  className = "",
  ...props
}: Props) => {
  return (
    <div className={className} style={props?.style}>
      <label
        htmlFor={id}
        className="cursor-pointer flex items-center  text-[14px]"
      >
        <input
          id={id}
          type="radio"
          value={value}
          name={name}
          required={required}
          className="hidden"
          onChange={onChange}
          checked={checked}
        />
        <div className="flex all-center min-w-[17px] w-[17px] h-[17px] border-[2px] border-neutral_500 rounded-full">
          <div
            className={`border-[2px] border-white rounded-full h-full w-full transition-all duration-200 ${checked ? "bg-blue" : ""}`}
          ></div>
        </div>
        <span className="ml-[8px]">{label}</span>
      </label>
    </div>
  );
};
