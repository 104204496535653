import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { BriefSection } from "@types";

const getParticipantBriefIndex = async (params: {
  participant_brief_id?: number | string;
  kind?: string;
  custom?: boolean;
  hidden?: boolean;
}): Promise<BriefSection[]> => {
  const queryParams = new URLSearchParams();

  if (params.participant_brief_id) {
    queryParams.append(
      "q[participant_brief_id_eq]",
      params.participant_brief_id.toString()
    );
  }

  if (params.kind) {
    queryParams.append("q[kind_eq]", params.kind);
  }
  if (params.custom !== undefined) {
    queryParams.append("q[custom_eq]", params.custom.toString());
  }
  if (params.hidden !== undefined) {
    queryParams.append("q[hidden_eq]", params.hidden.toString());
  }

  const response = await apiWrapper(
    `/participant_brief_sections?${queryParams.toString()}`,
    "GET"
  );

  return response.data.participant_brief_sections;
};

const getParticipantBriefSection = async (
  id: number | string
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/participant_brief_sections/${id}`, "GET");
};

const createParticipantBriefSection = async (
  params: any
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/participant_brief_sections`, "POST", params);
};

const updateParticipantBriefSection = async (
  participant_brief_section_id: number | string,
  params: any
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/participant_brief_sections/${participant_brief_section_id}`,
    "PUT",
    params
  );
};

const deleteParticipantBriefSection = async (
  participant_brief_section_id: number | string
): Promise<ApiResponse<any>> => {
  return apiWrapper(
    `/participant_brief_sections/${participant_brief_section_id}`,
    "DELETE"
  );
};

export {
  createParticipantBriefSection,
  updateParticipantBriefSection,
  deleteParticipantBriefSection,
  getParticipantBriefIndex,
  getParticipantBriefSection,
};
