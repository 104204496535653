import React, { createContext, ReactNode } from "react";
import { CampaignContent, ICampaign } from "types/types";
import { DateRange } from "@utils/dashboardUtils";

export interface CampaignMetrics {
  campaigns: number;
  engagementRate: number;
  impressions: number;
  posts: number;
  influencers: number;
}

export interface DashboardContextType {
  dateRange: DateRange;
  setDateRange: (range: DateRange) => void;
  metrics: CampaignMetrics;
  setMetrics: (metrics: CampaignMetrics) => void;
  isLoading: boolean;
  isPageLoading: boolean;
  currentBrandId: number | null;
  setCurrentBrandId: (id: number) => void;
  campaigns: ICampaign[];
  campaignContent: CampaignContent[];
  activePost: any;
  contentModalOpen: boolean;
  handleContentModalClick: (content: any) => void;
  handleContentModalClose: () => void;
  isDropdownOpen: boolean;
  setIsDropdownOpen: (isOpen: boolean) => void;
  isBrandDropdownOpen: boolean;
  setIsBrandDropdownOpen: (isOpen: boolean) => void;
  dropdownRef: React.RefObject<HTMLDivElement>;
  brandDropdownRef: React.RefObject<HTMLDivElement>;
  handleDateRangeChange: (newRange: DateRange) => void;
  handleBrandChange: (membership: any) => void;
  currentMembership: any;
  sortedMemberships: any[];
}

export const DashboardContext = createContext<DashboardContextType>(null!);

interface DashboardProviderProps {
  children: ReactNode;
  value: DashboardContextType;
}

export function DashboardProvider({ children, value }: DashboardProviderProps) {
  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
}
