const IconTick = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="11"
    viewBox="0 0 9 11"
    fill="none"
  >
    <path
      d="M4.56055 6.94653L0.23042 1.69653L8.89067 1.69653L4.56055 6.94653Z"
      fill="#000721"
    />
  </svg>
);

export default IconTick;
