import React, { useState } from "react";
import FIcon from "./FIcon";
import { ContainerProps } from "@types";

export default function Container({
  children,
  className = "",
  collapsible = false,
  title = "",
  subtitle = "",
  headerLeft,
  headerRight,
}: ContainerProps) {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className={`${className}`}>
      {collapsible ? (
        <div
          className="cursor-pointer flex items-center justify-between"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div className="flex flex-col sm:flex-row">
            <p className="font-sofia-pro text-[22px] text-dark h-[40px]">
              {title}
            </p>
            <p className=" text-[14px] text-neutral-600 sm:ml-[16px]">
              {subtitle}
            </p>
          </div>
          <div className={isExpanded ? "rotate-[180deg]" : ""}>
            <FIcon icon="chevron" size={18} color="#000F45" />
          </div>
        </div>
      ) : (
        <div className="flex justify-between items-center flex-col sm:flex-row">
          <div className="flex w-1/2 flex-col sm:flex-row items-center">
            {headerLeft ? (
              headerLeft
            ) : (
              <p className="font-sofia-pro text-[22px] text-dark h-[40px]">
                {title}
              </p>
            )}
            <p className=" text-[14px] text-neutral-600 sm:ml-[16px]">
              {subtitle}
            </p>
          </div>
          {headerRight && (
            <div className="flex items-center">{headerRight}</div>
          )}
        </div>
      )}
      {isExpanded && collapsible && children}
      {!collapsible && children}
    </div>
  );
}
