import React, { useCallback } from "react";

import { Sort } from "@types";
import { getBulletinList, deleteBulletin } from "@api/Bulletins/Bulletins";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import CreateOrUpdateBulletinModal from "@pages/bulletins/components/CreateOrUpdateBulletinModal";

const filterOptions = [
  { label: "All", static: "Bulletins", value: null },
  { label: "Active", static: "Bulletins", value: "active" },
  { label: "Featured", static: "Bulletins", value: "featured" },
  { label: "Archived", static: "Bulletins", value: "archived" },
];

const cardDropDownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Delete", value: "delete" },
];

const actions = {
  delete: async (id: number) => deleteBulletin(id),
};
const tableColumns = [
  {
    key: "name",
    label: "Title",
    isLink: true,
    linkURL: "/bulletins/{id}",
    imageKey: "image_url",
  },
  { key: "status", label: "State", isStateBubble: true },
  { nestedKey: "owner_user", key: "full_name", label: "Owner" },
  { nestedKey: "brand", key: "name", label: "Company" },
  { key: "start_date", label: "Start Date", isDate: true },
  { key: "created_at", label: "Created", isDate: true },
];

export default function BulletinsPage() {
  const getBulletins = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;
      const response = await getBulletinList(true, page, sort, {
        status_eq: filter,
        name_cont: search,
      });

      const { bulletins: items } = response.data;
      return {
        ...response.data,
        items,
      };
    },
    []
  );

  return (
    <SinglePageLayoutWithData
      entityName="bulletin"
      pageTitle="Bulletins"
      pageName="Bulletins"
      sortPathName="title"
      sortPathDate="date"
      filterOptions={filterOptions}
      filterPath="state"
      tableColumns={tableColumns}
      cardDropDownOptions={cardDropDownOptions}
      tableOnly
      rowActions={cardDropDownOptions}
      asyncActions={actions}
      getItems={getBulletins}
      updateModal={CreateOrUpdateBulletinModal}
    />
  );
}
