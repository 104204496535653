const MOCK_PERMISSIONS = {
  fohr_admin: {
    view_toggle_brands_accounts: true,
    use_search_influencers: true,
    view_all_campaigns: true,
    edit_all_campaigns: true,
    create_all_campaigns: true,
    view_overview: true,
    view_brief: true,
    edit_brief: true,
    create_brief: true,
    delete_brief: true,
    view_about: true,
    edit_about: true,
    create_sub_sections_about: true,
    delete_sub_sections_about: true,
    view_guidelines: true,
    edit_guidelines: true,
    view_creative_direction: true,
    edit_creative_direction: true,
    create_sub_sections_creative_direction: true,
    delete_sub_sections_creative_direction: true,
    view_products: true,
    edit_products: true,
    create_products: true,
    edit_products_products: true,
    delete_products: true,
    view_deliverables: true,
    edit_deliverables: true,
    create_deliverables: true,
    delete_deliverables: true,
    view_contract: true,
    edit_contract: true,
    create_contract: true,
    delete_contract: true,
    view_payment_terms: true,
    edit_payment_terms: true,
    view_participants: true,
    edit_participants: true,
    add_participants: true,
    delete_participants: true,
    view_offers: true,
    edit_offers: true,
    view_briefs: true,
    edit_briefs: true,
    view_content: true,
    edit_content: true,
    add_content: true,
    delete_content: true,
    view_groups: true,
    edit_groups: true,
    add_groups: true,
    delete_groups: true,
    view_participant_review: true,
    edit_status_participant_review: true,
    view_content_campaigns: true,
    edit_content_campaigns: true,
    add_content_campaigns: true,
    delete_content_campaigns: true,
    view_admin_review: true,
    edit_admin_review: true,
    add_admin_review: true,
    delete_admin_review: true,
    view_client_review: true,
    edit_client_review: true,
    add_client_review: true,
    delete_client_review: true,
    view_rejected_needs_action: true,
    edit_rejected_needs_action: true,
    add_rejected_needs_action: true,
    delete_rejected_needs_action: true,
    view_brand_approved: true,
    view_overall_approved: true,
    edit_overall_approved: true,
    view_comment: true,
    edit_comment: true,
    add_comment: true,
    view_feedback: true,
    edit_feedback: true,
    add_feedback: true,
    view_attribute: true,
    edit_attribute: true,
    add_attribute: true,
    view_all_deliverables: true,
    edit_all_deliverables: true,
    view_all_products: true,
    edit_all_products: true,
    view_reporting: true,
    edit_reporting: true,
    view_accepted: true,
    edit_accepted: true,
    view_applicants: true,
    edit_applicants: true,
    delete_applicants: true,
    search_content: true,
    view_questions: true,
    edit_questions: true,
    add_questions: true,
    delete_questions: true,
    view_onboarding_settings: true,
    edit_onboarding_settings: true,
    add_onboarding_settings: true,
    delete_onboarding_settings: true,
    view_bulletins: true,
    edit_bulletins: true,
    create_bulletins: true,
    view_groups_groups: true,
    edit_groups_groups: true,
    create_groups: true,
    feature_groups: true,
    archive_users_groups: true,
    view_outreach: true,
    create_outreach: true,
    edit_outreach: true,
    delete_outreach: true,
    view_reports: true,
    edit_reports: true,
    create_reports: true,
    delete_reports: true,
    view_notifications: true,
    edit_notifications: true,
    view_education_center: true,
    access_fohr_help_center: true,
    access_brand_help_center: true,
    access_influencer_help_center: true,
    view_influencer_profiles: true,
    edit_influencer_profiles: true,
    create_influencer_profiles: true,
    delete_influencer_profiles: true,
    view_brand_settings: true,
    edit_brand_settings: true,
    create_brand_settings: true,
    delete_brand_settings: true,
  },
  brand_owner: {
    view_toggle_brands_accounts: true,
    use_search_influencers: true,
    view_all_campaigns: true,
    create_all_campaigns: true,
    delete_all_campaigns: true,
    view_overview: true,
    view_brief: true,
    edit_brief: true,
    create_brief: true,
    delete_brief: true,
    view_about: true,
    edit_about: true,
    create_sub_sections_about: true,
    delete_sub_sections_about: true,
    view_guidelines: true,
    edit_guidelines: true,
    view_creative_direction: true,
    edit_creative_direction: true,
    create_sub_sections_creative_direction: true,
    delete_sub_sections_creative_direction: true,
    view_products: true,
    edit_products: true,
    create_products: true,
    edit_products_products: true,
    delete_products: true,
    view_deliverables: true,
    edit_deliverables: true,
    create_deliverables: true,
    delete_deliverables: true,
    view_contract: true,
    edit_contract: true,
    create_contract: true,
    delete_contract: true,
    view_payment_terms: true,
    edit_payment_terms: true,
    view_participants: true,
    edit_participants: true,
    add_participants: true,
    delete_participants: true,
    view_offers: true,
    edit_offers: true,
    view_briefs: true,
    edit_briefs: true,
    view_content: true,
    edit_content: true,
    add_content: true,
    delete_content: true,
    view_groups: true,
    edit_groups: true,
    add_groups: true,
    delete_groups: true,
    view_participant_review: true,
    edit_status_participant_review: true,
    view_content_campaigns: true,
    edit_content_campaigns: true,
    add_content_campaigns: true,
    delete_content_campaigns: true,
    view_admin_review: true,
    view_client_review: true,
    edit_client_review: true,
    add_client_review: true,
    delete_client_review: true,
    view_rejected_needs_action: true,
    edit_rejected_needs_action: true,
    add_rejected_needs_action: true,
    delete_rejected_needs_action: true,
    view_brand_approved: true,
    edit_brand_approved: true,
    view_overall_approved: true,
    edit_overall_approved: true,
    view_comment: true,
    edit_comment: true,
    add_comment: true,
    view_feedback: true,
    edit_feedback: true,
    add_feedback: true,
    view_attribute: true,
    edit_attribute: true,
    add_attribute: true,
    view_all_deliverables: true,
    view_all_products: true,
    edit_all_products: true,
    view_reporting: true,
    edit_reporting: true,
    view_accepted: true,
    edit_accepted: true,
    view_applicants: true,
    edit_applicants: true,
    delete_applicants: true,
    search_content: true,
    view_questions: true,
    edit_questions: true,
    add_questions: true,
    delete_questions: true,
    view_onboarding_settings: true,
    edit_onboarding_settings: true,
    add_onboarding_settings: true,
    delete_onboarding_settings: true,
    view_bulletins: true,
    edit_bulletins: true,
    create_bulletins: true,
    delete_bulletins: true,
    view_groups_groups: true,
    edit_groups_groups: true,
    create_groups: true,
    archive_users_groups: true,
    view_outreach: true,
    create_outreach: true,
    edit_outreach: true,
    delete_outreach: true,
    view_reports: true,
    edit_reports: true,
    create_reports: true,
    delete_reports: true,
    view_notifications: true,
    edit_notifications: true,
    view_education_center: true,
    access_brand_help_center: true,
    view_influencer_profiles: true,
    view_brand_settings: true,
    edit_brand_settings: true,
    create_brand_settings: true,
    delete_brand_settings: true,
  },
  brand_manager: {
    view_toggle_brands_accounts: true,
    use_search_influencers: true,
    view_all_campaigns: true,
    create_all_campaigns: true,
    delete_all_campaigns: true,
    view_overview: true,
    view_brief: true,
    edit_brief: true,
    create_brief: true,
    delete_brief: true,
    view_about: true,
    edit_about: true,
    create_sub_sections_about: true,
    delete_sub_sections_about: true,
    view_guidelines: true,
    edit_guidelines: true,
    view_creative_direction: true,
    edit_creative_direction: true,
    create_sub_sections_creative_direction: true,
    delete_sub_sections_creative_direction: true,
    view_products: true,
    edit_products: true,
    create_products: true,
    edit_products_products: true,
    delete_products: true,
    view_deliverables: true,
    edit_deliverables: true,
    create_deliverables: true,
    delete_deliverables: true,
    view_contract: true,
    edit_contract: true,
    create_contract: true,
    delete_contract: true,
    view_payment_terms: true,
    edit_payment_terms: true,
    view_participants: true,
    edit_participants: true,
    add_participants: true,
    delete_participants: true,
    view_offers: true,
    edit_offers: true,
    view_briefs: true,
    edit_briefs: true,
    view_content: true,
    edit_content: true,
    add_content: true,
    delete_content: true,
    view_groups: true,
    edit_groups: true,
    add_groups: true,
    delete_groups: true,
    view_participant_review: true,
    edit_status_participant_review: true,
    view_content_campaigns: true,
    edit_content_campaigns: true,
    add_content_campaigns: true,
    delete_content_campaigns: true,
    view_admin_review: true,
    view_client_review: true,
    edit_client_review: true,
    add_client_review: true,
    delete_client_review: true,
    view_rejected_needs_action: true,
    edit_rejected_needs_action: true,
    add_rejected_needs_action: true,
    delete_rejected_needs_action: true,
    view_brand_approved: true,
    edit_brand_approved: true,
    view_overall_approved: true,
    edit_overall_approved: true,
    view_comment: true,
    edit_comment: true,
    add_comment: true,
    view_feedback: true,
    edit_feedback: true,
    add_feedback: true,
    view_attribute: true,
    edit_attribute: true,
    add_attribute: true,
    view_all_deliverables: true,
    view_all_products: true,
    edit_all_products: true,
    view_reporting: true,
    edit_reporting: true,
    view_accepted: true,
    edit_accepted: true,
    view_applicants: true,
    edit_applicants: true,
    delete_applicants: true,
    search_content: true,
    view_questions: true,
    edit_questions: true,
    add_questions: true,
    delete_questions: true,
    view_onboarding_settings: true,
    edit_onboarding_settings: true,
    add_onboarding_settings: true,
    delete_onboarding_settings: true,
    view_bulletins: true,
    edit_bulletins: true,
    create_bulletins: true,
    delete_bulletins: true,
    view_groups_groups: true,
    edit_groups_groups: true,
    create_groups: true,
    archive_users_groups: true,
    view_outreach: true,
    create_outreach: true,
    edit_outreach: true,
    delete_outreach: true,
    view_reports: true,
    edit_reports: true,
    create_reports: true,
    delete_reports: true,
    view_notifications: true,
    edit_notifications: true,
    view_education_center: true,
    access_brand_help_center: true,
    view_influencer_profiles: true,
  },
  brand_user: {
    view_toggle_brands_accounts: true,
    use_search_influencers: true,
    view_all_campaigns: true,
    create_all_campaigns: true,
    view_overview: true,
    view_brief: true,
    edit_brief: true,
    create_brief: true,
    view_about: true,
    edit_about: true,
    create_sub_sections_about: true,
    delete_sub_sections_about: true,
    view_guidelines: true,
    edit_guidelines: true,
    view_creative_direction: true,
    edit_creative_direction: true,
    create_sub_sections_creative_direction: true,
    delete_sub_sections_creative_direction: true,
    view_products: true,
    edit_products: true,
    create_products: true,
    edit_products_products: true,
    delete_products: true,
    view_deliverables: true,
    edit_deliverables: true,
    create_deliverables: true,
    delete_deliverables: true,
    view_contract: true,
    edit_contract: true,
    create_contract: true,
    delete_contract: true,
    view_payment_terms: true,
    edit_payment_terms: true,
    view_participants: true,
    edit_participants: true,
    add_participants: true,
    delete_participants: true,
    view_offers: true,
    edit_offers: true,
    view_briefs: true,
    edit_briefs: true,
    view_content: true,
    edit_content: true,
    add_content: true,
    delete_content: true,
    view_groups: true,
    edit_groups: true,
    add_groups: true,
    delete_groups: true,
    view_participant_review: true,
    edit_status_participant_review: true,
    view_content_campaigns: true,
    edit_content_campaigns: true,
    add_content_campaigns: true,
    delete_content_campaigns: true,
    view_admin_review: true,
    view_client_review: true,
    edit_client_review: true,
    add_client_review: true,
    delete_client_review: true,
    view_rejected_needs_action: true,
    edit_rejected_needs_action: true,
    add_rejected_needs_action: true,
    delete_rejected_needs_action: true,
    view_brand_approved: true,
    edit_brand_approved: true,
    view_overall_approved: true,
    edit_overall_approved: true,
    view_comment: true,
    edit_comment: true,
    add_comment: true,
    view_feedback: true,
    edit_feedback: true,
    add_feedback: true,
    view_attribute: true,
    edit_attribute: true,
    add_attribute: true,
    view_all_deliverables: true,
    view_all_products: true,
    edit_all_products: true,
    view_reporting: true,
    edit_reporting: true,
    view_accepted: true,
    edit_accepted: true,
    view_applicants: true,
    delete_applicants: true,
    search_content: true,
    view_questions: true,
    edit_questions: true,
    add_questions: true,
    delete_questions: true,
    view_onboarding_settings: true,
    edit_onboarding_settings: true,
    view_bulletins: true,
    edit_bulletins: true,
    view_groups_groups: true,
    edit_groups_groups: true,
    create_groups: true,
    archive_users_groups: true,
    view_reports: true,
    edit_reports: true,
    create_reports: true,
    delete_reports: true,
    view_notifications: true,
    edit_notifications: true,
    access_influencer_help_center: true,
    edit_influencer_profiles: true,
    create_influencer_profiles: true,
    delete_influencer_profiles: true,
  },
  influencer: {
    view_brief: true,
    view_about: true,
    view_guidelines: true,
    view_creative_direction: true,
    view_products: true,
    view_deliverables: true,
    edit_deliverables: true,
    create_deliverables: true,
    delete_deliverables: true,
    view_contract: true,
    view_payment_terms: true,
    view_offers: true,
    view_briefs: true,
    view_content: true,
    view_content_campaigns: true,
    add_content_campaigns: true,
    view_rejected_needs_action: true,
    edit_rejected_needs_action: true,
    add_rejected_needs_action: true,
    view_brand_approved: true,
    view_overall_approved: true,
    view_feedback: true,
    view_attribute: true,
    view_all_deliverables: true,
    view_onboarding_settings: true,
    edit_onboarding_settings: true,
    view_bulletins: true,
    edit_bulletins: true,
    view_notifications: true,
    edit_notifications: true,
    view_education_center: true,
    access_influencer_help_center: true,
    view_influencer_profiles: true,
    edit_influencer_profiles: true,
    create_influencer_profiles: true,
    delete_influencer_profiles: true,
    view_brand_settings: true,
    edit_brand_settings: true,
    create_brand_settings: true,
    delete_brand_settings: true,
  },
  influencer_rep: {
    view_toggle_brands_accounts: true,
    view_brief: true,
    view_about: true,
    view_guidelines: true,
    view_creative_direction: true,
    view_products: true,
    view_deliverables: true,
    view_contract: true,
    view_payment_terms: true,
    view_offers: true,
    view_briefs: true,
    view_content: true,
    view_content_campaigns: true,
    add_content_campaigns: true,
    view_rejected_needs_action: true,
    edit_rejected_needs_action: true,
    add_rejected_needs_action: true,
    view_brand_approved: true,
    view_overall_approved: true,
    view_feedback: true,
    view_attribute: true,
    view_all_deliverables: true,
    view_all_products: true,
    view_onboarding_settings: true,
    edit_onboarding_settings: true,
    view_bulletins: true,
    edit_bulletins: true,
    view_notifications: true,
    edit_notifications: true,
  },
  open_links: {
    view_participant_review: true,
    view_influencer_profiles: true,
  },
};

export default MOCK_PERMISSIONS;
