import React, { useRef, useState } from "react";
import FButton from "@components/FButton";
import { FInput } from "@components/FInputs";

import { forgotPassword } from "@api/user";
import FIcon from "@components/FIcon";
import { Link } from "react-router-dom";
import { Captcha, CaptchaError, CaptchaInstance } from "@components/Captcha";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const captchaRef = useRef<CaptchaInstance>();

  const [waiting, setWaiting] = useState(false);

  return (
    <div>
      {success ? (
        <div className="flex flex-col items-center text-center">
          <div className="bg-light_beige_100 h-[64px] flex items-center justify-center mb-8 w-[64px]">
            <FIcon icon="email" color="#000721" size={24} />
          </div>
          <p className="text-3xl mb-4">Email on the way!</p>
          <p>
            We sent you password reset instructions. If it doesn’t show up check
            your spam folder.
          </p>
        </div>
      ) : (
        <>
          <p className="text-3xl mb-4 text-center">Forgot your password?</p>
          <p className="mb-12 text-center">
            All good. Enter your account’s email address and we’ll send you a
            link to reset your password.
          </p>
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              setWaiting(true);

              try {
                const token = captchaRef.current?.getToken();
                await forgotPassword(email, token);
                setSuccess(true);
              } catch (e) {
                if (e instanceof CaptchaError) {
                  setError(e.message);
                } else {
                  captchaRef.current?.reset();
                  setError(e.response.data.message);
                }
              } finally {
                setWaiting(false);
              }
            }}
          >
            <FInput
              className="mb-4"
              label="Your Email Address"
              required
              width={"100%"}
              value={email}
              onChange={(value) => setEmail(value)}
            />
            <Captcha
              ref={captchaRef}
              className="mb-4"
              action="reset-password"
            />
            <FButton
              className="mb-8"
              primary
              label="Send reset link"
              loading={waiting}
              width="100%"
              type="submit"
            />
          </form>
          {error ? <p className={"mt-[16px] text-red"}>{error}</p> : null}
        </>
      )}
      <div className="flex items-center justify-center mt-[40px]">
        <Link className="flex items-center gap-0.5" to="/login">
          <FIcon color="#000721" icon="back" size={21} />
          Return to login
        </Link>
      </div>
    </div>
  );
}
