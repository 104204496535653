import React, { useState } from "react";

import toast from "react-hot-toast";

import ConfirmationModal from "@components/Modals/ConfirmModal";
import RichTextEditor from "@components/RichTextEditor";
import FInput from "@components/FInputs/FInput";
import FIcon from "@components/FIcon";

import apiClient from "@apiClient";
import { useParams } from "react-router-dom";
import { CampaignBrief } from "@types";

const { campaign_brief_sections, participant_brief_sections } = apiClient;

export interface BriefPageTemplateProps {
  brief: CampaignBrief;
  onUpdate: (updates: Partial<CampaignBrief>) => void;
  pageTitle: string;
  pageDescription: string;
  children?: React.ReactNode;
  pageTitleCMSName?: string;
  customSectionName?: string;
  actionRight?: React.ReactNode;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const BriefPageTemplate = ({
  brief,
  onUpdate,
  pageTitle,
  pageDescription,
  children,
  pageTitleCMSName,
  customSectionName,
  actionRight,
  containerRef,
}: BriefPageTemplateProps) => {
  const [customSectionToDelete, setCustomSectionToDelete] =
    useState<number>(null);

  const { participant_id } = useParams();

  const { sections = [] } = brief;

  const section = sections.find(({ kind }) => kind === pageTitleCMSName);

  const customSections = sections.filter(
    ({ custom, kind }) => custom && kind === customSectionName
  );

  const handleRemoveCustomSection = async (id: number) => {
    const isParticipant = !!participant_id;
    const sectionApi = isParticipant
      ? participant_brief_sections
      : campaign_brief_sections;

    try {
      await sectionApi.delete(id);

      const newSections = sections.filter((section) => section.id !== id);

      onUpdate({ sections: newSections });
    } catch (error) {
      console.error("Error deleting section:", error);
      toast.error("An error occurred while deleting the section");
    }

    setCustomSectionToDelete(null);
  };

  const handleUpdateSection = (sectionId, updates) => {
    const newSections = sections.map((section) =>
      section.id === sectionId ? { ...section, ...updates } : section
    );

    onUpdate({ sections: newSections });
  };

  const addCustomSection = async (isParticipant = false) => {
    try {
      const sectionApi = isParticipant
        ? participant_brief_sections
        : campaign_brief_sections;
      const response = await sectionApi.create({
        [isParticipant ? "participant_brief_id" : "campaign_brief_id"]:
          brief.id,
        kind: customSectionName,
        position: 1,
        custom: true,
        title: "",
      });

      const newSection = response.data;

      const newSections = [...brief.sections, newSection];

      onUpdate({ sections: newSections });
    } catch (error) {
      console.error("Error creating new section:", error);
    }
  };

  return (
    <>
      <div ref={containerRef}>
        <div className="flex flex-col items-start pb-10 w-full">
          <div className="flex flex-row justify-between w-full">
            <div className="flex flex-col w-fit gap-2 items-start ">
              <h2 className="text-2xl font-semibold">{pageTitle}</h2>
              <p className="text-[15px] text-neutral_600">{pageDescription}</p>
            </div>
            {actionRight && (
              <div className="flex flex-row gap-4 w-fit">{actionRight}</div>
            )}
          </div>
          {pageTitleCMSName && (
            <div className="w-full mt-10 bg-white">
              <RichTextEditor
                id={pageTitleCMSName}
                value={section?.body || ""}
                onChange={(value) => {
                  handleUpdateSection(section.id, { body: value });
                }}
              />
            </div>
          )}
        </div>
        <div>{children}</div>
        {customSections.length > 0 && (
          <div className={`w-full  ${customSections.length > 0 ? "mb-8" : ""}`}>
            <div className="mb-10 pt-10 mt-4 border-t border-light_border">
              <h3 className="text-xl mb-2">Custom Sections</h3>
              <p className="text-neutral_600 text-[15px]">
                These sections will appear in the {pageTitle} section of your
                brief
              </p>
            </div>
            {customSections.map((section) => (
              <div
                className="w-full mt-8 pt-8 first:pt-0 first:border-t-0"
                key={section.id}
              >
                <div className="flex flex-row justify-between items-center gap-2">
                  <FInput
                    value={section.title}
                    onChange={(value) =>
                      handleUpdateSection(section.id, { title: value })
                    }
                    placeholder="Section Title"
                    label="Title"
                    required
                    className="mb-7 "
                    width={"100%"}
                    helperAction={{
                      text: "Delete",
                      onClick: () => {
                        setCustomSectionToDelete(section.id);
                      },
                    }}
                  />
                </div>
                <label className="block font-medium text-black text-sm mb-[6px]">
                  Body <span className="text-red">*</span>
                </label>
                <div className="w-full bg-white">
                  <RichTextEditor
                    id={`${section.id}`}
                    value={section.body || ""}
                    onChange={(value) => {
                      handleUpdateSection(section.id, { body: value });
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {customSectionName && (
          <div className="w-full">
            <button
              type="button"
              className="w-full h-full bg-[rgba(255,255,255,0.5)] hover:bg-white flex flex-row items-center justify-center gap-4 border border-neutral_400 py-4 cursor-pointer hover:border-neutral_600"
              onClick={(e) => {
                e.preventDefault();
                participant_id ? addCustomSection(true) : addCustomSection();
              }}
            >
              <FIcon icon="plus" size={12} />
              <p className="font-medium text-[15px]">Add Custom Section</p>
            </button>
          </div>
        )}
      </div>
      <ConfirmationModal
        title="Delete Section"
        subtitle="Are you sure you want to delete this section?"
        onAction={() => {
          handleRemoveCustomSection(customSectionToDelete);
        }}
        isOpen={!!customSectionToDelete}
        onClose={() => {
          setCustomSectionToDelete(null);
        }}
        actionLabel="Delete"
      />
    </>
  );
};

export default BriefPageTemplate;
